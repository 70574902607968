<template>
    <div id="groupedList">
        <ul class="list">
            <li
                v-for="(vendor, index) in datasource"
                :key="index"
            >
                <groupSelect
                    :datasource="vendor"
                    :tabindex="index"
                    :rememberChoice="rememberChoice"
                >
                    <template v-slot:select-list="vendorData">
                        <selectList
                            v-if="Object.keys(dsSelectedServices).length"
                            :auths="vendorData.auths"
                            :scAuths="feat"
                            :listKey="vendorData.vendName"
                            :dsSelectedKeys="dsSelectedServices[vendorData.vendName]"
                            @updateSelection="updateSelection($event)"
                        ></selectList>
                    </template>

                </groupSelect>
            </li>
        </ul>
        <div class="reload-button" @click="restoreSelection"><span>Очистить выбор</span></div>
    </div>
</template>

<script>
import groupSelect from './__groupSelect';
import selectList from './__selectList';
import ruMessages from "devextreme/localization/messages/ru.json";
import {locale, loadMessages, formatMessage} from 'devextreme/localization';
import localeService from '@/services/locale.service.js';

export default {
    name: "groupedList",
    props: ["feat", "datasource", "selectedServiceKeys"],
    components: {groupSelect, selectList},
    data() {
        return {
            open: false,
            dsSelectedServices: {},
            locale: null,
            locales: localeService.getLocales(),
            rememberChoice: {}
        }
    },
    created() {
        this.locale = this.getLocale();
        this.initMessages();
        locale(this.locale);
    },
    methods: {
        getLocale() {
            const locale = sessionStorage.getItem('locale');
            return locale != null ? locale : 'ru';
        },
        setLocale(locale) {
            sessionStorage.setItem('locale', locale);
        },
        initMessages() {
            loadMessages(ruMessages);
            loadMessages(localeService.getDictionary());
        },
        changeLocale(e) {
            this.setLocale(e.value);
            document.location.reload();
        },
        formatMessage: formatMessage,
        updateSelection(params) {
            this.dsSelectedServices[params.key] = params.data;
            this.rememberChoice = params
        },
        restoreSelection() {
            Object.keys(this.dsSelectedServices).forEach((dsKey) => {
                this.dsSelectedServices[dsKey] = [];
            })
            this.rememberChoice.data = []
        }
    },
    watch: {
        dsSelectedServices: {
            deep: true,
            handler() {
                const selectedKeys = [].concat
                    .apply([], Object.keys(this.dsSelectedServices)
                        .map(key => this.dsSelectedServices[key]))
                    .filter((value, index, self) => self.indexOf(value) === index);

                this.$emit("servicesChanged", selectedKeys)
            }
        }
    },
    mounted() {
        const entries = this.datasource.map((vendor) => [vendor.name, []]);
        this.dsSelectedServices = Object.fromEntries(entries);
    }
}
</script>

<style lang="scss" scoped>

#groupedList {
    padding: 20px 0 20px 0;

    @media screen and (max-width: 320px) {
        padding: 20px 0 50px 0;
    }

    .list {
        display: block;
    }

    & > ul {
        list-style: none;
        padding: 0;
    }

    .reload-button {
        cursor: pointer;
        color: #777;
        padding: 0 20px;
        width: 140px;
        font-size: 12px;
        float: right;
        text-transform: uppercase;
        background: url("/img/icons/clean.png") no-repeat;
        background-size: 15px 15px;
    }
}
</style>