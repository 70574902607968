const advantages = [
    {
        id: 0,
        title: 'гарантийное обслуживание',
        img: '/img/advantages-svg/warranty-service.svg',
        component: 'WarrantyService',
        icon: 'warrantyService',
        open: false,
        classActive: false
    },
    {
        id: 1,
        title: 'оригинальные запчасти',
        img: '/img/advantages-svg/original-spare-parts.svg',
        component: 'OriginalSpareParts',
        icon: 'originalSpareparts',
        open: false,
        classActive: false
    },
    {
        id: 2,
        title: 'сертифицированные инженеры',
        img: '/img/advantages-svg/sertificate-engineers.svg',
        component: 'CertifiedEngineers',
        icon: 'sertificateEngineers',
        open: false,
        classActive: false
    },
    {
        id: 3,
        title: 'контроль качества',
        img: '/img/advantages-svg/quanity-control.svg',
        component: 'QualityControl',
        icon: 'quanityControl',
        open: false,
        classActive: false
    },
    {
        id: 4,
        title: 'официальные статусы от производителя',
        img: '/img/advantages-svg/status.svg',
        component: 'ManufacturerStatuses',
        icon: 'status',
        open: false,
        classActive: false
    },
];

export {advantages};