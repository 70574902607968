import { render, staticRenderFns } from "./serviceGuarantee2020.vue?vue&type=template&id=06d31b00&scoped=true&"
import script from "./serviceGuarantee2020.vue?vue&type=script&lang=js&"
export * from "./serviceGuarantee2020.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d31b00",
  null
  
)

export default component.exports