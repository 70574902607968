<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      ГАРАНТИЙНЫЙ РЕМОНТ
      ОБОРУДОВАНИЯ HP INC.
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Сеть сервисных центров Rus.Support
      является официальным сервисным партнером HP Inc. в России
    </p>
  </div>
</template>

<script>
export default {
  name: "hpGuaranteeSliderContent"
}
</script>

<style lang="scss" scoped>

</style>