<template>
  <div class="row py-5">

    <div class="caption">
      <img class="caption__logo" src="/img/logo/hp-logo.png" alt="">
      <p class="caption__title">
        МЫ ВЫПОЛНЯЕМ ГАРАНТИЙНЫЙ РЕМОНТ <br> СЛЕДУЮЩЕЙ ТЕХНИКИ НР INC.
      </p>
    </div>
    <!-- /.caption -->

    <div class="col-md-6 pb-4">
      <div class="service-title">
        КОМПЬЮТЕРЫ, НОУТБУКИ, МОНОБЛОКИ <br>
        И ПЛАНШЕТЫ НР СЛЕДУЮЩИХ ЛИНЕЕК
      </div>
      <div class="service-item">
        <span class="service-item__text">Вычислительное оборудование домашних серий</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Вычислительное оборудование коммерческих серий</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Тонкие клиенты</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">РОS-терминалы для точек продаж</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Мониторы для дома и игр</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Мониторы офисных (коммерческих) серий</span>
        <span class="service-item__icon"></span>
      </div>
    </div>
    <!-- /.SERVICE-ITEM BLOCK -->

    <div class="col-md-6 pb-4">
      <div class="service-title">
        ПРИНТЕРЫ, МФУ, СKAHEPЫ <br>
        И ПЛОТТЕРЫ НР СЛЕДУЮЩИХ ЛИНЕЕК
      </div>
      <div class="service-item">
        <span class="service-item__text">Печатное оборудование домашних серий</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Печатное оборудование коммерческих серий</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Широкоформатные устройства печати НР Design Jet</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Широкоформатные устройства печати НР PageWide XL</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Широкоформатные устройства печати НР Latex</span>
        <span class="service-item__icon"></span>
      </div>
    </div>
    <!-- /.SERVICE-ITEM BLOCK -->

    <div class="b-shadow">
      <img class="b-shadow__icon" src="/img/hp-guarantee_icon01.png" alt="">
      <div class="b-shadow__content">
        <h2 class="b-shadow__title">ГАРАНТИЯ ПРОИЗВОДИТЕЛЯ</h2>
        <p class="b-shadow__text">
          Гарантия производителя служит защитой клиента от неисправностей по причине наличия заводских дефектов в
          оборудовании или преждевременного выхода узлов и компонентов из строя. В течение срока действия гарантии
          клиент
          имеет право на бесплатное устранение неисправности в соответствии с сервисной политикой производителя.
        </p>
      </div>
    </div>
    <!--  /.B-SHADOW BLOCK  -->

    <div class="b-shadow">
      <img class="b-shadow__icon" src="/img/hp-guarantee_icon02.png" alt="">
      <div class="b-shadow__content">
        <h2 class="b-shadow__title">ВЫЕЗДНАЯ ГАРАНТИЯ</h2>
        <p class="b-shadow__text">
          На ряд оборудования HP Inc действует выездная гарантия. Это значит, что выезд инженера к месту расположения
          устройства клиенту оплачивать не нужно. Бесплатное выездное обслуживание по заводской гарантии HP Inc.
          осуществляется на расстоянии до 160 км от сервисного центра.
          Для расширенной гарантии – до 250 км от сервисного центра
        </p>
      </div>
    </div>
    <!--  /.B-SHADOW BLOCK  -->

    <div class="col-md-9">

      <!--  LIST BLOCK  -->
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Если гарантийные обязательства производителя не включают выездное обслуживание, вы можете доставить
            оборудование в ближайший сервисный центр самостоятельно или вызвать нашего курьера
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Уточнить актуальный статус гарантийных обязательств на ваше устройство можно на
            <a class="link" href="https://support.hp.com/ru-ru/checkwarranty">официальном сайте HP
              Inc.</a>
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Если вы затрудняетесь найти продуктовый и серийный номер вашего устройства — воспользуйтесь
            <a class="link" href="http://rus.support/about/instruction">инструкцией</a>
          </p>
        </div>
      </div>
      <!--  /.LIST BLOCK  -->

    </div>

    <div class="col-md-3">

      <div class="note">
        <span class="note__square"></span>
        <p class="note__text">
          Пожалуйста, <b>уточняйте заранее</b> возможность обслуживания нужной линейки оборудования в нужном вам
          <a class="note__text_link" href=""><b>населенном пункте</b></a>
        </p>
      </div>
      <!-- /.NOTE BLOCK -->
    </div>

  </div>

</template>

<script>
export default {
  name: "TypeGuaranty"
}
</script>

<style lang="scss" scoped>
// Стили повторяющихся блоков: /styles/common/blocks.scss
</style>
