<template>
  <div class="row py-5">
    <p class="paragraph">
      Компания Hewlett Packard Enterprise — американская ИТ-компания, созданная в 2015 году после разделения корпорации
      Hewlett-Packard. Унаследовала бизнес в сегменте оборудования для ЦОД — производит серверы, суперкомпьютеры,
      системы и сети хранения данных, сетевое оборудование, конвергетные системы, а также занимается построением
      облачных инфраструктур.
    </p>
    <p class="paragraph">
      Получив все наработки, модельный ряд и долю рынка HP в перечисленных сегментах, компания HPE несколько
      дистанцировалась от HP, создав новый бренд, логотип, фирменный стиль и новое доменное имя официального сайта —
      hpe.com. Среди переданных в состав новой корпорации HPE активов поглощённые ранее HP производители аппаратного
      обеспечения такие как: Tandem Computers, DEC, Compaq, 3Com , 3PAR и другие.
    </p>
    <p class="paragraph">
      Уже став отдельной компанией HPE продолжил политику поглощения компаний для расширения портфеля своих решений и
      приобрёл таких производителей оборудования как Silicon Graphics International (SGI), SimpliVity, Nimble Storage,
      Cray и других. По итогам 2018 года HPE признан лидером по поставкам серверов на российский рынок. В мире компания
      является признанным лидеров в области IT.
    </p>
    <p class="paragraph">
      Официальный сайт: <a class="link" href="">www.hpe.com</a>
      Мы официально обслуживаем оборудование производства компании HP (Hewlett Packard Enterprise) в РФ с 2005 года.
    </p>

    <ul class="list-link">
      <li class="list-link__item"></li>
      <li class="list-link__item" v-for="(service, index) in serviceList" :key="index">
        <router-link class="list-link__link" :to="service.pathLink">
          {{ service.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HpeContent",
  data() {
    return {
      serviceList: [
        {
          name: 'ГАРАНТИЙНЫЙ РЕМОНТ',
          pathLink: '/about/hpe_guarantee'
        },
        {
          name: 'УСЛУГИ НРЕ PACKAGED SERVICE',
          pathLink: '/about/hpe_packaged'
        },
        {
          name: 'СЕРВИСНЫЕ КОНТРАКТЫ НА ОБОРУДОВАНИЕ HPE',
          pathLink: '/about/hpe_contracts'
        },
        {
          name: 'ПОСТГАРАНТИЙНОЕ ОБСЛУЖИВАНИЕ',
          pathLink: '/about/hpe_postguarantee'
        },
        {
          name: 'ИНСТАЛЛЯЦИЯ, МОДЕРНИЗАЦИЯ И РЕЛОКАЦИЯ ОБОРУДОВАНИЯ',
          pathLink: '/about/hpe_upgrade'
        },
        {
          name: 'ПРОАКТИВНЫЙ АНАЛИЗ ОБОРУДОВАНИЯ',
          pathLink: '/about/hpe_proactive'
        },
        {
          name: 'ПОСТАВКИ ОРИГИНАЛЬНЫХ ЗАПЧАСТЕЙ',
          pathLink: '/about/hpe_spareparts'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.paragraph {
  text-align: left;
}

.list-link {
  max-width: calc(100% / 12 * 6);

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
</style>