<template>
  <div>
    <CarouselSlider

        :carousel_data="carouselImages"

    />
  </div>
</template>

<script>

import CarouselSlider from "@/pages/About/we-do/OthersCourses/carousel/carousel-slider"

export default {
  name: "carousel-root",
  components: {
    CarouselSlider
  },
  data() {
    return {
      carouselImages: [
        {id: 1, name: 'img-1', img: 'IMG_20181010_102407.jpg'},
        {id: 2, name: 'img-2', img: 'IMG_20181010_102428.jpg'},
        {id: 3, name: 'img-3', img: 'IMG_20181010_102441.jpg'},
        {id: 4, name: 'img-4', img: 'IMG_20181010_102458.jpg'},
        {id: 5, name: 'img-5', img: 'IMG_20190821_174318.jpg'},
        {id: 6, name: 'img-6', img: 'IMG_20190821_174517.jpg'},
        {id: 7, name: 'img-7', img: 'IMG_20190821_174835.jpg'},
      ]
    }
  },
}
</script>

<style scoped>

</style>