<template>
  <div class="error">
    <div class="container">
      <div class="error_img">
        <div class="error_logo">
          <img src="/img/images/error_logo.png" alt="">
        </div>
        <div class="error_404">
          <img src="/img/images/error_404.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "error",
  created() {
    const layout = {
      name: "Main",
    };
    this.$store.commit('setLayout', layout)
  },
}
</script>

<style lang="scss" scoped>
.error {
  min-height: 600px;
  background: url("/img/images/error_bg.jpg") no-repeat center center;
  background-size: cover;

  .error_img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 600px;

    .error_logo {
      padding: 20px;
    }

    .error_404 {
      padding: 20px;
    }
  }
}
</style>