<template>
  <div class="row py-5">

    <div class="caption">
      <p class="caption__title">
        ФОРМЫ <br>
        <span class="title">
          ПРЕДОСТАВЛЕНИЯ УСЛУГИ
        </span>
      </p>
    </div>
    <!-- /.caption -->

    <div class="col-md-6">
      <div class="b-shadow">
        <img class="b-shadow__icon" src="/img/icons/hp-postguarantee_icon01.png" alt="">
        <div class="b-shadow__content">
          <h2 class="b-shadow__title">ПОЗАЯВОЧНО</h2>
          <p class="b-shadow__text">
            Работы ведутся по каждому отдельному обращению
          </p>
        </div>
      </div>
    </div>
    <!--  /.B-SHADOW BLOCK  -->

    <div class="col-md-6">
      <div class="b-shadow">
        <img class="b-shadow__icon" src="/img/icons/hp-postguarantee_icon02.png" alt="">
        <div class="b-shadow__content">
          <h2 class="b-shadow__title">РАМОЧНЫЙ КОНТРАКТ</h2>
          <p class="b-shadow__text">
            Условия обслуживания и оплаты зафиксированы в договоре на выбранный вами срок
          </p>
        </div>
      </div>
    </div>
    <!--  /.B-SHADOW BLOCK  -->

    <div class="col-md-8">

      <!--  LIST BLOCK  -->
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Как официальный сервисный центр, мы несем одинаковую ответственность за качество работ как по гарантийному,
            так и по негарантийному обслуживанию.
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Мы используем оригинальные запасные части и расходные материалы
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Наши специалисты прошли обучение и получили сертификаты, необходимые для обслуживания оборудования
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Гарантия на выполненные работы – <span class="link">1 год</span> <br>
            На запасные замененные части – <span class="link">от 90 дней и более</span>
          </p>
        </div>
      </div>
      <!--  /.LIST BLOCK  -->

      <p>Мы дорожим своим именем и не обманываем своих клиентов</p>

    </div>
    <div class="col-md-4">

      <div class="note">
        <span class="note__square"></span>
        <p class="note__text">
          Пожалуйста, <b>уточняйте заранее</b> возможность обслуживания нужной линейки оборудования в нужном вам <b>населенном
          пункте</b>
        </p>
      </div>
      <!-- /.NOTE -->

      <div class="note-white">
        <span class="note__square"></span>
        <span class="note__title">
          Обратите внимание
        </span>
        <p class="note__text">
          Мы гарантируем качество негарантийного (платного) обслуживания только в ряде брендированных сервисных центров.
          Такие сервисные центры выделены на карте особым значком:
        </p>
        <div class="note__img">
          <img src="/img/icons/hp-spareparts_sign.png" alt="">
        </div>
        <p>
          Остальные сервисные центры могут использовать собственные каналы поставки запчастей.
        </p>
      </div>
      <!-- /.NOTE-WHITE BLOCK -->

    </div>
  </div>
</template>

<script>
export default {
  name: "HpePostGuaranteeContent"
}
</script>

<style lang="scss" scoped>
// Стили повторяющихся блоков: /styles/common/blocks.scss
.title {
  text-align: center;
  font-size: 1em;
}

.note-white {
  max-width: 100%;
}
</style>
