<template>
    <div class="nearby">
        <div class="container">
            <p class="title">МЫ ВСЕГДА РЯДОМ</p>
            <p class="paragraph">
                Не отказывайте технике в грамотном и качественном обслуживании в официальном сервисном <br>
                центре. Найдите ближайший к вам здесь.
            </p>
            <div class="button">
                <router-link to="/#how-find">
                    <button class="button-search">
                        Найти
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Nearby",

}
</script>

<style lang="scss" scoped>
.nearby {
    background: linear-gradient(90deg, #2d34b6 0, #2daf86 100%, #06b0aa 100%);
    padding: 5rem 0;

    .title {
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
        font-size: 2em;
    }

    .paragraph {
        color: #FFFFFF;
        text-align: center;
        font-size: 1rem;
    }

    .button {
        @media screen and (max-width: 1200px) {
            text-align: center;
        }

        .button-search {
            background: none;
            border: 3px solid #FFFFFF;
            color: #FFFFFF;
            border-radius: 50px;
            width: 250px;
            padding: 1rem 0;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 600;
            outline: none;
            margin-top: 2rem;

            &:hover {
                background: #7db82d;
            }
        }
    }
}
</style>
