<template>
  <div class="row py-5">
    <p class="paragraph">
      Компания Hewlett-Packard (HP) была основана в 1939 году в городе Пало-Альто (США, штат Калифорния). Это место
      стало местом рождения знаменитой Силиконовой долины. За эти более чем 80 лет компания HP стала одной из крупнейших
      технологических компаний в мире.
    </p>
    <p class="paragraph">
      В 2015 году компания выделила серверный бизнес в отдельную компанию, а сама сменила название на HP Inc. Основной
      профиль деятельности HP Inc. сейчас — это производство персонального и печатного оборудования следующих видов:
    </p>

    <div class="list">
      <div class="list__icon"></div>
      <div class="list__content">
        <p class="list__content_text">
          Ноутбуки (включая нетбуки, ультрабуки и т.д.)
        </p>
      </div>
    </div>

    <div class="list">
      <div class="list__icon"></div>
      <div class="list__content">
        <p class="list__content_text">
          Компьютеры (десктопы, системные блоки)
        </p>
      </div>
    </div>

    <div class="list">
      <div class="list__icon"></div>
      <div class="list__content">
        <p class="list__content_text">
          Моноблоки
        </p>
      </div>
    </div>

    <div class="list">
      <div class="list__icon"></div>
      <div class="list__content">
        <p class="list__content_text">
          Мониторы
        </p>
      </div>
    </div>

    <div class="list">
      <div class="list__icon"></div>
      <div class="list__content">
        <p class="list__content_text">
          Планшеты
        </p>
      </div>
    </div>

    <div class="list">
      <div class="list__icon"></div>
      <div class="list__content">
        <p class="list__content_text">
          Принтеры
        </p>
      </div>
    </div>

    <div class="list">
      <div class="list__icon"></div>
      <div class="list__content">
        <p class="list__content_text">
          МФУ
        </p>
      </div>
    </div>

    <div class="list">
      <div class="list__icon"></div>
      <div class="list__content">
        <p class="list__content_text">
          Плоттеры и широкоформатные принтеры
        </p>
      </div>
    </div>

    <div class="list">
      <div class="list__icon"></div>
      <div class="list__content">
        <p class="list__content_text">
          Сканеры
        </p>
      </div>
    </div>

    <div class="list">
      <div class="list__icon"></div>
      <div class="list__content">
        <p class="list__content_text">
          Расходные материалы для печати
        </p>
      </div>
    </div>

    <div class="list">
      <div class="list__icon last"></div>
      <div class="list__content">
        <p class="list__content_text">
          Расходные материалы для печати
        </p>
      </div>
    </div>

    <p class="paragraph">
      В 2016 году HP Inc. поглотил подразделение корпорации Samsung, занимающееся производством принтеров и копиров. С
      тех пор HP обеспечивает продажи и сервис принтеров под торговой маркой Samsung.
    </p>
    <p class="paragraph">
      Официальный сайт: <a target="_blank" class="link" href="https://www8.hp.com/ru/ru/home.html">www.hp.com</a>
      Мы официально обслуживаем оборудование производства компании HP (HP Inc.) в РФ с 2005 года.
    </p>

    <ul class="list-link">
      <li class="list-link__item"></li>
      <li class="list-link__item" v-for="(service, index) in serviceList" :key="index">
        <router-link class="list-link__link" :to="service.pathLink">
          {{ service.name }}
        </router-link>
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  name: "HpContent",
  data() {
    return {
      serviceList: [
        {
          name: 'ГАРАНТИЙНЫЙ РЕМОНТ',
          pathLink: '/about/hp_guarantee'
        },
        {
          name: 'УСЛУГИ HP CARE PACK',
          pathLink: '/about/hp_carepack'
        },
        {
          name: 'СЕРВИСНЫЕ КОНТРАКТЫ НА ОБОРУДОВАНИЕ HP INC.',
          pathLink: '/about/hp_contracts'
        },
        {
          name: 'ПОСТГАРАНТИЙНОЕ ОБСЛУЖИВАНИЕ',
          pathLink: '/about/hp_postguarantee'
        },
        {
          name: 'СЕРВИСЫ ДЛЯ ШИРОКОФОРМАТНЫХ УСТРОЙСТВ HP DESIGNJET И PAGEWIDE XL',
          pathLink: '/about/hp_widescreen'
        },
        {
          name: 'АУТСОРСИНГ ПРОЦЕССОВ ПЕЧАТИ И ПОКОПИЙНАЯ ПЕЧАТЬ (MPS)',
          pathLink: '/about/hp_outsourceprint'
        },
        {
          name: 'РАБОЧЕЕ МЕСТО «КАК СЕРВИС» (DAAS)',
          pathLink: '/about/hp_daas'
        },
        {
          name: 'ИНСТАЛЛЯЦИЯ И МОДЕРНИЗАЦИЯ ОБОРУДОВАНИЯ',
          pathLink: '/about/hp_upgrade'
        },
        {
          name: 'ПОСТАВКИ ОРИГИНАЛЬНЫХ ЗАПЧАСТЕЙ',
          pathLink: '/about/hp_spareparts'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.paragraph {
  text-align: left;
}

.list-link {
  max-width: calc(100% / 12 * 6);

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
</style>