<template>
        <div class="static-block">
            <div :class="customClass" class="fb" :style="blockStyle">
                <a class="fb_link" v-bind:href="setHref" @click="switchAction">
                    <div class="fb_link_sign" :style="signStyle" align="center">
                        <span v-if="!icon" style="">{{sign}}</span>
                        <img v-if="icon" :src="icon" width="30">
                    </div>
                    <span class="fb_link_content" :style="linkStyle">
                        {{caption}}
                    </span>
                </a>
            </div>
        </div>
</template>
<script>
export default {
  props: {
      link: {
        type: String,
        required: false,
      },
      customClass: {
        type: String,
        required: false
      },
      size: {
        type: [String, Number, Object],
        required: true
      },
      icon: {
        type: String,
        required: false
      },
      sign: {
        type: String,
        required: false
      },
      caption: {
        type: String,
        required: false
      },
      applySwitchAction: {
        type: Boolean,
        required: false,
        default: true,
      },
      formType: {
        type: String,
        required: false
      }
    },
  computed: {
    linkStyle () {
       const styles = {
         padding:(this.size*10) + "px " + (this.size*60) + "px " + (this.size*10) + "px " + (this.size*80) + "px",
         maxWidth:(this.size*300) + "px",
         border: (this.size*3) + "px solid #ffffff",
         "font-size": this.size + "em",
         "letter-spacing": (this.size/7) + "em"
       };

      return this.jsonToCss(styles)
    },

    blockStyle () {
      const styles = {
        width:(this.size*300) + "px",
      };

      return this.jsonToCss(styles)
    },

    imgStyle () {
      const styles = {
        width:(this.size*40) + "px",
        height:(this.size*40) + "px",
        left: (this.size*15) + "px",
        top: "calc(50% - "+(this.size*20)+"px)",
        border: (this.size*3) + "px solid #ffffff",
      };

      return this.jsonToCss(styles)
    },

    signStyle () {
      const styles = {
        width:(this.size*40) + "px",
        height:(this.size*40) + "px",
        left: (this.size*15) + "px",
        top: "calc(50% - "+(this.size*20)+"px)",
        border: (this.size*3) + "px solid #ffffff",
        "font-size": (this.size*2) + "em"
      };

      return this.jsonToCss(styles)
    },
    setHref() {
    	if(this.formType.substr(0,1) === '/') {
    	    // console.log(this.formType)
    		return this.formType;
    	}
    }
  },
  methods: {
    jsonToCss(json) {
      return Object.keys(json).map(function(key) {
        return key + ":" + json[key]
      }).join(";")
    },
    switchAction() {
        if(this.formType === 'custom-click'){
            this.$emit("customClick", this.selected);
        }else{
            if(this.formType.substr(0,1) === '/') return;
            if (!this.applySwitchAction || !this.formType) return;

            const action = this.$store.state.feedbackMode ? "off" : "on";
            this.$store.commit('switchFeedbackMode', action)
            this.$store.commit('setModalFormType', this.formType)
        }
    }
  }
}
</script>
<style lang="scss" scoped>
    .fb {
        font-size: 1rem;
        padding:10px 0;
        //blockStyle
        width: 300px;

        &_link {
            position: relative;
            display: block;
            text-align: center;
            font-weight: bolder;
            text-decoration: none;
            color: #f1f1f1;
            z-index: 2;
            cursor: pointer;

            // signStyle
            .fb_link_sign {
              width: 70px;
              height: 40px;
              left: 15px;
              top: calc(50% - 20px);
              border: 3px solid #ffffff;
              font-size: 2em;
            }

            //
            .fb_link_content {

              padding: 10px 60px 10px 80px;
              max-width: 300px;
              border: 3px solid #ffffff;

              display: inline-block;
              top:0;
              text-align: left;
              position: relative;
              border-radius: 100px;
              line-height: 1.2 !important;
              font-weight: bolder !important;
              z-index: 0;
            }

            /*span {

                display: inline-block;
                top:0;
                text-align: left;
                position: relative;
                border-radius: 100px;
                line-height: 1.2 !important;
                font-weight: bolder !important;
                z-index: 0;

            }*/

            &:hover {
                background-color: #7db82d;
                border-color: #7db82d;
                cursor: pointer;
                border-radius: 100px;
            }

            img {
                position: absolute;
                border-radius: 1000px;
                z-index: 3;
            }

            &_sign {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
                z-index: 1;
                vertical-align: center;
                padding:1px;

                span {
                    vertical-align: middle;
                }
            }

            &:hover .fb_link_sign {
                background-color: inherit;
            }
        }
    }
    .blue{
        .fb_link{
            background-color: #0171bb;
            border-color: #0171bb;
            border-radius: 100px;
            &:hover {
                background-color: #7db82d;
                border-color: #7db82d;
                cursor: pointer;
                border-radius: 100px;
            }
        }

    }
</style>