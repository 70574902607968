<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      АУТСОРСИНГ
      ИТ-УСЛУГ
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Аутсорсинг позволяет частично или полностью передать обязанности по поддержке, обслуживанию и модернизации вашей
      ИТ-инфраструктуры нам.
    </p>
  </div>
</template>

<script>
export default {
  name: "othersOutsourceSliderContent"
}
</script>

<style scoped>

</style>