<template>
    <div class="container">
        <div class="row py-5">
            <div class="title">
                О СЕРВИСНОЙ СЕТИ <br>
                <b>RUS.SUPPORT</b>
            </div>
            <div class="col-md-6">
                <p>
                    Наш сервис был создан в 2004 году. 15 января 2005 года мы подписали наш первый сервисный договор с
                    HP и
                    стали авторизованным сервисным партнёром компании HP в России. C того момента мы непрерывно
                    оказываем
                    официальный сервис и гарантийное обслуживание для оборудования HP и являемся одним из ключевых
                    сервисных
                    партнёров HP в России.
                </p>
                <p>
                    8 октября 2014 года мы зарегистрировали доменное имя rus.support и с тех пор начали активное
                    продвижение
                    сервисной сети под этим именем. 25 февраля 2016 мы подали заявку на регистрацию товарного знака
                    Rus.Support. На данный момент под маркой Rus.Support работают около ста сервисных центров.
                </p>
                <p>
                    1 ноября 2015 года после разделения HP на две отдельных компании (HP Inc. и Hewlett Packard
                    Enterprise)
                    Rus.Support получил статус официального сервисного партнёра обоих производителей. Сегодня мы
                    являемся
                    крупнейшим сервисным партнёром по персональным и печатным устройствам HP Inc., по серверам и
                    системам
                    хранения данных производства Hewlett Packard Enterprise и по сетевым устройствам Aruba Networks
                    (дочерней компании HPE).
                </p>
                <p>
                    Кроме этого, мы производим гарантийное сервисное обслуживание печатной техники Samsung и источников
                    бесперебойного питания APC by Schneider Electric.
                </p>
            </div>
            <div class="col-md-6">
                <figure>
                    <img class="img-fluid" src="/img/about/official_delegation_hp.png" alt="">
                    <figcaption class="figure-caption">
                        Официальная делегация Hewlett-Packard в Сервисном центре <br> в Санкт-Петербурге, 2015
                    </figcaption>
                </figure>
            </div>
        </div>
    </div>
</template>

<script>

export default {}

</script>

<style lang="scss" scoped>
.title {
    margin-bottom: 1rem;
}

.figure-caption {
    padding: 1rem 0;
    text-align: center;
}
</style>
