<template>
    <div class="privacy-accordion__item-content">
        <!--    <p>4.1. Меры по обеспечению безопасности персональных данных при их обработке.</p>

            <p>
              4.1.1. Оператор при обработке персональных данных обязан принимать необходимые правовые, организационные и
              технические меры или обеспечивать их принятие для защиты персональных данных от неправомерного или случайного
              доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных
              данных, а также от иных неправомерных действий в отношении персональных данных.
            </p>

            <p>4.1.2. Обеспечение безопасности персональных данных достигается, в частности:</p>

            <ul class="content-list">
              <li>определением угроз безопасности персональных данных при их обработке в информационных системах персональных
                данных;
              </li>
              <li>применением организационных и технических мер по обеспечению безопасности персональных данных при их обработке
                в
                информационных системах персональных данных, необходимых для выполнения требований к защите персональных данных;
              </li>
              <li>учетом машинных носителей персональных данных;</li>
              <li>обнаружением фактов несанкционированного доступа к персональным данным и принятием мер;</li>
              <li>восстановлением персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа
                к
                ним;
              </li>
              <li>установлением правил доступа к персональным данным, обрабатываемым в информационной системе персональных
                данных, а
                также обеспечением регистрации и учета всех действий, совершаемых с персональными данными в информационной
                системе
                персональных данных;
              </li>
              <li>контролем за принимаемыми мерами по обеспечению безопасности персональных данных и уровня защищенности
                информационных систем персональных данных;
              </li>
              <li>назначением лица, ответственного за обработку персональных данных;
                установлением индивидуальных паролей доступа сотрудников в информационную систему в соответствии с их
                должностными
                обязанностями;
              </li>
              <li>использованием сертифицированного антивирусного программного обеспечения;</li>
              <li>обучением работников Оператора, непосредственно осуществляющих обработку персональных данных, положениям
                законодательства РФ о персональных данных, в том числе требованиям к защите персональных данных.
              </li>
            </ul>

            4.2. Защищаемые сведения о субъекте персональных данных.

            К защищаемым сведениям о субъекте персональных данных на Сайте относятся данные, позволяющие идентифицировать
            субъект персональных данных и/или получить о нем дополнительные сведения, предусмотренные законодательством и
            Положением.

            4.3. Защищаемые объекты персональных данных.

            <p>4.3.1. К защищаемым объектам персональных данных на Сайте относятся:</p>

            <ul class="content-list">
              <li>объекты информатизации и технические средства автоматизированной обработки информации, содержащей персональные
                данные;
              </li>
              <li>информационные ресурсы (базы данных, файлы и др.), содержащие информацию об информационно-телекоммуникационных
                системах, в которых циркулируют персональные данные, о событиях, произошедших с управляемыми объектами, о планах
                обеспечения бесперебойной работы и процедурах перехода к управлению в аварийных режимах;
              </li>
              <li>каналы связи, которые используются для передачи персональных данных в виде информативных электрических
                сигналов и
                физических полей;
              </li>
              <li>отчуждаемые носители информации на магнитной, магнитно-оптической и иной основе, применяемые для обработки
                персональных данных.
              </li>
            </ul>


            <p>
              4.3.2. Технологическая информация об информационных системах и элементах системы защиты персональных данных,
              подлежащая защите, включает:
            </p>

            <ul class="content-list">
              <li>сведения о системе управления доступом на объекты информатизации, на которых осуществляется обработка
                персональных
                данных;
              </li>
              <li>управляющая информация (конфигурационные файлы, таблицы маршрутизации, настройки системы защиты и пр.);
                технологическая информация средств доступа к системам управления (аутентификационная информация, ключи и
                атрибуты
                доступа и др.);
              </li>
              <li>характеристики каналов связи, которые используются для передачи персональных данных в виде информативных
                электрических сигналов и физических полей;
              </li>
              <li>информация о средствах защиты персональных данных, их составе и структуре, принципах и технических решениях
                защиты;
              </li>
              <li>служебные данные (метаданные) появляющиеся при работе программного обеспечения, сообщений и протоколов
                межсетевого
                взаимодействия, в результате обработки персональных данных.
              </li>
            </ul>


            <p>
              4.4. Требования к системе защиты персональных данных.
            </p>
            <p>
              Система защиты персональных данных должна соответствовать требованиям постановления Правительства РФ от 1 ноября
              2012 г. № 1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах
              персональных данных».
            </p>

            <p>4.4.1. Система защиты персональных данных должна обеспечивать:</p>
            <ul class="content-list">
              <li>своевременное обнаружение и предотвращение несанкционированного доступа к персональным данным и (или) передачи
                их
                лицам, не имеющим права доступа к такой информации;
              </li>
              <li>недопущение воздействия на технические средства автоматизированной обработки персональных данных, в результате
                которого может быть нарушено их функционирование;
              </li>
              <li>возможность незамедлительного восстановления персональных данных, модифицированных или уничтоженных вследствие
                несанкционированного доступа к ним;
              </li>
              <li>постоянный контроль за обеспечением уровня защищенности персональных данных.</li>
            </ul>
            <p>4.5. Ответственность.</p>
            <p>
              4.5.1. Все сотрудники Оператора, осуществляющие обработку персональных данных, обязаны хранить тайну о сведениях,
              содержащих персональные данные, в соответствии с Положением, требованиями законодательства РФ.
            </p>
            <p>
              4.5.2. Лица, виновные в нарушении требований Положения, несут предусмотренную законодательством РФ
              ответственность.
            </p>
            <p>
              4.5.3. Ответственность за соблюдение режима персональных данных по отношению к персональным данным, находящимся в
              базах данных Сайта, несут ответственные за обработку персональных данных.
            </p>-->

        <h2>4. Система защиты персональных данных</h2>
        <p>
            4.1. Для обеспечения защиты персональных данных Пользователя при их обработке приняты следующие правовые,
            организационные и технические меры от несанкционированного, неправомерного или случайного доступа к
            персональным данным, уничтожения, изменения, блокирования, копирования, предоставления, распространения
            персональных данных, а также от иных неправомерных действий в отношении персональных данных:
        </p>
        <p>
            - определение угроз безопасности персональных данных при их обработке в информационных системах персональных
            данных;
            <br>
            - применение организационных и технических мер по обеспечению безопасности персональных данных при их
            обработке в информационных системах персональных данных, необходимых для выполнения требований к защите
            персональных данных;
            <br>
            - учет машинных носителей персональных данных; <br>
            - обнаружение фактов несанкционированного доступа к персональным данным и принятием мер; <br>
            - восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного
            доступа к ним;
            <br>
            - установление правил доступа к персональным данным, обрабатываемым в информационной системе персональных
            данных, а также обеспечение регистрации и учета всех действий, совершаемых с персональными данными в
            информационной системе персональных данных;
            <br>
            - контроль за принимаемыми мерами по обеспечению безопасности персональных данных и
            уровня защищенности информационных систем персональных данных; <br>
            - использование сертифицированного антивирусного программного обеспечения; <br>
            - обучение работников Оператора, непосредственно осуществляющих обработку персональных данных, положениям
            законодательства РФ о персональных данных, в том числе требованиям к защите персональных данных.
            <br>
        </p>
        <p>
            4.2. Блокирование персональных данных, относящихся к соответствующему Пользователю, осуществляется с момента
            обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите
            прав
            субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или
            неправомерных действий.
        </p>
    </div>
</template>

<script>
export default {
    name: "termsOfService"
}
</script>

<style lang="scss" scoped>
.content-list {
    list-style: none;
}

.content-list > li {
    &::before {
        content: "- ";
    }
}
</style>