<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      ОБСЛУЖИВАНИЕ СЕТЕВОГО
      ОБОРУДОВАНИЯ (ARUBA PBS)
    </h2>
  </div>
</template>

<script>
export default {
  name: "arubaServiceSliderContent"
}
</script>

<style scoped>

</style>