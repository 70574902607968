<template>
  <div class="terms-accordion__item-content">

    <p>7.1. СЦ хранит принятое оборудование Заказчика безвозмездно в течение 45 календарных дней с момента завершения
      выполнения сервисных услуг (с момента окончания ремонта, или окончания диагностики, в тех случаях, когда Заказчик
      отказался от выполнения ремонта или не принял решения по его продолжению). Заказчик должен забрать оборудование в
      течение данного срока. При невыполнении этого требования, с Заказчика взимается плата за хранение в размере 50
      (пятьдесят) рублей 00 копеек за каждый день хранения свыше указанного срока. Выдача оборудования в этом случае
      происходит только после уплаты в СЦ всей суммы оказанных сервисных услуг и использованных запчастей и материалов,
      и платы за хранение оборудования свыше указанного срока.</p>
    <p>7.2. При неисполнении Заказчиком своей обязанности получить оборудование, переданное в СЦ для сервисного
      обслуживания в течение 3 месяцев после завершения выполнения сервисных услуг, в том числе и при уклонении от
      получения оборудования, СЦ имеет право в установленном законом порядке самостоятельно продать невостребованное
      оборудования по цене, сложившейся в месте хранения, с учётом состояния оборудования, либо утилизировать
      оборудование, если оно не исправно и его реализация и использование по назначению невозможны. Сумма, вырученная от
      продажи оборудования, передается Заказчику по его требованию, за вычетом сумм, причитающихся СЦ, в том числе платы
      за хранение оборудования более 45 дней и расходов на его продажу или утилизацию.</p>

  </div>
</template>

<script>
export default {
  name: "termsOfIssue2017"
}
</script>

<style scoped>

</style>