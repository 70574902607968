<template>
  <div class="terms-accordion__item-content">
    <p>1.1. Настоящие Условия (Договор) заключается между Обществом с ограниченной ответственностью «Рус.Саппорт»,
      именуемым в дальнейшем «Исполнитель», и юридическим или физическим лицом, именуемым в дальнейшем «Заказчик», на
      оказание услуг, выполнение работ поименованных в настоящем Договоре (далее – Услуги). Настоящий договор не
      является публичной офертой.</p>
    <p>1.2 Акцептом (безусловным принятием) условий настоящего договора (моментом заключения Договора) в соответствии со
      статьей 438 ГК РФ считается факт выполнения Заказчиком любого из следующих действий:</p>
    <p>1.2.1. оплата Заказчиком Услуг, предоставляемых Исполнителем в соответствии с условиями настоящего Договора;</p>
    <p>1.2.2. передача Заказчиком оборудования Исполнителю и подписание Заказчиком квитанции Исполнителя о приеме
      оборудования для сервисного обслуживания (сохранной расписки).</p>
    <p>1.3. Осуществляя акцепт в порядке, определенном п.1.2 настоящего Договора, Заказчик соглашается со всеми
      условиями Договора в том виде, в каком они изложены в тексте настоящего Договора, и в соответствии с Гражданским
      Кодексом Российской Федерации рассматривается как лицо, вступившее с Исполнителем в договорные отношения.</p>
    <p>1.4. Договор вступает в силу с момента акцепта оферты Заказчиком и действует:</p>
    <p>1.4.1. до момента исполнения Сторонами обязательств по Договору, а именно оплаты Заказчиком стоимости Услуг и
      оказания Исполнителем Услуг в объеме, соответствующем стоимости оплаченных Услуг, либо</p>
    <p>1.4.2. до момента расторжения Договора.</p>
    <p>1.5. Заключая настоящий Договор, Заказчик понимает значение своих действий и способен руководить ими, не
      находится под влиянием заблуждения, обмана, насилия, угрозы. Договор заключен Заказчиком добровольно, с
      предварительным полным ознакомлением с условиями настоящего Договора, содержание которых Заказчику понятно. Если
      Заказчик не имел соответствующих полномочий в момент заключения договора от имени юридического лица, то он, как
      физическое лицо, принимает на себя всю ответственность за исполнение данного договора.</p>
    <p>1.6. Исполнитель вправе в любое время изменить условия настоящего Договораили отозвать оферту до момента ее
      акцепта. В случае изменения Исполнителем условий настоящего Договора, изменения вступают в силу с момента
      размещения измененных условий в сети Интернет по адресу <a href="https://rus.support/terms">https://rus.support/terms</a>,
      если иной срок не указан Исполнителем при таком размещении. Заказчик соглашается и признает, что внесение
      изменений в настоящий Договор влечет за собой внесение этих изменений в заключенный в соответствии с п.1.2 и
      действующий между Заказчиком и Исполнителем договор, если иное прямо не предусмотрено Исполнителем.</p>
    <p>1.7. Договор может быть расторгнут:</p>
    <p>1.7.1. по инициативе Заказчика, в любое время, при условии отсутствия задолженности перед Исполнителем по
      оказанным по Договору Услугам;</p>
    <p>1.7.2. по инициативе любой из Сторон в случае нарушения другой Стороной условий Договора и своих обязательств,
      при условии уведомления об этом другой Стороны;</p>
    <p>1.7.3. по соглашению Сторон;</p>
    <p>1.7.4. по иным основаниям, предусмотренным настоящим Договором и/или законодательством РФ.</p>
  </div>
</template>

<script>
export default {
  name: "generalProvisions2017"
}
</script>