<template>
  <div class="terms-accordion__item-content">

    <p>
      7.1. Заказчик имеет возможность по согласованию с Исполнителем передать в СЦ или получить из СЦ оборудование
      посредством курьерской доставки. Под курьерской доставкой Стороны понимают передачу оборудования, как курьерами
      (сотрудниками) Сторон, так и курьерами привлеченных третьих лиц (курьерской службы/транспортной компании и т.п.).
    </p>
    <p>
      7.2. Курьерская доставка осуществляется только с предварительного согласия Исполнителя. Исполнитель имеет право по
      своему усмотрению отказать в приеме/выдаче оборудования посредством курьерской доставки, в том числе, но не
      ограничиваясь, в случаями, когда транспортировка несет в себе значительные риски повреждения оборудования, или
      диагностика/ремонт оборудования вне места обычной эксплуатации значительно затрудняет проведение сервисного
      обслуживания или вовсе делает его невозможным.
    </p>
    <p>
      7.3. Оплата
    </p>
    <p>
      7.3.1. В случае если осуществление доставки оборудования до/из СЦ не предусмотрено условиями гарантийных
      обязательств производителя, действующих на оборудование, или если оборудование или заявленная неисправность не
      подпадают под действие гарантийных обязательств производителя, то доставка осуществляется на платной основе за
      счет Заказчика.
    </p>
    <p>
      7.3.2. Исполнитель вправе предложить Заказчику услуги по организации курьерской доставки. В случае, если доставка
      осуществляется на платной основе, и Заказчик принимает предложение Исполнителя, то Заказчик обязан на условиях
      100% предоплаты оплатить стоимость доставки, в этом случае организация доставки до момента получения оплаты не
      производится. Иной порядок оплаты может быть согласован Сторонами отдельно.
    </p>
    <p>
      7.3.3. Заказчик праве самостоятельно и за свой счет организовать курьерскую доставку оборудования с учетом п.7.2
      настоящего Договора.
    </p>
    <p>
      7.4. Обязанности и ответственность Заказчика
    </p>
    <p>
      7.4.1. Заказчик несет полную ответственность за надлежащую подготовку оборудования к доставке. Заказчик обязан
      передать оборудование к перевозке и доставке в упаковке, соответствующей характеру оборудования, условиям
      перевозки, доставки и продолжительности пути, исключающей возможность повреждения оборудования при его обработке,
      перевозке и доставке, доступа к оборудованию без нарушения упаковки, порчи других отправлений и причинения
      какого-либо вреда работникам службы доставки или работникам Исполнителя. В случае несоблюдения данного условия
      риски повреждения оборудования несет Заказчик.
    </p>
    <p>
      7.4.2. Заказчик обязуется указывать в накладной доставки, а также сообщать Исполнителю полные и достоверные данные
      о фактическом отправителе и получателе (ФИО представителя и его должность), реквизиты доверенности уполномоченного
      представителя, а также адреса отправителя и получателя, в том числе почтовые индексы, номера контактных телефонов.
    </p>
    <p>
      7.4.3. Заказчик обязуется указывать в накладной доставки полное и достоверное описание вложения отправления и в
      обязательном порядке оформлять, хранить и предоставлять по первому требованию опись вложения, подписанную при
      отправке совместно с курьером, содержащую информацию о наименовании товарного вложения, его количестве, характере
      и свойствах.
    </p>
    <p>
      7.4.4. В случае если Заказчик отменил доставку и/или уклонился от передачи/получения оборудования в ранее
      оговоренные для доставки дату и время, то Исполнитель вправе удержать оплату за доставку в полном или частичном
      объеме по усмотрению Исполнителя (в зависимости от суммы понесенных Исполнителем расходов). Изменение даты и/или
      времени доставки Заказчиком возможно только по согласованию с Исполнителем.
    </p>
    <p>
      7.4.5. По окончании работ Заказчик обязан после получения оборудования из доставки подписать вложенные в
      отправление документы о проведенном сервисном обслуживании и не позднее следующего рабочего дня с момента их
      получения отправить подписанные документы Исполнителю. Если Заказчик не обнаружит в отправлении документов, то
      Заказчик обязан незамедлительно (в день получения оборудования) сообщить об этом Исполнителю с целью получения
      документов и дальнейшего их подписания.
    </p>
    <p>
      7.5. Обязанности и ответственность Исполнителя
    </p>
    <p>
      7.5.1. Исполнитель не несет ответственности за утрату, повреждение оборудования Заказчика (его части) и иных
      вложений отправления и просрочку сроков доставки в случаях:
    </p>
    <p>
      1) если такая утрата, повреждение (полностью или в части) или нарушение сроков доставки произошли вследствие
      обстоятельств непреодолимой силы, некачественной упаковки или свойств оборудования, иного вложения отправления, о
      которых Исполнителю не было известно;
    </p>
    <p>
      2) оборудование принято и выдано с соблюдением всех требований и при этом отсутствуют повреждения его наружной
      упаковки, а также в случае, если факт пропажи либо повреждения (полностью или в части) был установлен после
      вручения оборудования получателю;
    </p>
    <p>
      3) если неисполнение или ненадлежащее исполнение доставки произошли по вине Заказчика (в том числе из-за указания
      неполной и/или недостоверной информации, необходимой для доставки);
    </p>
    <p>
      4) оборудование или часть его задержана или изъята в порядке, установленном законодательством РФ.
    </p>
    <p>
      7.5.2. Исполнитель обязан сразу же после распаковки оборудования Заказчика, полученного из доставки от Заказчика,
      провести осмотр внешнего состояния оборудования, проверку комплектности отправки (без проверки наличия
      комплектующих, устанавливаемых в самом оборудовании) и зафиксировать результаты осмотра и проверки в системе учета
      заявок Исполнителя. Стороны признают, что любые обнаруженные таким образом недостатки или некомплектность возникли
      до передачи оборудования Исполнителю, и Исполнитель не несет за них ответственности.
    </p>
    <p>
      7.6. Размер ответственности Исполнителя
    </p>
    <p>
      7.6.1. При организации Исполнителем доставки силами третьих лиц ответственность Исполнителя перед Заказчиком в
      любом случае ограничена размерами ответственности третьих лиц перед Исполнителем. Исполнитель предоставляет
      Заказчику возмещение убытков в той же сумме в какой осуществляется возмещение третьими лицами и не несет никакой
      ответственности сверх этого.
    </p>
    <p>
      7.6.2. Исполнитель ни при каких обстоятельствах не несет ответственности и не возмещает косвенные убытки
      Заказчика, а также его упущенную выгоду.
    </p>

  </div>
</template>

<script>
export default {
  name: "termsOfIssue2021"
}
</script>

<style scoped>

</style>