<template>
  <div class="container py-4 equipment-isxl">
    <h1>Инсталляция устройств HP PageWide XL и DesignJet 3600XL</h1>
    <p>
      Сервис по установке и первоначальной настройке широкоформатных устройств HP PageWide XL и DesignJet 3600XL.
    </p>
    <img class="isxl_image" src="/img/certificate/ISXL.png" alt="">
    <p>
      <b>Обслуживаемое оборудование: </b>
    </p>
    <table class="table table-bordered table-isxl-service-equipment">
      <tbody>
      <tr>
        <td>HP PageWide XL</td>
      </tr>
      <tr>
        <td>DesignJet 3600XL</td>
      </tr>
      <tr>
        <td>Folder</td>
      </tr>
      </tbody>
    </table>
    <p>
      <b>География обслуживания:</b> Москва, Санкт-Петербург, иные города России.
    </p>
    <p>
      <b>Уровень сервиса:</b> дата и время проведения установки подлежат согласованию. Начало работ по установке - в
      течение 10 рабочих дней с момента подтверждения готовности оборудования и помещения к проведению работ.
    </p>
    <b>Состав сервиса:</b>
    <ul>
      <li>Предоставление информации по подготовке помещения и оборудования к установке</li>
      <li>Удаленный контроль готовности помещения и оборудования к установке</li>
      <li>Выезд квалифицированного специалиста</li>
      <li>Проверка комплектности оборудования</li>
      <li>Распаковка оборудования</li>
      <li>Демонтаж транспортировочных креплений, приведение в рабочее положение</li>
      <li>Подключение сетевых кабелей и кабелей питания</li>
      <li>Включение и инициализация устройства</li>
      <li>Базовая настройка и проверка работоспособности оборудования</li>
      <li>Проведение тренинга оператора</li>
    </ul>
    <p>
      <b>Сервисные пакеты:</b>
    </p>
    <table class="table table-bordered table-isxl-service-pack">
      <tbody>
      <tr>
        <th scope="row"></th>
        <td>Варианты</td>
        <td>
          Для адресов установки оборудования в Москве и Санкт-Петербурге
        </td>
        <td>
          Для адресов установки оборудования в иных городах
        </td>
      </tr>
      <tr>
        <th scope="row">1</th>
        <td colspan="1">Инсталляция HP PageWide XL</td>
        <td>ISXLPW-1</td>
        <td>ISXLPW-2</td>
      </tr>
      <tr>
        <th scope="row">2</th>
        <td colspan="1">Инсталляция HP DesignJet 3600XL</td>
        <td>ISXLDJ-1</td>
        <td>ISXLDJ-2</td>
      </tr>
      <tr>
        <th scope="row">3</th>
        <td>Инсталляция фальцовщика F40/F60/F70</td>
        <td>ISXLF-1</td>
        <td>ISXLF-2</td>
      </tr>
      <tr>
        <th scope="row">4</th>
        <td>Инсталляция одновременно HP PageWide XL и фальцовщика F40/F60/F70</td>
        <td>ISXLPWF-1</td>
        <td>ISXLPWF-2</td>
      </tr>
      <tr>
        <th scope="row">5</th>
        <td>Инсталляция одновременно HP DesignJet 3600XL и фальцовщика F40/F60/F70</td>
        <td>ISXLDJF-1</td>
        <td>ISXLDJF-2</td>
      </tr>
      <tr>
        <th scope="row">6</th>
        <td>Установка дополнительного лотка (drawer) *</td>
        <td>ISXLODR-1
        </td>
        <td>ISXLODR-2
        </td>
      </tr>
      <tr>
        <th scope="row">7</th>
        <td>Установка верхнего укладчика (top stacker) *
        </td>
        <td>ISXLOTS-1
        </td>
        <td>ISXLOTS-1
        </td>
      </tr>
      <tr>
        <th scope="row">8</th>
        <td>Установка SmartStream или SmartTracker **
        </td>
        <td>ISXLOSS-1
        </td>
        <td>
          ISXLOSS-1
        </td>
      </tr>
      <tr>
        <th scope="row">9</th>
        <td>Установка PageWide XL 4000/4500 Accessory Upgrade Kit *
        </td>
        <td>ISXLOAUK-1
        </td>
        <td>ISXLOAUK-2</td>
      </tr>
      <tr>
        <th scope="row">10</th>
        <td>Установка High-capacity stacker (non-pro version) *</td>
        <td>ISXLOHCS-1
        </td>
        <td>ISXLOHCS-2</td>
      </tr>
      <tr>
        <th scope="row">11</th>
        <td>Установка Pro Stacker *
        </td>
        <td>ISXLOPST-1
        </td>
        <td>ISXLOPST-2</td>
      </tr>
      <tr>
        <th scope="row">12</th>
        <td>Установка HP PageWide XL Pro Sheet Feeder *
        </td>
        <td>ISXLOPSF-1
        </td>
        <td>
          ISXLOPSF-2
        </td>
      </tr>
      </tbody>
    </table>
    <p class="equipment-note">* Сервисный пакет может быть приобретен и использован только одновременно с сервисным
      пакетом по инсталляции широкоформатного печатного устройства.</p>
    <p class="equipment-note">** Сервисный пакет может быть приобретен и использован только одновременно с сервисным
      пакетом по инсталляции широкоформатного печатного устройства. Возможность проведения установки опции необходимо
      предварительно согласовывать с Rus.Support.</p>
    <p>
      <b>Ограничения сервиса:</b>
    </p>
    <p>
      Сервисные услуги оказываются по рабочим дням и в рабочее время. Клиент обеспечивает возможность непрерывного
      выполнения работ в пределах рабочего времени. Исполнение по графику, имеющему перерывы/простои, равно как и
      исполнение во внерабочие часы/нерабочие дни возможно только по предварительному согласованию с Rus.Support и может
      потребовать дополнительной оплаты.
    </p>
    <p>
      Перед началом работ Rus.Support направляет клиенту требования к подготовке помещения для установки оборудования
      (если это применимо для конкретного оборудования), которые должны быть выполнены клиентом до начала проведения
      работ.

    </p>
    <p>
      Клиент обеспечивает беспрепятственный доступ специалистов Rus.Support к месту установки оборудования.
    </p>
    <p>
      Места установки оборудования должно быть доступно регулярным общественным транспортом. В непосредственной близости
      от места установки (до 10 км) должна находиться гостиница. Отсутствия каждодневного транспортного сообщения или
      гостиницы в непосредственной близости требует обязательного предварительного согласования возможности выполнения
      работ с Rus.Support и может потребовать дополнительной оплаты.
    </p>
    <p>
      Клиент самостоятельно и за свой счет предоставляет материалы и оборудование необходимые для запуска устройства, и
      не входящие в комплект поставки (например, бумагу, картриджи, рабочую станцию для проверки работы оборудования из
      сети заказчика и т.д.).
    </p>
    <p>
      Перемещение оборудования не производится. Клиент размещает устройство на том месте, где и будет выполнена
      инсталляция.
    </p>
    <p>
      Если производится инсталляция нескольких устройств HP PageWide XL или DesignJet 3600XL по одному адресу, то
      демонстрации режимов работы и основных пользовательских сервисных операций производится только для одного из
      устройств.
    </p>
    <p>
      Срок действия сертификата 1 год с даты выпуска, указанной на сертификате.
    </p>
  </div>
</template>

<script>
export default {
  name: "ISXL",
  created() {
    const layout = {
      name: "Main",
    };
    this.$store.commit('setLayout', layout)
  },
}
</script>

<style lang="scss" scoped>
.table-isxl-service-equipment {
  width: 50%;
}

.table-isxl-service-pack {
  font-size: 14px;
}

.equipment-isxl {
  max-width: 940px;
}

.isxl_image {
  float: right;
  width: 280px;
}
</style>