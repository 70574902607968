<template>
  <svg>
    <path
        d="M163.5,483.5h232v96a8.06,8.06,0,0,0,13.7,5.7l42.3-42.3,42.3,42.3a8,8,0,0,0,13.6-5.7v-96h24a32,32,0,0,0,32-32v-256h40a8,8,0,0,0,8-8,32,32,0,0,0-32-32h-368a32,32,0,0,0-32,32v256h-40a8,8,0,0,0-8,8A32.18,32.18,0,0,0,163.5,483.5Zm429.9-304H565.7a16,16,0,0,1,27.7,0Zm-405.9,280h200v-16h-192v-256a16,16,0,0,1,16-16H552a32.09,32.09,0,0,0-4.5,16v256h-32v16h29.8a16.09,16.09,0,0,1-13.8,8h-24v-8h-16V560.2l-34.3-34.3a8,8,0,0,0-11.3,0h0l-34.3,34.3V459.5h-16v8h-232a16.25,16.25,0,0,1-13.9-8Z"
        transform="translate(0 0)"/>
    <path
        d="M401.3,446.7a8,8,0,0,0,8.89,7h0c.2,0,.4-.1.6-.1l20.6-4.1L445.7,465a8,8,0,0,0,11.3.4c.2-.1.3-.3.4-.4l14.3-15.4,20.6,4.1a8,8,0,0,0,9.5-6.9l2.5-20.9,19.1-8.8a7.93,7.93,0,0,0,4-10.5s0-.07-.05-.1l-.3-.6-10.2-18.4,10.3-18.3a8,8,0,0,0-3-10.9l-.6-.3-19.1-8.8-2.5-20.9a8,8,0,0,0-8.89-7h0l-.6.1-20.7,4.1L457.4,310a8.21,8.21,0,0,0-11.6-.1h0l-.1.1-14.3,15.5-20.6-4.1a8,8,0,0,0-9.4,6.3h0c0,.2-.1.4-.1.6l-2.5,20.9L379.7,358a7.93,7.93,0,0,0-3.95,10.5s0,.07.05.1h0l.3.6,10.3,18.4-10.3,18.3a8,8,0,0,0,3,10.9h0l.6.3,19.1,8.8Zm1.2-63L394.3,369l15.3-7a8.12,8.12,0,0,0,4.6-6.3l2-16.7,16.5,3.3a7.69,7.69,0,0,0,7.4-2.4l11.4-12.4L463,339.8a7.69,7.69,0,0,0,7.4,2.4l16.5-3.3,2,16.7a7.81,7.81,0,0,0,4.6,6.3l15.3,7-8.3,14.7a7.77,7.77,0,0,0,0,7.8l8.2,14.7-15.3,7a8.12,8.12,0,0,0-4.6,6.3l-2,16.8-16.5-3.3a7.64,7.64,0,0,0-7.4,2.4l-11.4,12.4-11.4-12.4a7.69,7.69,0,0,0-7.4-2.4l-16.5,3.3-2-16.8a7.81,7.81,0,0,0-4.6-6.3l-15.3-7,8.3-14.7a7.36,7.36,0,0,0-.1-7.7Z"
        transform="translate(0 0)"/>
    <path
        d="M451.5,427.5a40,40,0,1,0-40-40A40,40,0,0,0,451.5,427.5Zm0-64a24,24,0,1,1-24,24h0A24.07,24.07,0,0,1,451.5,363.5Z"
        transform="translate(0 0)"/>
    <rect x="307.5" y="203.5" width="128" height="16"/>
    <rect x="227.5" y="251.5" width="288" height="16"/>
    <rect x="227.5" y="283.5" width="288" height="16"/>
    <rect x="235.5" y="387.5" width="104" height="16"/>
    <circle cx="371.5" cy="371.5" r="361.5" style="fill:none;stroke:#0071ba;stroke-miterlimit:10;stroke-width:20px"/>
  </svg>
</template>

<script>
export default {
  name: "warranty-service"
}
</script>

<style scoped>

</style>