<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      СБОРКА И ПОСТАВКА
      СЕРВЕРОВ И СХД ПОД ЗАКАЗ
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Мы выполняем полный спектр работ по поставке серверных систем: от подготовки спецификации до запуска сервера на
      вашей площадке
    </p>
  </div>
</template>

<script>
export default {
  name: "othersServersSliderContent"
}
</script>

<style scoped>

</style>