<template>
  <div>
    <!-- Content start -->
    <div class="container list-service-wrap">
      <HpGuaranteeContent></HpGuaranteeContent>
    </div>
    <!-- Content end -->
  </div>
</template>

<script>

import HpGuaranteeContent from "@/pages/About/we-do/HpGuarantee/HpGuaranteeContent"

let btnSize = 1;
let clientScreenSize = document.documentElement.clientWidth

function btnResize() {
  if (clientScreenSize <= 768) {
    btnSize = 0.7
  }
}

btnResize()

const buttons = [
  {
    link: "link",
    customClass: "class",
    size: btnSize,
    sign: "+",
    caption: "ОФОРМИТЬ НОВУЮ ЗАЯВКУ",
    formType: "create-order"
  }
];

const slides = [
  {
    background: "/img/page-slider/hp-guarantee.jpg",
    template: "hpGuaranteeSliderContent",
    buttons: buttons
  },
];

export default {
  data() {
    return {
      dsVendors: [],
      slides: slides
    }
  },
  created() {
    const layout = {
      name: "Slider",
      data: this.slides
    };
    this.$store.commit('setLayout', layout)

    //console.log(this.$route.params.sc, this.$store.state.serviceCenters)
  },
  components: {
    HpGuaranteeContent,
  },
}
</script>

<style lang="scss" scoped>
.form-button {
  margin: 0;
}
</style>
