<template>
  <!-- Content start -->
  <div class="container list-service-wrap">
    <HpePackagedContent/>
  </div>
  <!-- Content end -->
</template>

<script>

import HpePackagedContent from "@/pages/About/we-do/HpePackaged/HpePackagedContent";

const buttons = [];

const slides = [
  {
    background: "/img/page-slider/hpe-packaged.jpg",
    template: "hpePackagedSliderContent",
    buttons: buttons
  },
];

export default {
  data() {
    return {
      dsVendors: [],
      slides: slides
    }
  },
  created() {
    const layout = {
      name: "Slider",
      data: this.slides
    };
    this.$store.commit('setLayout', layout)

    //console.log(this.$route.params.sc, this.$store.state.serviceCenters)
  },
  components: {
    HpePackagedContent,
  }
}
</script>

<style lang="scss" scoped>
.list-service-wrap {
  max-width: 1140px;
}
</style>
