<template>
    <div class="selectList">
        <DxList
            v-model="selectedKeys"
            :data-source="dataSource"
            :show-selection-controls="true"
            :selection-mode="selectionMode"
            :select-all-mode="selectAllMode"
            @selection-changed="selectionChanged($event)"
            @initialized="listInitialized"
        >
            <template #item="{ data: item }">
                <div>
                    {{ item.name }}
                    <img class="question-img" :id="'auth'+item.id" src="/img/icons/question-mark.svg" alt="">
                    <DxTooltip
                        :target="'#auth'+item.id"
                        show-event="dxhoverstart"
                        hide-event="dxhoverend"
                        position="top"
                    >
                        <template>
                            <p>
                                {{ item.desc }}
                            </p>
                        </template>
                    </DxTooltip>
                </div>
            </template>
        </DxList>

    </div>
</template>

<script>
import DxList from 'devextreme-vue/list';
import ArrayStore from 'devextreme/data/array_store';
import 'devextreme-vue/check-box';
import DxTooltip from "devextreme-vue/tooltip";

export default {
    name: "selectList",
    props: ["auths", "dsSelectedKeys", "listKey"],
    components: {
        DxList,
        DxTooltip
    },
    data() {
        return {
            dataSource: new ArrayStore({
                key: 'id',
                data: this.auths.map((auth) => {
                    return {
                        id: auth.sdau_id,
                        name: auth.sdau_name,
                        desc: auth.sdau_description
                    }
                }),
            }),
            selectedKeys: [],
            selectionMode: 'multiple',
            // selectionMode: 'all',
            selectAllMode: 'page',
            listInstance: null,
        };
    },
    methods: {
        selectionChanged(event) {
            let dsCache = this.dsSelectedKeys;

            if (event.addedItems.length) {
                const addedKeys = event.addedItems.map((item) => item.id);
                dsCache = [...dsCache, ...addedKeys];
            }
            if (event.removedItems.length) {
                const removedKeys = event.removedItems.map((item) => item.id);
                dsCache = dsCache.filter((key) => !removedKeys.includes(key));
            }
            this.$emit("updateSelection", {
                data: dsCache,
                key: this.listKey
            });
        },

        listInitialized(event) {
            this.listInstance = event.component.instance();
        },
    },
    watch: {
        dsSelectedKeys() {
            if (!this.dsSelectedKeys.length)
                this.listInstance.reload();
        }
    }
}
</script>

<style lang="scss" scoped>
.question-img {
    width: 10px;
}

::v-deep .dx-checkbox-icon {
    font-family: "DXIcons" !important;
}
</style>