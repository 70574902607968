/*jshint esversion: 6 */
const locales = [
    {
        'Name': 'English',
        'Value': 'en'
    },
    {
        'Name': 'Deutsch',
        'Value': 'de'
    },
    {
        'Name': 'Русский',
        'Value': 'ru'
    }
];

const dictionary = {
    'en': {
        'Number': 'Number',
        'Contact': 'Contact',
        'Company': 'Company',
        'Amount': 'Amount',
        'PaymentDate': 'Payment Date'
    },
    'de': {
        'Number': 'Nummer',
        'Contact': 'Ansprechpartner',
        'Company': 'Firma',
        'Amount': 'Betrag',
        'PaymentDate': 'Zahlungsdatum'
    },
    'ru': {
        'Number': 'Номер',
        'Contact': 'Имя',
        'Company': 'Организация',
        'Amount': 'Сумма',
        'PaymentDate': 'Дата оплаты'
    }
};

export default {
    getLocales() {
        return locales;
    },
    getDictionary() {
        return dictionary;
    }
};