<template>
  <div class="row py-5">

    <div class="col-md-4">
      <div class="note-white">
        <span class="note__square"></span>
        <div class="title">
          ИНСТАЛЛЯЦИЯ
        </div>
        <p class="note__text">
          Проведем анализ площадки, подготовку, монтаж, запуск, настройку и нагрузочное тестирование новых
          систем
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div class="note-white">
        <span class="note__square"></span>
        <div class="title">
          МОДЕРНИЗАЦИЯ
        </div>
        <p class="note__text">
          Добавим новые компоненты к уже имеющемуся оборудованию. Например, дополнительные диски, память или
          процессоры
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div class="note-white">
        <span class="note__square"></span>
        <div class="title">
          МИГРАЦИЯ
        </div>
        <p class="note__text">
          Переместим имеющееся у вас оборудование на новое место, включая бэкап, демонтаж, упаковку и
          транспортировку, анализ новой площадки, монтаж и проверку работоспособности на новом месте
        </p>
      </div>
    </div>

    <img class="img-fluid image" src="/img/pages/hpe-upgrade_schema.png" alt="">

    <div class="title">
      <b>В КАКИХ СЛУЧАЯХ <br>
        РЕКОМЕНДУЕТСЯ</b>
    </div>

    <!-- LIST BLOCK -->
    <div class="list">
      <div class="list__icon"></div>
      <div class="list__content">
        <p class="list__content_text">
          Необходимо минимизировать простои в работе при установке, демонтаже и переходе на новые продукты
        </p>
      </div>
    </div>
    <div class="list">
      <div class="list__icon"></div>
      <div class="list__content">
        <p class="list__content_text">
          Требуется переместить оборудование между географически распределенными площадками
        </p>
      </div>
    </div>
    <div class="list">
      <div class="list__icon last"></div>
      <div class="list__content">
        <p class="list__content_text">
          Необходимо сократить риски, связанные с проводимыми изменениями
        </p>
      </div>
    </div>
    <!-- /.LIST BLOCK -->

    <p class="paragraph">Если вас интересуют сервисы по инсталляции, модернизации или миграции оборудования HPE —
      присылайте нам
      запрос на адрес <a class="link" href="mailto:sale@rus.support">sale@rus.support</a>, и мы сделаем вам предложение
    </p>
  </div>
</template>

<script>
export default {
  name: "HpeUpgradeContent"
}
</script>

<style lang="scss" scoped>

.title {
  margin-bottom: 2rem;
}

.note-white {
  max-width: 100%;
  height: 100%;

  @media screen and (max-width: 767px) {
    height: auto;
  }
}

.image {
  margin: 3rem 0;
  padding: 0 3rem;
}
</style>