<template>
  <div class="row py-5 text-center">

    <p class="paragraph">
      Мы выполняем полный спектр работ по поставке серверных систем: от подготовки спецификации до запуска сервера на
      вашей площадке
    </p>
    <p class="paragraph">
      Мы выполняем подбор конфигурации, сборку, тестирование, инсталляцию и поддержку серверного оборудования
      стандартной архитектуры из доступных на рынке комплектующих.
    </p>
    <p class="paragraph">
      Квалифицированные специалисты учтут требования заказчика и предложат подходящее под задачи и экономичное решение.
      При необходимости возможна установка и настройка различных операционных систем и программного обеспечения. Каждая
      единица проходит путь от детального расчета конфигурации до выходного тестирования в целях обеспечения стабильной
      и качественной работы.
    </p>
    <p class="paragraph">
      После установки серверов заказчику мы обеспечиваем выездное гарантийное обслуживание силами специалистов наших
      сервисных центров.
      Заказчик может выбрать нужный ему уровень сервисного обслуживания.
    </p>

    <div class="col-md-2 p-0">
      <div class="product">
        <div class="product__icon"></div>
        <div class="product__title">
          <span class="link">ПОДБЕРЕМ СЕРВЕР</span>
        </div>
      </div>
      <!-- PRODUCT BLOCK -->
    </div>
    <div class="col-md-2 p-0">
      <div class="product">
        <div class="product__icon center"></div>
        <div class="product__title">
          <span class="link">ПРОВЕДЕМ СБОРКУ</span>
        </div>
      </div>
      <!-- PRODUCT BLOCK -->
    </div>
    <div class="col-md-2 p-0">
      <div class="product">
        <div class="product__icon center"></div>
        <div class="product__title">
          <span class="link">НАСТРОИМ ОБОРУДОВАНИЕ</span>
        </div>
      </div>
      <!-- PRODUCT BLOCK -->
    </div>
    <div class="col-md-2 p-0">
      <div class="product">
        <div class="product__icon center"></div>
        <div class="product__title">
          <span class="link">УСТАНОВИМ ОПЕРАЦИОННУЮ СИСТЕМУ</span>
        </div>
      </div>
      <!-- PRODUCT BLOCK -->
    </div>
    <div class="col-md-2 p-0">
      <div class="product">
        <div class="product__icon center"></div>
        <div class="product__title">
          <span class="link">ПРОТЕСТИРУЕМ ГОТОВОЕ ОБОРУДОВАНИЕ</span>
        </div>
      </div>
      <!-- PRODUCT BLOCK -->
    </div>
    <div class="col-md-2 p-0">
      <div class="product">
        <div class="product__icon last"></div>
        <div class="product__title">
          <span class="link">ПРОВЕДЕМ ИНСТАЛЯЦИЮ В ВАШ ЦОД</span>
        </div>
      </div>
      <!-- PRODUCT BLOCK -->
    </div>

    <p class="paragraph">
      Если вы заинтересовались данной услугой – присылайте запрос на адрес
      <a class="link" href="mailto:sale@rus.support">sale@rus.support</a>
    </p>

  </div>
</template>

<script>
export default {
  name: "OthersServersContent"
}
</script>

<style lang="scss" scoped>
.link {
  font-size: 14px;
}
</style>