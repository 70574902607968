<template>
  <div class="row py-5">
    <div class="col-md-8">
      <p class="title"><b>СЕРВИСНЫЕ КОНТРАКТЫ</b>ПОЗВОЛЯЮТ</p>

      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Вернуть на гарантию старое оборудование, у которого гарантия уже закончилась
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Получить гибкие сроки поддержки
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Самостоятельно устанавливать нужный срок гарантии и уровень сервиса
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Выравнять сроки и условия гарантии для всего парка устройств, а значит, контролировать срок окончания
            поддержки будет легче
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Оплачивать услуги ежемесячно или ежеквартально
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Досрочно прекратить получение услуг
          </p>
        </div>
      </div>

      <div class="note-white">
        <span class="note__square"></span>
        <p class="note__text">
          Цена сервисного контракта зависит от количества устройств на обслуживании
        </p>
        <p class="note__text">
          При увеличении объёма контракта предоставляются СКИДКИ
        </p>
        <p class="note__text">
          Сервисные контракты неприменимы для устройств домашних серий и доступны только для юридических лиц
        </p>
      </div>
      <p class="prompt">
        Если вас интересует сервисный контракт для парка оборудования HP Inc. — отправьте запрос на адрес
        <a class="link" href="mailto:sale@rus.support">sale@rus.support</a> и мы предоставим вам коммерческое
        предложение.
      </p>
    </div>
    <div class="col-md-4">
      <div class="note-white">
        <span class="note__square"></span>
        <span class="note__title">
          Сервисные контракты обеспечивает производитель
        </span>
        <p class="note__text">
          Это значит, что сроки и условия будут отражаться на официальном сайте HPE, а оригинальные запасные части
          всегда будут в наличии
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HpContractsContent"
}
</script>

<style lang="scss" scoped>
.note-white {
  max-width: 100%;
}
</style>
