<template>
  <div class="row py-5">

    <div class="col-md-9">
      <p class="paragraph">
        Услуга актуальна для тех, кто не хочет тратить собственные ресурсы на покупку и поддержку рабочих мест и хочет
        платить только за реально использованные сервисы.
      </p>
      <div class="title">МЫ ВЫПОЛНЯЕМ</div>

      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            пусконаладке промышленных печатных устройств
          </p>
        </div>
      </div>
      <!-- /.LIST BLOCK -->
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Автоматический мониторинг состояния оборудования
          </p>
        </div>
      </div>
      <!-- /.LIST BLOCK -->
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Обслуживание и ремонт оборудования
          </p>
        </div>
      </div>
      <!-- /.LIST BLOCK -->
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Оперативную замену в случае поломки устройства
          </p>
        </div>
      </div>
      <!-- /.LIST BLOCK -->

      <div class="title">ВЫ ПЛАТИТЕ</div>
      <p class="paragraph">
        За используемые вашими сотрудниками устройства ежемесячно, то есть за фактически оказанные услуги по
        предоставлению и поддержке устройств конечных пользователей
        В зависимости от потребностей вашего бизнеса мы подберем нужные услуги и разработаем индивидуальный тарифный
        план.
      </p>
      <p class="paragraph">
        Если вас заинтересовала данная услуга, пришлите запрос на <a class="link" href="mailto:sale@rus.support">sale@rus.support</a>
        и мы предоставим вам предложение
      </p>
    </div>
    <div class="col-md-3">
      <div class="note-white">
        <span class="note__square"></span>
        <p class="note__text">
          В зависимости от потребностей вашего бизнеса, мы подберем нужные услуги и разработаем индивидуальный тарифный
          план.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HpDaasContent"
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
  margin: 1rem 0;
}
</style>
