<template>
    <div class="container py-5">

        <a class="download-price"
           :href="locOrigin + pathToPdf"
           :download="sourcePrice.fileName">
            Скачать в PDF
        </a>

        <div style="text-align: center">
            <!--<h1 class="download-title">{{ sourcePrice.title }}</h1>-->
            <pdf class="download-file"
                 v-for="i in numPages"
                 :key="i"
                 :src="src"
                 :page="i"
            />
        </div>

    </div>
</template>

<script>

import pdf from 'vue-pdf';
import dataPrice from './data.pricelists.json'


export default {
    name: "PriceLists",
    data() {
        let spp = dataPrice.Pricelists.find((item) => item.name === this.$route.params.name)
        let loadingTask = pdf.createLoadingTask(location.origin + spp.path);

        return {
            src: loadingTask,
            numPages: undefined,
            locOrigin: location.origin,
            pathToPdf: spp.path
        }
    },
    components: {
        pdf
    },
    created() {
        const layout = {
            name: "Main",
            data: this.slides
        };
        this.$store.commit('setLayout', layout)
    },
    computed: {
        sourcePrice() {
            return dataPrice.Pricelists.find((item) =>
                item.name === this.$route.params.name
            )
        }
    },
    mounted() {
        this.src.promise.then(pdf => {
            this.numPages = pdf.numPages;
        })
    }
}
</script>

<style lang="scss" scoped>

.download-price {
    text-decoration: none;
    background-color: #0abba7;
    color: #ffffff;
    padding: 10px;
    display: block;
    max-width: 150px;
    text-align: center;
    border-radius: 5px;
    transition: .2s;
    box-shadow: 0 3px 10px #00000052;

    &:hover {
        background-color: #078174;
    }
}

.download-title {
    padding: 20px 0;
}

.download-file {
    margin: 15px auto;
    max-width: 1000px;
}
</style>