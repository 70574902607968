<template>
    <div class="container-sponsors">
        <router-link
            v-for="(link, index) in linkItems"
            :key="index"
            :to="link.link"
            class="link-regular link-sponsors"
        >
            <img
                class="link-sponsors__img"
                :class="link.classLogo"
                :src="imagePath(link.img)"
            />
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'linkSponsors',
    data() {
        const linkItems = [
            {
                id: 0,
                link: '/about/avision',
                img: 'Avision-logo.png',
                classLogo: 'avision_logo',
            },
            {
                id: 1,
                link: '/about/hp',
                img: 'hp.svg',
            },
            {
                id: 2,
                link: '/about/hpe',
                img: 'hpe.svg',
            },
            {
                id: 3,
                link: '',
                img: 'katusha_white_logo.svg',
                classLogo: 'katusha_logo',
            },
            {
                id: 4,
                link: '',
                img: 'Huawei_Standard_logo.svg.png',
                classLogo: 'huawei_logo',
            },
        ];
        return {
            linkItems: linkItems,
            imagePath: (imageName) => '/img/layout/' + imageName,
        };
    },
};
</script>

<style lang="scss" scoped>
.container-sponsors {
    /* height: 80px;
    white-space: nowrap;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; */

    & > .link-sponsors:first-child:before {
        border: none;
    }

    .link-sponsors {
        display: inline-block;
        //padding: 10px 30px;
        height: 100%;
        width: 20%;
        position: relative;
        text-align: center;
        padding: 10px 0;

        .link-sponsors__img {
            //padding: 0.5rem 0;
            //margin: 0.7rem 0 0 1.5rem;
            margin: 1rem;

            @media screen and (max-width: 1024px) {
                width: 80%;
            }
        }

        .link-sponsors__img.katusha_logo {
            width: 70%;
        }

        .link-sponsors__img.huawei_logo {
            width: 35%;
        }

        .avision_logo {
            max-width: 110%;
            margin-left: -45px;
            border: 5px solid #ffffff;
        }

        /*img {
        vertical-align: middle;
    }*/

        .image-helper {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }

        &:before {
            content: ' ';
            position: absolute;
            width: 1px;
            left: 0;
            top: 12px;
            height: 70%;
            border-left: 1px solid white;
        }
    }
}
</style>
