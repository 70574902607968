const mapPriceLists = [
    {
        id: 0,
        priceCapture: 'msk',
        name: 'nameTest 1',
        link: '/pricelists/20220321_price_МСК.pdf'
    },
    {
        id: 1,
        priceCapture: 'spb',
        name: 'nameTest 2',
        link: '/pricelists/20220321_price_СПб.pdf'
    },
    {
        id: 2,
        priceCapture: 'sam',
        name: 'nameTest 3',
        link: '/pricelists/20220321_price_САМ.pdf'
    },
    {
        id: 3,
        priceCapture: 'krd',
        name: 'nameTest 3',
        link: '/pricelists/20220321_price_КРД.pdf'
    }
];

export {mapPriceLists};