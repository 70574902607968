<template>
    <div class="privacy-accordion__item-content">
        <!--    <p>3.1. Получение персональных данных.</p>
            <p>
              3.1.1. Все персональные данные следует получать от самого Пользователя. В случае получения согласия на обработку
              персональных данных от представителя Пользователя полномочия данного представителя на дачу согласия от
              Пользователя
              проверяются оператором.
            </p>
            <p>
              3.2. Лица, имеющие право доступа к персональным данным.
            </p>
            <p>
              3.2.1. Правом доступа к персональным данным субъектов обладают лица, наделенные соответствующими полномочиями в
              соответствии со своими служебными обязанностями.
            </p>
            <p>
              3.2.2. Перечень лиц, имеющих доступ к персональным данным, утверждается генеральным
            </p>
            <p>
              3.2.3. директором Оператора.
            </p>
            <p>
              3.3. Порядок и сроки хранения персональных данных на Сайте.
            </p>
            <p>
              3.3.1. Оператор осуществляет только хранение персональных данных Пользователей на Сайте.
            </p>
            <p>
              3.3.2. Сроки хранения персональных данных: с момента предоставления данных Пользователем до тех пор, пока
              Пользователь не заявит о своем желании удалить свои персональные данные с Сайта.
            </p>
            <p>
              3.3.3. Оператором не ведется обработка персональных данных Пользователей на бумажных носителях информации.
            </p>
            <p>
              3.4. Блокирование персональных данных.
            </p>
            <p>
              3.4.1. Под блокированием персональных данных понимается временное прекращение Оператором операций по их обработке
              по
              требованию Пользователя при выявлении им недостоверности обрабатываемых сведений или неправомерных, по мнению
              субъекта персональных данных, действий в отношении его данных.
            </p>
            <p>
              3.4.2. Оператор не передает персональные данные третьим лицам и не поручает обработку персональных данных
              сторонним
              лицам и организациям. Персональные данные Пользователей Сайта обрабатывают только сотрудники Оператора
              (администраторы баз данных и т. д.), допущенные установленным порядком к обработке персональных данных
              Пользователей.
            </p>
            <p>
              3.4.3. Блокирование персональных данных на Сайте осуществляется на основании письменного заявления от субъекта
              персональных данных.
            </p>
            <p>
              3.5. Уничтожение персональных данных.
            </p>
            <p>
              3.5.1. Под уничтожением персональных данных понимаются действия, в результате которых становится невозможным
              восстановить содержание персональных данных на Сайте и/или в результате которых уничтожаются материальные носители
              персональных данных.
            </p>
            <p>
              3.5.2. Субъект персональных данных вправе в письменной форме требовать уничтожения своих персональных данных в
              случае, если персональные данные являются неполными, устаревшими, недостоверными, незаконно полученными или не
              являются необходимыми для заявленной цели обработки.
            </p>
            <p>
              3.5.3. В случае отсутствия возможности уничтожения персональных данных Оператор осуществляет блокирование таких
              персональных данных.
            </p>
            <p>
              3.5.4. Уничтожение персональных данных осуществляется путем стирания информации с использованием
              сертифицированного
              программного обеспечения с гарантированным уничтожением (в соответствии с заданными характеристиками для
              установленного программного обеспечения с гарантированным уничтожением).
            </p>-->
        <h2>3. Обработки персональных данных Пользователей</h2>
        <p>
            3.1. Сайт собирает и хранит только те персональные данные, которые необходимы для предоставления сервисов
            или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством
            предусмотрено обязательное хранение персональных данных в течение определенного законом срока.
        </p>
        <p>
            В случае получения уведомления от Пользователя об отзыве согласия на обработку персональных данных Сайт
            прекращает обработку персональных данных Пользователя в срок, не превышающий 10 (десяти) рабочих дней с
            момента получения.
        </p>
        <p>
            Уведомление об отзыве согласия на обработку персональных данных направляется на адрес электронной почты:
            support@complete.ru, а также путем письменного обращения по юридическому адресу: 197345, Санкт-Петербург г,
            ул Яхтенная, д. 22, к. 1, литера А, помещ. 375.
        </p>
        <p>3.2. Персональную информацию Пользователя Сайт обрабатывает в следующих целях:</p>
        <p>
            3.2.1. Идентификации Пользователя, зарегистрированного на Сайте, для заключения и/или исполнения договора
            между Пользователем и Оператором, регистрации на мероприятия, подачи заявок на обратный звонок, заполнение
            анкеты соискателя на вакансии Оператора, оптимизации рекламы.
        </p>
        <p>
            3.2.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.
        </p>
        <p>
            3.2.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся
            использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя.
        </p>
        <p>
            3.2.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
        </p>
        <p>
            3.2.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
        </p>
        <p>
            3.2.6. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем,
            связанных с использованием Сайта.
        </p>
        <p>
            3.2.7. Осуществления рекламной деятельности с согласия Пользователя.
        </p>
        <p>
            3.3. Условия обработки персональных данных Пользователей:
        </p>
        <p>3.3.1. Сайт хранит персональные данные Пользователей в соответствии с внутренними регламентами конкретных
            сервисов.</p>
        <p>
            3.3.2. Оператор осуществляет сбор информации во время посещения Пользователем Сайта в целях сбора статистики
            использования и анализа эффективности, персонализации
            и корректировки взаимодействия, а также совершенствования своих продуктов и услуг. Сбор информации
            осуществляется с использованием различных технологий, в том числе Файлов Cookie.
        </p>
        <p>3.3.2.1. Информация, сбор которой осуществляется с помощью Файлов Cookie, включает в себя:</p>
        <p>
            - посещенные страницы; <br>
            - количество посещений страниц; <br>
            - длительность пользовательской сессии; <br>
            - точки входа (сторонние сайты, с которых Пользователь по ссылке переходит на сайты Оператора); <br>
            - точки выхода (ссылки на сайтах Оператора, по которым Пользователь переходит на сторонние сайты); <br>
            - страна Пользователя; <br>
            - регион Пользователя; <br>
            - провайдер Пользователя; <br>
            - браузер Пользователя; <br>
            - системные языки Пользователя; <br>
            - операционная система Пользователя; <br>
            - разрешение экрана Пользователя; <br>
            - количество цветов экрана Пользователя. <br>
        </p>
        <p>
            3.3.2.2. Файлы Cookie, делятся на «файлы cookie сеанса» и «постоянные cookie». Файлы cookie сеанса помогают
            Пользователю эффективнее осуществлять навигацию по Сайту и отслеживать перемещения между страницами, чтобы
            не приходилось заново вводить информацию, уже предоставленную во время текущего посещения Сайта или
            необходимую для совершения транзакции. Файлы cookie сеанса хранятся во временной памяти и удаляются при
            закрытии браузера. Постоянные cookie хранят предпочтения Пользователя для текущего и последующих посещений
            сайтов, записываются на жесткий диск устройства Пользователя и сохраняют корректность при повторном запуске
            браузера. Постоянные cookie хранятся в устройстве Пользователя в течение времени, указанного в параметрах
            Файлов Cookie или до момента их удаления Пользователем.
        </p>
        <p>
            3.3.2.3. Оператор использует Файлы Cookie в целях анализа использования Сайта (в частности, для определения
            числа Пользователей и продолжительности посещения) и его эффективности (для определения тем, которые больше
            всего интересуют Пользователя), а также для упрощения навигации по Сайту и его использования и в данном
            качестве не ассоциируются с персональными данными. Файлы Cookie также используются для персонализации
            взаимодействия с Пользователем посредством их сопоставления с данными профиля или предпочтениями
            пользователя.
        </p>
        <p>
            3.3.2.4. Пользователь может указать предпочтительные варианты использования большинства Файлов Cookie и
            аналогичных технологий через браузер. Информация о контроле над Файлами Cookie содержится в разделе
            «Настройки» (или подобном) используемого браузера. В большинстве случаев Пользователь можете настроить
            уведомления браузера о получении Файла Cookie и в каждом конкретном случае решать, следует ли принять этот
            файл или нет. Пользователь может полностью отключить Файлы Cookie в браузере. При блокировке, отключении или
            ином запрете использования Файлов Cookie сайт может отображаться неправильно.
        </p>
        <p>
            3.4. В отношении персональных данных Пользователя сохраняется их конфиденциальность, кроме случаев
            добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.
            При использовании отдельных сервисов Пользователь соглашается с тем, что определенная часть его персональных
            данных становится общедоступной.
        </p>
        <p>
            3.5. Лица, имеющие право доступа к персональным данным.
        </p>
        <p>
            3.5.1. Правом доступа к персональным данным субъектов обладают лица, наделенные соответствующими
            полномочиями в соответствии со своими служебными обязанностями.
        </p>
        <p>
            3.5.2. Перечень лиц, имеющих доступ к персональным данным, утверждается единоличным исполнительным органом
            Оператора.
        </p>
        <p>
            3.6. Блокирование персональных данных.
        </p>
        <p>
            3.6.1. Под блокированием персональных данных понимается временное прекращение Оператором операций по их
            обработке по требованию Пользователя при выявлении им недостоверности обрабатываемых сведений или
            неправомерных, по мнению субъекта персональных данных, действий в отношении его данных.
        </p>
        <p>
            3.6.2. Оператор не передает персональные данные третьим лицам и не поручает обработку персональных данных
            сторонним лицам и организациям. Персональные данные Пользователей Сайта обрабатывают только сотрудники
            Оператора (администраторы баз данных и т. д.), допущенные установленным порядком к обработке персональных
            данных Пользователей.
        </p>
        <p>
            3.6.3. Блокирование персональных данных на Сайте осуществляется на основании
            письменного заявления от субъекта персональных данных.
        </p>
        <p>
            3.7. Уничтожение персональных данных.
        </p>
        <p>
            3.7.1. Под уничтожением персональных данных понимаются действия, в результате которых становится невозможным
            восстановить содержание персональных данных на Сайте и/или в результате которых уничтожаются материальные
            носители персональных данных.
        </p>
        <p>
            3.7.2. Субъект персональных данных вправе в письменной форме требовать уничтожения своих персональных данных
            в случае, если персональные данные являются неполными, устаревшими, недостоверными, незаконно полученными
            или не являются необходимыми для заявленной цели обработки.
        </p>
        <p>
            3.7.3. В случае отсутствия возможности уничтожения персональных данных Оператор осуществляет блокирование
            таких персональных данных.
        </p>
        <p>
            3.7.4. Уничтожение персональных данных осуществляется путем стирания информации с использованием
            сертифицированного программного обеспечения с гарантированным уничтожением (в соответствии с заданными
            характеристиками для установленного программного обеспечения с гарантированным уничтожением).
        </p>
        <p>
            3.8. Обработка персональных данных Пользователя осуществляется до момента отзыва Пользователем согласия на
            обработку персональных данных следующими способами: сбор, запись, систематизация, накопление, хранение,
            уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление,
            доступ), обезличивание, блокирование, удаление, уничтожение персональных данных, в том числе в
            информационных системах персональных данных с использованием средств автоматизации или без использования
            таких средств. Обработка персональных данных Пользователей осуществляется в соответствии с Федеральным
            законом от 27.07.2006 N 152-ФЗ «О персональных данных».
        </p>
        <p>
            3.8.1. Администрация Сайта, не исполнившая свои обязательства, несет ответственность за убытки, понесенные
            Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством
            Российской Федерации.
        </p>
        <p>3.8.2. В случае утраты или разглашения конфиденциальной информации Администрация Сайта не несет
            ответственности, если данная конфиденциальная информация:</p>
        <p>3.8.2.1. Стала публичным достоянием до ее утраты или разглашения.</p>
        <p>3.8.2.2. Была получена от третьей стороны до момента ее получения Администрацией Сайта.</p>
        <p>3.8.2.3. Была разглашена с согласия Пользователя.</p>
        <p>3.9. При утрате или разглашении персональных данных Администрация Сайта информирует Пользователя об утрате
            или разглашении персональных данных.</p>
        <p>
            3.10. Администрация Сайта принимает необходимые организационные и технические меры для защиты персональной
            информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования,
            копирования, распространения, а также от иных неправомерных действий третьих лиц.
        </p>
        <p>
            3.11. Администрация Сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков
            или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
        </p>
    </div>
</template>

<script>
export default {
    name: "personalData"
}
</script>

<style scoped>

</style>