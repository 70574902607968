<template>
  <div class="terms-accordion__item-content">
    <p>8.1. Если оборудование имеет активную гарантию производителя, действующую на территории РФ, и неисправность
      попадает под условия гарантии производителя, то срок выполнения ремонтных работ по устранению заявленных
      недостатков, начиная со дня следующего за днём приёмки оборудования в СЦ составляет до 45 календарных дней
      включительно для оборудования, переданного к сервисному обслуживанию физическим лицом и до 45 календарных дней,
      начиная со дня получения всех необходимых запчастей для ремонта, для оборудования, переданного к сервисному
      обслуживанию юридическим лицом .</p>
    <p>8.2. В случае обнаружения следов неквалифицированного сервисного обслуживания оборудования, механических
      повреждений, следов воздействия жидкостей, поражения током, коррозии, нарушения условий эксплуатации, Заказчику
      может быть отказано в гарантийном ремонте и СЦ имеет право предложить дальнейший ремонт на платной основе.</p>
    <p>8.3. Для определённых категорий устройств гарантийный ремонт производится заменой на аналогичное оборудование.
      Перечень устройств, к которым относится данный вид ремонта, определяется производителем оборудования.</p>
    <p>8.4. Срок гарантии производителя на некоторые компоненты оборудования может быть меньше, чем срок гарантии на
      оборудование в целом. В частности, это может касаться аккумуляторных батарей и определенных моделей жестких
      дисков. Данные ограничения и перечень таких компонент определяется производителем. В случаях, если неисправность
      связана с такими компонентами, то их замена может рассматриваться как негарантийный случай даже при наличии
      действующей гарантии на оборудование.</p>
    <p>8.5. Гарантия на работы и установленные в оборудование запчасти длится до даты окончания гарантии производителя
      на оборудование, или в течение 90 календарных дней с момента окончания ремонта, смотря какой срок наступит позже.
      Гарантия на печатные устройства, обслуживаемые по схеме unit exchange (замена целиком), составляет 30 дней с
      момента окончания работ.</p>
    <p>8.6. В некоторых случаях, для уточнения сроков действия гарантии, СЦ может запросить копии документов о покупке.
      До тех пор, пока Заказчик не предоставит их, СЦ не сможет продолжать обслуживание по гарантии. В таком случае
      датой начала гарантийного сервисного обслуживания признается дата предоставления полного комплекта таких
      документов.</p>
    <p>8.7. Сервис по гарантийному сервисному обслуживанию оборудования оказывается на условиях производителя
      оборудования, на условиях настоящего документа («Условия сервисного обслуживания») и законодательства РФ.</p>
    <p>8.8. СЦ не обязан по завершению ремонта выдавать Заказчику заменённые в ходе ремонта неисправные запасные части,
      если иное не предусмотрено условиями гарантийного обслуживания.</p>
  </div>
</template>

<script>
export default {
  name: "storageСonditions2017"
}
</script>

<style scoped>

</style>