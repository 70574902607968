<template>
  <div class="row py-5">
    <div class="caption">
      <p class="caption__title">
        МЫ ВЫПОЛНЯЕМ ГАРАНТИЙНЫЙ РЕМОНТ <br> <span class="title">СЛЕДУЮЩЕЙ ТЕХНИКИ</span> НРE
      </p>
      <img class="caption__logo" src="/img/logo/hpe-logo.png" alt="">
    </div>
    <!-- /.caption -->

    <div class="b-shadow">
      <img class="b-shadow__icon" src="/img/icons/hp-guarantee_icon01.png" alt="">
      <div class="b-shadow__content">
        <h2 class="b-shadow__title">ГАРАНТИЯ ПРОИЗВОДИТЕЛЯ</h2>
        <p class="b-shadow__text">
          Гарантия производителя служит защитой клиента от неисправностей по причине наличия заводских дефектов в
          оборудовании или преждевременного выхода узлов и компонентов из строя. В течение срока действия гарантии
          клиент имеет право на бесплатное устранение неисправности в соответствии с сервисной политикой производителя.
        </p>
      </div>
    </div>
    <!--  /.B-SHADOW BLOCK  -->

    <div class="col-md-6 pb-4">
      <div class="service-title">
        СИСТЕМЫ ХРАНЕНИЯ ДАННЫХ HPE
      </div>
      <div class="service-item">
        <span class="service-item__text">Дисковые массивы HPE MSA 1000/2000</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Дисковые массивы HPE StoreVirtual 3000/4000</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Дисковые массивы семейства HPE EVA</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Дисковые массивы HPE StoreServ 3PAR</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Ленточные устройства HPE StoreEver (MSL, DAT, Ultrium)</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Файловые хранилища HPE StoreEasy (NAS, X1000/X3000/X5000)</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Системы резервного копирования HPE StoreOnce (D2D)</span>
        <span class="service-item__icon"></span>
      </div>
    </div>
    <!-- /.SERVICE-ITEM BLOCK -->

    <div class="col-md-6 pb-4">
      <div class="service-title">
        СЕРВЕРЫ HPE
      </div>
      <div class="service-item">
        <span class="service-item__text">Серверы HPE ProLiant, BladeSystem (стандартной архитектуры)</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Веб-инфраструктура HPE Moonshot</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-item">
        <span class="service-item__text">Компонуемая инфраструктура HPE Synergy</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-title">
        СЕТЕВОЕ ОБОРУДОВАНИЕ HPE
      </div>
      <div class="service-item">
        <span class="service-item__text">SAN свитчи</span>
        <span class="service-item__icon"></span>
      </div>
      <div class="service-title">
        HI-END ОБОРУДОВАНИЕ
      </div>
      <div class="service-item">
        <span class="service-item__text">HPE x86 BCS Servers (Proliant 9xx), HPE Legacy BCS (Itanium, SuperDome), HPE SAP HANA, NonStop, XP arrays</span>
        <span class="service-item__icon"></span>
      </div>
    </div>
    <!-- /.SERVICE-ITEM BLOCK -->

    <div class="col-md-6">
      <div class="b-shadow">
        <img class="b-shadow__icon" src="/img/icons/hp-guarantee_icon02.png" alt="">
        <div class="b-shadow__content">
          <h2 class="b-shadow__title">ВЫЕЗДНАЯ ГАРАНТИЯ</h2>
          <p class="b-shadow__text">
            На большую часть оборудования HPE действует выездная гарантия. Это значит, что выезд инженера к месту
            расположения устройства вам оплачивать не нужно. Бесплатное выездное обслуживание по заводской гарантии HPE
            осуществляется на расстоянии до 50 км от сервисного центра. Для расширенной гарантии - до 220 км от
            сервисного центра
          </p>
        </div>
      </div>
      <!--  /.B-SHADOW BLOCK  -->
    </div>
    <div class="col-md-6">
      <div class="b-shadow">
        <img class="b-shadow__icon" src="/img/icons/hpe-guarantee_icon03.png" alt="">
        <div class="b-shadow__content">
          <h2 class="b-shadow__title">КРУГЛОСУТОЧНАЯ ПОДДЕРЖКА</h2>
          <p class="b-shadow__text">
            Для оборудования со срочным уровнем сервиса в ряде городов мы обеспечиваем круглосуточную дежурную смену,
            обеспечивающую поддержку в режиме 24x7
          </p>
        </div>
      </div>
      <!--  /.B-SHADOW BLOCK  -->
    </div>

    <div class="col-md-9 mt-5">
      <!--  LIST BLOCK  -->
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Уточнить актуальный статус гарантийных обязательств на ваше устройство можно на сайте HPE по
            <a class="link" href="https://support.hpe.com/hpsc/wc/public/home">ссылке</a>
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Обслуживание выполняется в соответствии с <a class="link" href="http://rus.support/terms">Условиями
            сервисного
            обслуживания</a>
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Если вы затрудняетесь найти продуктовый и серийный номер вашего устройства, воспользуйтесь
            <a class="link" href="http://rus.support/about/instruction">инструкцией</a>
          </p>
        </div>
      </div>
      <!--  /.LIST BLOCK  -->
    </div>
    <div class="col-md-3 mt-5">
      <div class="note">
        <span class="note__square"></span>
        <p class="note__text">
          Пожалуйста, <b>уточняйте заранее</b> возможность обслуживания нужной линейки оборудования в нужном вам
          <a class="note__text_link" href=""><b>населенном пункте</b></a>
        </p>
      </div>
      <!-- /.NOTE BLOCK -->
    </div>
  </div>
</template>

<script>
export default {
  name: "HpeGuarantee"
}
</script>

<style lang="scss" scoped>
.caption {
  margin-bottom: 2rem;
}

.title {
  font-size: 2rem;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
}

.b-shadow {
  height: 100%;

  @media screen and (max-width: 767px) {
    height: auto;
  }
}

</style>