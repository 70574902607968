<template>
  <div class="row py-5">
    <div class="caption">
      <p class="caption__title">
        Реквизиты
      </p>
    </div>
    <!-- /.caption -->
    <div class="requisites">
      <p class="requisites-title">ООО «Рус.Сaппорт»</p>
      <div class="requisites-block">

        <!--Old requisites-->
        <!--<p class="requisite">Юридический адрес: 191119, Санкт-Петербург, Лиговский пр., д. 123, лит. А, пом. 12Н</p>-->
        <p class="requisite">
          Юридический адрес: 197345, Г.САНКТ-ПЕТЕРБУРГ, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ № 65, УЛ ЯХТЕННАЯ, Д. 22 К. 1
          ЛИТЕРА А, ПОМЕЩ. 375
        </p>

        <p class="requisite">Фактический адрес: г. Санкт-Петербург, ул. Яхтенная, д. 22, корп.1, лит. А</p>
        <p class="requisite">Телефон: +7 (812) 386-90-04</p>
      </div>
      <div class="requisites-block">
        <p class="requisite">ОГРН: 5067847216069</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequisitesContent"
}
</script>

<style lang="scss" scoped>
.requisites {
  text-align: left;
  border: 2px solid #0071ba;
  border-radius: 2rem;
  padding: 2rem 5rem;
  max-width: 1140px;
  margin: 0 auto;

  .requisites-title {
    font-weight: 600;
    font-size: 1.25rem;
  }

  .requisites-block {
    margin: 1rem 0;

    .requisite {
      font-size: 0.875rem;
      margin: 0.313rem 0;
    }
  }
}
</style>