<template>
    <div class="row py-5">
        <div class="caption">
            <p class="caption__title">
                ПОЛОЖЕНИЕ
            </p>
        </div>
        <!-- /.caption -->

        <p class="title">
            о порядке хранения и защиты персональных данных пользователей
            ООО "Рус.Саппорт"
            (редакция от «14» ноября 2023 г.)
        </p>
        <PrivacyAccordion/>
    </div>
</template>

<script>

import PrivacyAccordion from "./PrivacyAccordion/PrivacyAccordion";

export default {
    name: "PrivacyContent",
    components: {
        PrivacyAccordion
    }
}
</script>

<style lang="scss" scoped>
.paragraph {
    font-size: 1.188em;
    text-align: left;
}

.title {
    text-align: center;
    font-size: 2em;
}
</style>