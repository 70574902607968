<template>
  <div class="row py-5">
    <p class="paragraph">
      На базе собственного тренинг-центра мы проводим практические занятия для сервисных инженеров и технических
      специалистов по обслуживанию и ремонту устройств печати
    </p>

    <Carousel/>

    <p class="paragraph">
      Основной профиль наших мероприятий – это занятия для инженеров, обслуживающих печатную технику HP Inc., знакомство
      с новыми устройствами и технологиями HP, демонстрация оборудования клиентам.
    </p>
    <p class="paragraph">
      Если вы заинтересованы в улучшении теоретических знаний и практических навыков ваших сервисных специалистов –
      присылайте нам запрос на адрес edu@rus.support.
    </p>
  </div>
</template>

<script>

import Carousel from "@/pages/About/we-do/OthersCourses/carousel/carousel-root"

export default {
  name: "OthersCoursesContent",
  components: {
    Carousel
  }
}
</script>

<style lang="scss" scoped>

</style>