<template>
  <router-link to="/" class="link-home link-regular">
    <img class="link-home__img" src="/img/layout/logo.svg"/>
  </router-link>
</template>

<script>
export default {
  name: "linkHome"
}
</script>

<style lang="scss" scoped>
.link-home__img {
  padding: 1.3rem 0;
  max-width: 180px;


  @media screen and (max-width: 1024px) {
    width: 80%;
  }

  @media screen and (max-width: 420px) {
    width: 60%;
  }
}
</style>