<template>
    <div class="accordion">
        <p class="edition-show" @click="editionShow">Показать все редакции</p>
        <div class="edition-hidden-block" v-if="editionsShow">
            <div class="edition-hidden-block__item" @click="switcher('2021')">Редакция от 23.07.2021</div>
            <div class="edition-hidden-block__item" @click="switcher('2020')">Редакция от 06.05.2020</div>
            <div class="edition-hidden-block__item" @click="switcher('2017')">Редакция от 12.09.2017</div>
        </div>

        <div v-for="(item, index) in editions[defaultEdition][0].obj" :key="index">
            <div class="accordion-title" @click="item.visible = !item.visible">
                {{ item.num }}. {{ item.title }}
            </div>
            <div class="accordion-content">
                <transition name="fade">
                    <component :is="item.component" v-if="item.visible"/>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>

import {editionTitles} from "../Editions/editionTitles";

//2017
import admissionConditions2017 from "../Editions/2017/admissionConditions2017";
import generalProvisions2017 from "../Editions/2017/generalProvisions2017";
import outOfWarranty2017 from "../Editions/2017/outOfWarranty2017";
import provisionServices2017 from "../Editions/2017/provisionServices2017";
import rightsContent2017 from "../Editions/2017/rightsContent2017";
import serviceConditions2017 from "../Editions/2017/serviceConditions2017";
import serviceGuarantee2017 from "../Editions/2017/serviceGuarantee2017";
import storageСonditions2017 from "../Editions/2017/storageСonditions2017";
import termsContent2017 from "../Editions/2017/termsContent2017";
import termsOfIssue2017 from "../Editions/2017/termsOfIssue2017";
import termsOfService2017 from "../Editions/2017/termsOfService2017";
//2020
import admissionConditions2020 from "../Editions/2020/admissionConditions2020";
import generalProvisions2020 from "../Editions/2020/generalProvisions2020";
import outOfWarranty2020 from "../Editions/2020/outOfWarranty2020";
import provisionServices2020 from "../Editions/2020/provisionServices2020";
import rightsContent2020 from "../Editions/2020/rightsContent2020";
import serviceConditions2020 from "../Editions/2020/serviceConditions2020";
import serviceGuarantee2020 from "../Editions/2020/serviceGuarantee2020";
import storageConditions2020 from "../Editions/2020/storageСonditions2020";
import termsContent2020 from "../Editions/2020/termsContent2020";
import termsOfIssue2020 from "../Editions/2020/termsOfIssue2020";
import termsOfService2020 from "../Editions/2020/termsOfService2020";
//2021
import admissionConditions2021 from "../Editions/2021/admissionConditions2021";
import generalProvisions2021 from "../Editions/2021/generalProvisions2021";
import outOfWarranty2021 from "../Editions/2021/outOfWarranty2021";
import provisionServices2021 from "../Editions/2021/provisionServices2021";
import rightsContent2021 from "../Editions/2021/rightsContent2021";
import serviceConditions2021 from "../Editions/2021/serviceConditions2021";
import serviceGuarantee2021 from "../Editions/2021/serviceGuarantee2021";
import storageConditions2021 from "../Editions/2021/storageСonditions2021";
import termsContent2021 from "../Editions/2021/termsContent2021";
import termsOfIssue2021 from "../Editions/2021/termsOfIssue2021";
import termsOfService2021 from "../Editions/2021/termsOfService2021";

export default {
    name: "Accordion",
    props: ["placementDate"],
    data() {
        return {
            editions: editionTitles,
            defaultEdition: "2021",
            editionsShow: false,
        }
    },
    components: {
        admissionConditions2017,
        generalProvisions2017,
        outOfWarranty2017,
        provisionServices2017,
        rightsContent2017,
        serviceConditions2017,
        serviceGuarantee2017,
        storageСonditions2017,
        termsContent2017,
        termsOfIssue2017,
        termsOfService2017,
        //2020
        admissionConditions2020,
        generalProvisions2020,
        outOfWarranty2020,
        provisionServices2020,
        rightsContent2020,
        serviceConditions2020,
        serviceGuarantee2020,
        storageConditions2020,
        termsContent2020,
        termsOfIssue2020,
        termsOfService2020,
        //2021
        admissionConditions2021,
        generalProvisions2021,
        outOfWarranty2021,
        provisionServices2021,
        rightsContent2021,
        serviceConditions2021,
        serviceGuarantee2021,
        storageConditions2021,
        termsContent2021,
        termsOfIssue2021,
        termsOfService2021
    },
    methods: {
        switcher(year) {
            if (year) {
                this.defaultEdition = year
                this.$emit('placementDate', {
                    placeDate: this.editions[this.defaultEdition][0].placement_date,
                    effectDate: this.editions[this.defaultEdition][0].effective_date
                })
            }
        },
        editionShow() {
            this.editionsShow = !this.editionsShow
        }
    },
    mounted() {
        this.$emit('placementDate', {
            placeDate: this.editions[this.defaultEdition][0].placement_date,
            effectDate: this.editions[this.defaultEdition][0].effective_date
        })
    },
    watch: {
        editions() {
            console.log('edit')
        }
    }
}
</script>

<style lang="scss" scoped>
.accordion {
    .edition-show {
        font-weight: 600;
        font-size: 20px;
        color: #0a73bb;
        cursor: pointer;
    }

    .edition-hidden-block {
        border: 1px solid #0a73bb;
        padding: 10px;
        margin-bottom: 20px;

        &__item {
            padding: 5px 0;
            cursor: pointer;
            color: #0a73bb;
            transition: .2s;
            font-weight: 600;

            &:hover {
                color: #0a73bb75;
            }
        }
    }

    .accordion-title {
        background-color: #0a73bb;
        color: #ffffff;
        padding: 10px;

        &:hover {
            cursor: pointer;
        }
    }

    .accordion-content {
        padding: 10px;
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>