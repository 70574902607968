<template>
    <div id="homeSlide2" class="slide-content">
        <div class="slider__2 d-sm-flex">
            <div>
                <div class="slider__2-up">11</div>
                <div class="slider__2-bottom">
                    часовых <br>
                    поясов
                </div>
            </div>
            <span class="col-auto separator">/</span>
            <div>
                <div class="slider__2-up">120</div>
                <div class="slider__2-bottom">
                    сервисных <br>
                    центров
                </div>
            </div>
            <span class="col-auto separator">/</span>
            <div>
                <div class="slider__2-up">>2000</div>
                <div class="slider__2-bottom">
                    заявок <br>
                    в месяц
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "homeSlide2Content"
  }
</script>

<style lang="scss" scoped>
    .slider__2 {
        display: flex;
        justify-content: space-around;
        font-size: 1vw;

        @media (max-width: 768px) {
            font-size: 7px;
        }


        .slider__2-up {
            font-size: 4em;
            color: white;
            font-weight: 600;
        }

        .slider__2-bottom {
            text-transform: uppercase;
            font-size: 2em;
            color: white;
            font-weight: 600;
        }

        .separator {
            font-size: 6em;
            color: white;
            font-weight: 100;
            opacity: 0.5;
        }
    }
</style>