<template>
    <div class="privacy-accordion__item-content">
        <!--    <p>
              <b>Сайт</b> — совокупность программных и аппаратных средств для ЭВМ, обеспечивающих публикацию для всеобщего обозрения
              информации и данных, объединенных общим целевым назначением, посредством технических средств, применяемых для
              связи между ЭВМ в сети Интернет. Под Сайтом в Соглашении понимается Сайт, расположенный в сети Интернет по адресу:
              https://rus.support/.
            </p>
            <p><b>Пользователь</b> — пользователь сети Интернет и, в частности, Сайта.</p>
            <p><b>Федеральный закон (ФЗ)</b> — Федеральный закон от 27 июля 2006 г. № 152 ФЗ «О персональных данных».</p>
            <p>
              <b>Персональные данные</b> — любая информация, относящаяся прямо или косвенно к определенному или определяемому
              физическому
              лицу (субъекту персональных данных).
            </p>
            <p>
              <b>Оператор</b> — организация, самостоятельно или совместно с другими лицами организующая обработку персональных данных,
              а
              также определяющая цели обработки персональных данных, подлежащих обработке, действия (операции), совершаемые с
              персональными данными. Оператором является общество с ограниченной ответственностью «Рус.Саппорт», расположенное
              по
              адресу: 191144, г.Санкт-Петербург, ул. Моисеенко, д. 24, лит. А, пом. 108.
            </p>
            <p>
              <b>Обработка персональных данных</b> — любое действие (операция) или совокупность действий (операций), совершаемых с
              использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор,
              запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
              передачу
              (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
            </p>
            <p>
              <b>Автоматизированная обработка персональных данных</b> — обработка персональных данных с помощью средств вычислительной
              техники.
            </p>
            <p>
              <b>Распространение персональных данных</b> — действие, направленное на раскрытие персональных данных определенному кругу
              лиц по предварительному согласию, в случаях, предусмотренных законом.
            </p>
            <p>
              <b>Предоставление персональных данных</b> — действия, направленные на раскрытие персональных данных определенному лицу
              или
              определенному кругу лиц.
            </p>
            <p>
              <b>Блокирование персональных данных</b> — временное прекращение обработки персональных данных (за исключением случаев,
              если
              обработка необходима для уточнения персональных данных).
            </p>
            <p>
              <b>Уничтожение персональных данных</b> — — действия, в результате которых становится невозможным восстановить содержание
              персональных данных в информационной системе персональных данных и/или в результате которых уничтожаются
              материальные носители персональных данных.
            </p>
            <p>
              <b>Обезличивание персональных данных</b> — действия, в результате которых становится невозможным без использования
              дополнительной информации определить принадлежность персональных данных к конкретному субъекту персональных данных
            </p>
            <p>
              <b>Информационная система персональных данных (ИСПДн)</b> — совокупность содержащихся в базах данных персональных данных
              и
              обеспечивающих их обработку информационных технологий и технических средств.
            </p>-->
        <p>
            Настоящая Политика конфиденциальности персональных данных (далее - Политика конфиденциальности) является
            неотъемлемой частью Публичной оферты, размещенной на сайте в сети Интернет по адресу: https://rus.support
            (далее - Сайт).
            Использование сервисов Сайта означает безоговорочное согласие Пользователя с настоящей Политикой
            конфиденциальности и указанными в ней условиями обработки его персональных данных; в случае несогласия с
            этими условиями Пользователь должен воздержаться от использования сервисов.
        </p>
        <h2>1. Термины и определения</h2>
        <p>
            В настоящей Политике конфиденциальности используются следующие основные термины и определения:
        </p>
        <p>
            <strong>Персональные данные (ПДн)</strong> - любая информация, относящаяся к прямо или косвенно
            определенному или
            определяемому физическому лицу (субъекту персональных данных).
        </p>
        <p>
            <strong>Информационная система персональных данных (ИСПДн)</strong> — совокупность содержащихся в базах
            данных персональных
            данных и обеспечивающих их обработку информационных технологий и технических средств.
        </p>
        <p>
            <strong>Оператор персональных данных (Оператор)</strong> - государственный орган, муниципальный орган,
            юридическое или
            физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку
            персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных,
            подлежащих обработке, действия (операции), совершаемые с персональными данными. Оператором является общество
            с ограниченной ответственностью "Рус.Саппорт".
        </p>
        <p>
            <strong>Обработка персональных данных</strong> - любое действие (операция) или совокупность действий
            (операций), совершаемых
            с использованием средств автоматизации или без использования таких средств с персональными данными, включая
            сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
            уничтожение персональных данных.
        </p>
        <p>
            <strong>Автоматизированная обработка персональных данных</strong> - обработка персональных данных с помощью
            средств
            вычислительной техники.
        </p>
        <p>
            <strong>Неавтоматизированная обработка персональных данных</strong> - обработка персональных данных,
            осуществляемая при
            непосредственном участии человека без использования средств автоматизации.
        </p>
        <p>
            <strong>Распространение персональных данных</strong> - действия, направленные на раскрытие персональных
            данных
            неопределенному кругу лиц.
        </p>
        <p>
            <strong>Предоставление персональных данных</strong> - действия, направленные на раскрытие персональных
            данных определенному
            лицу или определенному кругу лиц.
        </p>
        <p>
            <strong>Блокирование персональных данных</strong> - временное прекращение обработки персональных данных (за
            исключением
            случаев, если обработка необходима для уточнения персональных данных).
        </p>
        <p>
            <strong>Уничтожение персональных данных</strong> - действия, в результате которых становится невозможным
            восстановить
            содержание персональных данных в информационной системе персональных данных и (или) в результате которых
            уничтожаются материальные носители персональных данных.
        </p>
        <p>
            <strong>Обезличивание персональных данных</strong> - действия, в результате которых становится невозможным
            без использования
            дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных
            данных.
        </p>
        <p>
            <strong>Конфиденциальность персональных данных</strong> – обязательное для соблюдения оператором и иными
            лицами, получившими
            доступ к персональным данным, требование не раскрывать третьим лицам и не распространять персональные данные
            без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.
        </p>
        <p>
            <strong>Субъект персональных данных</strong> - физическое лицо, которое прямо или косвенно, определено или
            определяемо с
            помощью персональных данных.
        </p>
        <p>
            <strong>Персональные данные ограниченного доступа</strong> - персональные данные субъектов персональных
            данных, подлежащие
            защите в установленном законодательством Российской Федерации порядке.
        </p>
        <p>
            <strong>Безопасность персональных данных</strong> - состояние защищенности персональных данных,
            характеризуемое способностью
            пользователей, технических средств и информационных технологий обеспечить конфиденциальность, целостность и
            доступность персональных данных при их обработке в информационных системах персональных данных.
        </p>
        <p>
            <strong>Режим защиты персональных данных</strong> - нормативно установленные правила, определяющие
            ограничения доступа к
            персональным данным, порядок передачи и условия их хранения.
        </p>
        <p>
            <strong>Защита персональных данных</strong> - комплекс мероприятий технического, организационного и
            организационно-технического характера, направленных на защиту сведений, относящихся к определенному или
            определяемому на основании такой информации субъекту персональных данных.
        </p>
        <p>
            <strong>Сайт</strong> — совокупность программных и аппаратных средств для ЭВМ, обеспечивающих публикацию для
            всеобщего
            обозрения информации и данных, объединенных общим целевым назначением, посредством технических средств,
            применяемых для связи между ЭВМ в сети Интернет. Под Сайтом в Политике конфиденциальности понимается Сайт,
            расположенный в сети Интернет по адресу: https://rus.support.
        </p>
        <p>
            <strong>Пользователь</strong> — пользователь сети Интернет и, в частности, Сайта.
        </p>
        <p>
            <strong>Файлы Cookie</strong> — текстовые файлы, которые браузер каждый раз пересылает серверу при попытке
            открыть страницу
            соответствующего сайта, используемые для аутентификации Пользователя, хранения его персональных предпочтений
            и настроек, отслеживания состояния сессии доступа Пользователя, ведения статистики о Пользователях и т.п.
        </p>
        <p>
            <strong>Администрация Сайта</strong> – уполномоченные сотрудники на управление Cайтом, которые организуют и
            (или)
            осуществляют обработку персональных данных, а также определяет цели обработки персональных данных, состав
            персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
        </p>
    </div>
</template>

<script>
export default {
    name: "termsDefinitions"
}
</script>

<style scoped>

</style>