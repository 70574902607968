<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      АУТСОРСИНГ ПРОЦЕССОВ ПЕЧАТИ
      И ПОКОПИЙНАЯ ПЕЧАТЬ (MPS)
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Rus.support оказывает услуги аутсорсинга печати по всей России. Это современный подход к офисной печати, когда вы
      передаете ведение всех процессов подрядчику. Мы поможем оптимизировать работу устройств, улучшить качество и
      уменьшить затраты на печать.
    </p>
  </div>
</template>

<script>
export default {
  name: "hpOutsourceprintSliderContent"
}
</script>

<style scoped>

</style>