<template>
  <div class="carousel-wrapper">
    <div class="carousel-slider" :style="{'margin-left': '-' + (100 * sliderIndex) + '%'}">
      <CarouselItem

          v-for="item in carousel_data"
          :key="item.id"
          :items="item"

      />
    </div>
    <button @click="prevSlider">Prev</button>
    <button @click="nextSlider">Next</button>
  </div>
</template>

<script>

import CarouselItem from "@/pages/About/we-do/OthersCourses/carousel/carousel-item"

export default {
  name: "carousel-slider",
  components: {
    CarouselItem
  },
  props: {
    carousel_data: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      sliderIndex: 0
    }
  },
  methods: {
    prevSlider() {
      if (this.sliderIndex > 0) {
        this.sliderIndex--
      }
    },
    nextSlider() {
      if (this.sliderIndex >= this.carousel_data.length - 1) {
        this.sliderIndex = 0
      } else {
        this.sliderIndex++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel-wrapper {
  max-width: 1024px;
  margin: 0 auto;
  overflow: hidden;

  .carousel-slider {
    display: flex;
    transition: all ease .5s;
  }
}
</style>