<template>
  <div class="row py-5">
    <img src="/img/pages/aruba_service.jpg" alt="">
    <p>
      Когда работа локальной сети жизненно важна для вашей организации и бизнес нуждается в персонализированном и
      качественном обслуживании сетевой инфраструктуры – обратитесь к авторизованному сервисному партнеру Aruba.
    </p>
    <p>
      Как сервисный партнер Aruba, мы можем предложить вам единую точку приема обращений по вопросам работы сети, гибкие
      уровни поддержки, исходя из ваших потребностей, услуги по замене и настройке сетевого оборудования, обновлению
      прошивок, предоставление регулярной отчётности. То есть все, что расширяет стандартные условия базовой гарантии на
      сетевые устройства Aruba.
    </p>
    <p>
      Для решения данных задач мы обладаем опытом, штатом обученных и сертифицированных специалистов и доступом к
      технической информации и поддержке производителя.
    </p>
    <p>
      Если вас заинтересовала поддержка сетевой инфраструктуры – присылайте нам запрос на адрес
      <a class="link" href="mailto:sale@rus.support">sale@rus.support</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "ArubaContent"
}
</script>

<style scoped>

</style>
