/*jshint esversion: 6 */
const wedoLinks = [
  {
    groupId:"hpList",
    groupDisplayName:"HP",
    links: [
      {
        id: 0,
        linkName: 'Гарантийный ремонт',
        linkPath: '/about/hp_guarantee'
      },
      {
        id: '',
        linkName: 'Услуги HP Care Pack',
        linkPath: '/about/hp_carepack'
      },
      {
        id: '',
        linkName: 'Сервисные контракты на оборудование HP Inc.',
        linkPath: '/about/hp_contracts'
      },
      {
        id: '',
        linkName: 'Постгарантийное обслуживание',
        linkPath: '/about/hp_postguarantee'
      },
      {
        id: '',
        linkName: 'Поставки оригинальных запчастей',
        linkPath: '/about/hp_spareparts'
      },
      {
        id: '',
        linkName: 'Сервисы для широкоформатных устройств HP',
        linkPath: '/about/hp_widescreen'
      },
      {
        id: '',
        linkName: 'Аутсорсинг печати (MPS)',
        linkPath: '/about/hp_outsourceprint'
      },
      {
        id: '',
        linkName: 'Инсталляция и модернизация оборудования',
        linkPath: '/about/hp_upgrade'
      },
      {
        id: '',
        linkName: 'Рабочее место «как сервис» (DaaS)',
        linkPath: '/about/hp_daas'
      },
    ]
  },



  {
    groupId:"hpeList",
    groupDisplayName:"HPE",
    links: [
      {
        id: 0,
        linkName: 'Гарантийный ремонт',
        linkPath: '/about/hpe_guarantee'
      },
      {
        id: 1,
        linkName: 'Услуги НРЕ Packaged Service',
        linkPath: '/about/hpe_packaged'
      },
      {
        id: 2,
        linkName: 'Сервисные контракты на оборудование HPE',
        linkPath: '/about/hpe_contracts'
      },
      {
        id: 3,
        linkName: 'Инсталляция, модернизация и релокация оборудования',
        linkPath: '/about/hpe_upgrade'
      },
      {
        id: 4,
        linkName: 'Проактивный анализ оборудования',
        linkPath: '/about/hpe_proactive'
      },
      {
        id: 5,
        linkName: 'Постгарантийное обслуживание',
        linkPath: '/about/hpe_postguarantee'
      },
      {
        id: 6,
        linkName: 'Поставки оригинальных запчастей',
        linkPath: '/about/hpe_spareparts'
      },
    ]
  },
  {
    groupId:"samsungList",
    groupDisplayName:"SAMSUNG",
    links: [
      {
        id: 0,
        linkName: 'Гарантийный ремонт принтеров и МФУ',
        linkPath: '/about/samsung_guarantee'
      },
      {
        id: 1,
        linkName: 'Постгарантийное обслуживание',
        linkPath: '/about/samsung_postguarantee'
      },
      {
        id: 2,
        linkName: 'Поставки оригинальных запчастей',
        linkPath: '/about/samsung_spareparts'
      }
    ]
  },
  {
    groupId:"othersList",
    groupDisplayName:"ПРОЧИЕ УСЛУГИ",
    links: [
      {
        id: 0,
        linkName: 'Аутсорсинг ИТ-услуг',
        linkPath: '/about/others_outsource'
      },
      {
        id: 1,
        linkName: 'Сборка и поставка серверов под заказ',
        linkPath: '/about/others_servers'
      },
      {
        id: 2,
        linkName: 'Обслуживание ИБП APC',
        linkPath: '/about/others_apc'
      },
      {
        id: 3,
        linkName: 'Курсы для сервисных инженеров и мероприятия для клиентов',
        linkPath: '/about/others_courses'
      },
    ]
  },

];

export default {
  getWedoLinks() {
    return wedoLinks;
  },
};