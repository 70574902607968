<template>
  <!-- Content start -->
  <div class="container list-service-wrap">
    <HpContent/>
  </div>
  <!-- Content end -->
</template>

<script>

import HpContent from "@/pages/About/Hp/HpContent";

const buttons = [];

const slides = [
  {
    background: "/img/page-slider/main_page_1.jpg",
    template: "hpSlideContent",
    buttons: buttons
  },
];

export default {
  name: "Hp",
  data() {
    return {
      dsVendors: [],
      slides: slides
    }
  },
  created() {
    const layout = {
      name: "Slider",
      data: this.slides
    };
    this.$store.commit('setLayout', layout)

    //console.log(this.$route.params.sc, this.$store.state.serviceCenters)
  },
  components: {
    HpContent
  }
}
</script>

<style scoped>

</style>