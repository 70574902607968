const accordionContent = [
    {
        id: 0,
        title: '1. Термины и определения',
        component: 'termsDefinitions',
        componetShow: false,
        styleClass: 'active'
    },
    {
        id: 1,
        title: '2. Общие положения',
        component: 'generalProvisions',
        componetShow: false
    },
    {
        id: 3,
        title: '3. Обработка персональных данных',
        component: 'personalData',
        componetShow: false
    },
    {
        id: 4,
        title: '4. Система защиты персональных данных',
        component: 'termsOfService',
        componetShow: false
    },
    {
        id: 5,
        title: '5. Дополнительные условия',
        component: 'finalProvisions',
        componetShow: false
    },
];

export {accordionContent};