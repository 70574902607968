const footerServiceLinks = [
    {
        id: 0,
        nameLink: 'Hewlett Packard Enterprise',
        pathLink: '/about/hpe'
    },
    {
        id: 1,
        nameLink: 'HP inc.',
        pathLink: '/about/hp'
    },
    {
        id: 3,
        nameLink: 'Samsung',
        pathLink: '/about/samsung'
    },
    {
        id: 4,
        nameLink: 'APC by Schneider Electric',
        pathLink: '/about/apc'
    },
    {
        id: 5,
        nameLink: 'Прочие услуги',
        pathLink: '/about/otherservices'
    },
];

const footerOtherLinks = [
    {
        id: 0,
        nameLink: 'Условия обслуживания',
        pathLink: '/terms'
    },
    {
        id: 1,
        nameLink: 'Реквизиты',
        pathLink: '/requisites'
    },
    {
        id: 2,
        nameLink: 'Полезная информация',
        pathLink: '/about/instruction'
    }
];

export {footerServiceLinks, footerOtherLinks}