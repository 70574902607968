<template>
  <div class="row py-5">
    <div class="title">
      <b>СЕРВИСНЫЕ КОНТРАКТЫ</b><br>
      ПОЗВОЛЯЮТ
    </div>
    <div class="col-md-8">

      <!-- LIST BLOCK -->
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Вернуть на гарантию старое оборудование, у которого гарантия уже закончилась
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Получить гибкие сроки поддержки
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Самостоятельно устанавливать нужный срок гарантии и уровень сервиса
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Срочно восстановить критически важное оборудование, вплоть до SLA со временем ремонта 6 часов
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Выравнять сроки и условия гарантии для всего парка устройств, а значит, контролировать срок
            окончания поддержки будет легче
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Оплачивать услуги ежемесячно или ежеквартально
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Получить выездное обслуживание для всех видов оборудования HPE
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Увеличить радиус выездного обслуживания до 220 км
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Включить дополнительные опции, необходимые вашей компании
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Досрочно прекратить получение услуг
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Позволяют вашим сотрудникам проходить обучающие курсы в учебном центре HPE в Москве
          </p>
        </div>
      </div>
      <!-- /.LIST BLOCK -->

    </div>
    <div class="col-md-4">
      <div class="note-white">
        <span class="note__square"></span>
        <span class="note__title">
          Срочное восстановление
        </span>
        <p class="note__text">
          Сервис гарантированного восстановления доступен лишь в некоторых городах. Уточняйте у нас возможность срочного
          обслуживания
        </p>
      </div>
      <!-- /.NOTE-WHITE BLOCK -->

      <div class="note">
        <span class="note__square"></span>
        <p class="note__text">
          Пожалуйста, <b>уточняйте заранее</b> возможность обслуживания нужной линейки оборудования в нужном вам
          <a class="note__text_link" href=""><b>населенном пункте</b></a>
        </p>
      </div>
      <!-- /.NOTE BLOCK -->
    </div>

    <div class="caption">
      <p class="caption__title">
        ОТЛИЧИЕ ОТ HPE PACKAGED SERVICE
      </p>
    </div>
    <!-- /.caption -->

    <div class="col-md-6">

      <div class="b-shadow">
        <div class="b-shadow__content">
          <h2 class="title">СЕРВИСНЫЕ КОНТРАКТЫ HPE</h2>

          <!--  LIST BLOCK  -->
          <div class="list">
            <div class="list__icon"></div>
            <div class="list__content">
              <p class="list__content_text">
                Сервисное обслуживание со сроками и уровнем сервиса, который вы выбираете сами.
              </p>
            </div>
          </div>
          <div class="list">
            <div class="list__icon"></div>
            <div class="list__content">
              <p class="list__content_text">
                В сервисный контракт можно добавить дополнительные опции. Например, работу выделенного менеджера по
                технической поддержке, проактивные работы, персональный график оплаты.
              </p>
            </div>
          </div>
          <div class="list">
            <div class="list__icon"></div>
            <div class="list__content">
              <p class="list__content_text">
                Цена зависит от перечня выбранных услуг.
              </p>
            </div>
          </div>
          <div class="list">
            <div class="list__icon last"></div>
            <div class="list__content">
              <p class="list__content_text">
                Зарегистрировать сервисный контракт можно на устройство, у которого давно закончилась гарантия.
              </p>
            </div>
          </div>
          <!--  /.LIST BLOCK  -->

        </div>
      </div>
      <!--  /.B-SHADOW BLOCK  -->

    </div>

    <div class="col-md-6">
      <div class="b-shadow">
        <div class="b-shadow__content">
          <h2 class="title">HPE PACKAGED SERVICE</h2>

          <!--  LIST BLOCK  -->
          <div class="list">
            <div class="list__icon"></div>
            <div class="list__content">
              <p class="list__content_text">
                Готовые пакеты сервисных услуг.
              </p>
            </div>
          </div>
          <div class="list">
            <div class="list__icon"></div>
            <div class="list__content">
              <p class="list__content_text">
                Сроки и уровни сервиса заранее определены и подходят для типовых ситуаций.
              </p>
            </div>
          </div>
          <div class="list">
            <div class="list__icon"></div>
            <div class="list__content">
              <p class="list__content_text">
                Пакеты имеют фиксированную цену.
              </p>
            </div>
          </div>
          <div class="list">
            <div class="list__icon last"></div>
            <div class="list__content">
              <p class="list__content_text">
                Зарегистрировать сервисный пакет HPE Packaged Service можно на устройство с действующей гарантией или
                в течение 30 дней после ее окончания.
              </p>
            </div>
          </div>
          <!--  /.LIST BLOCK  -->

          <p>Подробнее о HPE Packaged Service: <a class="link" href="http://rus.support/about/we-do?action=hpe_packaged">ссылка</a></p>

        </div>
      </div>
      <!--  /.B-SHADOW BLOCK  -->
    </div>

    <p class="prompt">
      Если вас интересуют услуги HPE Packaged Services — отправьте нам заявку на адрес
      <a class="link" href="mailto:sale@rus.support">sale@rus.support</a>, и мы
      подберем нужный сервис и вышлем вам предложение
    </p>
  </div>
</template>

<script>
export default {
  name: "HpePackagedContent"
}
</script>

<style lang="scss" scoped>

.title {
  margin-bottom: 1rem;
}

.note-white {
  max-width: 100%;
}

.b-shadow {
  height: 100%;

  @media screen and (max-width: 767px) {
    height: auto;
  }
}

.prompt {
  margin-top: 2rem;
}
</style>