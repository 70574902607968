<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      СЕРВИСНЫЕ КОНТРАКТЫ
      НА ОБОРУДОВАНИЕ HP INC.
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Для заказчиков с большим парком оборудования HP Inc. выгоднее и удобнее приобретать сервис не в виде набора
      пакетов HP CarePack, а в виде сервисных контрактов
    </p>
  </div>
</template>

<script>
export default {
  name: "HpContractsSliderContent"
}
</script>

<style lang="scss" scoped>

</style>