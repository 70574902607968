<template>
  <div id="departmentsMapView">
    <div class="map-section">
      <yandex-map
          :key="componentKey"
          :zoom="zoom"
          style="width: 100%; height: 100%"
          :settings="settings"
          :coords="location.coords"
          :controls="['zoomControl']"
          map-type="map"
          :scroll-zoom=false
          @click="onClick"
          @map-was-initialized="mapInitializedAction($event)"
      >
        <ymap-marker
            v-for="(data) in features"
            :key="data.ssc_id"
            :marker-id="data.ssc_id"
            :marker-type="options.markerType"
            :hint-content="options.hintContent"
            :icon="iconTemplate(data.ssc_isself)"
            :coords="markerCoord(data)"
            @balloonopen="bindBalloonListeners"
            @balloonclose="unbindBalloonListeners"
        >
          <balloon
              slot="balloon"
              :featureData="data"
              :options="balloonOptions"
          ></balloon>
        </ymap-marker>
      </yandex-map>
    </div>
    <div class="toner" v-if="!selectedServiceKeys.length">
      <div class="message-area">
        <p>Пожалуйста, <span class="message-area__item">выберите</span> производителя и вид оборудования, чтобы начать
          поиск сервисного центра</p>
      </div>
    </div>
  </div>
</template>

<script>
import {yandexMap, ymapMarker} from 'vue-yandex-maps'
import balloon from './__balloon'

export default {
  name: "Map",
  props: ["features", "location", "zoom", "selectedServiceKeys", "scToLoad"],
  components: {yandexMap, ymapMarker, balloon},
  data() {
    const settings = {
      apiKey: '870eeb7b-3515-4a77-b4b5-a57f172731e4',
      lang: 'ru_RU',
      coordorder: 'latlong',
      version: '2.1'
    };
    const options = {
      markerType: "placemark",
      hintContent: "",
    };
    const balloonOptions = {
      headerIcon: "/images/balloones/top/top_icon.png",
    };

    return {
      options: options,
      settings: settings,
      balloonOptions: balloonOptions,
      mapInstance: null,
      gettingSc: null,
      featuresMod: [],
      componentKey: 0
    }
  },

  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    onClick(e) {
      this.coords = e.get('coords');
    },
    bindBalloonListeners() {
      let balloon = document.getElementById('widthBalloon').parentNode.parentNode;
      balloon.style.width = '455px';

      if (document.documentElement.clientWidth <= 500) {
        balloon.style.width = '290px';
      }

      if (document.documentElement.clientWidth <= 350) {
        balloon.style.width = '100%';
      }

      window.onresize = () => {
        if (document.documentElement.clientWidth <= 500) {
          balloon.style.width = '290px';
        }
        if (document.documentElement.clientWidth > 500) {
          balloon.style.width = '455px';
        }
      }

      let ballonMargin = document.querySelectorAll('.ymaps-2-1-79-balloon__content')
      ballonMargin[0].style.marginRight = '0'

      let baloonCloseBackground = document.querySelectorAll('.ymaps-2-1-79-balloon__close-button')
      baloonCloseBackground[0].style.opacity = 1;
      baloonCloseBackground[0].style.backgroundColor = 'white';

      document.getElementById('create-order').addEventListener('click', this.handler)
      document.getElementById('feedback').addEventListener('click', this.handler)
      document.querySelectorAll('.question-img').forEach((elem) => {
        elem.addEventListener('mouseover', this.hoverAuthItem)
      })
      document.querySelectorAll('.question-img').forEach((elem) => {
        elem.addEventListener('mouseout', this.hoverAuthItemDelete)
      })
    },
    unbindBalloonListeners() {
      document.getElementById('create-order').removeEventListener('click', this.handler)
      document.getElementById('feedback').removeEventListener('click', this.handler)
    },
    handler(e) {
      if (e.currentTarget.id === 'create-order' || e.currentTarget.id === 'feedback') {
        const action = this.$store.state.feedbackMode ? "off" : "on";
        this.$store.commit('switchFeedbackMode', action)
        this.$store.commit('setModalFormType', e.currentTarget.id)
      }

      for (let i = 0; i < this.$store.state.serviceCenters.length; i++) {
        let sc = this.$store.state.serviceCenters[i]

        if (e.currentTarget.className === 'button ' + sc.ssc_capture) {
          const adr = sc.ssc_address
          const id = sc.ssc_id
          this.$store.commit('setAdressCapture', adr)
          this.$store.commit('setSscId', id)
        }
      }
    },

    hoverAuthItem(e) {
      let dataset = e.target.dataset.tooltip;

      if (e.target.className) {
        let elem = e.target.nextSibling

        elem.textContent = dataset
        elem.style.cssText = "position: absolute; display: block;"
        elem.style.zIndex = 9999999
        elem.style.width = '300px'
        elem.style.top = '-30px'

        if (elem.offsetHeight >= 40) {
          elem.style.top = '-50px'
        }

        if (elem.offsetHeight >= 90) {
          elem.style.top = '-90px'
        }
      }

    },

    hoverAuthItemDelete(e) {
      let elem = e.target.nextSibling
      if (e.target.className) {
        e.target.nextSibling.innerHTML = ''
        elem.style.cssText = "position: absolute; display: none;"
      }
    },

    markerCoord(data) {
      return [data.ssc_latitude, data.ssc_longitude];
    },
    iconTemplate(isself) {
      const serverName = location.origin;
      const imageName = isself === '1' ? "icon_self.png" : "icon_default.png";
      const imageHref = serverName + "/img/icons/" + imageName;
      const imageSize = isself === '1' ? [45, 45] : [40, 40];
      return {
        layout: 'default#image',
        imageHref: imageHref,
        imageSize: imageSize,
        imageOffset: [-20, -20],
      }
    },
    mapInitializedAction(e) {
      this.mapInstance = e;
      if (this.mapInstance && this.gettingSc) {
        this.openScRecord()
      }
    },
    openScRecord(map) {
      const mapInstance = this.mapInstance;
      const gettingSc = this.gettingSc;
    }
  },
  watch: {
    scToLoad(val) {
      this.gettingSc = val;

      if (this.mapInstance && this.gettingSc) {
        this.openScRecord()
      }
    },
    features() {
      if (this.features.length !== 0) {
        this.forceRerender();
      }
    }
  }
}
</script>

<style lang="scss" scoped>

#departmentsMapView {
  position: relative;
  width: 100%;
  height: 100%;
}

#departmentsMapView:after {
  //content: "";
  //display: block;
  //padding-bottom: 100%;
}

#departmentsMapView > div.map-section {
  position: absolute;
  width: 100%;
  height: 75%;
  z-index: 1;
  @media screen and (max-width: 991px) {
    height: 100%;
  }
}

#departmentsMapView > .toner {
  position: absolute;
  width: 100%;
  height: 75%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 991px) {
    height: 100%;
  }

  .message-area {
    font-family: "HP Simplified";
    background-color: $base-color;
    width: 60%;
    border: 0;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    font-size: 16px;

    @media screen and (max-width: 1199px) {
      font-size: 14px;
    }

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }

    @media screen and (max-width: 425px) {
      font-size: 10px;
    }


    .message-area__item {
      font-weight: 600;
      border-bottom: 1px solid #ffffff;
      font-family: "HP Simplified Rg";
    }

    p {
      padding: 10px;
      color: #f7f7f7;
      text-transform: uppercase;
      margin: 0;
    }
  }
}
</style>