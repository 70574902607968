<template>
  <div class="hp">
    <!-- Content start -->
    <div class="container list-service-wrap">
      <OtherServiceContent/>
    </div>
    <!-- Content end -->
  </div>
</template>

<script>

import OtherServiceContent from "@/pages/About/OtherServices/OtherServiceContent";

const slides = [
  {
    background: "",
    name: "slide1"
  },
];

export default {
  name: "Hp",
  data() {
    return {
      dsVendors: [],
      slides: slides
    }
  },
  created() {
    const layout = {
      name: "Main",
      data: this.slides
    };
    this.$store.commit('setLayout', layout)

    console.log(this.$route.params.sc, this.$store.state.serviceCenters)
  },
  components: {
    OtherServiceContent
  }
}
</script>

<style scoped>

</style>