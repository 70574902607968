<template>
    <div class="accordion-advantage__desc">
        <p>
            Все наши сервисные инженеры проходят обучение по обслуживаемому ими оборудованию в соответствии с
            требованиями и
            стандартами производителей. Обучение инженеров по специализированным сервисным программам как в нашей
            стране, так
            и за рубежом, позволяют соблюдать высокие стандарты обслуживания и гарантировать заказчикам максимальное
            качество
            услуг.
        </p>
        <p>
            Помимо тренингов и курсов от производителей Рус.Саппорт периодически проводит собственные курсы для
            сервисных
            инженеров в формате удалённых вебинаров или на базе нашего учебного класса в Санкт-Петербурге.
        </p>
    </div>
</template>

<script>
export default {
    name: "CertifiedEngineers",
}
</script>

<style scoped>

</style>