<template>
  <div class="switch__list-map">
    <span class="switch__show">Показать:</span>
    <div class="buttongroup">
      <button
              class="switch__btn-map"
              v-bind:class="{'selected': isActive('map')}"
              @click="$emit('displayTypeChanged', 'map')">
        На карте
      </button>
      <button
              class="switch__btn-list"
              v-bind:class="{'selected': isActive('list')}"
              @click="$emit('displayTypeChanged', 'list')">
        Списком
      </button>
    </div>
  </div>
</template>

<script>
export default {
    name: "SwitchListMap",
    props: ["selectedDisplayType"],
    methods: {
        isActive(type) {
            return this.selectedDisplayType === type;
        }
    }
}
</script>

<style lang="scss" scoped>
  .switch__list-map {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 20px;


    .switch__show {
      //margin: auto;
      //flex-grow: 2;
    }

    .buttongroup {
      display: flex;
      justify-content: space-between;
      flex-grow: 2;

      .switch__btn-map {

      }


      button {
        width: 46%;
        margin: 0 2%;
        padding: 6px 10%;
        border-radius: 10px;
        border: 2px solid #dcdcdc;
        background-color: #ffffff;
        outline: none;
        text-transform: uppercase;
        font-size: 14px;

        @media screen and (max-width: 1199px) {
          font-size: 10px;
        }

        @media screen and (max-width: 425px) {
          padding: 2px;
          font-size: 12px;
        }

        &:hover {
          background: #eee;
          border-color: #0171bb;
        }

        &.selected {
          border: 2px solid #0171bb;
        }
      }
    }


  }
</style>