import { render, staticRenderFns } from "./serviceConditions2020.vue?vue&type=template&id=8fb08338&scoped=true&"
import script from "./serviceConditions2020.vue?vue&type=script&lang=js&"
export * from "./serviceConditions2020.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fb08338",
  null
  
)

export default component.exports