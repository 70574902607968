<template>
    <div id="WeDoMenu" class=" nav-menu" >
        <div class="toggle-area" @click="$emit('toggleClick')"></div>

        <div class="menu-area">

            <div class="close-button" @click="$emit('toggleClick')"><span>X</span></div>

            <div class="menu-group-block" v-if="WedoLinks && WedoLinks.length" v-for="(group, index) in WedoLinks" :key="index">

                <h2 class="nav-menu__title">{{group.groupDisplayName}}</h2>
                <ul class="nav-menu__list" >
                    <li class="nav-menu__item" v-for="(link, linkIndex) in group.links" :key="linkIndex" @click="$emit('toggleClick')">
                        <router-link class="nav-menu__link" :to="link.linkPath">
                            {{ link.linkName }}
                        </router-link>
                    </li>
                </ul>

            </div>

        </div>

    </div>
</template>

<script>
    import headerData from "./data.js";
export default {
    name: "navMenu",
    data() {
      const WedoLinks = headerData.getWedoLinks()
        return {
          WedoLinks:WedoLinks
        }
    },
  mounted () {
      //console.log(this.WedoLinks)
  }
}
</script>

<style lang="scss" scoped>
    #WeDoMenu {
        width:100%;
        position: absolute;
        left: 0;
        margin: 5px 0;
        text-align: left;
        display: grid;
        word-wrap: break-word;



        .toggle-area {
            position: fixed;
            top:0;
            left:0;
            width: 100vw;
            height: 100vh;
            z-index:4;
            background-color: transparent;
        }

        .menu-area {
            position: relative;
            z-index: 5 !important;
            background-color: white;
            padding: 30px;
            margin: 0 5px;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
            border: 0;
            border-radius: 5px;
            box-shadow: 0 10px 10px rgba(0,0,0,.5);


            .close-button {
                position: absolute;
                right: 10px;
                top:10px;
                cursor: pointer;
                color: white;
                font-weight: bolder;
                background-color: #888;
                width: 20px;
                height: 20px;
                text-align: center;
                box-sizing: padding-box;
                border: 0;
                border-radius: 5px;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $base-color;
                }

            }

        }

        .menu-group-block {

        }
    }

    @media (max-width: 576px) {
        #WeDoMenu {

            .menu-area {
                display: grid;
                grid-template-columns:none;
            }

        }
    }




.nav-menu {

    .nav-menu__title {
        color: #0073c0;
        font-size: 1em;
        font-weight: 600;
    }

    .nav-menu__list {
        font-size: 14px;
        list-style: none;
        padding: 0;

        .nav-menu__item {
            padding: 5px 0;
            line-height: 16px;

            @media screen and (max-width: 345px) {
              font-size: 12px;
            }

            .nav-menu__link {
                text-decoration: none;
                color: #787878;

                &:hover {
                    color: #0073c0;
                }
            }
        }
    }
}
</style>