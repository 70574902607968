import axios from 'axios';
import authService from "./auth.service";

const API_BASEURL = process.env.VUE_APP_API_BASEURL;
let config = {
  baseURL: `${API_BASEURL}/`
};

const httpClient = axios.create(config);

const authInterceptor = config => {
  config.headers.Authorization = `Bearer ${authService.getToken()}`;
  return config;
};

httpClient.interceptors.request.use(authInterceptor)

export default httpClient;