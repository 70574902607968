const files = [
    {
        id: 2,
        title: 'Клиент для работы с ServiceDesk 8.3.25.1374 (.rar 127 mb) (для 32-bit операционных систем)',
        path: '/uploads/setuptc_8_3_25_1374.rar',
        img: '/img/icons/rar.png',
        version: '8.3.25.1374',
        visibility: 1
    },
    {
        id: 3,
        title: 'Клиент для работы с ServiceDesk 8.3.25.1374 (.rar 131 mb) (для 64-bit операционных систем)',
        path: '/uploads/setuptc64_8_3_25_1374.rar',
        img: '/img/icons/rar.png',
        version: '8.3.25.1374',
        visibility: 1
    },
    // {
    //     id: 4,
    //     title: 'Клиент для работы с ServiceDesk 8.3.20.1710 (.rar 104,3 mb) (для 32-bit операционных систем)',
    //     path: '/uploads/setuptc_8_3_20_1710.rar',
    //     img: '/img/icons/rar.png',
    //     version: '8.3.20.1710',
    //     visibility: 1
    // },
    // {
    //     id: 5,
    //     title: 'Клиент для работы с ServiceDesk 8.3.20.1710 (.rar 108,8 mb) (для 64-bit операционных систем)',
    //     path: '/uploads/setuptc64_8_3_20_1710.rar',
    //     img: '/img/icons/rar.png',
    //     version: '8.3.20.1710',
    //     visibility: 1
    // },
];

export {files};