<template>
  <div id="departmentsListView">
    <div class="toner" v-if="!features.length">
      <div class="message-area">
        <p>Пожалуйста, <span class="message-area__item">выберите</span> производителя и вид оборудования, чтобы начать
          поиск сервисного центра</p>
      </div>
    </div>
    <div class="list-container">
      <DxList
          :data-source="features"
          :focus-state-enabled="false"
          :active-state-enabled="false"
          :use-native-scrolling="true"
          :no-data-text="''"
          :hover-state-enabled="false"
      >
        <template #item="{ data }">
          <featureTemplate :data="data"/>
        </template>
      </DxList>
    </div>
  </div>
</template>

<script>
import DxList from 'devextreme-vue/list';
import featureTemplate from './__featureTemplate'

export default {
  name: "List",
  props: ["features"],
  components: {
    DxList,
    featureTemplate
  },
  data() {
    return {};
  },
  watch: {}
}
</script>

<style lang="scss" scoped>


#departmentsListView {
  height: 75%;

  @media screen and (max-width: 991px) {
    height: 100%;
  }

  .list-container {
    height: 100%;
    background-color: #ffffff;
    border: 1px solid #ececec;

    @media screen and (max-width: 991px) {
      height: 100%;
    }
  }

  .toner {
    width: 100%;
    height: 75%;
    background-color: #00000080;
    z-index: 99;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 991px) {
      height: 100%;
    }

    .message-area {
      position: absolute;
      font-family: "HP Simplified";
      background-color: #0171bb;
      width: 60%;
      border: 0;
      border-radius: 15px;
      -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 30%);
      box-shadow: 0 0 10px rgb(0 0 0 / 30%);

      p {
        padding: 10px;
        color: #f7f7f7;
        text-transform: uppercase;
        margin: 0;

        .message-area__item {
          font-weight: 600;
          border-bottom: 1px solid #fff;
          font-family: "HP Simplified Rg";
        }
      }
    }
  }
}

::v-deep .dx-checkbox-icon {
  font-family: "DXIcons" !important;
}

::v-deep .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
  cursor: default;
}

::v-deep .dx-list-item {
  cursor: default;
}

//::v-deep .dx-list-item:first-of-type {
//    border-top: none;
//    cursor: auto;
//}

</style>