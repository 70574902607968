<template>
    <div id="slider" class="header-slider" >

        <div id="slide" v-for="(slide, index) in slides" :key="slide.name" >
            <transition name="fade">
                <div class="slide-background" v-if="index === currentSlide" :style="slideStyle"></div>
            </transition>
        </div>

        <div class="slot-header-content">
            <slot name="header"></slot>
        </div>

        <div class="wrapper header-slider_wrapper" >
            <div class="header-slider_content container">
                <div class="content" style="height:200px;">
                    <component v-bind:is="slides[currentSlide].template"/>
                </div>

                <div class="content-buttons" v-if="slides[currentSlide].buttons.length">
                    <div class="form-button" style="position:static" v-for="(button,index) in slides[currentSlide].buttons " :key="index">
                        <formButton
                                :link="button.link"
                                :customClass="button.customClass"
                                :size="button.size"
                                :sign="button.sign"
                                :caption="button.caption"
                                :formType="button.formType"
                        />
                    </div>

                </div>
            </div>

            <div class="beak">
                <div class="beak-left"></div>
                <div class="beak-right"></div>
            </div>
        </div>
    </div>
</template>
<script>
  import formButton from "@/components/widgets/formButton"

  export default {
    props: ["slides"],
    data () {
      return {
        currentSlide: 0,
        headerOffsetHeight: 110,
      }
    },
    components: {
      formButton
    },
    computed: {
      slideStyle () {
        const styles = {
          "background": "url("+this.slides[this.currentSlide].background+") no-repeat center"
        };

        return Object.keys(styles).map(function(key) {
          return key + ":" + styles[key]
        }).join(";")
      }

    },
    methods: {
      screenResize () {
        const header = document.getElementById("header");
        if(header)
          this.headerOffsetHeight = header.offsetHeight
      },
      runSlider(slides) {
        const slidesAmount = slides.length;
        if(slidesAmount > 1) {
          this.timer = setInterval(() => {
            if (this.currentSlide < slidesAmount - 1)
              return ++this.currentSlide
            this.currentSlide = 0
          }, 4000)
        }
      },
      setBackgroundPosition () {
        const header = document.getElementById("header");
        if(header)
          this.headerOffsetHeight = header.offsetHeight;
      },
      jsonToCss(json) {
        return Object.keys(json).map(function(key) {
          return key + ":" + json[key]
        }).join(";")
      }
    },
    watch: {
      slides (value) {
        if (this.timer) {
          clearInterval(this.timer)
          this.currentSlide = 0
          this.timer = null
        }
        if (value.length>1) this.runSlider(value)
      }
    },
    created() {
      window.addEventListener("resize", this.screenResize);
    },
    beforeDestroy () {
      clearInterval(this.timer)
    },
    destroyed() {
      window.removeEventListener("resize", this.screenResize);
    },
    mounted () {
      this.setBackgroundPosition();
      if (this.slides.length) this.runSlider(this.slides)
    },
}
</script>
<style lang="scss" scoped>
    .header-slider {
        position: relative;
        background: linear-gradient(90deg, #0676c1 0, rgba(56, 149, 198, .7) 50%, rgba(86, 176, 181, .5) 100%);
            position: relative;

        #slide {
            position: absolute !important;
            width: 100%;
            height: 100%;
            z-index: -1;

            .slide-background {
                position: static;
                background-size: cover !important;
                width: 100%;
                height: 100%;
                top: 0;
            }
        }
        &_wrapper {
            /*background: linear-gradient(90deg, #0676c1 0, rgba(56, 149, 198, .7) 50%, rgba(86, 176, 181, .5) 100%);
            position: relative;*/
        }
        &_content {
            display: flex;
            position:relative;
            flex-direction: column;
            justify-content: space-around;
            padding: 10px;
            padding-top: 0;
            min-height: 35vw;
            width: 100%;
            height: 100%;

            @media screen and (max-width: 768px) {
              padding: 0 15px 30px 15px;
            }

            .content {
                display: block;
                position: static;
                height: 100%;
                width: 100%;

                @media screen and (max-width: 992px) {
                    margin: auto;
                }
            }

            .content-buttons {
                position:relative;
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                @media screen and (max-width: 320px) {
                  display: block;
                }
            }

            .content-buttons > div.form-button {
                margin: auto;
            }
        }
        .beak {
            position:relative;
            bottom:0;
            width: 100% !important;
            height: 20px;
            background: transparent;
            padding: 1px 0;

            .beak-left, .beak-right {
                position: absolute;
                border-bottom: 20px solid #fff;
                z-index: 1;
                width: 50%;
            }

            .beak-left {
                left: 0;
                border-right: 20px solid transparent;
            }

            .beak-right {
                right: 0;
                border-left: 20px solid transparent;
            }
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    @media (max-width: 768px) {
        .content-buttons > div.form-button {
            margin: 0 !important;
        }

    }
</style>