<template>
  <div class="response" v-if="searchOrder && searchOrder.id">
    <h1 class="case-status-main">Проверка статуса заявки</h1>
    <div class="table-responsive">
      <table class="table table-hover">
        <tbody>
        <tr>
          <td><b>Номер заявки:</b></td>
          <td>{{ searchOrder.num }}</td>
        </tr>
        <tr>
          <td><b>Статус текущей заявки:</b></td>
          <td>{{ searchOrder.status }}</td>
        </tr>
        <tr>
          <td><b>Тип ремонта:</b></td>
          <td>{{ searchOrder.type_repair }}</td>
        </tr>
        <tr>
          <td><b>Дата создания заявки:</b></td>
          <td>{{ searchOrder.date_order }}</td>
        </tr>
        <tr>
          <td><b>Сервисный центр:</b></td>
          <td>{{ searchOrder.service_center }}</td>
        </tr>
        <tr>
          <td><b>Продуктовый номер:</b></td>
          <td>{{ searchOrder.product_num }}</td>
        </tr>
        <tr>
          <td><b>Серийный номер:</b></td>
          <td>{{ searchOrder.serial_num }}</td>
        </tr>
        <tr>
          <td><b>Модель: </b></td>
          <td>{{ searchOrder.model }}</td>
        </tr>
        <tr>
          <td><b>Дата поступления запчастей по первичной диагностике:</b></td>
          <td>{{ searchOrder.date_part }}</td>
        </tr>
        <tr>
          <td><b>Потребовалась повторная диагностика:</b></td>
          <td>{{ searchOrder.rediag }}</td>
        </tr>
        <tr>
          <td><b>Дата завершения работ по заявке:</b></td>
          <td>{{ searchOrder.date_stop }}</td>
        </tr>
        <tr>
          <td><b>Требуемая оплата:</b></td>
          <td>{{ searchOrder.invoicing }}</td>
        </tr>
        <tr>
          <td><b>Поступившая оплата:</b></td>
          <td>{{ searchOrder.pays }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaseStatusResponse",
  computed: {
    searchOrder() {
      return this.$store.state.foundApp
    }
  }
}
</script>

<style lang="scss" scoped>
.response {
  padding: 30px 0;
  max-width: 1140px;
  margin: 0 auto;

  .case-status-main {
    text-align: center;
    text-transform: uppercase;
    padding: 50px 0 20px 0;
  }
}
</style>