<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420 512"><path d="M255,59.94A10,10,0,1,0,246,50,10,10,0,0,0,255,59.94Z" transform="translate(-46)"/><path d="M456,60a50.06,50.06,0,0,1-50-50A10,10,0,0,0,396,0H116a10,10,0,0,0-10,10A50.06,50.06,0,0,1,56,60,10,10,0,0,0,46,70V233.19A290.45,290.45,0,0,0,253.13,511.58a10,10,0,0,0,5.74,0A290.49,290.49,0,0,0,466,233.19V70A10,10,0,0,0,456,60ZM446,233.19c0,119.45-76.22,222.89-190,258.35A270.4,270.4,0,0,1,66,233.19V79.29A70.18,70.18,0,0,0,125.29,20H386.71A70.18,70.18,0,0,0,446,79.29Z" transform="translate(-46)"/><path d="M373.51,46a10,10,0,0,0-9.16-6H296a10,10,0,0,0,0,20h62a109.87,109.87,0,0,0,48,48V233.19c0,97.86-58.75,182.3-150,216.1-91.25-33.8-150-118.24-150-216.1V108a109.87,109.87,0,0,0,48-48H216a10,10,0,0,0,0-20H147.65a10,10,0,0,0-9.16,6A90.08,90.08,0,0,1,92,92.49a10,10,0,0,0-6,9.16V233.19c0,107.21,66.31,200.65,166.66,236.17a10.07,10.07,0,0,0,6.68,0C359.61,433.87,426,340.49,426,233.19V101.65a10,10,0,0,0-6-9.16A90.08,90.08,0,0,1,373.51,46Z" transform="translate(-46)"/><path d="M166,236a90,90,0,1,0,90-90A90.1,90.1,0,0,0,166,236Zm160,0a70,70,0,1,1-70-70A70.08,70.08,0,0,1,326,236Z" transform="translate(-46)"/><path d="M233.07,238.93a10,10,0,0,0-14.14,14.14l20,20a10,10,0,0,0,14.14,0l50-50a10,10,0,0,0-14.14-14.14L246,251.86Z" transform="translate(-46)"/></svg>
</template>

<script>
export default {
  name: "quanity-control-icon"
}
</script>

<style scoped>

</style>