<template>
    <div class="department-list-item">
        <div class="item-col vendor-offset"
             v-bind:class="{ iconSelf: (data.ssc_isself == '1'), iconDefault: (data.ssc_isself == '0')}">
        </div>
        <div class="item-col list-content">
            <p align="left" class="paragraph caption">
                Сервисный центр сети РУС.САППОРТ <br>
                <b>{{ data.ssc_name }}</b>
            </p>
            <div class="button-group">
                <div class="button"
                     :class="btn.btnCapture"
                     v-for="btn in scButtons"
                     :key="btn.id"
                     :id="btn.formType"
                     @click="button"
                >
                    {{ btn.caption }}
                </div>
                <!--<div class="price-list"
                     v-for="(price, index) in priceList"
                     :key="'C'+index"
                >
                  <a class="price-link"
                     v-if="price.priceCapture === data.ssc_capture" :href="price.link"
                     target="_blank">
                     <span class="price-desc">
                         Посмотреть прайслист на негарантийный сервис
                     </span>
                  </a>
                </div>-->
                <!-- /.price-list-->
            </div>
            <div class="contacts">
                <div class="contacts-wrap">
                    <div class="contacts-adress" @click="textSelection">
            <textarea
                class="copy-text"
                wrap="soft" rows="1"
                cols="100"
                :value="data.ssc_address"
                @click.stop.prevent="copyText"
            />
                        <span class="mobile-text">
              {{ data.ssc_address }}
            </span>
                    </div>
                    <div class="contacts-connection">
                        <div class="contacts-feedback">
              <span class="contacts-feedback__phone" @click="textSelection">
                <textarea
                    class="copy-text"
                    cols="20"
                    rows="1"
                    :value="data.ssc_telephone"
                    @click.stop.prevent="copyText"
                />
              </span>
                            <span class="mobile-text">
                {{ data.ssc_telephone }}
              </span>
                            <br>
                            <span class="contacts-feedback__email" @click="textSelection">
                <textarea
                    class="copy-text"
                    cols="20"
                    rows="1"
                    :value="data.ssc_email"
                    @click.stop.prevent="copyText"
                />
              </span>
                            <span class="mobile-text">
                {{ data.ssc_email }}
              </span>
                        </div>
                        <div class="contacts-workhours">
                            {{ data.ssc_workhours }}
                        </div>
                    </div>
                </div>
            </div>

            <span class="auths" @click="test">Список обслуживаемого оборудования</span>

            <div style="padding-top: 15px;">
                <div class="auths-list" v-for="(itemAuths, index) in data.auths" :key="index">
                    <div class="auths-list-equipment" v-if="isShow">
                        <div class="equipment-icon-offset" :style="iconOffsetStyle(index)"></div>
                        <div class="equipment-list">
                            <div class="equipment-item" v-for="(sdauItem, index) in itemAuths" :key="index">
                                <div
                                    v-if="data.ssc_works[sdauItem.sdau_id].sca_sdau_warranty === '1' || data.ssc_works[sdauItem.sdau_id].sca_sdau_non_warranty === '1'">
                                    {{ sdauItem.sdau_name }}
                                    <!--
                                                    <img v-on:mouseover="hoverAuthItem" v-on:mouseleave="hoverAuthItemDelete"
                                                         :data-tooltip="sdauItem.sdau_description" :id="'auth'+sdauItem.sdau_id"
                                                         class="question-img" style="width: 10px;" src="/img/icons/question-mark.svg" alt="">
                                                    <div class="auth-tooltip"></div>
                                    -->

                                    <div class="tooltiptest">
                                        <img :id="'auth'+sdauItem.sdau_id"
                                             class="question-img" style="width: 12px;"
                                             src="/img/icons/question-mark.svg"
                                             alt="">
                                        <div class="tooltiptexttest">{{ sdauItem.sdau_description }}</div>
                                    </div>


                                    <div class="tooltiptest">
                                        <img v-if="data.ssc_works[sdauItem.sdau_id].sca_sdau_warranty === '1'"
                                             style="width: 15px; margin: 0 5px"
                                             src="/img/icons/warr.png" alt=""
                                        >
                                        <div class="tooltiptexttest">Гарантийное обслуживание</div>
                                    </div>

                                    <div class="tooltiptest">
                                        <img v-if="data.ssc_works[sdauItem.sdau_id].sca_sdau_non_warranty === '1'"
                                             style="width: 15px; margin: 0 5px"
                                             src="/img/icons/oow.png" alt=""
                                        >
                                        <div class="tooltiptexttest">Платное обслуживание</div>
                                    </div>


                                    <!--              <span v-for="(item, index) in data.ssc_works" :key="index + 1">
                                                    <span v-if="item.sca_sdau_id === sdauItem.sdau_id">
                                                      <img v-if="item.sca_sdau_warranty === '1'" src="/img/icons/ok.svg" alt="" style="width: 15px;">
                                                      <img v-if="item.sca_sdau_non_warranty === '1'" src="/img/icons/pay.svg" alt="" style="width: 15px;">
                                                    </span>
                                                  </span>-->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span class="auths rollup" v-if="isShow" @click="test">Свернуть &and;</span>
        </div>
        <!-- /.item-col list-content-->
    </div>
</template>

<script>
import {mapPriceLists} from "../Map/mapPriceLists";
import notify from 'devextreme/ui/notify';

export default {
    name: "featureTemplate",
    props: ["data"],
    data() {
        return {
            isShow: false,
            priceList: mapPriceLists,
            scButtons: [
                {
                    id: 0,
                    btnCapture: this.data.ssc_capture,
                    caption: "Создать заявку",
                    formType: "create-order",
                },
                {
                    id: 1,
                    btnCapture: this.data.ssc_capture,
                    caption: "Оставить отзыв в службу качества",
                    formType: "feedback",
                },
            ],
            iconOffsetStyle(vendorName) {
                vendorName = vendorName.split(" ").join("").replace(/\./g, "") || "other" || "Другие";

                let backgroundUrl = "/img/vendors/" + vendorName + ".svg";
                return {
                    background: 'url(' + backgroundUrl + ') no-repeat',
                    "background-size": 'contain',
                    "background-position": "0 50%",
                    width: '15%',
                    height: '30px',
                }
            },
        }
    },
    methods: {
        copyText(e) {
            let text = e.target
            text.setAttribute('type', 'text');
            text.select();

            try {
                document.execCommand('copy');
                notify("Текст скопирован в буфер обмена", "success", 5000);
            } catch (err) {
                notify("Oops! Что-то пошло не так. Свяжитесь с нами. webdevelopment@tillypad.ru", "error", 10000);
            }

            text.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges();
        },
        textSelection(e) {
            let email = e.target
            let range = new Range();

            range.setStart(email, 0);
            range.setEnd(email, 1);

            document.getSelection().removeAllRanges();
            document.getSelection().addRange(range);
        },
        hoverAuthItem(e) {
            let dataset = e.target.dataset.tooltip;

            if (e.target.className) {

                let elem = e.target.nextSibling

                elem.textContent = dataset
                elem.style.cssText = "position: absolute; display: block;"
                elem.style.zIndex = 9999999
                elem.style.width = '300px'
                elem.style.top = '-30px'

                if (elem.offsetHeight >= 40) {
                    elem.style.top = '-50px'
                }

                if (elem.offsetHeight >= 90) {
                    elem.style.top = '-90px'
                }
            }
        },
        hoverAuthItemDelete(e) {
            let elem = e.target.nextSibling
            if (e.target.className) {
                e.target.nextSibling.innerHTML = ''
                elem.style.cssText = "position: absolute; display: none;"
            }
        },
        test() {
            this.isShow = !this.isShow
        },
        button(e) {

            if (e.currentTarget.id === 'create-order' || e.currentTarget.id === 'feedback') {

                const action = this.$store.state.feedbackMode ? "off" : "on";
                this.$store.commit('switchFeedbackMode', action)
                this.$store.commit('setModalFormType', e.currentTarget.id)
            }

            for (let i = 0; i < this.$store.state.serviceCenters.length; i++) {
                let test = this.$store.state.serviceCenters[i]

                if (e.currentTarget.className === 'button ' + test.ssc_capture) {
                    const adr = test.ssc_address
                    const id = test.ssc_id

                    this.$store.commit('setAdressCapture', adr)
                    this.$store.commit('setSscId', id)
                }
            }
        },
        // sortedObjProps() {
        //     const arr = Object.entries(this.data.auths)
        //     for (let i = 0; i < arr.length; i++) {
        //         this.arrItem.push(Object.values(arr[i][1]))
        //     }
        // }
    },
}
</script>

<style lang="scss" scoped>

.tooltiptest {
    position: relative;
    display: inline-block;
    z-index: 99;
}

.tooltiptest .tooltiptexttest {
    visibility: hidden;
    width: 170px;
    background-color: white;
    color: #000000;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    box-shadow: 0 3px 5px #00000063;
    position: absolute;
    z-index: 99;
}

.tooltiptest:hover .tooltiptexttest {
    visibility: visible;
    z-index: 99;
}

.department-list-item {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    padding: 30px 15px 15px 0;

    .item-col {
        .auths {
            color: #0071ba;
            border-bottom: 2px dashed #0071ba;
            //margin: 0 0 10px 0;

            &:hover {
                cursor: pointer;
            }
        }

        .auths.rollup {
            margin: 100px;
        }

        .auths-list {
            margin-top: 10px;

            .auths-list-equipment {
                display: flex;

                .equipment-icon-offset {
                    image-rendering: optimizeSpeed;
                }

                .equipment-list {
                    width: 100%;

                    .equipment-item {
                        position: relative;

                        .auth-tooltip {
                            background-color: #ffffff;
                            box-shadow: 0 3px 15px #00000063;
                            padding: 5px;
                            border-radius: 5px;
                            color: #000000;
                            display: none;
                            text-align: center;
                        }

                        .question-img {

                        }
                    }
                }

            }
        }

        .button-group {
            display: flex;
            align-items: center;
            //margin: 10px 0;

            @media screen and (max-width: 1400px) {
                flex-wrap: wrap;
            }

            @media screen and (max-width: 390px) {
                //margin: 15px 5px;
                //width: 100%;
                display: block;
            }


            .button {
                text-align: center;
                border-radius: 5px;
                padding: 5px;
                background: rgb(237, 252, 241);
                border: 1px solid rgba(77, 120, 148, 0.5);
                cursor: pointer;
                margin: 10px 5px 5px 5px;

                @media screen and (max-width: 1400px) {
                    //margin: 15px 5px;
                }

                @media screen and (max-width: 390px) {
                    //margin: 15px 5px;
                    width: 100%;
                }
            }

            .price-list {
                //margin-top: 10px;

                @media screen and (max-width: 390px) {
                    //margin: 15px 5px;
                    width: 100%;
                }

                .price-link {
                    text-decoration: none;
                    color: #0a0a0a;
                    text-align: center;
                    border-radius: 5px;
                    padding: 5px;
                    background: rgb(237, 252, 241);
                    border: 1px solid rgba(77, 120, 148, 0.5);
                    cursor: pointer;
                    margin: 5px;
                    display: inline-block;

                    @media screen and (max-width: 1400px) {
                        //margin: 20px 5px;
                    }

                    @media screen and (max-width: 390px) {
                        //margin: 15px 5px;
                        width: 100%;
                    }

                    .price-icon {
                        max-width: 20px;
                        margin-right: 10px;
                    }

                    .price-desc {
                        .price-desc__opacity {
                            color: #909090;
                        }
                    }
                }
            }
        }

        /*.price-list {
            margin-bottom: 10px;

            .price-link {
                text-decoration: none;
                color: #0a0a0a;

                .price-icon {
                    max-width: 20px;
                    margin-right: 10px;
                }

                .price-desc {
                    .price-desc__opacity {
                        color: #909090;
                    }
                }
            }
        }*/
        &.vendor-offset {
            // background: red;
            height: 100px;
            width: 120px !important;
            background-size: cover;
            background-repeat: no-repeat !important;

            &.iconSelf {
                background: url('/img/icons/icon_self.png') top right 20px;
            }

            &.iconDefault {
                background: url('/img/icons/icon_default.png') top right 20px;
            }

            @media screen and (max-width: 390px) {
                display: none;
            }
        }

        &.list-content {
            width: 100%;
            white-space: normal;

            & > .paragraph.caption {
                text-align: left;
                color: #0a0a0a;
                font-size: 17px;
                letter-spacing: .07em;

                @media screen and (max-width: 390px) {
                    font-size: 14px;
                }
            }

            .contacts {
                display: flex;
                //padding: 10px 0;
                //margin: 20px 0;
                padding: 0 0 10px 0;
                font-size: 15px;
                max-width: 450px;

                .contacts-offset {
                    padding: 0 10px;

                    .contacts-offset__icon {
                        display: block;
                        background: url(/img/icons/call.svg) no-repeat;
                        background-size: contain;
                        background-position: 0 50%;
                        width: 40px;
                        height: 100%;
                    }
                }

                .contacts-wrap {
                    .contacts-adress {
                        padding: 10px 0;


                    }

                    .contacts-connection {
                        display: flex;
                        flex-wrap: wrap;

                        .contacts-feedback {
                            width: 40%;
                            border-right: 1px solid #0073c01a;

                            @media screen and (max-width: 390px) {
                                width: 100%;
                            }

                            .contacts-feedback__phone {
                                padding-top: 0;
                                margin-right: 10px;
                            }

                            .contacts-feedback__email {

                            }
                        }

                        .contacts-workhours {
                            width: 40%;
                            padding-left: 25px;

                            @media screen and (max-width: 390px) {
                                width: 100%;
                                padding: 10px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

textarea {
    border: none;
    resize: none;
    outline: none;
    font-weight: 300;
    overflow: hidden;
}

.copy-text {
    @media screen and (max-width: 390px) {
        display: none;
    }
}

.mobile-text {
    display: none;

    @media screen and (max-width: 390px) {
        display: block;
    }
}

</style>