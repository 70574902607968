<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      ОБСЛУЖИВАНИЕ
      ОБОРУДОВАНИЯ APC
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Rus.support проводит ремонт, профилактику, инсталляцию и поставку запасных частей для источников бесперебойного
      питания APC by Schneider Electric
    </p>
  </div>
</template>

<script>
export default {
  name: "othersApcSliderContent"
}
</script>

<style scoped>

</style>