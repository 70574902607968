<template>
  <div class="about">
    <!-- Content start -->
    <Network></Network>
    <Video></Video>
    <NetworkService></NetworkService>
    <Nearby></Nearby>
    <ContactCenter></ContactCenter>
    <AboutPartners></AboutPartners>
    <!-- Content end -->
  </div>
</template>

<script>

import Network from "@/pages/About/Network";
import Video from "@/pages/About/Video";
import NetworkService from "@/pages/About/NetworkService";
import Nearby from "@/pages/About/Nearby";
import ContactCenter from "@/pages/About/ContactCenter";
import AboutPartners from "@/pages/About/AboutPartners";

const buttons = [];

const slides = [
  {
    background: "/img/page-slider/main_page_1.jpg",
    template: "hpSlideContent",
    buttons: buttons
  },
];

export default {
  data() {
    return {
      dsVendors: [],
      slides: slides,
    }
  },
  created() {
    const layout = {
      name: "Main",
      data: this.slides
    };
    this.$store.commit('setLayout', layout)
  },
  components: {
    Network,
    Video,
    NetworkService,
    Nearby,
    ContactCenter,
    AboutPartners
  },
}

</script>