<template>
    <div class="terms-wrapper">
        <div class="container">
            <div data-v-238a9690="" class="caption">
                <p data-v-238a9690="" class="caption__title">
                    УСЛОВИЯ ОБСЛУЖИВАНИЯ
                </p>
            </div>
            <div class="terms-titles">
                <p class="paragraph">
                    УСЛОВИЯ (ДОГОВОР) СЕРВИСНОГО ОБСЛУЖИВАНИЯ<br>
                    Российская Федерация, город Санкт-Петербург
                </p>
                <p class="paragraph">
                    Дата размещения: {{ placementDate }}
                </p>
                <p class="paragraph">
                    Дата вступления в силу: {{ effectiveDate }}
                </p>
            </div>

            <Accordion @placementDate="placement"/>
        </div>
    </div>
</template>

<script>

import Accordion from "./Accordion/Accordion";

export default {
    name: "Test",
    data() {
        return {
            placementDate: '',
            effectiveDate: ''
        }
    },
    components: {
        Accordion
    },
    created() {
        const layout = {
            name: "Main",
            data: this.slides
        };
        this.$store.commit('setLayout', layout)
    },
    methods: {
        placement(data) {
            this.placementDate = data.placeDate
            this.effectiveDate = data.effectDate
        }
    }
}
</script>

<style lang="scss" scoped>
.terms-wrapper {
    padding: 20px 0;

    .paragraph {
        font-size: 1.188em;
        text-align: left;
    }
}
</style>