<template>
    <div class="links-mobile">
        <div class="bt-call">
            <router-link to="call" class="link-call">
                <span class="image-helper"></span>
                <img class="phone-icon" src="/img/layout/call.svg">
            </router-link>
        </div>
        <div class="bt-login">
            <router-link to="login" class="link-login">
                <span class="image-helper"></span>
                <img class="phone-icon" src="/img/layout/lk.png">
            </router-link>
        </div>

    </div>
</template>

<script>
  export default {
    name: "linkHome"
  }
</script>

<style lang="scss" scoped>

    .links-mobile {
        display: flex;
        min-height: 80px;
        justify-content: flex-end;
        align-items: center;

        &>* {
            margin: 0 5px;
        }

        .bt-call {
            background: #7db82d;
            width:50px;
            height:50px;
            border:0;
            border-radius: 30px;

            .link-call {
                display: block;
                height: 100%;

                img {
                    width: 50%;
                }
            }
        }
        .bt-login {
            width:50px;
            height:50px;

            .link-login {
                display: block;
                height: 100%;
                img {
                    width: 40%;
                }
            }
        }

    }

    .image-helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    img {
        vertical-align: middle;
    }

</style>