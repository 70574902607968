<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      КУРСЫ
      ДЛЯ СЕРВИСНЫХ ИНЖЕНЕРОВ
      И МЕРОПРИЯТИЯ ДЛЯ КЛИЕНТОВ
    </h2>
  </div>
</template>

<script>
export default {
  name: "othersCoursesSliderContent"
}
</script>

<style scoped>

</style>