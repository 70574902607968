<template>
    <div id="formFeedbackApc">
        <form
            id="contactForm"
            @submit.prevent="handleSubmit($event)"
        >
            <div class="dx-fieldset">
                <div class="dx-fieldset-header form-title">УТОЧНИТЕ ВОЗМОЖНОСТЬ ОБСЛУЖИВАНИЯ ВАШЕГО ОБОРУДОВАНИЯ APC
                </div>

                <!-- Имя -->
                <div class="dx-field">
                    <div class="dx-field-label">Имя</div>
                    <div class="dx-field-value">
                        <DxAutocomplete
                            :data-source="names"
                            v-model="formData.name"
                            placeholder="Имя"
                            :search-timeout="100"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Пожалуйста, введите ваше имя"/>
                                <DxPatternRule
                                    :pattern="namePattern"
                                    message="Имя введено неверно"
                                />
                            </DxValidator>
                        </DxAutocomplete>
                    </div>
                </div>
                <!-- /.Имя -->

                <!-- Телефон -->
                <div class="dx-field">
                    <div class="dx-field-label">Телефон</div>
                    <div class="dx-field-value">
                        <DxTextBox
                            :mask-rules="phoneMaskRules"
                            :use-masked-malue="true"
                            mask="+7 (000) 000-0000"
                            mask-invalid-message="Телефон введен неверно"
                            v-model="formData.phone"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Пожалуйста, введите ваш телефонный нормер"/>
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
                <!-- Телефон -->

                <!-- Email -->
                <div class="dx-field">
                    <div class="dx-field-label">Email</div>
                    <div class="dx-field-value">
                        <DxAutocomplete
                            :data-source="emails"
                            v-model="formData.email"
                            placeholder="Email"
                            :search-timeout="100"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Пожалуйста, введите ваш email"/>
                                <DxEmailRule message="Email введен неверно"/>
                            </DxValidator>
                        </DxAutocomplete>
                    </div>
                </div>
                <!-- /.Email -->

                <!-- Местоположение -->
                <div class="dx-field">
                    <div class="dx-field-label">Город</div>
                    <div class="dx-field-value">
                        <DxAutocomplete
                            :data-source="locations"
                            v-model="formData.city"
                            placeholder="Город"
                            :search-timeout="100"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Пожалуйста, укажите ваш город"/>
                                <DxPatternRule
                                    :pattern="namePattern"
                                    message="Город введен неверно"
                                />
                            </DxValidator>
                        </DxAutocomplete>
                    </div>
                </div>
                <!-- /.Местоположение -->

                <!-- Сообщение -->
                <div class="dx-field">
                    <div class="dx-field-value">
                        <DxTextArea
                            v-model="formData.message"
                            placeholder="Сообщение"

                        >
                            <DxValidator>
                                <DxRequiredRule message="Пожалуйста, введите ваше сообщение"/>
                            </DxValidator>
                        </DxTextArea>
                    </div>
                </div>
                <!-- /.Сообщение -->

                <!-- CheckBox -->
                <DxCheckBox
                    id="check"
                    :value="false"
                    text="Отправляя запрос на обслуживание, вы соглашаетесь с Условиями обработки и использования персональных данных и с Условиями обслуживания Rus.support"
                >
                    <DxValidator>
                        <DxCompareRule
                            :comparison-target="checkTermsComparison"
                            message="Вы должны принять Условия"
                        />
                    </DxValidator>
                </DxCheckBox>
                <!-- /.CheckBox -->

                <div class="form-content-bottom submit-area" align="center" style="padding: 10px 0 40px 0">
                    <DxButton
                        id="button"
                        :use-submit-behavior="true"
                        text="Отправить"
                        template="buttonTemplate"
                    >
                        <template #buttonTemplate="{ data }">
                            <div>
                                <div class="button-template hovered dx-button-text">
                                    {{ data.text }}
                                </div>
                                <div class="send-preload">
                                    <DxLoadIndicator
                                        :visible="loadIndicatorVisible"
                                        class="button-indicator"
                                    />
                                    <div v-if="loadIndicatorVisible" class="dx-button-text">{{ buttonText }}</div>
                                </div>
                            </div>
                        </template>
                    </DxButton>

                </div>
                <!--/.form-content-bottom submit-area-->
            </div>
            <!--/.dx-fieldset-->
        </form>
    </div>
</template>

<script>

import httpClient from "../../../../services/http.service";
// import DxSelectBox from 'devextreme-vue/select-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
// import DxDateBox from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
// import DxValidationSummary from 'devextreme-vue/validation-summary';
// import DxRadioGroup from 'devextreme-vue/radio-group';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import {DxAutocomplete} from "devextreme-vue/autocomplete";
import {
    DxValidator,
    DxRequiredRule,
    DxCompareRule,
    DxEmailRule,
    DxPatternRule,
    // DxStringLengthRule,
    // DxRangeRule,
    // DxAsyncRule,
    // DxCustomRule
} from 'devextreme-vue/validator';
import notify from "devextreme/ui/notify";

export default {
    name: "formFeedbackApc",
    components: {
        DxTextBox,
        DxValidator,
        DxRequiredRule,
        DxPatternRule,
        DxEmailRule,
        DxTextArea,
        DxCheckBox,
        DxCompareRule,
        DxButton,
        DxLoadIndicator,
        DxAutocomplete
    },
    data() {
        const contactTemplate = {
            text: String(),
            name: String(),
            surname: String(),
            company: String(),
            city: String(),
            phone: null,
            email: null,
            agreement: false,
            servePoint: null,
            repairType: null,
            numOrder: null,
            message: ""
        };

        const phoneMaskRules = {
            X: /[02-9]/
        };

        return {
            names: [],
            emails: [],
            locations: [],
            buttonText: 'Подождите, идет отправка данных...',
            loadIndicatorVisible: false,
            formData: contactTemplate,
            namePattern: /^[^0-9]+$/,
            phoneMaskRules: phoneMaskRules,
            submitted: false,
            successMessage: "Спасибо за обращение! Наши специалисты свяжутся с вами в ближайшее время!"
        }
    },

    methods: {
        clearForm() {
            this.$emit('restartFormApc');
        },
        handleSubmit(event) {
            this.loadIndicatorVisible = true
            this.$submitter.submit(this.formData)
                .then(result => {
                    if (result.success) {
                        this.sendAppealApc()
                    } else {
                        notify("Oops! Что-то пошло не так. Свяжитесь с нами. webdevelopment@tillypad.ru", "error", 10000);
                        this.loadIndicatorVisible = false
                    }
                });
        },
        checkTermsComparison() {
            return true;
        },
        async sendAppealApc() {
            const {status, data} = await httpClient.post('/appeal-apc', {
                formData: this.formData,
                preLoad: this.loadIndicatorVisible = true
            });
            if (status === 200) {
                this.loadIndicatorVisible = false;

                localStorage.setItem('username', this.formData.name);
                localStorage.setItem('email', this.formData.email);
                localStorage.setItem('location', this.formData.city);

                this.formData = {};
                this.submitted = true;

                this.clearForm()
                notify("Спасибо, что отправили заявку! Мы скоро свяжемся с вами.", "success", 5000);
            } else {
                console.log(status, data)
            }
        },
    },
    mounted() {
        if (localStorage.username !== null) {
            this.names.push(localStorage.username)
            this.emails.push(localStorage.email)
            this.locations.push(localStorage.location)
        }
    },
    watch: {
        formData: {
            deep: true,
            handler(val) {
                // console.log(val)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.form-content-bottom {
    position: relative;
    //background-color: red;

    #button {

        .send-preload {
            display: flex;
            align-items: center;
            //padding-top: 20px;

            .button-indicator {
                margin: 0 10px;
                position: absolute;
                bottom: 15px;
                left: 100px;
            }

            .dx-button-text {
                position: absolute;
                bottom: 20px;
            }
        }

        .button-template {
            transition: background-color 0.3s ease;
            background-color: #0a73bb;
            color: white;
            font-size: 1.5em;
            line-height: 2em;
            border: 0;
            border-radius: 50px;
            padding: 8px 50px;
        }

    }
}

::v-deep .dx-fieldset-header {
    color: #0071ba;
    font-weight: 600;
}

#formFeedbackApc {
    box-shadow: 0 1px 10px #0000003b;
    padding-top: 1px;
}

::v-deep .dx-field-label {
    width: 100%;
    display: none;
}

::v-deep .dx-field-value {
    width: 100% !important;
}

::v-deep .dx-button {
    border-color: transparent !important;
    background-color: transparent !important;
}

::v-deep .dx-button:hover {
    background-color: transparent !important;
}

.button-template:hover {
    background-color: $bootstrap-green !important;
}
</style>