<template>
  <div class="tutorials-content">
    <h1 class="tutorials-title">Сервисное обслуживание</h1>
    <div class="tutorials-sub-title">Доступ в ServiceDesk:</div>
    <div class="tutorials-instruction">

      <div class="tutorials-checkbox">

        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="vAll" value="8.3.10.2561" v-model="checked">
          <label class="form-check-label" for="vAll">Показать все</label>
        </div>


        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="v1" value="8.3.25.1374" v-model="checkedNames">
          <label class="form-check-label" for="v1">Версия 8.3.25.1374</label>
        </div>

<!--        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="v2" value="8.3.20.1710" v-model="checkedNames">
          <label class="form-check-label" for="v2">Версия 8.3.20.1710</label>
        </div>-->

      </div>

      <a class="tutorials-instruction__link" href="/uploads/SD_connect_20200324.pdf" target="_blank">
        <div class="tutorials-instruction__item">
          <img class="tutorials-instruction__img" src="/img/icons/pdf.png" alt="">
          <div class="tutorials-instruction__title">Инструкция по подключению ServiceDesk</div>
        </div>
      </a>


      <div v-for="(item, index) in checkedNames" :key="index">

        <div v-for="(file, index) in files" :key="index+'A'">
          <div v-if="item === file.version && checked === false">
            <a download class="tutorials-instruction__link" :href="file.path" target="_blank">
              <div class="tutorials-instruction__item">
                <img class="tutorials-instruction__img" :src="file.img" alt="">
                <div class="tutorials-instruction__title">{{ file.title }}</div>
              </div>
            </a>
          </div>
        </div>

      </div>

      <div v-for="(file, index) in files" :key="index+'B'">
        <div v-if="checked === true">
          <a download class="tutorials-instruction__link" :href="file.path" target="_blank">
            <div class="tutorials-instruction__item">
              <img class="tutorials-instruction__img" :src="file.img" alt="">
              <div class="tutorials-instruction__title">{{ file.title }}</div>
            </div>
          </a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import {files} from "./files";

export default {
  name: "TutorialsContent",
  data() {
    return {
      files: files,
      tutorials: null,
      checkedNames: [],
      checked: true,
    }
  },

}
</script>

<style lang="scss" scoped>
.tutorials-content {
  max-width: 1140px;
  margin: 50px auto;

  .tutorials-title {
    text-align: center;
    padding: 10px;
  }

  .tutorials-sub-title {
    text-align: center;
    padding: 10px;
  }

  .tutorials-instruction {
    .tutorials-checkbox {
      margin-bottom: 20px;
    }

    .tutorials-instruction__link {
      text-decoration: none;
      color: #000000;

      &:hover {
        text-decoration: underline;
        color: #0a73bb;
      }

      .tutorials-instruction__item {
        border: 1px solid #000000;
        padding: 5px;
        margin: 5px 0;
        display: flex;
        align-items: center;

        .tutorials-instruction__img {
          max-width: 50px;
        }

        .tutorials-instruction__title {
          text-align: center;
          width: 100%;
        }
      }
    }


  }
}
</style>