<template>
    <div class="accordion-advantage__desc">
        <p>
            Мы являемся авторизованным сервисным партнёром таких производителей как Катюша, Huawei, Аэродиск.
            Вы всегда можете проверить, действительно ли авторизован сервисный центр на гарантийное обслуживание по
            списку на сайтах производителей.
        </p>
        <p>
            Список сервисных центров на сайте Катюша: <a href="https://katusha-it.ru/service_center" target="_blank">https://katusha-it.ru/all-partner.html</a>
        </p>
        <p>
            Список сервисных центров на сайте Huawei: <a href="https://consumer.huawei.com/ru/support/service-center/"
                                                         target="_blank">https://consumer.huawei.com/ru/support/service-center/</a>
        </p>
    </div>
</template>

<script>
export default {
    name: "ManufacturerStatuses"
}
</script>

<style scoped>

</style>