<template>
    <div class="accordion-advantage__desc">
        <p>
            Сервисные центры сети Рус.Саппорт авторизованы на гарантийное обслуживание оборудования, то есть выполняют
            обязательства производителя по заводской или расширенной гарантии на оборудование. Ремонт и обслуживание в
            рамках гарантии производителя бесплатны для клиентов.
        </p>
        <p>
            Разные сервисные центры сети Рус.Саппорт авторизованы на разные линейки оборудования разных производителей.
            Узнать какое именно оборудование авторизован обслуживать интересующий вас сервисный центр можно на карте
            сервисных центров.
        </p>
        <p>
            Ремонт по стандартной или расширенной гарантии на устройство может осуществляться в сервисном центре или на
            месте его эксплуатации (в зависимости от условий гарантии).
        </p>
        <p>
            При ремонте в сервисном центре клиент самостоятельно доставляет оборудование в СЦ для последующей
            диагностики и ремонта.
            Выездное обслуживание выполняется по адресу расположения оборудования и выезд осуществляется бесплатно, если
            этот адрес находится в пределах зоны обслуживания, определенной производителем.
        </p>
    </div>
</template>

<script>
export default {
    name: "WarrantyService"
}
</script>

<style lang="scss" scoped>

</style>