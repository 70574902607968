<template>
    <div class="accordion">
        <div class="advantage-wrap" v-for="(item, i) in advantages" :key="i" :index="i" @click="toggleOpen(i)">
            <div class="accordion-advantage" :class="item.classActive ? 'active': ''">

                <IconBase
                    :width="widthIcon"
                    :height="widthIcon"
                    :view-box="viewBox"
                    :class="item.classActive ? 'active': ''"
                    class="accordion-advantage__img"
                >
                    <component :is="item.icon"/>
                </IconBase>

                <div class="accordion-advantage__title">
                    {{ item.title }}
                </div>

                <IconBase
                    class="arrow"
                >
                    <arrow/>
                </IconBase>

            </div>
            <!-- /.accordion-advantage -->

            <!-- MOBILE version description components -->
            <component
                :key="i + 'A'"
                :index="i"
                :class="item.open ? 'open': ''"
                :is="item.component"
                class="mobile"
            />
        </div>

        <!-- DESKTOP version description components -->
        <component
            v-for="(item, i) in advantages"
            :key="i + 'A'"
            :index="i"
            :class="item.open ? 'open': ''"
            :is="item.component"
            class="desktop"
        />
        <!-- /.accordion-advantage__desc -->

    </div>
    <!-- /.accordion -->
</template>

<script>

import {advantages} from "./advantagesData";
import WarrantyService from "@/pages/Home/components/advantagesAccordion/advantagesComponents/WarrantyService";
import OriginalSpareParts from "@/pages/Home/components/advantagesAccordion/advantagesComponents/OriginalSpareParts";
import CertifiedEngineers from "@/pages/Home/components/advantagesAccordion/advantagesComponents/CertifiedEngineers";
import QualityControl from "@/pages/Home/components/advantagesAccordion/advantagesComponents/QualityСontrol";
import ManufacturerStatuses from "@/pages/Home/components/advantagesAccordion/advantagesComponents/ManufacturerStatuses";

import IconBase from "@/pages/Home/components/advantagesAccordion/svg/IconBase";

import WarantyService from "@/pages/Home/components/advantagesAccordion/svg/icons/WarrantyService"
import warrantyService from "@/pages/Home/components/advantagesAccordion/svg/icons/warranty-service-icon"
import originalSpareparts from "@/pages/Home/components/advantagesAccordion/svg/icons/original-spareparts-icon"
import sertificateEngineers from "@/pages/Home/components/advantagesAccordion/svg/icons/sertificate-engineers-icon"
import quanityControl from "@/pages/Home/components/advantagesAccordion/svg/icons/quanity-control-icon"
import status from "@/pages/Home/components/advantagesAccordion/svg/icons/status";
import arrow from "@/pages/Home/components/advantagesAccordion/svg/icons/arrow";

export default {
    name: "Advantages",
    data() {
        return {
            advantages: advantages,
            viewBox: "0 0 843 543",
            widthIcon: 100,
            classIcon: 'accordion-advantage__img',
        }
    },
    components: {
        WarrantyService,
        OriginalSpareParts,
        CertifiedEngineers,
        QualityControl,
        ManufacturerStatuses,
        IconBase,
        WarantyService,
        warrantyService,
        originalSpareparts,
        sertificateEngineers,
        quanityControl,
        status,
        arrow
    },
    methods: {
        toggleOpen(index) {
            this.advantages = this.advantages.map((item, i) => {
                if (index === i) {
                    item.open = !item.open;
                    item.classActive = !item.classActive;
                } else {
                    item.open = false;
                    item.classActive = false;
                }
                return item;
            });
        },
    },
}
</script>

<style lang="scss" scoped>

.accordion {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 40px 0 65px 0;

    @media screen and (max-width: 992px) {
        display: block;
    }

    @media screen and (max-width: 767px) {
        padding: 20px 0;
    }

    .advantage-wrap {

        .accordion-advantage {
            position: relative;
            text-align: center;
            width: 200px;

            @media screen and (max-width: 1400px) {
                width: 150px;
            }

            @media screen and (max-width: 991px) {
                width: 100%;
                display: flex;
                align-items: center;
                border: 2px solid $base-color;
                justify-content: space-around;
                border-radius: 10rem;
                margin: 0.625rem 0;
            }

            .arrow {
                width: 23px;
                height: 23px;
                position: absolute;
                top: 40px;
                right: -10px;
                transition: .3s;

                fill: none;
                stroke: $base-color;
                stroke-miterlimit: 20;
                stroke-width: 20px;

                @media screen and (max-width: 991px) {
                    width: 15px;
                    height: 15px;
                    right: 15px;
                    top: auto;
                    transform: rotate(90deg);
                }
            }

            .accordion-advantage__img {
                margin-bottom: 20px;
                color: $base-color;
                border: 3px solid $base-color;
                border-radius: 100%;
                transition: .1s;

                @media screen and (max-width: 991px) {
                    width: 40px;
                    height: 40px;
                    margin: 5px 5px;
                    position: absolute;
                    border: none;
                    left: 0;
                }

                &:hover {
                    color: white;
                    border: 3px solid $bootstrap-green;
                    background-color: $bootstrap-green;

                    @media screen and (max-width: 991px) {
                        color: $base-color;
                        border: none;
                        background-color: white;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .accordion-advantage__img.active {
                color: white;
                border: 3px solid $bootstrap-green;
                background-color: $bootstrap-green;

                @media screen and (max-width: 991px) {
                    border: none;
                }
            }

            .accordion-advantage__title {
                color: $base-color;
                font-weight: 600;
                text-transform: uppercase;
                padding: 0 15px;

                @media screen and (max-width: 991px) {
                    width: 70%;
                    padding: 0.9rem 0;
                    color: #000000;
                }

                @media screen and (max-width: 475px) {
                    font-size: 0.625em;
                }

                @media screen and (max-width: 320px) {
                    width: 58%;
                    text-align: left;
                    font-size: 0.563em;
                }
            }
        }

        .accordion-advantage.active {
            .accordion-advantage__title {
                @media screen and (max-width: 991px) {
                    color: #FFFFFF;
                }
            }
        }

        &:nth-last-child(6) {
            .arrow {
                display: none;

                @media screen and (max-width: 991px) {
                    display: block;
                }
            }
        }

        .accordion-advantage.active {
            .arrow {
                @media screen and (max-width: 991px) {
                    stroke: #FFFFFF;
                    transform: rotate(-90deg);
                }
            }
        }

        .accordion-advantage.active {
            @media screen and (max-width: 991px) {
                background-color: $bootstrap-green;
                border: 2px solid $bootstrap-green;
            }
        }

        .accordion-advantage__desc.open.mobile {
            display: none;
            @media screen and (max-width: 991px) {
                display: block;
                border: none;
                padding: 0.625rem;
                font-size: 0.75em;
            }
        }
    }

    .accordion-advantage__desc {
        border: 1px solid $base-color;
        border-radius: 2rem;
        padding: 2.5rem;
        margin: 1rem 0;
        display: none;
        text-align: left;
        background-color: #FFFFFF;
        max-width: 1040px;
    }

    .accordion-advantage__desc.open {
        display: block;
        border: 1px solid $base-color;
        border-radius: 2rem;
        padding: 2.5rem;
        margin: 30px 0;
        text-align: left;
        background-color: #FFFFFF;
        width: 100%;
    }

    .accordion-advantage__desc.open.desktop {
        @media screen and (max-width: 991px) {
            display: none;
        }
    }
}

</style>