import { render, staticRenderFns } from "./HpDaasContent.vue?vue&type=template&id=72fcb13f&scoped=true&"
import script from "./HpDaasContent.vue?vue&type=script&lang=js&"
export * from "./HpDaasContent.vue?vue&type=script&lang=js&"
import style0 from "./HpDaasContent.vue?vue&type=style&index=0&id=72fcb13f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72fcb13f",
  null
  
)

export default component.exports