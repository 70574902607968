<template>
    <div class="privacy-accordion__item-content">
        <!--    <p>
              5.1. В случае изменения действующего законодательства РФ, внесения изменений в нормативные документы по защите
              персональных данных настоящее Положение действует в части, не противоречащей действующему законодательству до
              приведения его в соответствие с такими.
            </p>
            <p>
              5.2. Условия настоящего Положения устанавливаются, изменяются и отменяются Оператором в одностороннем порядке без
              предварительного уведомления Пользователя. С момента размещения на Сайте новой редакции Положения предыдущая
              редакция считается утратившей свою силу.
            </p>
            <p>
              5.3. Если Пользователь не согласен с условиями настоящего Положения, то он должен немедленно удалить свой профиль
              с
              Сайта, в противном случае продолжение использования Пользователем Сайта означает, что Пользователь согласен с
              условиями настоящего Положения.
            </p>-->
        <h2>5. Дополнительные условия</h2>
        <p>
            5.1. Администрация Сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия
            Пользователя.
        </p>
        <p>
            5.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не
            предусмотрено новой редакцией Политики конфиденциальности.
        </p>
        <p>
            5.3. Если Пользователь не согласен с условиями настоящей Политики конфиденциальности, то он должен
            немедленно
            удалить свой профиль с Сайта, в противном случае продолжение использования Пользователем Сайта означает, что
            Пользователь согласен с условиями настоящей Политики конфиденциальности .
        </p>
    </div>
</template>

<script>
export default {
    name: "finalProvisions"
}
</script>

<style scoped>

</style>