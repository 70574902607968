<template>
  <div class="row py-5">

    <div class="caption">
      <p>
        Как найти <span class="caption__title">продуктовый и серийный</span> номер устройства
      </p>
    </div>
    <!-- /.caption -->

    <div class="instruction">
      <p class="paragraph">
        Серийный номер (serial number, SN) – номер, однозначно идентифицирующий конкретный юнит оборудования. Состоит из
        10 цифр и букв.
      </p>
      <p class="paragraph">Примеры: CNU1283W8D, CZC42145NX, 5CD34852S1</p>
      <p class="paragraph">Продуктовый номер (product number, PN, SKU)- номер, определяющий модель оборудования,
        продукта.
        Состоит из 6-7
        цифр и букв, иногда с трехзначным суффиксом в конце, идущим через дефис или через решетку.</p>
      <p class="paragraph">Примеры: CE651A, E7G13EA#ACB, 735151-B21</p>
    </div>
    <div class="instruction">
      <h2 class="paragraph paragraph-title">НОУТБУКИ</h2>
      <p class="paragraph paragraph-title">Наклейка на устройстве</p>
      <p class="paragraph">
        Наклейка с номером устройства находится на тыльной стороне ноутбука сверху или под крышкой. На некоторых
        устройствах наклейка расположена внутри главного отсека аккумулятора. Для того чтобы прочитать номер,
        потребуется
        снять аккумулятор.
      </p>
    </div>

    <div class="col-md-4">
      <img class="img-fluid instruction-img" src="/img/pages/on-the-back-side-of-the-notebook.jpg" alt="">
    </div>
    <div class="col-md-4">
      <img class="img-fluid instruction-img" src="/img/pages/under-the-cover-of-the-back-side-of-the-notebook.jpg"
           alt="">
    </div>
    <div class="col-md-4">
      <img class="img-fluid instruction-img" src="/img/pages/inside-the-battery-compartment.jpg" alt="">
    </div>

    <div class="instruction">
      <h2 class="paragraph paragraph-title">BIOS в окне «Информация о системе»</h2>
      <p class="paragraph">
        Включите компьютер, нажав на кнопку питания. Сразу же нажмите клавишу Esc несколько раз подряд до отображения
        меню запуска. Нажмите F1 или выберите пункт «Информация о системе» в меню запуска. Вы увидите идентификационные
        номера.
      </p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/system-information-laptop.jpg" alt="">
    </div>

    <div class="instruction">
      <h2 class="paragraph paragraph-title">Утилита «Информация о системе»</h2>
      <p class="paragraph">
        В ОС Windows одновременно нажмите клавиши Fn + Esc для вызова утилиты «Информация о системе HP». На вашем
        устройстве окно «Информация о системе» может выглядеть иначе.
      </p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/utility-system-information.jpg" alt="">
    </div>

    <div class="instruction">
      <h2 class="paragraph paragraph-title">Консольная команда</h2>
      <p class="paragraph">
        В ОС Windows одновременно нажмите клавиши Win + R. В появившемся окне введите команду cmd. В новом окне введите
        команду wmic bios get serialnumber и нажмите Enter. Ниже появится информация о серийном номере. Способ подходит
        почти для всех персональных и серверных устройств с ОС Windows.
      </p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/cmd.jpg" alt="">
    </div>

    <div class="instruction">
      <h2 class="paragraph paragraph-title">РАБОЧИЕ СТАНЦИИ (СИСТЕМНЫЕ БЛОКИ)</h2>
      <p class="paragraph paragraph-title">Наклейка на устройстве</p>
      <p class="paragraph">Наклейка с номером устройства размещается на его верхней, боковой или задней панели.</p>
    </div>

    <div class="instruction">
      <p class="paragraph paragraph-title">Консольная команда</p>
      <p class="paragraph">
        В ОС Windows одновременно нажмите клавиши Win + R. В появившемся окне введите команду cmd. В новом окне введите
        команду wmic bios get serialnumber и нажмите Enter. Ниже появится информация о серийном номере. Способ подходит
        почти для всех персональных и серверных устройств с ОС Windows.
      </p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/cmd.jpg" alt="">
    </div>

    <div class="instruction">
      <h2 class="paragraph paragraph-title">МОНОБЛОКИ (ALL-IN-ONE)</h2>
      <p class="paragraph paragraph-title">Наклейка на устройстве</p>
      <p class="paragraph">Наклейка с номером находится на задней панели всех устройств all-in-one</p>
    </div>

    <div class="instruction">
      <p class="paragraph paragraph-title">Утилита «Информация о системе»</p>
      <p class="paragraph">В ОС Windows одновременно нажмите клавиши Ctrl + Alt + S для вызова утилиты «Информация о
        системе HP». На вашем устройстве окно «Информация о системе» может выглядеть иначе.
      </p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/utility-system-information2.jpg" alt="">
    </div>

    <div class="instruction">
      <p class="paragraph paragraph-title">Консольная команда</p>
      <p class="paragraph">
        В ОС Windows одновременно нажмите клавиши Win + R. В появившемся окне введите команду cmd. В новом окне введите
        команду wmic bios get serialnumber и нажмите Enter. Ниже появится информация о серийном номере. Способ подходит
        почти для всех персональных и серверных устройств с ОС Windows.
      </p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/cmd.jpg" alt="">
    </div>

    <div class="instruction">
      <h2 class="paragraph paragraph-title">ПЛАНШЕТЫ</h2>
      <p class="paragraph paragraph-title">Наклейка или гравировка на устройстве</p>
      <p class="paragraph">Чаще всего используется гравировка. Информация наносится либо на заднюю часть планшета, либо
        на кромку.</p>
    </div>
    <div class="col-md-5">
      <figure>
        <img class="img-fluid" src="/img/pages/hp8.jpg" alt="">
        <figcaption class="figure-caption">
          Расположение номера продукта и номера модели на планшете HP 8
        </figcaption>
        <img class="img-fluid" src="/img/pages/elitepad-900.jpg" alt="">
        <figcaption class="figure-caption">
          Расположение кода продукта ElitePad 900
          Некоторые модели имеют выдвижную этикетку с номерами. Она находится справа, рядом с кнопкой питания.
        </figcaption>
      </figure>
    </div>

    <figure class="figure">
      <img class="img-fluid" src="/img/pages/slate7.jpg" alt="">
      <img class="img-fluid" src="/img/pages/the-extraction-chamber.jpg" alt="">
      <figcaption class="figure-caption">
        Расположение отсека для этикетки Slate 2
      </figcaption>
    </figure>

    <div class="instruction">
      <p class="paragraph paragraph-title">Информация в ОС Android</p>
      <p class="paragraph">
        В ОС Android на начальном экране коснитесь значка «Все приложения». Зайдите в настройки и перейдите в раздел
        «Сведения о планшете».
      </p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/android.jpg" alt="">
    </div>

    <div class="instruction">
      <h2 class="paragraph paragraph-title">МОНИТОРЫ</h2>
      <p class="paragraph paragraph-title">Наклейка на устройстве</p>
      <p class="paragraph">
        Наклейка с номером устройства находится на задней панели мониторов. На некоторых моделях (например, F1903),
        чтобы увидеть наклейку, необходимо снять декоративную панель.
      </p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/sticker-on-the-back-of-the-monitor.jpg" alt="">
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/decorative-panel.jpg" alt="">
    </div>

    <div class="instruction">
      <h2 class="paragraph paragraph-title">ПРИНТЕРЫ И МФУ</h2>
      <p class="paragraph paragraph-title">Наклейка на устройстве</p>
      <p class="paragraph">Наклейку с номером устройства можно найти на задней, нижней панели принтера или внутри
        области доступа к картриджам.</p>
    </div>

    <div class="instruction">
      <p class="paragraph paragraph-title">Программное обеспечение «Центр решений HP»</p>
      <p class="paragraph">Серийный номер можно найти с помощью ПО «Центр решений HP», установленного на компьютере.</p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/HP-Solution-Center.jpg" alt="">
    </div>

    <div class="instruction">
      <p class="paragraph paragraph-title">Программное обеспечение HP Printer Assistant</p>
      <p class="paragraph">Серийный номер можно найти с помощью ПО HP Printer Assistant, установленного на
        компьютере.</p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/HP-Printer-Assistant1.jpg" alt="">
    </div>

    <div class="instruction">
      <p class="paragraph paragraph-title">Программное обеспечение для принтеров LaserJet</p>
      <p class="paragraph">Серийный номер можно найти с помощью ПО для принтера LaserJet, установленного на
        компьютере.</p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/LaserJet.jpg" alt="">
    </div>

    <div class="instruction">
      <p class="paragraph paragraph-title">Печать листа конфигурации принтера</p>
      <p class="paragraph">Найдите серийный номер в листе конфигурации. Распечатать лист можно через панель управления
        на самом устройстве. На моделях, которые не имеют данной панели, печать запускается зажатием одной или двух
        кнопок. Смотрите точную информацию в инструкции к своему устройству.</p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/print-sheet-printer-configuration.jpg" alt="">
    </div>

    <div class="instruction">
      <h2 class="paragraph paragraph-title">СКАНЕРЫ</h2>
      <p class="paragraph paragraph-title">Наклейка на устройстве</p>
      <p class="paragraph">Наклейка с номером устройства находится на его задней крышке.</p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/scanner.jpg" alt="">
    </div>

    <div class="instruction">
      <h2 class="paragraph paragraph-title">СЕРВЕРЫ</h2>
      <p class="paragraph paragraph-title">Наклейка на устройстве</p>
      <p class="paragraph">Наклейка с номером устройства находится на верхней крышке сервера или на выдвижной
        этикетке.</p>
    </div>
    <div class="col-md-4">
      <img class="img-fluid" src="/img/pages/server.jpg" alt="">
    </div>
    <div class="col-md-4">
      <img class="img-fluid" src="/img/pages/server2.jpg" alt="">
    </div>

    <div class="instruction">
      <p class="paragraph paragraph-title">Программное обеспечение ILO</p>
      <p class="paragraph">Зайдите через браузер на IP адрес сетевого интерфейса ILO и войдите в аккаунт. На главной
        странице отобразится серийный номер сервера.</p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/ilo.jpg" alt="">
    </div>

    <div class="instruction">
      <p class="paragraph paragraph-title">Информация в Rom-Based Setup Utility (RBSU)</p>
      <p class="paragraph">При включении сервера нажмите клавишу «F9», чтобы зайти в RBSU. С правой стороны будет окно с
        серийным и продуктовым номерами.</p>
    </div>

    <div class="instruction">
      <p class="paragraph paragraph-title">Консольная команда</p>
      <p class="paragraph">В ОС Windows одновременно нажмите клавиши Win + R. В появившемся окне введите команду cmd. В
        новом окне введите команду wmic bios get serialnumber и нажмите Enter. Ниже появится информация о серийном
        номере. Способ подходит почти для всех персональных и серверных устройств с ОС Windows.</p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/rbsu.jpg" alt="">
    </div>

    <div class="instruction">
      <p class="paragraph paragraph-title">Консольная команда</p>
      <p class="paragraph">При включении сервера нажмите клавишу F9, чтобы зайти в RBSU. С правой стороны будет окно с
        серийным и продуктовым номерами.</p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/cmd.jpg" alt="">
    </div>

    <div class="instruction">
      <h2 class="paragraph paragraph-title">СИСТЕМЫ ХРАНЕНИЯ ДАННЫХ</h2>
      <p class="paragraph paragraph-title">Наклейка на устройстве</p>
      <p class="paragraph">Наклейка с номером устройства находится на верхней или фронтальной части контроллера.</p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/kontroller.jpg" alt="">
    </div>

    <div class="instruction">
      <p class="paragraph paragraph-title">Вывод консольной команды</p>
      <p class="paragraph">Номер можно получить при помощи вывода определенной команды, например, # show enclosures.</p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/consol.jpg" alt="">
    </div>

    <div class="instruction">
      <h2 class="paragraph paragraph-title">СЕТЕВЫЕ УСТРОЙСТВА</h2>
      <p class="paragraph paragraph-title">Наклейка на устройстве</p>
      <p class="paragraph">Наклейка с номером устройства находится на его нижней части.</p>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="/img/pages/net.jpg" alt="">
    </div>

  </div>
</template>

<script>
export default {
  name: "InstructionContent"
}
</script>

<style lang="scss" scoped>
.instruction {
  margin: 0.625rem 0;

  .paragraph {
    text-align: left;
    margin: 0.188rem 0;
  }

  .paragraph-title {
    font-weight: 600;
    font-size: 1em;
  }
}

.instruction-img {
  padding: 1rem;
}

.figure {
  text-align: left;
}
</style>