<template>
  <div class="terms-accordion__item-content">
    <p>
      8.1. СЦ хранит принятое оборудование Заказчика безвозмездно в течение 45 календарных дней с момента завершения
      выполнения сервисных услуг (с момента окончания ремонта, или окончания диагностики, в тех случаях, когда Заказчик
      отказался от выполнения ремонта, не принял решения по его продолжению или же продолжение ремонта невозможно).
    </p>
    <p>
      8.2. СЦ хранит запасные части, готовые к выдаче Заказчику, безвозмездно в течение 45 календарных дней с момента
      оповещения Заказчика о готовности СЦ к выдаче запасных частей Заказчику (или его представителю).
    </p>
    <p>
      8.3. Заказчик должен забрать оборудование и/или запасные части в течение данного срока (либо лично, либо
      посредствам курьерской доставки). При невыполнении этого требования, с Заказчика взимается плата за хранение в
      размере 100 (сто) рублей 00 копеек за каждый день хранения свыше указанного срока. Выдача оборудования в этом
      случае происходит только после уплаты в СЦ всей суммы оказанных сервисных услуг и использованных запчастей и
      материалов, и платы за хранение оборудования свыше указанного срока.
    </p>
    <p>
      8.4. При неисполнении Заказчиком своей обязанности получить оборудование и/или запасные части, готовые к выдаче, в
      течение 3 месяцев после уведомления сервисным центром о готовности оборудования и/или запасных частей к выдачи, в
      том числе и при уклонении от получения оборудования и/или запасных частей, СЦ имеет право в установленном законом
      порядке самостоятельно продать невостребованное оборудование и/или запасные части по цене, сложившейся в месте
      хранения, с учётом состояния оборудования и/или запчастей, либо утилизировать оборудование и/или запасные части,
      если они не исправны и их реализация и использование по назначению невозможны. Сумма, вырученная от продажи
      оборудования и/или запчастей, передается Заказчику по его требованию, за вычетом сумм, причитающихся СЦ, в том
      числе платы за хранение оборудования и/или запчастей более 45 дней и расходов на их продажу или утилизацию.
    </p>
  </div>
</template>

<script>
export default {
  name: "storageConditions2021"
}
</script>

<style scoped>

</style>