<template>
  <div class="footer mt-auto">
    <div class="footer-container">
      <div class="footer-col">
        <img class="footer-logo" src="/img/layout/logo.svg" alt="">
        <div class="footer-phone">
          <span class="footer-phone__text">ЕДИНЫЙ НОМЕР СЕТИ</span>
          <span class="footer-phone__number">8 800 7-555-777</span>
        </div>
        <!-- /.footer-phone -->
        <div class="footer-social">
          <a class="footer-social__link" href="https://vk.com/hpservice">
            <img class="footer-social__icon" src="/img/social/vk.svg" alt="">
          </a>
          <a class="footer-social__link" href="https://www.instagram.com/rus.support/">
            <img class="footer-social__icon" src="/img/social/insta.svg" alt="">
          </a>
          <a class="footer-social__link" href="https://www.youtube.com/watch?v=gWzQvIC1iLo">
            <img class="footer-social__icon" src="/img/social/youtube.svg" alt="">
          </a>
        </div>
        <!-- /.footer-social -->
      </div>
      <!-- /.footer-col -->

      <div class="footer-col">
        <p class="footer-title">
          СЕРВИСНЫЕ УСЛУГИ
        </p>
        <div class="footer-list">
          <div class="footer-list__item" v-for="(links, index) in footerService" :key="index">
            <router-link class="footer-list__link" :to="links.pathLink">{{ links.nameLink }}</router-link>
          </div>
        </div>
      </div>
      <!-- /.footer-col -->

      <div class="footer-col">
        <p class="footer-title">
          ДРУГОЕ
        </p>
        <div class="footer-list">
          <div class="footer-list__item" v-for="(otherItem, index) in footerOther" :key="index">
            <router-link class="footer-list__link" :to="otherItem.pathLink">
              {{ otherItem.nameLink }}
            </router-link>
          </div>
          <div class="footer-list__item">
            <span class="footer-list__link support" @click="support">
              Связаться со службой качества
            </span>
          </div>
          <div class="footer-list__item">
<!--            <div class="footer-list__link price-list" @click="openPriceList">
              Скачать прайслист
            </div>-->

            <PriceList
                v-show="isShow"
                @closePriceList="closePriceList"
            />

          </div>
          <!-- /.footer-list__item -->
        </div>
        <!-- /.footer-list -->
      </div>
      <!-- /.footer-col -->
      <div class="footer-col">
        <p class="footer-title">
          ПРИНИМАЕМ К ОПЛАТЕ
        </p>
        <div class="footer-payment">
          <img class="footer-payment__logo" src="/img/pay-icon/visa.png" alt="">
          <img class="footer-payment__logo" src="/img/pay-icon/mcard.png" alt="">
          <img class="footer-payment__logo" src="/img/pay-icon/mir.png" alt="">
        </div>
        <div class="personal-area">
          <a class="personal-area__link" href="https://payments.rus.support/site/login">
            <img class="personal-area__icon" src="/img/icons/personal.svg" alt="">
            <span class="personal-area__text">Личный кабинет</span>
          </a>
        </div>
      </div>
      <!-- /.footer-col -->
    </div>
    <div class="storage-personal-data">
      <router-link class="storage-personal-data__link" to="/privacy">
        <span>Положение о порядке хранения и защиты персональных данных пользователей</span>
        <span>(с) ООО "Рус.Саппорт" {{getThisYear}}</span>
      </router-link>
    </div>
    <modal-window-left
        v-if="feedbackMode"
    />
  </div>
</template>

<script>

import {footerServiceLinks, footerOtherLinks} from "./links"
import ModalWindowLeft from "../modal/modalWindowLeft";
import PriceList from "@/components/layouts/common/footer/pricelist/PriceList";

export default {
  components: {ModalWindowLeft, PriceList},
  props: {
    selectedPrice: {
      type: [Array, Object],
    },
    selectedList: {
      type: [String, Object]
    }
  },
  data() {
    return {
      footerService: footerServiceLinks,
      footerOther: footerOtherLinks,
      isShow: false,
      mForm: ""
    }
  },
  computed: {
    feedbackMode() {
      return this.$store.state.feedbackMode
    },
    getThisYear() {
      const date = new Date();
      return date.getFullYear();
    }
  },
  methods: {
    openPriceList() {
      this.isShow = true
    },
    closePriceList() {
      this.isShow = false
    },
    support() {
      this.$store.state.feedbackMode = true
      this.$store.state.modalFormType = "feedback"
    },
  },
}
</script>

<style lang="scss" scoped>

.footer {
  background-color: #0071ba;
  font-size: 16px;

  @media screen and (max-width: 475px) {
    font-size: 14px;
  }

  .footer-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
    padding: 2rem 0;

    .footer-col {
      width: calc(100% / 12 * 3);
      padding: 2rem 0.625rem;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1024px) {
        width: calc(100% / 12 * 6);
        align-items: center;
        text-align: center;
      }

      @media screen and (max-width: 475px) {
        width: 100%;
        padding: 0.625rem;
      }
    }

    .footer-logo {
      max-width: 200px;
    }

    .footer-phone {
      .footer-phone__text, .footer-phone__number {
        color: #ffffff;
      }

      .footer-phone__text {
        font-weight: 600;
      }
    }

    .footer-social {
      display: flex;

      .footer-social__link {
        padding: 0 1rem 0 0;

        .footer-social__icon {
          width: 30px;
        }
      }
    }

    .footer-phone {
      padding: 1.5rem 0;

      span {
        display: block;
      }
    }

    .footer-title {
      color: #ffffff;
      font-weight: 600;
    }

    .footer-payment {
      .footer-payment__logo {
        padding: 1.5rem 1rem 2.2rem 0;
      }
    }

    .personal-area {
      .personal-area__link {
        display: flex;
        align-items: center;
        text-decoration: none;

        .personal-area__icon {
          max-width: 25px;
          margin: 10px 5px;
        }

        .personal-area__text {
          color: #ffffff;
          margin: 10px 5px 0 5px;
        }
      }
    }

    .footer-list {
      padding: 0;

      .footer-list__item {
        padding: 0.4rem 0;

        .footer-list__link {
          color: #ffffff;
          text-decoration: none;
        }

        .support {
          &:hover {
            cursor: pointer;
          }
        }
      }

      .footer-list__link.price-list {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .storage-personal-data {
    padding: 1rem 0;
    text-align: center;
    background-color: #035c96;

    @media screen and (max-width: 1024px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 475px) {
      font-size: 0.875rem;
    }

    @media screen and (max-width: 360px) {
      font-size: 0.75rem;
    }

    span {
      display: block;
    }

    .storage-personal-data__link {
      color: rgb(171, 191, 193);
    }
  }
}

</style>
