<template>
    <div id="header">
        <div class="wrap wrap-max">
            <div class="header-container container ">
                <div
                    class="flex-wrap d-flex justify-content-between"
                    style="align-items: baseline;"
                >
                    <div class="link-home height" align="left">
                        <link-home />
                    </div>
                    <div
                        class="link-sponsors d-none d-md-block d-lg-block d-xl-block height"
                    >
                        <link-sponsors />
                    </div>
                    <div
                        class="link-mobile d-md-none d-lg-none d-xl-none height"
                        v-if="screenWidth && screenWidth > 420"
                    >
                        <link-mobile />
                    </div>

                    <div
                        class="mobile-menu d-md-none d-lg-none d-xl-none height"
                        v-if="screenWidth && screenWidth <= 420"
                    >
                        <div class="bt-call">
                            <router-link to="call" class="link-call">
                                <span class="image-helper"></span>
                                <img
                                    class="phone-icon"
                                    src="/img/layout/call.svg"
                                />
                            </router-link>
                        </div>
                        <mobile-menu>
                            <template v-slot:headerMenu>
                                <header-menu
                                    :wedoShow="wedoShow"
                                    :headerBottom="headerBottom"
                                    @wedoOpen="wedoOpenAction"
                                />
                            </template>

                            <template v-slot:mobileLinks>
                                <link-mobile />
                            </template>
                        </mobile-menu>
                    </div>
                    <div
                        ref="headerContainer"
                        class="header-menu height d-flex align-items-center justify-content-center"
                        v-if="screenWidth && screenWidth > 420"
                    >
                        <header-menu
                            :wedoShow="wedoShow"
                            :headerBottom="headerBottom"
                            @wedoOpen="wedoOpenAction"
                        />
                    </div>
                </div>

                <div class="wedo-container">
                    <transition name="fade">
                        <WeDoMenu
                            v-if="wedoShow"
                            @toggleClick="wedoShow = false"
                        />
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import linkHome from '../common/linkHome';
import linkMobile from '../common/linkMobile';
import linkSponsors from '../common/linkSponsors';
import headerMenu from '../common/headerMenu';
import mobileMenu from '../common/mobileMenu';
import WeDoMenu from '../common/header/WeDoMenu';

export default {
    name: 'pageHeader',
    components: {
        linkHome,
        headerMenu,
        linkSponsors,
        linkMobile,
        mobileMenu,
        WeDoMenu,
    },
    data() {
        return {
            screenWidth: null,
            wedoShow: false,
            isMounted: false,
            headerBottom: 0,
        };
    },
    props: ['headerSize'],
    methods: {
        wedoOpenAction() {
            this.wedoShow = !this.wedoShow;
        },
    },
    watch: {
        headerSize: {
            deep: true,
            handler(size) {
                this.headerBottom = size.height;
            },
        },
    },
    mounted() {
        const that = this;
        this.screenWidth = window.screen.width;
        window.addEventListener('resize', function() {
            that.screenWidth = this.screen.width;
        });
    },
};
</script>

<style lang="scss" scoped>
#header {
    text-align: center;

    .bt-call {
        background: #7db82d;
        width: 40px;
        height: 40px;
        border: 0;
        border-radius: 30px;
        position: absolute;
        top: 23%;
        right: 19%;

        @media screen and (max-width: 320px) {
            right: 24%;
        }

        .link-call {
            display: block;
            height: 100%;

            img {
                width: 50%;
                padding: 10px 0;
            }
        }
    }

    .wrap {
        //padding: 15px 0;
        //background: $header-gradient;
        &.wrap-max {
            width: 100%;
            height: 100%;
        }
        .header-container {
            min-height: 70px;
        }
    }
    .height {
        min-height: 80px;
    }
    .header-container {
        position: relative;
        width: 100%;

        .flex-wrap {
            flex-wrap: wrap;
        }
        .flex-wrap .link-home {
            flex: 1 1 227px;

            @media screen and (max-width: 280px) {
                flex: 1 1 200px;
            }
        }
        .flex-wrap .link-sponsors {
            flex: 2 2 420px;
        }
        .flex-wrap .header-menu {
            flex: 3 3 260px;
        }
        .flex-wrap .link-mobile {
            flex: 3 3 140px;
        }
        .flex-wrap .mobile-menu {
            flex: 3 3 0;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
