<template>
    <div class="base-layout d-flex flex-column h-100">
        <header ref="header" v-resize="onHeaderResize">
            <slot name="header" :size="headerSize"></slot>
            <vue-title></vue-title>
        </header>
        <main>
            <slot name="content"></slot>
        </main>
        <footer class="mt-auto">
            <slot name="footer"></slot>
        </footer>
    </div>
</template>
<script>
import resize from 'vue-resize-directive'

export default {
    name: "base_layout",
    data() {
        const defaultTicks = 1000 * 100
        return {
            headerSize: null,
            defaultTicks: defaultTicks,
            ticks: defaultTicks
        }
    },
    directives: {
        resize,
    },
    methods: {
        awaitRef(refName, callback) {
            if (this.ticks === 0) return this.ticks = this.defaultTicks
            if (!this.$refs[refName]) {
                setTimeout(() => {
                    this.awaitRef(refName)
                }, 1000)
            } else {
                callback(this.$refs[refName])
            }
            this.ticks--
        },
        onHeaderResize(element) {
            this.headerSize = {
                width: element.offsetWidth,
                height: element.offsetHeight
            }
        }
    },
    mounted() {


    }
}
</script>
<style lang="scss" scoped>
.base-layout {
    header {
        font-family: "HP Simplified";
    }

    main {
    }

    footer {
    }
}
</style>
