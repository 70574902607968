<template>
  <div class="container">
    <CaseStatusForm
        v-if="!searchOrder"
        :key="componentKey"
        @restartCaseStatusForm="forceRerender"
    />
    <CaseStatusResponse/>
    <div class="found-response" v-if="searchOrder.ord || searchOrder.notf">
      <div class="found-message" v-if="searchOrder.ord">
        Заявка <b>{{ searchOrder.ord }}</b> находится на регистрации. Данные по статусу будут доступны как только заявка
        будет
        передана в сервисный центр на исполнение.
      </div>
      <div class="not-found-message" v-if="searchOrder.notf !== ''">
        {{ searchOrder.notf }}
      </div>
      <div class="case-status-button">
        <DxButton
            id="button"
            :use-submit-behavior="true"
            text="Проверить статус заявки"
            template="buttonTemplate"
            @click="reloadPage"
        >
          <template #buttonTemplate="{ data }">
            <div class="button-template hovered"
                 style="transition: background-color 0.3s ease; background-color: #0a73bb; color: white; font-size: 1.5em; line-height: 2em; border:0; border-radius: 50px; padding: 0 50px;">
              {{ data.text }}
            </div>
          </template>
        </DxButton>
      </div>
    </div>
  </div>

</template>

<script>

import CaseStatusForm from "./CaseStatusForm";
import CaseStatusResponse from "./CaseStatusResponse";
import DxButton from 'devextreme-vue/button';

export default {
  name: "CaseStatus",
  data() {
    return {
      componentKey: 0,
    }
  },
  created() {
    const layout = {
      name: "Main",
    };
    this.$store.commit('setLayout', layout)
  },
  components: {
    CaseStatusForm,
    CaseStatusResponse,
    DxButton
  },
  computed: {
    searchOrder() {
      return this.$store.state.foundApp
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    reloadPage() {
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.found-response {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .not-found-message {
    text-align: center;
  }

  .case-status-button {
    margin: 30px 0;
  }
}

::v-deep .dx-button {
  border-color: transparent !important;
  background-color: transparent !important;
}

::v-deep .dx-button:hover {
  background-color: transparent !important;
}

.button-template:hover {
  background-color: $bootstrap-green !important;
}
</style>