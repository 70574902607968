<template>
    <div id="layout" class="h-100">
        <BaseLayout>
            <template v-slot:header>
                <pageHeader/>
            </template>

            <template v-slot:content>
                <slot></slot>
            </template>

            <template v-slot:footer>
                <pageFooter/>
            </template>
        </BaseLayout>
    </div>
</template>

<script>
    import pageHeader from "./pageHeader"
    import pageFooter from "./pageFooter"
    import BaseLayout from "../BaseLayout"
  export default {
    name: "MainLayout",
    components: {pageHeader, pageFooter, BaseLayout}
  }
</script>

<style scoped>

</style>