<template>
    <div id="homeSlide1" class="slide-content">
        <div class="row slider__1">
            <p>
                КРУПНЕЙШАЯ В РОССИИ СЕТЬ <br>
                АВТОРИЗОВАННЫХ СЕРВИСНЫХ ЦЕНТРОВ <br>
                <span class="bold">HP Inc. и Hewlett Packard Enterprise</span>
            </p>
        </div>
    </div>
</template>

<script>
  export default {
    name: "homeSlide1Content"
  }
</script>

<style lang="scss" scoped>
    .slide-content {
        height: 100%;

        font-size: 1vw;

        @media (max-width: 576px) {
            padding: 20px 5px;
        }

        @media (max-width: 768px) {
            font-size: 8px;
        }
    }

    .slider__1 {
        color: white;
        line-height: 1.5em;
        font-size: 2.2em;

        .bold {
            font-weight: 600;
        }
    }
</style>