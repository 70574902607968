function errorLogging(browser_console_error = null, request_sending_time = null) {
    let date = new Date();
    const params = {
        date_now: date.toLocaleString("ru", {timeZone: 'Europe/Moscow'}),
        browser: navigator.userAgent,
        page: window.location.href,
        page_referer: document.referrer,
        browser_console_error: String(browser_console_error),
        request_sending_time: String(request_sending_time)
    };

    fetch(process.env.VUE_APP_API_BASEURL + 'log/error-logging', {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => res.json());
}

export {errorLogging};