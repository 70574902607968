<template>
    <div class="group-select" :tabindex="tabindex" @blur="open = false">
        <div class="caption-link" :class="{ open: open, active: isActive }" @click="openSelect">
            <div class="vendor-offset" :style="offsetStyle(datasource.image)"></div>
            <span>{{ datasource.name }}</span>
            <span class="arrow" style="display:block; width: 20%"></span>
        </div>

        <div class="select-area" :class="{ selectHide: !open }" @mousedown.prevent>
            <div class="static-box" :class="{ selectHide: !open }">
                <slot name="select-list"
                      v-bind:auths="datasource.authorisations"
                      v-bind:vendName="datasource.name"
                >
                </slot>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "groupSelect",
    props: {
        datasource: {
            type: Object,
            required: true,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
        rememberChoice: {
            type: [Object, Array]
        }
    },
    components: {},
    data() {
        return {
            open: false,
            isActive: false
        };
    },
    methods: {
        offsetStyle(imageUrl) {
            imageUrl = imageUrl || "other";
            const backgroundUrl = '/img' + imageUrl;
            return {
                background: 'url(' + backgroundUrl + ') no-repeat',
                "background-size": 'contain',
                "background-position": "0 50%",
                "margin-left": "20px",
                width: '20%',
                height: '30px',
            }
        },
        openSelect() {
            this.open = !this.open
        },
    },
    watch: {
        rememberChoice() {
            if (this.rememberChoice.key === this.datasource.name && this.rememberChoice.data.length > 0) {
                return this.isActive = true
            }

            if (this.rememberChoice.key === this.datasource.name && this.rememberChoice.data.length === 0) {
                return this.isActive = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.group-select {
    outline: none;
    position: relative;

    .select-area {
        height: 100%;
        position: relative;
        border: 0;
        border-radius: 5px;
        z-index: 100;

        .static-box {
            width: 430px;

            @media screen and (max-width: 992px) {
                width: 100%;
            }
        }
    }

    .select-area > .static-box {
        position: static !important;
        z-index: 200;
        background-color: #ffffff;
        box-shadow: 0 2px 18px -6px #00000042;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .selectHide {
        display: none;
    }

    .caption-link {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        font-size: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-color: transparent;
        border-radius: 10px;
        width: 100%;
        height: 60px;
        margin: 10px 0;
        cursor: pointer;
        -webkit-box-shadow: 0 6px 6px -6px #777;
        -moz-box-shadow: 0 6px 6px -6px #777;
        box-shadow: 0 6px 12px -6px #777;
        transition: border-color .2s ease-out, box-shadow .2s ease-out;

        @media screen and (max-width: 1199px) {
            font-size: 14px;
            height: 55px;
        }

        &:hover {
            //border: 2px solid #0171bb;
            border-color: rgba(1, 61, 101, 0.3);
            box-shadow: 0 6px 12px -6px #015b97;
        }

        img {
            width: 70px;
            height: 50px;
            object-fit: contain;
            margin-left: 15px;
        }

        .arrow::before {
            position: absolute;
            content: '';
            border-top: 3px solid #0171bb;
            transform: rotate(45deg);
            transform-origin: top left;
            top: 20px;
            right: 18px;
            width: 10px;
            height: 10px;
            transition: .5s;
        }

        .arrow::after {
            position: absolute;
            content: '';
            border-bottom: 3px solid #0171bb;
            transform: rotate(-45deg);
            transform-origin: bottom left;
            top: 24px;
            right: 18px;
            width: 10px;
            height: 10px;
            transition: .5s;
        }
    }

    .caption-link.active {
        background-color: #daf4ff;
    }

    .caption-link.open {
        border-color: rgba(1, 113, 186, 0.2);
        box-shadow: 0 6px 12px -6px #015b97;

        .arrow::before {
            transform: rotate(135deg);
            top: 22px;
        }

        .arrow::after {
            transform: rotate(-135deg);
            top: 22px;
        }
    }

    @media (min-width: 992px) {
        .select-area {
            position: absolute;
            right: -420px;
            top: 0;
            width: 400px;
            height: 100%;
        }
    }

    @media screen and (max-width: 991px) {
        .caption-link {

            .arrow::before {
                transform-origin: center;
                top: 22px;
                right: 23px;
            }

            .arrow::after {
                transform-origin: center;
                top: 22px;
                right: 23px;
            }
        }


        .caption-link.open {

            .arrow::before {
                transform: rotate(135deg);
            }

            .arrow::after {
                transform: rotate(45deg);
            }
        }
    }
}
</style>