<template>
    <div class="pricelist">
        <div class="pricelist__modal">
            <span class="pricelist__close" @click="$emit('closePriceList')"></span>
            <div class="pricelist__inner">
                <p class="pricelist__text">
                    Пожалуйста, выберите сервисный центр, для которого необходимо показать прайс-лист.
                    Обратите внимание, что для различных СЦ прайс листы могут отличаться.
                </p>
                <select v-model="selectedList" class="pricelist__select" name="select" id="">
                <option v-for="(item, index) in selectedPrice" :key="index" :value="item">
                    {{ item.name }}
                </option>
            </select>
            </div>
            <a class="show-pricelist" target="_blank" :href="selectedList.pdfLink">Показать прайслист</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "PriceList",
    data() {
        return {
            selectedList: {id: 0, name: 'Рус.Саппорт - Санкт-Петербург', pdfLink: '/pricelists/20220321_price_СПб.pdf', value: 'sbp'},
            selectedPrice: [
                {id: 0, name: 'Рус.Саппорт - Санкт-Петербург', pdfLink: '/pricelists/20220321_price_СПб.pdf', value: 'sbp'},
                {id: 1, name: 'Рус.Саппорт - Самара', pdfLink: '/pricelists/20220321_price_САМ.pdf', value: 'samara'},
                {id: 2, name: 'Рус.Саппорт - Москва', pdfLink: '/pricelists/20220321_price_МСК.pdf', value: 'moscow'},
                {id: 3, name: 'Рус.Саппорт - Краснодар', pdfLink: '/pricelists/20220321_price_КРД.pdf', value: 'krasnodar'},
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
.pricelist {
    position: relative;

    .pricelist__modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        background-color: white;
        position: absolute;
        bottom: -105px;
        padding: 1.5rem;

        .pricelist__close {
            position: absolute;
            right: 0;
            top: 0;
            width: 30px;
            height: 30px;
            background-color: white;

            &:hover {
                cursor: pointer;
            }

            &::before {
                content: '';
                position: absolute;
                border-top: 3px solid #035c96;
                width: 15px;
                top: 17px;
                right: 12px;
                transform: rotate(45deg);
            }

            &::after {
                content: '';
                position: absolute;
                border-top: 3px solid #035c96;
                width: 15px;
                top: 17px;
                right: 12px;
                transform: rotate(-45deg);
            }
        }

        .pricelist__inner {
            color: #0073c0;
        }

        .pricelist__select {
            width: 100%;
            border: none;
            outline: none;
            border-bottom: 2px solid #0073c0;
            padding: 0.5rem 0;
        }

        .show-pricelist {
            display: block;
            margin-top: 1rem;
            background-color: white;
            border: 2px solid #0073c0;
            border-radius: 50px;
            padding: 0.7rem 2rem;
            color: #0073c0;
            transition: 0.2s;
            text-decoration: none;

            &:hover {
                background-color: #0073c0;
                color: #ffffff;
            }
        }
    }
}
</style>