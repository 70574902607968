<template>
  <svg xmlns="http://www.w3.org/2000/svg"

       :width="width"
       :height="height"
       :viewBox="viewBox"
       :aria-labelledby="iconName"
       role="presentation"
       :index="index"
  >

    <title
        lang="en"
    >{{iconName}} icon
    </title>

    <g :fill="iconColor">
      <slot/>
    </g>

  </svg>
</template>

<script>
export default {
  name: "IconBase",
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    viewBox: {
      type: [Number, String],
      default: "0 0 743 743"
    },
    index: {
      type: Number
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px;
}
</style>