<template :key="">
  <div id="formCreateOrder">
    <div v-if="submitted" style="padding:20%">
      <div class="repair_icon"></div>
      <p class="text-center answer-header">СПАСИБО ЗА ОБРАЩЕНИЕ!</p>
      <p class="text-center answer">Мы зарегистрировали его под номером</p>
      <p class="text-center answer-number">{{ successMessage }}</p>
      <p class="text-center answer">Наши специалисты свяжутся с вами в ближайшее время! </p>
    </div>

    <form
        id="contactForm"
        v-if="dataLoaded && !submitted"
        @submit.prevent="handleSubmit($event)"
    >
      <!-- Персональные данные -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">Персональные данные</div>
        <!-- Имя -->
        <div class="dx-field">
          <div class="dx-field-label">Имя</div>
          <div class="dx-field-value">
            <DxAutocomplete
                :data-source="names"
                v-model="formData.name"
                placeholder="Имя"
                :search-timeout="100"
            >
              <DxValidator>
                <DxRequiredRule message="Пожалуйста, введите ваше имя"/>
                <DxPatternRule
                    :pattern="namePattern"
                    message="Имя введено неверно"
                />
              </DxValidator>
            </DxAutocomplete>
          </div>
        </div>
        <!-- /.Имя -->

        <!-- Фамилия -->
        <div class="dx-field">
          <div class="dx-field-label">Фамилия</div>
          <div class="dx-field-value">
            <DxAutocomplete
                :data-source="surnames"
                v-model="formData.surname"
                placeholder="Фамилия"
                :search-timeout="100"
            >
              <DxValidator>
                <DxRequiredRule message="Пожалуйста, введите вашу фамилию"/>
                <DxPatternRule
                    :pattern="namePattern"
                    message="Фамилия введена неверно"
                />
              </DxValidator>
            </DxAutocomplete>
          </div>
        </div>
        <!-- /.Фамилия -->

        <!-- Телефон -->
        <div class="dx-field">
          <div class="dx-field-label">Телефон</div>
          <div class="dx-field-value">
            <DxTextBox
                :mask-rules="phoneMaskRules"
                :use-masked-malue="true"
                mask="+7 (000) 000-0000"
                mask-invalid-message="Телефон введен неверно"
                v-model="formData.phone"
            >
              <DxValidator>
                <DxRequiredRule message="Пожалуйста, введите ваш телефонный нормер"/>
              </DxValidator>
            </DxTextBox>
          </div>
        </div>
        <!-- Телефон -->

        <!-- Email -->
        <div class="dx-field">
          <div class="dx-field-label">Email</div>
          <div class="dx-field-value">
            <DxAutocomplete
                :data-source="emails"
                v-model="formData.email"
                placeholder="Email"
                :search-timeout="100"
            >
              <DxValidator>
                <DxRequiredRule message="Пожалуйста, введите ваш email"/>
                <DxEmailRule message="Email введен неверно"/>
              </DxValidator>
            </DxAutocomplete>
          </div>
        </div>
        <!-- /.Email -->

      </div>
      <!-- /.Персональные данные -->

      <!-- Местоположение -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">Местоположение</div>
        <div class="dx-field">
          <div class="dx-field-label">Город</div>
          <div class="dx-field-value">
            <DxAutocomplete
                :data-source="locations"
                v-model="formData.city"
                placeholder="Город"
                :search-timeout="100"
            >
              <DxValidator>
                <DxRequiredRule message="Пожалуйста, укажите ваш город"/>
                <DxPatternRule
                    :pattern="namePattern"
                    message="Город введен неверно"
                />
              </DxValidator>
            </DxAutocomplete>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Организация</div>
          <div class="dx-field-value">
            <DxAutocomplete
                :data-source="organizations"
                v-model="formData.company"
                placeholder="Организация"
                :search-timeout="100"
            />
          </div>
        </div>
      </div>
      <!-- /.Местоположение -->

      <div class="dx-fieldset">
        <div class="dx-fieldset-header">Сведения об оборудовании</div>
        <div class="dx-field">
          <div class="dx-field-label">Требуется:</div>
          <div class="dx-field-value">
            <DxRadioGroup
                :items="repairTypeNames"
                @valueChanged="repairTypeNameChanges"
            >
              <DxValidator>
                <DxRequiredRule
                    message="Пожалуйста, выберите вид обслуживания - по гарантии / платное"/>
              </DxValidator>
            </DxRadioGroup>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Серийный номер оборудования</div>
          <div class="dx-field-value">
            <DxTextBox
                v-model="formData.serialNumber"
                placeholder="Серийный номер оборудования"
            >
              <DxValidator>
                <DxCustomRule
                    :reevaluate="true"
                    message="Пожалуйста, введите серийный номер оборудования"
                    :validation-callback="serialRequiredRule"
                ></DxCustomRule>
              </DxValidator>
            </DxTextBox>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Продуктовый номер оборудования</div>
          <div class="dx-field-value">
            <DxTextBox
                v-model="formData.productNumber"
                placeholder="Продуктовый номер оборудования"
            >
              <DxValidator>
                <DxCustomRule
                    :reevaluate="true"
                    message="Пожалуйста, введите продуктовый номер оборудования"
                    :validation-callback="productRequiredRule"
                ></DxCustomRule>
              </DxValidator>
            </DxTextBox>
          </div>
        </div>
      </div>

      <div class="dx-fieldset">
        <div class="dx-fieldset-header">Выбор сервисного центра</div>
        <div class="dx-field">
          <div class="dx-field-label">Производитель</div>
          <div class="dx-field-value">
            <DxSelectBox
                v-model="formData.vendor"
                :items="vendors"
                :show-clear-button="true"
                :valueExpr="valueExpression"
                :displayExpr="displayExpression"
                placeholder="Выберите производителя"
                @change="vendorChanges()"
            >
              <DxValidator>
                <DxRequiredRule message="Пожалуйста, укажите производителя"/>
              </DxValidator>
            </DxSelectBox>

            <div class="infoHpe" v-if="this.$store.state.infoHpe">
              <span v-if="this.$store.state.selectVendor !== 'Samsung'">{{ this.$store.state.selectVendor }}</span>
              <span v-if="this.$store.state.selectVendor === 'Samsung'">HP Inc.</span> приняла
              решение остановить работу на
              российском рынке. Таким образом,
              останавливается обслуживание и
              ремонт оборудования по гарантии производителя. Мы более не можем оказывать гарантийный сервис техники
              <span v-if="this.$store.state.selectVendor !== 'Samsung'">{{ this.$store.state.selectVendor }}</span>
              <span v-if="this.$store.state.selectVendor === 'Samsung'">HP Inc.</span>
              Для обслуживания оборудования вы можете выбрать платный, негарантийный вариант обслуживания.
              Если вас интересует долгосрочный контракт на комплексное обслуживание имеющегося у вас парка оборудования
              – пожалуйста отправьте запрос на
              <a href="mailto:b2b@rus.support">b2b@rus.support</a>
              Если вы обращаетесь уже в рамках действующего с нами (не с производителем) сервисного контракта – просьба
              открывать заявки через наш контакт-центр.
            </div>

          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Вид оборудования (опционально)</div>
          <div class="dx-field-value">
            <DxSelectBox
                v-model="formData.environment"
                :items="environments"
                :show-clear-button="true"
                :valueExpr="valueExpression"
                :displayExpr="displayExpression"
                placeholder="Выберите вид оборудования (опционально)"
                @change="vendorChanges()"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Выбор сервисного центра (опционально)</div>
          <div class="dx-field-value">

            <DxSelectBox
                v-if="this.$store.state.adressCapture !== null"
                v-model="formData.servePoint = this.$store.state.sscId"
                :items="servePoints"
                :show-clear-button="true"
                :valueExpr="valueExpression"
                :displayExpr="displayExpression"
                placeholder="Выберите сервисный центр (опционально)"
                @change="vendorChanges()"
                item-template="item"
            >

              <template #item="{ data }">
                <AddressItem :item-data="data"/>
              </template>
            </DxSelectBox>

            <DxSelectBox
                v-else
                v-model="formData.servePoint"
                :items="servePoints"
                :show-clear-button="true"
                :valueExpr="valueExpression"
                :displayExpr="displayExpression"
                placeholder="Выберите сервисный центр (опционально)"
                @change="vendorChanges()"
                item-template="item"
            >

              <template #item="{ data }">
                <AddressItem :item-data="data"/>
              </template>
            </DxSelectBox>
          </div>
        </div>
      </div>

      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">Описание проблемы</div>
          <div class="dx-field-value">
            <DxTextArea
                v-model="formData.message"
                placeholder="Описание проблемы"
            />
          </div>
        </div>
        <DxCheckBox
            id="check"
            :value="false"
            text="Отправляя запрос на обслуживание, вы соглашаетесь с Условиями обработки и использования персональных данных и с Условиями обслуживания Rus.support"
        >
          <DxValidator>
            <DxCompareRule
                :comparison-target="checkTermsComparison"
                message="Вы должны принять Условия"
            />
          </DxValidator>
        </DxCheckBox>

      </div>

      <div class="dx-fieldset">
        <DxValidationSummary id="summary"/>
      </div>

      <div class="form-content-bottom submit-area" align="center" style="padding: 10px 0 40px 0">
        <DxButton
            id="button"
            :use-submit-behavior="true"
            text="Отправить"
            template="buttonTemplate"
            :disabled="this.$store.state.infoHpe"
        >

          <template #buttonTemplate="{ data }">
            <div>
              <div class="button-template hovered dx-button-text">
                {{ data.text }}
              </div>
              <div class="send-preload">
                <DxLoadIndicator
                    :visible="loadIndicatorVisible"
                    class="button-indicator"
                />
                <div v-if="loadIndicatorVisible" class="dx-button-text">{{ buttonText }}</div>
              </div>
            </div>
          </template>

        </DxButton>
      </div>
    </form>

    <div class="form-content-bottom submit-area" align="center" style="padding: 10px 0 40px 0"
         v-if="submitted"
    >

      <formButton
          :link="button.link"
          :customClass="button.customClass"
          :size="button.size"
          :sign="button.sign"
          :caption="button.caption"
          :formType="button.formType"
          @customClick="restartForm"
      />
    </div>
  </div>
</template>

<script>
import httpClient from "@/services/http.service.js";
import formButton from "@/components/widgets/formButton";
import DxSelectBox from 'devextreme-vue/select-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxDateBox from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
import DxValidationSummary from 'devextreme-vue/validation-summary';
import DxRadioGroup from 'devextreme-vue/radio-group';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import {DxAutocomplete} from "devextreme-vue/autocomplete";
import {
  DxValidator,
  DxRequiredRule,
  DxCompareRule,
  DxEmailRule,
  DxPatternRule,
  DxStringLengthRule,
  DxRangeRule,
  DxAsyncRule,
  DxCustomRule
} from 'devextreme-vue/validator';

import DxForm, {
  DxSimpleItem,
  DxButtonItem,
  DxGroupItem
} from 'devextreme-vue/form';
import 'devextreme-vue/text-area';
import 'devextreme-vue/radio-group';

import notify from 'devextreme/ui/notify';
import AddressItem from './formCreateOrder/templates/AddressItem'
import DxTextArea from 'devextreme-vue/text-area';
import {errorLogging} from "@/services/log.service";

export default {
  name: "formCreateOrder",
  components: {
    DxAutocomplete,
    formButton,
    DxLoadIndicator,
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxRequiredRule,
    DxGroupItem,
    DxPatternRule,
    DxEmailRule,
    DxSelectBox,
    DxCheckBox,
    DxTextBox,
    DxDateBox,
    DxButton,
    DxValidationSummary,
    DxValidator,
    DxCompareRule,
    DxStringLengthRule,
    DxRangeRule,
    DxAsyncRule,
    DxRadioGroup,
    DxCustomRule,
    AddressItem,
    DxTextArea
  },
  data() {
    const phoneMaskRules = {
      X: /[02-9]/
    };
    const nameEditorOptions = {
      height: "40",
      maxLength: "255",
      placeholder: "Имя"
    };
    const surnameEditorOptions = {
      height: "40",
      maxLength: "255",
      placeholder: "Фамилия"
    };
    const companyEditorOptions = {
      height: "40",
      maxLength: "255",
      placeholder: "Организация"
    };
    const cityEditorOptions = {
      height: "40",
      maxLength: "255",
      placeholder: "Ваш город"
    };
    const emailEditorOptions = {
      height: "40",
      maxLength: "255",
      placeholder: "E-mail"
    };
    const textAreaOptions = {
      height: "80",
      maxLength: "1000",
      placeholder: "Напишите нам"
    };
    const buttonOptions = {
      text: 'ОТПРАВИТЬ',
      type: 'success',
      useSubmitBehavior: true
    };

    const textBoxOptions = {
      height: "40",
      maxLength: "255",
      placeholder: "Type something..."
    };
    const checkBoxOptions = {
      text: "Согласен на обработку персональных данных"
    };

    const radioEditorOptions = {
      items: ["1", "2"],
      value: "1"
    };

    const repairTypeNames = [
      "обслуживание по гарантии производителя",
      "платное, негарантийное обслуживание"
    ];

    const repairTypes = [
      {
        id: 0,
        name: "обслуживание по гарантии производителя"
      },
      {
        id: 1,
        name: "платное, негарантийное обслуживание"
      }
    ];

    const contactTemplate = {
      text: String(),
      name: String(),
      surname: String(),
      company: String(),
      city: String(),
      phone: null,
      email: null,
      agreement: false,
      vendor: null,
      environment: null,
      servePoint: null,
      repairType: null,
      serialNumber: null,
      productNumber: null,
      message: ""
    };

    return {
      test: this.$store.state.infoHpe,
      names: [],
      surnames: [],
      emails: [],
      locations: [],
      organizations: [],
      layoutKey: 0,
      buttonText: 'Подождите, идет отправка данных...',
      loadIndicatorVisible: false,
      formData: contactTemplate,
      useColCountByScreen: true,
      useTreeCols: true,
      phoneMaskRules: phoneMaskRules,
      emailEditorOptions: emailEditorOptions,
      companyEditorOptions: companyEditorOptions,
      nameEditorOptions: nameEditorOptions,
      textAreaOptions: textAreaOptions,
      buttonOptions: buttonOptions,
      textBoxOptions: textBoxOptions,
      checkBoxOptions: checkBoxOptions,
      surnameEditorOptions: surnameEditorOptions,
      cityEditorOptions: cityEditorOptions,
      radioEditorOptions: radioEditorOptions,
      phonePattern: /^\+\s*7\s*\(\s*[0-9]\d{2}\)\s*\d{3}\s*-\s*\d{4}$/,
      namePattern: /^[^0-9]+$/,
      formInstance: null,

      repairTypeNames: repairTypeNames,
      repairTypes: repairTypes,
      selectedRepairTypeName: repairTypeNames[0],
      submitted: false,
      preorder: '',
      successMessage: '',
      btnSize: 1,
      button: {
        link: "link",
        customClass: "blue",
        size: 1,
        sign: "+",
        caption: "ОФОРМИТЬ ЕЩЕ ОДНУ ЗАЯВКУ",
        formType: "custom-click",
      },
      checkVendor: null,
      date: new Date()
    };
  },
  computed: {
    dataLoaded() {
      return this.$store.state.serviceVendors.length && this.$store.state.serviceCenters.length;
    },
    vendors() {
      const servePointSelected = (this.formData.servePoint && this.formData.servePoint.length)
          ? this.$store.state.serviceCenters
              .find((data) => data.ssc_id === this.formData.servePoint)
          : null;

      let availableVendors = this.$store.state.serviceVendors;

      if (servePointSelected !== null) {
        availableVendors = availableVendors.filter((vendorItem) => {
          const itemAuthorizations = vendorItem.authorisations.map((item) => item.sdau_id);
          return servePointSelected.authKeys.split(",")
              .some(key => itemAuthorizations.includes(key));
        });
      }

      return availableVendors.map((data) => {
        return {
          value: data.vendor_id,
          displayValue: data.name
        }
      });
    },
    environments() {
      const formData = this.formData;

      const selectedVendor = this.$store.state.serviceVendors
          .find(data => data.vendor_id === formData.vendor);
      const selectedServePoint = (this.formData.servePoint && this.formData.servePoint.length)
          ? this.$store.state.serviceCenters
              .find((data) => data.ssc_id === this.formData.servePoint)
          : null;

      if (!selectedVendor) return [];

      let availableAuthorizations = selectedVendor.authorisations;

      if (selectedServePoint !== null) {
        availableAuthorizations = availableAuthorizations
            .filter((authData) => selectedServePoint.authKeys
                .split(",").includes(authData.sdau_id))
      }

      return availableAuthorizations.map(authData => {
        return {value: authData.sdau_id, displayValue: authData.sdau_name}
      });
    },
    servePoints() {
      const environmentSelected = this.formData.environment;
      const vendorSelected = this.formData.vendor;

      let environmentRange = [];

      if (environmentSelected !== null) {
        environmentRange.push(environmentSelected)
      } else if (vendorSelected !== null) {
        environmentRange = this.environments.map(data => data.value)
      }

      return this.$store.state.serviceCenters
          .filter((data) => data.authKeys ? data.authKeys
              .split(",")
              .some(key => environmentRange.length
                  ? environmentRange.includes(key)
                  : true) : false)
          .map(item => {
            return {
              value: item.ssc_id,
              displayValue: item.ssc_address
            }
          });
    },
    selectedRepairType() {
      return this.repairTypes.find(type => type.name === this.formData.repairType);
    },
  },
  methods: {
    restartForm() {
      this.submitted = false;
      this.formData.serialNumber = '';
      this.formData.productNumber = '';
      this.formData.message = '';
    },
    displayExpression(item) {
      if (!item) return "";
      return item.displayValue
    },
    valueExpression(item) {
      if (!item) return "";
      return item.value
    },
    serialRequiredRule() {
      return !(this.selectedRepairType && this.selectedRepairType.id === 0 && !this.formData.serialNumber)
    },
    productRequiredRule() {
      return !(this.selectedRepairType && this.selectedRepairType.id === 0 && !this.formData.productNumber)
    },
    repairTypeNameChanges(e) {
      this.formData.repairType = e.value
    },
    vendorChanges() {
      this.formData.environment = null
    },
    screenByWidth(width) {
      return width < 720 ? 'sm' : 'md';
    },
    handleSubmit(event) {
      this.$submitter.submit(this.formData)
          .then(result => {
            if (result.success) {
              this.newOrder();
            } else {
              notify("Oops! Что-то пошло не так. Свяжитесь с нами. webdevelopment@tillypad.ru", "error", 10000);
              this.loadIndicatorVisible = false
            }
          });
    },
    async newOrder() {
      try {
        let error = null;

        /**
         * Timer: измеряет количество времени в секундах
         * потрачено на отправку запроса.
         * @type {number}
         */
        let seconds = 0;
        let timer = setInterval(() => {
          seconds++;
          if (seconds > 20) {
            notify("Не удалось отправить заявку. Свяжитесь с нами. webdevelopment@tillypad.ru", "error", 20000);
            clearInterval(timer)
            errorLogging('', seconds)
          }
        }, 1000)

        const {status, data} = await httpClient.post('/set-order', {
          formData: this.formData,
          preLoad: this.loadIndicatorVisible = true,
        });

        if (status === 200) {
          this.submitted = true;
          this.successMessage = data;
          this.loadIndicatorVisible = false;

          localStorage.setItem('username', this.formData.name);
          localStorage.setItem('surname', this.formData.surname);
          localStorage.setItem('email', this.formData.email);
          localStorage.setItem('location', this.formData.city);
          localStorage.setItem('organization', this.formData.company);

          // stops the timer
          clearInterval(timer)

          notify("Спасибо, что отправили заявку! Мы скоро свяжемся с вами.", "success", 5000);
        } else {
          console.log(error)
        }
      } catch (error) {
        errorLogging(error, null);
        console.error(error)
      }
    },
    formInitialized(event) {
      //console.log(event.component.instance())
      this.formInstance = event.component.instance()
    },
    checkTermsComparison() {
      return true;
    },
  },
  mounted() {
    if (localStorage.username !== null) {
      this.names.push(localStorage.username)
      this.surnames.push(localStorage.surname)
      this.emails.push(localStorage.email)
      this.locations.push(localStorage.location)
      this.organizations.push(localStorage.organization)
    }
  },
  watch: {
    formData: {
      deep: true,
      handler(val) {
        if (val.repairType !== null) {
          if (this.selectedRepairType.id === 0 && val.vendor != 6 && val.vendor != null) {
            this.$store.commit('setInfoHpe', true)
            this.$store.commit('setVendor', this.vendors.find(item => item.value === val.vendor).displayValue)
          } else {
            this.$store.state.infoHpe = false;
          }
        } else this.$store.state.infoHpe = false;
        /*if (this.date.getFullYear() === 2022 && this.date.getMonth() === 6 && this.date.getHours() === 17 && this.date.getMinutes() > 0 && this.date.getDate() === 7) {
          if (val.repairType !== null) {
            if (this.selectedRepairType.id === 0 && val.vendor != 5 && val.vendor != null) {
              this.$store.commit('setInfoHpe', true)
              this.$store.commit('setVendor', this.vendors[val.vendor - 1].displayValue)
              this.$store.state.hpeBrand = false
            } else {
              this.$store.state.infoHpe = false;
            }
          } else this.$store.state.infoHpe = false;
        } else {
          if (val.repairType !== null) {
            if (this.selectedRepairType.id + val.vendor == 2) {
              this.$store.commit('setInfoHpe', true)
              this.$store.state.hpeBrand = true
            } else {
              this.$store.state.infoHpe = false
            }
          }
        }*/
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.form-content-bottom {
  position: relative;
  //background-color: red;

  #button {

    .send-preload {
      display: flex;
      align-items: center;
      //padding-top: 20px;

      .button-indicator {
        margin: 0 10px;
        position: absolute;
        bottom: 15px;
        left: 100px;
      }

      .dx-button-text {
        position: absolute;
        bottom: 20px;
      }
    }

    .button-template {
      transition: background-color 0.3s ease;
      background-color: #0a73bb;
      color: white;
      font-size: 1.5em;
      line-height: 2em;
      border: 0;
      border-radius: 50px;
      padding: 8px 50px;
    }

  }
}


::v-deep .dx-field-label {
  width: 100%;
  display: none;
}

::v-deep .dx-field-value {
  width: 100% !important;
}

::v-deep .dx-button {
  border-color: transparent !important;
  background-color: transparent !important;
}

::v-deep .dx-button:hover {
  background-color: transparent !important;
}

.button-template:hover {
  background-color: $bootstrap-green !important;
}

.answer-header {
  color: #0073c0;
  font-weight: 600;
}

.answer {
  color: #0073c0;
}

.answer-number {
  color: #0073c0;
  font-weight: 600;
}

.infoHpe {
  padding: 10px;
  font-weight: 600;
  color: red;

  a {
    color: red;
  }
}
</style>