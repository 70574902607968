<template>
  <div class="row py-5 justify-content-center">
    <p class="paragraph">
      Наши инженеры по работе с широкоформатными устройствами проходят очное обучение в HP Inc., по результатам которого
      получают сервисные сертификаты. Полученная квалификация позволяет проводить установку, настройку, модернизацию
      устройств серии HP DesignJet, PageWide XL и HP Latex
    </p>
    <div class="col-md-4 text-center">
      <div class="device">
        <img class="img-fluid" src="/img/pages/hp-widescreen_dev01.png" alt="">
        <a class="link" target="_blank" href="https://www8.hp.com/ru/ru/printers/large-format/designjet-plotters.html">
          Устройства HP DesignJet
        </a>
      </div>
    </div>

    <div class="col-md-4 text-center">
      <div class="device">
        <img class="img-fluid" src="/img/pages/hp-widescreen_dev02.png" alt="">
        <a class="link" target="_blank"
           href="https://www8.hp.com/ru/ru/commercial-printers/pagewide-printers/overview.html">
          Устройства HP PageWide XL
        </a>
      </div>
    </div>

    <div class="col-md-4 text-center">
      <div class="device">
        <img class="img-fluid" src="/img/pages/hp-widescreen_dev03.png" alt="">
        <a class="link" target="_blank"
           href="https://www8.hp.com/ru/ru/commercial-printers/latex-printers/products.html">
          Устройства HP Latex
        </a>
      </div>
    </div>

    <div class="col-md-6">
      <div class="b-shadow">
        <img class="b-shadow__icon" src="/img/icons/hp-widescreen_icon01.png" alt="">
        <div class="b-shadow__content">
          <h2 class="b-shadow__title">
            РЕСУРСНЫЕ НАБОРЫ MAINTENANCE KIT
          </h2>
          <p class="b-shadow__text">
            Поставляем наборы с расходными материалами для широкоформатной техники. Их необходимо заменить при
            достижении определенного количества отпечатков. Это обеспечит высокое качество печати и продлит срок службы
            устройства
          </p>
        </div>
      </div>
      <!--  /.B-SHADOW BLOCK  -->
      <div class="b-shadow">
        <img class="b-shadow__icon" src="/img/icons/hp-widescreen_icon02.png" alt="">
        <div class="b-shadow__content">
          <h2 class="b-shadow__title">
            ВЫЕЗДНОЕ ОБСЛУЖИВАНИЕ
          </h2>
          <p class="b-shadow__text">
            При необходимости мы обслуживаем данные устройства в любой точке России, с которой есть регулярное
            транспортное сообщение. Если поблизости нет авторизованного сервисного центра, сертифицированный инженер
            будет командирован
          </p>
        </div>
      </div>
      <!--  /.B-SHADOW BLOCK  -->
      <div class="b-shadow">
        <img class="b-shadow__icon" src="/img/icons/hp-widescreen_icon03.png" alt="">
        <div class="b-shadow__content">
          <h2 class="b-shadow__title">
            УСЛУГИ ПО ЗАПУСКУ И ИНСТАЛЛЯЦИИ
          </h2>
          <p class="b-shadow__text">
            Чем сложнее техника, тем сложнее инсталляция. Для запуска широкоформатных устройств часто требуются услуги
            профессионального инженера. Неквалифицированный запуск может привести к выходу устройства из строя
          </p>
        </div>
      </div>
      <!--  /.B-SHADOW BLOCK  -->
    </div>

  </div>
</template>

<script>
export default {
  name: "HpWidescreenContent"
}
</script>

<style lang="scss" scoped>

.device a {
  display: block;
}

.b-shadow {
  margin: 2.5rem 0;
}

</style>
