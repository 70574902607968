<template>
    <div class="accordion-advantage__desc">
        <p>
            Мы контролируем качество обслуживания, но не всегда можем удаленно понять остался ли доволен клиент каким-то
            конкретным ответом или каким-то определенным сотрудником сервисного центра.
        </p>
        <p>
            Если у вас есть замечания или жалобы на работу сервисных центров нашей сети, просим позвонить в наш
            контакт-центр по телефону 8 800 7-555-777 и оставьте свой отзыв оператору. Вы также можете оставить отзыв,
            написав письмо по адресу
            <a href="mailto:feedback@rus.support">feedback@rus.support</a>
        </p>
    </div>
</template>

<script>
export default {
    name: "QualityControl"
}
</script>

<style scoped>

</style>