<template>
  <div class="row py-5">

    <div class="caption">
      <p class="caption__title">
        ПРЕИМУЩЕСТВА
      </p>
    </div>
    <!-- /.caption -->

    <div class="col-md-4">
      <div class="b-shadow">
        <img class="b-shadow__icon" src="/img/icons/hp-spareparts_icon01.png" alt="">
        <div class="b-shadow__content">
          <h2 class="b-shadow__title">ОРИГИНАЛЬНЫЕ ЗАПАСНЫЕ ЧАСТИ</h2>
          <p class="b-shadow__text">
            Как официальный сервисный партнер, мы гарантируем оригинальность и высокое качество запасных частей и
            расходных материалов
          </p>
        </div>
      </div>
      <!--  /.B-SHADOW BLOCK  -->
    </div>
    <div class="col-md-4">
      <div class="b-shadow">
        <img class="b-shadow__icon" src="/img/icons/hp-spareparts_icon02.png" alt="">
        <div class="b-shadow__content">
          <h2 class="b-shadow__title">ШИРОКИЙ АССОРТИМЕНТ</h2>
          <p class="b-shadow__text">
            Мы предлагаем оригинальные запасные части и расходные материалы практически для любого обслуживаемого нашей
            сетью оборудования
          </p>
        </div>
      </div>
      <!--  /.B-SHADOW BLOCK  -->
    </div>
    <div class="col-md-4">
      <div class="b-shadow">
        <img class="b-shadow__icon" src="/img/icons/hp-spareparts_icon01.png" alt="">
        <div class="b-shadow__content">
          <h2 class="b-shadow__title">ГАРАНТИЯ 3 МЕСЯЦА</h2>
          <p class="b-shadow__text">
            На запчасти предоставляется гарантия от <br>
            <span class="link">90 дней и более</span>
          </p>
        </div>
      </div>
      <!--  /.B-SHADOW BLOCK  -->
    </div>

    <div class="col-md-12">
      <div class="note">
        <span class="note__square"></span>
        <p>
          Вы также можете обратиться к нам за проверкой запасных частей на оригинальность. Для крупных поставок возможна
          проверка с привлечением специалистов производителей оборудования
        </p>
        <p>
          Используя настоящие запчасти, вы сможете не только восстановить работоспособность устройства, но и
          гарантировать его надежную и безопасную работу
        </p>
      </div>
    </div>
    <div class="col-md-12">
      <div class="note-white">
        <span class="note__square"></span>
        <span class="note__title">
          Обратите внимание
        </span>
        <p class="note__text">
          Мы контролируем качество ПЛАТНЫХ запасных частей только в ряде брендированных сервисных центров. Такие
          сервисные центры выделены на карте особым значком
        </p>
        <div class="note__icon">
          <img src="/img/icons/hp-spareparts_sign.png" alt="">
        </div>
        <p>
          Остальные сервисные центры могут использовать собственные каналы поставки запчастей. Если вы хотите быть
          уверены в поставке — присылайте запрос на адрес <a class="link" href="mailto:sale@rus.support">sale@rus.support</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HpSparepartsContent"
}
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
}

.b-shadow {
  display: block;
  text-align: center;
  height: 90%;

  .b-shadow__icon {
    max-width: 100px;
    margin: 0 0 20px 0;
  }
}

.note-white {
  max-width: 100%;
}
</style>
