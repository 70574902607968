<template>
  <div class="row py-5">

    <div class="title">
      МЫ ВЫПОЛНЯЕМ
    </div>

    <div class="col-md-8">

      <!-- LIST BLOCK -->
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Аудит микрокода и ПО и составление рекомендаций по обновлению
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Аудит текущего состояния с анализом существующих проблем
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Аудит производительности с отчетом о наличии «узких» мест и планом модернизации
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Анализ текущей инфраструктуры, учитывая потребности вашей компании
          </p>
        </div>
      </div>
      <!-- /.LIST BLOCK -->

      <div class="title">
        В КАКИХ СЛУЧАЯХ <br>
        РЕКОМЕНДУЕТСЯ
      </div>

      <!-- LIST BLOCK -->
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Нужен детальный отчет о состоянии и исправности системы (healthcheck)
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Необходимо выявить возможные «узкие» места и принять профилактические меры
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Требуется повысить производительность системы, ее доступность и стабильность, провести сложную
            настройку
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Требуется составить план комплексного обновления микрокода на оборудование
          </p>
        </div>
      </div>
      <!-- /.LIST BLOCK -->

      <p class="paragraph">
        Если вас интересуют наши проактивные сервисы — присылайте запрос на адрес
        <a class="link" href="mailto:sale@rus.support">sale@rus.support</a>,
        и мы вышлем вам предложение
      </p>
    </div>
    <div class="col-md-4">
      <div class="note-white">
        <span class="note__square"></span>
        <span class="note__title">
          Проактивный анализ помогает
        </span>
        <p class="note__text">
          обеспечить необходимый уровень производительности ИТ-систем, прогнозировать ее возможности для
          дальнейшего масштабирования
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HpeProactiveContent"
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 1rem;
}
</style>