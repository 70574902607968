<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      НРE
      PАCKAGED SERVICE
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Пакеты HPE Packaged Services предлагают дополнительные сервисы для оборудования Hewlett Packard Enterprise
    </p>
  </div>
</template>

<script>
export default {
  name: "hpePackagedSliderContent"
}
</script>

<style scoped>

</style>