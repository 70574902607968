<template>
  <div class="terms-accordion__item-content">

    <p>
      10.1. Если оборудование не имеет действующих гарантийных обязательств производителя оборудования, неисправность не
      попадает под условия гарантии производителя, или определенная услуга не входит в состав гарантийных обязательств,
      то СЦ может выполнить сервисное обслуживание на платной основе.
    </p>
    <p>
      10.2. Срок выполнения негарантийного сервисного обслуживания и/или поставки запасных частей зависит от наличия
      запасных частей на локальных складах СЦ или его поставщиков. Ожидаемый срок ремонта в каждом конкретном случае
      согласовывается с Заказчиком до заказа запасных частей. Как правило срок такого негарантийного ремонта составляет
      от 10 до 20 рабочих дней, но в некоторых случаях может быть увеличен СЦ в одностороннем порядке на срок до 60
      рабочих дней, о чём СЦ обязуется уведомить Заказчика своевременно.
    </p>
    <p>
      10.3. Заказчик имеет право запросить у СЦ выполнение сервисного обслуживания без проведения диагностики силами СЦ,
      основываясь на самостоятельно проведенной Заказчиком диагностике неисправности, и/или запросить использование для
      проведения работ по сервисному обслуживанию запасных частей и материалов Заказчика. СЦ имеет право согласиться или
      отказаться от проведения такого сервисного обслуживания по своему усмотрению. В случае согласия СЦ не несет
      ответственности:
    </p>
    <ul>
      <li>за точность диагностики и результат устранения неисправности по итогам выполнения таких работ;</li>
      <li>за возможные неисправности оборудования и/или запчастей, или любые иные последствия, возникшие в итоге
        проведения сервисного обслуживания по причине неточности самостоятельно проведенной Заказчиком диагностики или
        из-за некачественных предоставленных Заказчиком запасных частей или материалов;
      </li>
      <li>за техническую возможность выполнения работ по сервисному обслуживанию.</li>
    </ul>
    <p>
      Вне зависимости от результатов проведения такого сервисного обслуживания Заказчик обязан оплатить стоимость таких
      работ. СЦ также не дает гарантии на запасные части и материалы, предоставленные Заказчиком.
    </p>
    <p>
      10.4. По результатам диагностики Заказчику озвучиваются стоимость и ожидаемые сроки выполнения сервисного
      обслуживания, с учётом сроков поставки необходимых для такого обслуживания запасных частей. Данное предложение
      актуально в течение 3 рабочих дней, если в самом предложении не указан другой срок. Работы выполняются на условиях
      100% предоплаты. В случае, если Заказчик производит оплату позднее этого срока, то стоимость и сроки могут
      измениться. Фактом оплаты считается поступление полной суммы на расчётный счёт СЦ, или предоставление копии
      банковского платёжного поручения, подтверждающего перевод средств, или успешное прохождение транзакции при оплате
      банковской картой непосредственно в СЦ.
    </p>
    <p>
      10.5. В случае отказа от ремонта Заказчик обязан оплатить стоимость уже выполненных с оборудованием работ и уже
      использованных материалов и запасных частей. Оборудование возвращается Заказчику только после предоставления
      документов об оплате уже выполненных СЦ работ или поступления оплаты.
    </p>
    <p>
      10.6. Гарантия на работы составляет 90 календарных дней (если иное не указано в карточке ремонта (для работ,
      проводившихся в СЦ) или в акте выездного обслуживания (при выездных работах)) и исчисляется с момента окончания
      работ. Гарантия распространяется на проведенные СЦ работы, при условии отсутствия на оборудовании следов
      механических и электрических повреждений, проникновения жидкостей, иных нарушений условий эксплуатации, возникших
      после окончания работ. Гарантийные требования принимаются лишь в течение указанного гарантийного срока.
    </p>
    <p>
      10.7 Гарантия на предоставленные сервисным центром запасные части составляет 90 календарных дней (если иное не
      указано
      в карточке ремонта (для работ, проводившихся в СЦ), в акте выездного обслуживания (при выездных работах), в
      коммерческом предложении или акте приема-передачи запасных частей) и исчисляется следующим образом:
    </p>
    <ul>
      <li>
        Если продажа запчастей сервисным центром Заказчику происходила в рамках осуществляемого сервисным центром
        ремонта оборудования Заказчика, и
        <ul type="circle">
          <li>
            обслуживание оборудования Заказчика производилось в СЦ и на момент поступления запасных частей оборудование
            Заказчика находилось в СЦ, то гарантия на запасные части исчисляется момента окончания работ по установке
            таких запчастей в оборудование Заказчика и уведомления Заказчика об окончании таких работ;
          </li>
          <li>
            в иных случаях гарантия на запасные части исчисляется с момента оповещения Заказчика о готовности СЦ к
            проведению работ с использованием запасных частей.
          </li>
        </ul>
      </li>
      <li>
        Если продажа запчастей сервисным центром Заказчику происходила не в рамках осуществляемого сервисным центром
        ремонта оборудования Заказчика (отдельная продажа запчастей), и
        <ul type="circle">
          <li>
            в случае, когда получение запасных частей из сервисного центра в рамках заявки является обязанностью
            Заказчика, то гарантия на запасные части исчисляется с даты оповещения Заказчика о готовности СЦ к выдаче
            запасных частей Заказчику (или его представителю);
          </li>
          <li>
            в случае, когда доставка запасных частей из сервисного центра Заказчику является обязанностью СЦ в рамках
            заявки, то гарантия на запасные части исчисляется с даты отправки запасных частей Заказчику транспортной
            (курьерской) компанией.
          </li>
        </ul>
      </li>

    </ul>

    <p>
      10.8. СЦ может признать оборудование неремонтопригодным и сообщить о невозможности ремонта Заказчику, например, по
      причине отсутствия необходимых запасных частей и недоступности их к заказу. При этом уже выполненные СЦ работы по
      сервисному обслуживанию подлежат оплате Заказчиком в полном объёме.
    </p>
    <p>
      10.9. СЦ при приёмке оборудования не даёт предварительных гарантий относительно ожидаемых результатов и сроков
      ремонта. Стоимость ремонта (включая стоимость работ и запчастей) может быть установлена только после проведения
      диагностики. СЦ не гарантирует одинаковую стоимость одних и тех же запасных частей, заказанных в рамках разных
      ремонтов.
    </p>
    <p>
      10.10. В ходе негарантийного ремонта СЦ использует только те микрокоды, драйверы и иное программное обеспечение
      производителя (далее совместно именуемые ПО), которое производитель разместил в свободном доступе. Если для
      доступа к ПО требуется активная официальная поддержка производителя, то такое ПО не может использоваться СЦ в
      рамках негарантийного обслуживания оборудования.
    </p>

    <p>
      10.11. При достижении оборудованием даты «End of support» (даты официального окончания поддержки модели
      оборудования, установленной производителем) производитель прекращает выпуск микрокодов, драйверов и иного
      программного обеспечения для оборудования, не гарантирует производство и доступность запасных частей для него.
      Прекращается техническая поддержка такого оборудования производителем, что приводит к невозможности эскалации
      проблем, исправления багов и уязвимостей в ПО, обновления документации. В связи с этим негарантийное обслуживание
      оборудования, достигшего даты «End of support» в некоторых случаях может быть невозможно.
    </p>

  </div>
</template>

<script>
export default {
  name: "outOfWarranty2021"
}
</script>

<style scoped>

</style>