import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../pages/Home/Home.vue';
import ServiceSearch from '../pages/ServiceMap/ServiceSearch.vue';
import ServiceMap from '@/pages/ServiceMap/ServiceMap';
import error from '../pages/error';
import About from '@/pages/About/About';
import Hp from '@/pages/About/Hp/Hp';
import Hpe from '@/pages/About/Hpe/Hpe';
import Aruba from '@/pages/About/Aruba/Aruba';
import HpGuarantee from '@/pages/About/we-do/HpGuarantee/HpGuarantee';
import HpCarepack from '@/pages/About/we-do/HpCarepack/HpCarepack';
import HpContracts from '@/pages/About/we-do/HpContracts/HpContracts';
import HpPostguarantee from '@/pages/About/we-do/HpPostguarantee/HpPostguarantee';
import HpSpareparts from '@/pages/About/we-do/HpSpareparts/HpSpareparts';
import HpWidescreen from '@/pages/About/we-do/HpWidescreen/HpWidescreen';
import HpOutsourceprint from '@/pages/About/we-do/HpOutsourceprint/HpOutsourceprint';
import HpUpgrade from '@/pages/About/we-do/HpUpgrade/HpUpgrade';
import HpDaas from '@/pages/About/we-do/HpDaas/HpDaas';
import ArubaService from '@/pages/About/we-do/ArubaService/ArubaService';
import SamsungGuarantee from '@/pages/About/we-do/SamsungGuarantee/SamsungGuarantee';
import SamsungPostguarantee from '@/pages/About/we-do/SamsungPostguarantee/SamsungPostguarantee';
import SamsungSpareparts from '@/pages/About/we-do/SamsungSpareparts/SamsungSpareparts';
import Samsung from '@/pages/About/Samsung/Samsung';
import HpeGuarantee from '@/pages/About/we-do/HpeGuarantee/HpeGuarantee';
import HpePostGuarantee from '@/pages/About/we-do/HpePostGuarantee/HpePostGuarantee';
import HpePackaged from '@/pages/About/we-do/HpePackaged/HpePackaged';
import HpeContracts from '@/pages/About/we-do/HpeContracts/HpeContracts';
import HpeUpgrade from '@/pages/About/we-do/HpeUpgrade/HpeUpgrade';
import HpeProactive from '@/pages/About/we-do/HpeProactive/HpeProactive';
import HpeSpareparts from '@/pages/About/we-do/HpeSpareparts/HpeSpareparts';
import OthersOutsource from '@/pages/About/we-do/OthersOutsource/OthersOutsource';
import OthersServers from '@/pages/About/we-do/OthersServers/OthersServers';
import OthersApc from '@/pages/About/we-do/OthersApc/OthersApc';
import Apc from '@/pages/About/Apc/Apc';
import OthersCourses from '@/pages/About/we-do/OthersCourses/OthersCourses';
import OtherServices from '@/pages/About/OtherServices/OtherServices';

import Requisites from '@/pages/Requisites/Requisites';
import Instruction from '@/pages/About/Instruction/Instruction';
import Tutorials from '../pages/Tutorials/Tutorials';
import Privacy from '../pages/Privacy/Privacy';
import OrderCaseStatus from '../pages/OrderCaseStatus/CaseStatus';
import ISA3MN from '../pages/Certificate/info/ISA3MN';
import ISA3KT from '../pages/Certificate/info/ISA3KT';
import ISXL from '../pages/Certificate/info/ISXL';
import Terms from '../pages/Terms/Terms';
import store from '../store/index.js';
import AvisionVue from '../pages/About/Avision/Avision.vue';
import PriceLists from "@/pages/PriceLists/PriceLists.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    // {
    //   path: '/:sc',
    //   name: 'HomeSC',
    //   component: Home
    // },
    {
        path: '/map',
        name: 'ServiceMap',
        component: ServiceMap,
    },
    // Certificates
    {
        path: '/certificate/info/ISA3MN',
        name: 'ISA3MN',
        component: ISA3MN,
    },
    {
        path: '/certificate/info/ISA3KT',
        name: 'ISA3KT',
        component: ISA3KT,
    },
    {
        path: '/certificate/info/ISXL',
        name: 'ISXL',
        component: ISXL,
    },
    // /.Certificates
    {
        path: '/sc/:capture',
        name: 'ServiceSearch/',
        component: ServiceSearch,
    },
    {
        path: '/sc',
        name: 'ServiceSearch/',
        component: ServiceSearch,
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../pages/About/About'),
    },
    {
        path: '/about/hp',
        name: 'Hp',
        component: () => import('../pages/About/Hp/Hp'),
    },
    {
        path: '/about/hpe',
        name: 'Hpe',
        component: () => import('../pages/About/Hpe/Hpe'),
    },
    {
        path: '/about/hp_guarantee',
        name: 'HpGuarantee',
        component: () => import('../pages/About/we-do/HpGuarantee/HpGuarantee'),
    },
    {
        path: '/about/hp_carepack',
        name: 'HpCarepack',
        component: () => import('../pages/About/we-do/HpCarepack/HpCarepack'),
    },
    {
        path: '/about/hp_contracts',
        name: 'HpContracts',
        component: () => import('../pages/About/we-do/HpContracts/HpContracts'),
    },
    {
        path: '/about/hp_postguarantee',
        name: 'HpPostguarantee',
        component: () =>
            import('../pages/About/we-do/HpPostguarantee/HpPostguarantee'),
    },
    {
        path: '/about/hp_spareparts',
        name: 'HpSpareparts',
        component: () =>
            import('../pages/About/we-do/HpSpareparts/HpSpareparts'),
    },
    {
        path: '/about/hp_widescreen',
        name: 'HpWidescreen',
        component: () =>
            import('../pages/About/we-do/HpWidescreen/HpWidescreen'),
    },
    {
        path: '/about/hp_outsourceprint',
        name: 'HpOutsourceprint',
        component: () =>
            import('../pages/About/we-do/HpOutsourceprint/HpOutsourceprint'),
    },
    {
        path: '/about/hp_upgrade',
        name: 'HpUpgrade',
        component: () => import('../pages/About/we-do/HpUpgrade/HpUpgrade'),
    },
    {
        path: '/about/hp_daas',
        name: 'HpDaas',
        component: () => import('../pages/About/we-do/HpDaas/HpDaas'),
    },
    {
        path: '/about/samsung_guarantee',
        name: 'SamsungGuarantee',
        component: () =>
            import('../pages/About/we-do/SamsungGuarantee/SamsungGuarantee'),
    },
    {
        path: '/about/samsung_postguarantee',
        name: 'SamsungPostGuarantee',
        component: () =>
            import(
                '../pages/About/we-do/SamsungPostguarantee/SamsungPostguarantee'
            ),
    },
    {
        path: '/about/samsung_spareparts',
        name: 'SamsungSpareParts',
        component: () =>
            import('../pages/About/we-do/SamsungSpareparts/SamsungSpareparts'),
    },
    {
        path: '/about/samsung',
        name: 'Samsung',
        component: Samsung,
    },
    {
        path: '/about/hpe_guarantee',
        name: 'HpeGuarantee',
        component: () =>
            import('../pages/About/we-do/HpeGuarantee/HpeGuarantee'),
    },
    {
        path: '/about/hpe_postguarantee',
        name: 'HpePostGuarantee',
        component: () =>
            import('../pages/About/we-do/HpePostGuarantee/HpePostGuarantee'),
    },
    {
        path: '/about/hpe_packaged',
        name: 'HpePackaged',
        component: () => import('../pages/About/we-do/HpePackaged/HpePackaged'),
    },
    {
        path: '/about/hpe_contracts',
        name: 'HpeContracts',
        component: () =>
            import('../pages/About/we-do/HpeContracts/HpeContracts'),
    },
    {
        path: '/about/hpe_upgrade',
        name: 'HpeUpgrade',
        component: () => import('../pages/About/we-do/HpeUpgrade/HpeUpgrade'),
    },
    {
        path: '/about/hpe_proactive',
        name: 'HpeProactive',
        component: () =>
            import('../pages/About/we-do/HpeProactive/HpeProactive'),
    },
    {
        path: '/about/hpe_spareparts',
        name: 'HpeSpareparts',
        component: () =>
            import('../pages/About/we-do/HpeSpareparts/HpeSpareparts'),
    },
    {
        path: '/about/others_outsource',
        name: 'OthersOutsource',
        component: () =>
            import('../pages/About/we-do/OthersOutsource/OthersOutsource'),
    },
    {
        path: '/about/others_servers',
        name: 'OthersServers',
        component: () =>
            import('../pages/About/we-do/OthersServers/OthersServers'),
    },
    {
        path: '/about/others_apc',
        name: 'OthersApc',
        component: () => import('../pages/About/we-do/OthersApc/OthersApc'),
    },
    {
        path: '/about/Apc',
        name: 'Apc',
        component: Apc,
    },
    {
        path: '/about/others_courses',
        name: 'OthersCourses',
        component: () =>
            import('../pages/About/we-do/OthersCourses/OthersCourses'),
    },
    {
        path: '/about/otherservices',
        name: 'OtherServices',
        component: OtherServices,
    },
    {
        path: '/requisites',
        name: 'Requisites',
        component: Requisites,
    },
    {
        path: '/about/instruction',
        name: 'Instruction',
        component: Instruction,
    },
    /*{
        path: '/case-status',
        name: 'CaseStatus',
        component: () => import('@/pages/CaseStatus/CaseStatus'),
    },*/
    {
        path: '/order/case-status',
        name: 'OrderCaseStatus',
        component: OrderCaseStatus,
    },
    {
        path: '/connect',
        name: 'Tutorials',
        component: Tutorials,
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy,
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms,
    },
    {
        path: '/about/avision',
        name: 'Avision',
        component: AvisionVue,
    },
    {
        path: '/pricelists/:name',
        name: 'PriceLists',
        component: PriceLists
    },
    {
        path: '/*',
        name: '',
        component: error,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    const newTime = new Date().getTime();
    store.commit('setLoading', {
        value: true,
        time: newTime,
    });
    setTimeout(() => next(), 500);
});
router.afterEach((to, from) => {
    const newTime = new Date().getTime();
    // console.log(newTime)
    store.commit('setLoading', {
        value: false,
        time: newTime,
    });
});

export default router;

/*
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.

  //console.warn(to.matched);
  
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with mif (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  } else {
    document.title = DEFAULT_TITLE;
  }

  //console.log('title = ', document.title);

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));
  
  next();
});

//export default router
*/
