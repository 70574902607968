<template>
    <div class="video">
        <div class="container">
            <iframe width="100%" height="600" src="https://www.youtube.com/embed/gWzQvIC1iLo" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
            </iframe>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.video {
    background-color: #F1F1F1;
    padding: 2rem 0;
}
</style>
