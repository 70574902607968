<template>
    <div class="accordion-advantage__desc">
        <p>
            Для гарантийных ремонтов мы получаем запасные части со складов производителя, что гарантирует их
            оригинальность и
            совместимость.
        </p>
        <p>
            Все сервисные центры сети Рус.Саппорт, как собственные, так и партнёрские, получают запасные части для
            гарантийных
            ремонтов напрямую от производителей оборудования.
        </p>
        <p>
            Также мы имеем доступ к каналам платных оригинальных запасных частей и предлагаем своим клиентам приобрести
            у нас
            запасные части в случае такой необходимости.
        </p>
        <p>
            Используя оригинальные запчасти, клиент сможет не только восстановить работоспособность устройства, но и
            гарантировать его надежную и безопасную работу.
        </p>
        <p>
            На поставленные запчасти предоставляется гарантия от 90 дней до года.
        </p>
        <p>
            Внимание: услуга поставки платных запчастей гарантируется только в собственных брендированных сервисных
            центрах
            сети Рус.Саппорт. Сети, входящие в сеть Рус.Саппорт по партнёрской программе, могут иметь собственные
            политики
            поставки платных запасных частей и свои каналы поставки, за которые сеть Рус.Саппорт не несёт
            ответственности.
        </p>
        <p>
            Наши собственные брендированные сервисные центры выделены на карте таким значком: <img
            src="/img/icons/icon_self.png" alt="">
        </p>
    </div>
</template>

<script>
export default {
    name: "OriginalSpareParts"
}
</script>

<style scoped>

</style>