<template>
  <div class="row py-5">
    <p class="paragraph">
      Samsung Group — группа компаний, созданная в Корее в 1930-х годах. Сейчас это огромный концерн, в который входят
      компании, работающие в таких областях, как электроника и микроэлектроника, химическая промышленность,
      строительство, автомобилестроение, тяжелая промышленность, финансы и кредит, страхование.
    </p>
    <p class="paragraph">
      Одна из компаний концерна, Samsung Electronics, долгое время производила принтеры и МФУ, пока в конце 2017 года не
      продала свой бизнес по производству печатной техники компании HP Inc.
    </p>
    <p class="paragraph">
      Информация на сайте Samsung: <a class="link" href="https://www.samsung.com/ru/support/newsalert/101187/"
                                      target="_blank">https://www.samsung.com/ru/support/newsalert/101187/</a>
    </p>
    <p class="paragraph">
      Мы официально обслуживаем печатное оборудование производства компании Samsung в РФ с 2017 года.
    </p>

    <ul class="list-link">
      <li class="list-link__item"></li>
      <li class="list-link__item">
        <a class="list-link__link" href="https://rus.support/about/we-do?action=hp_guarantee">
          ГАРАНТИЙНЫЙ РЕМОНТ ПРИНТЕРОВ И МФУ
        </a>
      </li>
      <li class="list-link__item">
        <a class="list-link__link" href="https://rus.support/about/we-do?action=hp_guarantee">
          ПОСТГАРАНТИЙНОЕ ОБСЛУЖИВАНИЕ
        </a>
      </li>
      <li class="list-link__item">
        <a class="list-link__link" href="https://rus.support/about/we-do?action=hp_guarantee">
          ПОСТАВКИ ОРИГИНАЛЬНЫХ ЗАПЧАСТЕЙ
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SamsungContent"
}
</script>

<style lang="scss" scoped>
.paragraph {
  text-align: left;
}

.list-link {
  max-width: calc(100% / 12 * 6);

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
</style>