<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      ГАРАНТИЙНЫЙ РЕМОНТ ОБОРУДОВАНИЯ
      HEWLETT PACKARD ENTERPRISE
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Сеть сервисных центров Rus.Support является официальным сервисным партнером Hewlett Packard Enterprise (HPE) в
      России
    </p>
  </div>
</template>

<script>
export default {
  name: "hpeGuaranteeSliderContent"
}
</script>

<style scoped>

</style>