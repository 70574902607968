<template>
    <div class="custom-item">
        <div class="address-string">
            {{ displayValue }}
        </div>
    </div>
</template>
<script>
  export default {
    props: {
      itemData: {
        type: Object
      }
    },
    computed: {
      displayValue () {
        const dVal = this.itemData.displayValue;
        if(!dVal) return "";
        let displayValue = dVal;

        if(!isNaN(displayValue[0])) {
          displayValue = displayValue.substring(8);
        }

        return displayValue;
      }
    }
  };
</script>
<style lang="scss" scoped>

    ::v-deep .dx-list-item-content, .dx-item-content {
        white-space: normal !important;
    }
    .custom-item {
        padding:0 !important;
    }

    .address-string {
        padding: 10px;
        height: 100% !important;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
</style>