<template>
  <div>
    <div class="row py-5">
      <p class="paragraph">
        Аутсорсинг - это передача определённых видов производственной деятельности другой компании, специализирующейся в
        нужной области. Аутсорсинг позволяет повысить эффективность предприятия в целом и использовать освободившиеся
        организационные, финансовые и человеческие ресурсы для основной деятельности предприятия и развития новых
        направлений.
      </p>
    </div>

    <div class="row">
      <p class="paragraph">
        Мы предоставляем услуги аутсорсинга ИТ-процессов в разных областях, в основном это:
      </p>
      <div class="col-md-8">

        <!-- LIST BLOCK -->
        <div class="list">
          <div class="list__icon"></div>
          <div class="list__content">
            <p class="list__content_text">
              <a class="link" href="https://rus.support/about/we-do?action=hp_outsourceprint">аутсорсинг процессов
                печати</a>
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon"></div>
          <div class="list__content">
            <p class="list__content_text">
              аутсорсинг первой/второй линий поддержки пользователей
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              аутсорсинг контакт-центра
            </p>
          </div>
        </div>
        <!-- /.LIST BLOCK -->

      </div>
    </div>

    <div class="row">
      <div class="col-md-7">

        <div class="title">
          В КАКИХ СЛУЧАЯХ <br>
          РЕКОМЕНДУЕТСЯ
        </div>

        <!-- LIST BLOCK -->
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Есть ограничения по размеру штата
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Нужно вывести непрофильные функции
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Только выходите на рынок
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Происходит интенсивный рост компании
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Хотите привлечь специалистов на короткий период
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Есть узкоспециальные требования к сотрудникам
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Нужны сотрудники в регионах без открытия локальных офисов
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Хотите снизить административные расходы на ведение кадрового учета и расчет заработной платы
            </p>
          </div>
        </div>
        <!-- /.LIST BLOCK -->

      </div>
      <div class="col-md-5">
        <img class="img-fluid" src="/img/pages/others-outsource_schema.png" alt="">
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">

        <div class="title">
          ВЫ ПОЛУЧАЕТЕ
        </div>

        <!-- LIST BLOCK -->
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Обслуживание ИТ-инфраструктуры командой профессионалов
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Минимальные простои оборудования
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Круглосуточную техническую поддержку
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Гарантию на проведенные работы
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Индивидуальную схему и условия сотрудничества
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Возможность сосредоточиться на основном бизнесе и своих профильных задачах
            </p>
          </div>
        </div>
        <!-- /.LIST BLOCK -->

      </div>

      <p class="paragraph">
        Если вас интересуют услуги аутсорсинга ИТ-поддержки – пришлите нам запрос на адрес
        <a class="link" href="mailto:sale@rus.support">sale@rus.support</a>
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: "OthersOutsourceContent"
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 1rem;
}
</style>