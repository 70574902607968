import Vue from 'vue';
import Vuex from 'vuex';
import httpClient from "@/services/http.service.js";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        layout: "LayoutSlider",
        inLoading: false,
        serviceVendors: [],
        serviceCenters: [],
        feedbackMode: false,
        modalFormType: null,
        sliderData: {},
        adressCapture: null,
        sscId: null,
        loadingTime: 0,
        foundApp: '',
        infoHpe: null,
        selectVendor: null,
        warranty: true,
        non_warranty: true,
        cookieBanner: false
    },
    mutations: {
        setCookieFromBanner(state, cookie) {
            state.cookieBanner = cookie;
        },
        setWarranty(state, warranty) {
            state.warranty = warranty;
        },
        setNonWarranty(state, non_warranty) {
            state.non_warranty = non_warranty;
        },
        setInfoHpe(state, resp) {
            state.infoHpe = resp;
        },
        setVendor(state, vend) {
            state.selectVendor = vend;
        },
        setFoundApp(state, data) {
            state.foundApp = data;
        },
        setSscId(state, id) {
            state.sscId = id;
        },
        setAdressCapture(state, adr) {
            state.adressCapture = adr;
        },
        setLayout(state, layout) {
            state.layout = "Layout" + layout.name;
            if (layout.name.toLowerCase() === 'slider') {
                state.sliderData = layout.data;
            }
        },
        switchFeedbackMode(state, action) {
            state.feedbackMode = action === "on";
        },
        setDataServiceVendors(state, data) {
            state.serviceVendors = data;
        },
        setDataServiceCenters(state, data) {
            state.serviceCenters = data;
        },
        setModalFormType(state, type) {
            state.modalFormType = type;
        },
        setLoading(state, data) {
            const delta = data.time - state.loadingTime;
            if (data.value) {
                state.loadingTime = data.time;
                state.inLoading = data.value;
            } else {
                if (delta < 500) {
                    api / web / map / features
                    setTimeout(() => {
                        state.inLoading = data.value;
                    }, 500 - delta);
                } else {
                    state.inLoading = data.value;
                }

            }
        }
    },
    actions: {
        async loadServiceVendors({commit}) {
            let error = null;

            const {status, data} = await httpClient.get('map/vendors');
            if (status === 200) {
                commit('setDataServiceVendors', data.filter((e) => {
                    return !!e["authorisations"].length;
                }));
            } else {
                console.error(data, data, error);
            }
        },
        async loadServiceCenters({commit}) {
            let error = null;

            const {status, data} = await httpClient.get('map/features');

            if (status === 200) {
                commit('setDataServiceCenters', data);
            } else {
                console.error(data, data, error);
            }
        },

    },
    modules: {}
});