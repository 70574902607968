<template>
    <div class="row py-5">

        <div class="col-md-12">

            <p>
                American Power Conversion (APC) — американская компания, известная как производитель источников
                бесперебойного питания, в 2007 году поглощенная электромашиностроительной корпорацией Schneider
                Electric. Была основана в 1981 году тремя инженерами-электротехниками из Массачусетского
                технологического института.
            </p>

            <p>
                Сейчас является лидером на рынке источников бесперебойного питания, сетевых фильтров, стабилизаторов
                напряжения, а также предлагает широкий спектр оборудования для энергоснабжения и охлаждения центров
                обработки данных.
            </p>

            <p>
                Мы имеем сервисную авторизацию в городе Санкт-Петербург на выполнение гарантийного и постгарантийного
                ремонта, а также выполняем регламентное сервисное обслуживание для однофазного и трехфазного
                оборудования АРС:
            </p>

            <div class="list">
                <div class="list__icon"></div>
                <div class="list__content">
                    <p class="list__content_text">
                        Сетевые фильтры
                    </p>
                </div>
            </div>

            <div class="list">
                <div class="list__icon"></div>
                <div class="list__content">
                    <p class="list__content_text">
                        Удлинители
                    </p>
                </div>
            </div>

            <div class="list">
                <div class="list__icon"></div>
                <div class="list__content">
                    <p class="list__content_text">
                        Стабилизаторы напряжения
                    </p>
                </div>
            </div>

            <div class="list">
                <div class="list__icon"></div>
                <div class="list__content">
                    <p class="list__content_text">
                        ИБП серии Back UPS
                    </p>
                </div>
            </div>

            <div class="list">
                <div class="list__icon"></div>
                <div class="list__content">
                    <p class="list__content_text">
                        ИБП серии Smart-UPS
                    </p>
                </div>
            </div>

            <div class="list">
                <div class="list__icon"></div>
                <div class="list__content">
                    <p class="list__content_text">
                        UPS Symmetra LX/RM
                    </p>
                </div>
            </div>

            <div class="list">
                <div class="list__icon"></div>
                <div class="list__content">
                    <p class="list__content_text">
                        Smart-UPS VTP/ Galaxy 3500
                    </p>
                </div>
            </div>

            <div class="list">
                <div class="list__icon last"></div>
                <div class="list__content">
                    <p class="list__content_text">
                        Symmetra PX 48/160 КВА (PX2)
                    </p>
                </div>
            </div>

            <p>
                Также мы выполняем пусконаладочные и монтажные работы, включающие в себя не только инсталляцию и запуск
                оборудования APC, но и смежные работы по поставке, монтажу и техническому обслуживанию щитового
                оборудования до 1000 В:
            </p>

            <div class="list">
                <div class="list__icon last"></div>
                <div class="list__content">
                    <p class="list__content_text">
                        Силовых щитов и щитов освещения
                    </p>
                </div>
            </div>

            <div class="list">
                <div class="list__icon last"></div>
                <div class="list__content">
                    <p class="list__content_text">
                        Вводно-распределительного устройства (ВРУ)
                    </p>
                </div>
            </div>

            <div class="list">
                <div class="list__icon last"></div>
                <div class="list__content">
                    <p class="list__content_text">
                        Главного распределительного щита (ГРЩ)
                    </p>
                </div>
            </div>

            <div class="list">
                <div class="list__icon last"></div>
                <div class="list__content">
                    <p class="list__content_text">
                        Автоматического ввода резерва (АВР)
                    </p>
                </div>
            </div>
            <p>
                <i>Официальный сайт: <a class="link" target="_blank" href="https://www.apc.com/">www.apc.com</a></i>
                <br>
                <i>Мы официально обслуживаем оборудование производства компании APC by Schneider Electric с 2017
                    года.</i>
            </p>

            <ul class="list-link col-md-4">
                <li class="list-link__item"></li>
                <li class="list-link__item">
                    <a class="list-link__link" href="">
                        ОБСЛУЖИВАНИЕ ИБП APC
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "ApcContent"
}
</script>

<style lang="scss" scoped>

</style>
