<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div align="center" class="selected" :class="{ open: open }" @click="open = !open">
      <span>{{ selectedDistance.distance + " км " }}</span>
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
          v-for="(zoom, i) of zoomMap"
          :key="i"
          @click="selected = zoom,open = false, $emit('distanceSelected', zoom)"
      >
        {{ zoom.distance + " км" }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "distanceSelect",
  props: {
    zoomMap: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    selectedDistance: {
      type: Object,
      required: true,
      default: null
    }
  },
  data() {
    return {
      selected: this.selectedDistance,
      open: false,
    };
  },
  // mounted() {
  //   this.$emit("distanceSelected", this.selected);
  // }
  watch: {
    selectedDistance() {
      // console.log(this.selectedDistance);
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-select {
  position: relative;
  width: 30%;
  text-align: left;
  outline: none;
  line-height: 55px;

  @media screen and (max-width: 475px) {
    line-height: 50px;
  }

  .selected {
    background-color: #0171bb;
    border-radius: 10px;
    border: 2px solid #0171bb;
    color: #fff;
    cursor: pointer;
    user-select: none;
    height: 103%;
    margin-right: -1%;
    margin-top: -1%;

    @media screen and (max-width: 1199px) {
      font-size: 13px;
    }
  }
}

.custom-select .selected.open {
  /*border: 1px solid #ad8225;*/
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 28px;
  margin: 0 5px;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;

  @media screen and (max-width: 1199px) {
    margin: 0 3px;
  }

  @media screen and (max-width: 475px) {
    top: 25px;
  }
}

.custom-select .items {
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid rgba(1, 61, 101, 0.3);
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 1;

  @media screen and (max-width: 1199px) {
    font-size: 13px;
  }
}

.custom-select .items div {
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.custom-select .items div:hover {
  background-color: #eee;
}

.selectHide {
  display: none;
}
</style>