<template>
    <div class="container">
        <div class="row py-5 align-items-center">
            <div class="col-md-6">
                <img class="img-fluid" src="/img/about/foto2.png" alt="">
            </div>
            <div class="col-md-6">
                <div class="title">
                    ОБ УСЛУГАХ СЕРВИСНОЙ СЕТИ <br>
                    <b>RUS.SUPPORT</b>
                </div>
                <p>
                    Наша специализация — официальный и профессиональный сервис по обслуживанию ИТ-оборудования ведущих
                    мировых производителей. Мы выполняем гарантийное и постгарантийное обслуживание техники как на
                    разовой основе, так и в рамках сервисных контрактов, производим инсталляцию оборудования, ввод в
                    эксплуатацию и вывод из неё, регулярное и профилактическое обслуживание, продаем оригинальные
                    запасные части и расходные материалы.
                </p>
                <p>
                    Вот уже более 15 лет мы занимаемся сервисным бизнесом в России, обеспечивая обслуживание
                    оборудования в 11 часовых поясах: от Калининграда до Петропавловска-Камчатского.
                </p>
                <p>
                    Являясь официальным сервисным представителем производителей оборудования, мы уделяем много внимания
                    качеству обслуживания наших клиентов, а также сертификации и обучению наших сервисных инженеров.
                </p>
            </div>

            <div class="col-md-6 py-5">
                <div class="title">
                    О СЕРВИСЕ <b>RUS.SUPPORT</b>
                </div>
                <p>
                    Наше кредо — качественное и профессиональное обслуживание. Качество обслуживания в наших сервисных
                    центрах контролируется не только нами, но и производителями оборудования по самым высоким
                    международным стандартам. Мы постоянно проходим аудиты и нашу работу оценивают по целому ряду
                    показателей, сравнивая их с показателями работы сервисных партнёров HP в РФ и других странах Европы.
                </p>
                <p>
                    В распоряжении Rus.Support есть вся официальная сервисная документация, утилиты, доступ к курсам и
                    тренингам для сервисных инженеров. Мы первыми имеем доступ к актуальной технической информации от
                    производителей и оперативно получаем оригинальные запасные части для обслуживания ваших устройств.
                    Мы постоянно совершенствуем экспертизу и проводим обучение инженеров как на мероприятиях HPI и HPE,
                    так и на базе своего собственного учебного центра.
                </p>
                <p>
                    Для наших клиентов доступен круглосуточный колл-центр и специалисты службы контроля качества.
                </p>
                <p>
                    Не отказывайте своей технике и в грамотном и качественном обслуживании в официальном сервисном
                    центре. Обращайтесь к нам.
                </p>
            </div>
            <div class="col-md-6 py-5">
                <img class="img-fluid" src="/img/about/foto3.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.title {
    margin: 1rem 0;
}
</style>
