<template>
  <div class="terms-accordion__item-content">
    <p>5.1. Заказчик обязуется предоставить сотруднику СЦ беспрепятственный доступ к обслуживаемому оборудованию, а
      также доступ к управляющим консолям и административному программному обеспечению (ПО).</p>
    <p>5.2. Заказчик обеспечивает безопасные условия труда сотрудников СЦ.</p>
    <p>5.3. Если для проведения сервисного обслуживания требуются определенные ресурсы (электропитание, IP-адреса,
      сетевые соединения, доступ в интернет и т.д.), то Заказчик также обязан их предоставить.</p>
    <p>5.4. Если выезд сотрудника СЦ для проведения сервисного обслуживания не предусмотрен условиями гарантийных
      обязательств производителя, действующих на оборудование, или если оборудование или заявленная неисправность не
      подпадают под действие гарантийных обязательств производителя, то для осуществления выездного обслуживания
      Заказчик обязан на условиях 100% предоплаты оплатить стоимость такого выездного обслуживания, а также стоимость
      сервисного обслуживания, которое планируется к выполнению в рамках такого выезда, в соответствии с Прейскурантом
      Исполнителя.</p>
  </div>
</template>

<script>
export default {
  name: "termsOfService2020"
}
</script>

<style scoped>

</style>