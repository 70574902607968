<template>
  <div class="row py-5">
    <p class="paragraph">
      Изначально компания появилась в 2002 году в Калифорнии (США) под именем Aruba Wireless Networks. В 2015 году
      компания была куплена HP.
    </p>
    <p class="paragraph">
      В ходе слияния с сетевым подразделением HP портфель решений Aruba был расширен и сейчас под этой торговой маркой
      продвигаются не только точки доступа и контроллеры для беспроводных сетей, но и различные свитчи, коммутаторы, ПО
      для управления и анализа сетей.
    </p>
    <p class="paragraph">
      Официальный сайт: <a class="link" href="https://www.arubanetworks.com/">www.arubanetworks.com</a>
      Мы официально обслуживаем оборудование производства компании Aruba в РФ с 2015 года.
    </p>

    <ul class="list-link">
      <li class="list-link__item"></li>
      <li class="list-link__item">
        <a class="list-link__link" href="https://rus.support/about/we-do?action=aruba_service">
          ОБСЛУЖИВАНИЕ СЕТЕВОГО ОБОРУДОВАНИЯ (ARUBA PBS)
        </a>
      </li>
    </ul>

  </div>
</template>

<script>

export default {
  name: "ArubaContent",
}
</script>

<style lang="scss" scoped>
.paragraph {
  text-align: left;
}

.list-link {
  max-width: calc(100% / 12 * 6);

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
</style>