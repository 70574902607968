<template>
    <!-- Content start -->
    <div class="container list-service-wrap">
        <ApcContent/>
    </div>
    <!-- Content end -->
</template>

<script>

import ApcContent from "@/pages/About/Apc/ApcContent";

const buttons = [];

const slides = [
    {
        background: "/img/page-slider/main_page_1.jpg",
        template: "apcSliderContent",
        buttons: buttons
    },
];

export default {
    name: "Apc",
    data() {
        return {
            dsVendors: [],
            slides: slides
        }
    },
    created() {
        const layout = {
            name: "Slider",
            data: this.slides
        };
        this.$store.commit('setLayout', layout)

        console.log(this.$route.params.sc, this.$store.state.serviceCenters)
    },
    components: {
        ApcContent
    }
}
</script>

<style scoped>

</style>