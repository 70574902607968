<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 424 480"><path d="M256,96a24,24,0,1,0-24-24A24,24,0,0,0,256,96Zm0-32a8,8,0,1,1-8,8A8,8,0,0,1,256,64Z" transform="translate(-72 -16)"/><path d="M128,336H384a8,8,0,0,0,8-8V152a8,8,0,0,0-8-8H128a8,8,0,0,0-8,8V328A8,8,0,0,0,128,336Zm128-96a24,24,0,1,1,24-24A24,24,0,0,1,256,240Zm-16.78,16h33.56A23.24,23.24,0,0,1,296,279.22V320H216V279.22A23.24,23.24,0,0,1,239.22,256ZM136,160H376V320H312V279.22a39.15,39.15,0,0,0-26.18-36.82,40,40,0,1,0-59.64,0A39.15,39.15,0,0,0,200,279.22V320H136Z" transform="translate(-72 -16)"/><rect x="48" y="336" width="120" height="16"/><rect x="184" y="336" width="48" height="16"/><rect x="48" y="384" width="192" height="16"/><rect x="248" y="336" width="32" height="16"/><path d="M432,64H313l6.93-38.59a8,8,0,0,0-6.47-9.28A7.4,7.4,0,0,0,312,16H200a8,8,0,0,0-8,8,7.4,7.4,0,0,0,.13,1.41L199.05,64H80a8,8,0,0,0-8,8V488a8,8,0,0,0,8,8H432a8,8,0,0,0,8-8V421.11l54.11-63.94a8,8,0,0,0-.45-10.83l-24-24a8,8,0,0,0-11.31,0q-.32.32-.6.66L440,345.2V72A8,8,0,0,0,432,64ZM302.44,32l-14.36,80H223.92L209.56,32ZM424,480H88V80H201.92l7.44,41.41a8,8,0,0,0,7.87,6.59h77.54a8,8,0,0,0,7.87-6.59L310.08,80H424V365.19L399.34,396l-17.68-17.68a8,8,0,0,0-11.31,0h0l-24,24a8,8,0,0,0,0,11.31h0l48,48A8,8,0,0,0,400,464h.33a8,8,0,0,0,5.78-2.82L424,440Zm40.66-140,12.48,12.47L399.51,444.2,363.31,408,376,395.31l18.34,18.35a8,8,0,0,0,11.91-.66Z" transform="translate(-72 -16)"/><rect x="48" y="432" width="72" height="16"/><rect x="136" y="432" width="128" height="16"/></svg>
</template>

<script>
export default {
  name: "sertificate-engineers-icon"
}
</script>

<style scoped>

</style>