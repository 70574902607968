<template>
  <div class="row py-5">

    <div class="caption">
      <p class="caption__title">
        МЫ ВЫПОЛНЯЕМ ГАРАНТИЙНЫЙ РЕМОНТ<br>
        <b>СЛЕДУЮЩЕЙ ТЕХНИКИ</b> SAMSUNG
      </p>
      <img class="caption__logo" src="/img/logo/samsung-logo.png" alt="">
    </div>
    <!-- /.caption -->

    <div class="col-md-3 p-0">
      <div class="product">
        <div class="product__icon"></div>
        <div class="product__title">
          Цветные принтеры
        </div>
      </div>
      <!-- PRODUCT BLOCK -->
    </div>
    <div class="col-md-3 p-0">
      <div class="product">
        <div class="product__icon center"></div>
        <div class="product__title">
          Монохромные принтеры
        </div>
      </div>
      <!-- PRODUCT BLOCK -->
    </div>
    <div class="col-md-3 p-0">
      <div class="product">
        <div class="product__icon center"></div>
        <div class="product__title">
          Цветные МФУ
        </div>
      </div>
      <!-- PRODUCT BLOCK -->
    </div>
    <div class="col-md-3 p-0">
      <div class="product">
        <div class="product__icon last"></div>
        <div class="product__title">
          Монохромные МФУ
        </div>
      </div>
      <!-- PRODUCT BLOCK -->
    </div>

    <div class="b-shadow">
      <img class="b-shadow__icon" src="/img/icons/hp-guarantee_icon01.png" alt="">
      <div class="b-shadow__content">
        <h2 class="b-shadow__title">ГАРАНТИЯ ПРОИЗВОДИТЕЛЯ</h2>
        <p class="b-shadow__text">
          Гарантия производителя служит защитой клиента от неисправностей по причине наличия заводских дефектов в
          оборудовании или преждевременного выхода узлов и компонентов из строя. В течение срока действия гарантии
          клиент имеет право на бесплатное устранение неисправности в соответствии с сервисной политикой
          производителя.
        </p>
      </div>
    </div>
    <!--  /.B-SHADOW BLOCK  -->
    <div class="b-shadow">
      <img class="b-shadow__icon" src="/img/icons/hp-guarantee_icon02.png" alt="">
      <div class="b-shadow__content">
        <h2 class="b-shadow__title">ВЫЕЗДНАЯ ГАРАНТИЯ</h2>
        <p class="b-shadow__text">
          На ряд оборудования Samsung действует выездная гарантия. Это значит, что выезд инженера к месту расположения
          устройства клиенту оплачивать не нужно. Бесплатное выездное обслуживание по заводской гарантии Samsung
          осуществляется на расстоянии до 160 км от сервисного центра.
          Для расширенной гарантии – до 250 км от сервисного центра
        </p>
      </div>
    </div>
    <!--  /.B-SHADOW BLOCK  -->

    <div class="col-md-9">
      <!--  LIST BLOCK  -->
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Если гарантийные обязательства производителя не включают выездное обслуживание, вы можете доставить
            оборудование в ближайший сервисный центр самостоятельно или вызвать нашего курьера
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Уточнить актуальный статус гарантийных обязательств на ваше устройство можно на
            <a class="link" href="https://support.hp.com/ru-ru/checkwarranty">официальном сайте HP
              Inc.</a>
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Если вы затрудняетесь найти продуктовый и серийный номер вашего устройства — воспользуйтесь
            <a class="link" href="http://rus.support/about/instruction">инструкцией</a>
          </p>
        </div>
      </div>
      <!--  /.LIST BLOCK  -->
    </div>

    <div class="col-md-3">
      <div class="note">
        <span class="note__square"></span>
        <p class="note__text">
          Пожалуйста, <b>уточняйте заранее</b> возможность обслуживания нужной линейки оборудования в нужном вам
          <a class="note__text_link" href=""><b>населенном пункте</b></a>
        </p>
      </div>
      <!-- /.NOTE BLOCK -->
    </div>

  </div>
  <!-- /.row -->
</template>

<script>
export default {
  name: "SamsungGuaranteeContent"
}
</script>

<style lang="scss" scoped>

.caption {
  .caption__logo {
    margin: 2rem 0 5rem 0;
  }
}

.title {
  text-align: center;
}

</style>
