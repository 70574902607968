import { render, staticRenderFns } from "./SamsungGuaranteeContent.vue?vue&type=template&id=386d7baf&scoped=true&"
import script from "./SamsungGuaranteeContent.vue?vue&type=script&lang=js&"
export * from "./SamsungGuaranteeContent.vue?vue&type=script&lang=js&"
import style0 from "./SamsungGuaranteeContent.vue?vue&type=style&index=0&id=386d7baf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "386d7baf",
  null
  
)

export default component.exports