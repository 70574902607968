<template>
  <div class="terms-accordion__item-content">
    <p>Сервисный Центр (далее именуемый «СЦ») – сервисный центр Исполнителя.</p>
    <p>Заявка – разовое задание Заказчика Сервисному Центру на выполнение сервисного обслуживания определенной единицы
      оборудования Заказчика.</p>
    <p>Сервисное обслуживание – набор услуг по Заявке, оказываемых Сервисных Центром Заказчику. Сервисное обслуживание
      может включать в себя услуги по диагностике, ремонту и профилактике оборудования, выезду инженера, услуги по
      транспортировке запасных частей («Запчастей»). Для выполнения сервисного обслуживания могут использоваться
      запасные части (детали) и материалы.</p>
  </div>
</template>

<script>
export default {
  name: "termsContent2020"
}
</script>