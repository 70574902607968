<template>
  <div class="row py-5">

    <div class="col-md-8">

      <p class="title">БЛАГОДАРЯ <b>HP CARE PACK</b> ВЫ МОЖЕТЕ</p>

      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <span class="list__content_title">
            ПРОДЛИТЬ ГАРАНТИЮ ПРОИЗВОДИТЕЛЯ
          </span>
          <p class="list__content_text">
            Продление гарантии позволяет продолжить обслуживание оборудования в официальном сервисном центре, а также
            сохраняет короткие сроки поставки оригинальных запасных частей при наступлении гарантийного случая
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <span class="list__content_title">
            ПОЛУЧИТЬ ВЫЕЗДНОЕ ОБСЛУЖИВАНИЕ
          </span>
          <p class="list__content_text">
            С помощью HP CarePack можно получить выездное обслуживание даже для тех устройств, у которых в заводскую
            гарантию не включен выезд. Также HP CarePack расширяет стандартную дистанцию выездного обслуживания до 320
            км от сервисного центра, что позволяет вам получать выездное обслуживание даже на достаточно удалённых
            площадках
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <span class="list__content_title">
            МИНИМИЗИРОВАТЬ РИСКИ ПРОСТОЯ ОБОРУДОВАНИЯ
          </span>
          <p class="list__content_text">
            Вы выбираете сервисное предложение, подходящее именно вашей компании:
          </p>
          <ul>
            <li>
              Defective Media Retention (DMR) – позволяет менять носители информации (например, жесткие диски), без
              необходимости возвращать производителю неисправный носитель
            </li>
            <li>
              Accidental Damage Prevention (ADP) – защищает мобильные персональные устройства от случайных физических
              повреждений
            </li>
          </ul>
        </div>
      </div>

      <div class="note-white">
        <span class="note__square"></span>
        <span class="note__title">
          Цена сервисного пакета
        </span>
        <p class="note__text">
          зависит от количества приобретенных сервисных пакетов. При большом объеме предоставляются скидки
        </p>
      </div>

      <p class="prompt">
        Если вас интересует подбор HP Care Pack для вашего оборудования HP Inc. — отправьте запрос на адрес
        <a class="link" href="mailto:sale@rus.support">sale@rus.support</a> и мы предоставим вам предложение.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarepackContent"
}
</script>

<style lang="scss" scoped>

</style>
