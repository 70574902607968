<template>
    <div class="contact-center">
        <div class="container">
            <p>
                В ООО «Рус.Саппорт» проведена СОУТ на основании: Федерального закона Российской Федерации N 426-ФЗ «О
                специальной оценке условий труда», приказа Минтруда России №33н от 24.01.2014 «Об утверждении Методики
                проведения специальной оценки условий труда, Классификатора вредных и (или) опасных производственных
                факторов, формы отчета о проведении специальной оценки условий труда и инструкции по её заполнению».
                Согласно Перечня рекомендуемых мероприятий по улучшению условий труда мероприятия по улучшению условий
                труда не требуются.
            </p>
            <a class="link" href="/uploads/Рус.Саппорт.pdf">Сводная ведомость результатов проведения специальной оценки условий
                труда</a>
            <h2 class="title">
                Единый номер контакт-центра Рус.Саппорт: <b>8 800 7-555-777</b>
            </h2>
            <p>
                По этому номеру вы сможете открыть заявку, запросить информацию о ходе работ, оставить отзыв для службы
                контроля качества нашей сети сервисных центров.
            </p>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.title {
    font-size: 2em;
    margin: 2rem 0;
}

.contact-center {
    padding: 2rem 0;
}
</style>
