<template>
  <div class="carousel-items">
    <img :src="require('../../../../../assets/carousel/' + items.img)" alt="">
  </div>
</template>

<script>
export default {
  name: "carousel-item",
  props: {
    items: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style scoped>

</style>