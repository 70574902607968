<template>
    <div class="vendor-page-slide-logo">
        <img class="img-fluid" src="/img/logo/Avision-logo.png" alt="" />
    </div>
</template>

<script>
export default {
    name: 'avisionSlideConent',
};
</script>

<style lang="scss" scoped>
.vendor-page-slide-logo {
    text-align: center;
}
</style>
