<template>
    <div id="formFeedback">
        <div v-if="submitted" style="padding:20%"><span>{{ successMessage }}</span></div>

        <form
            id="contactForm"
            v-if="dataLoaded && !submitted"
            @submit.prevent="handleSubmit($event)"
        >
            <div class="dx-fieldset">
                <div class="dx-fieldset-header">Персональные данные</div>

                <!-- Имя -->
                <div class="dx-field">
                    <div class="dx-field-label">Имя</div>
                    <div class="dx-field-value">
                        <DxAutocomplete
                            :data-source="names"
                            v-model="formData.name"
                            placeholder="Имя"
                            :search-timeout="100"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Пожалуйста, введите ваше имя"/>
                                <DxPatternRule
                                    :pattern="namePattern"
                                    message="Имя введено неверно"
                                />
                            </DxValidator>
                        </DxAutocomplete>
                    </div>
                </div>
                <!-- /.Имя -->

                <!-- Фамилия -->
                <div class="dx-field">
                    <div class="dx-field-label">Фамилия</div>
                    <div class="dx-field-value">
                        <DxAutocomplete
                            :data-source="surnames"
                            v-model="formData.surname"
                            placeholder="Фамилия"
                            :search-timeout="100"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Пожалуйста, введите вашу фамилию"/>
                                <DxPatternRule
                                    :pattern="namePattern"
                                    message="Фамилия введена неверно"
                                />
                            </DxValidator>
                        </DxAutocomplete>
                    </div>
                </div>
                <!-- /.Фамилия -->

                <!--Телефон-->
                <div class="dx-field">
                    <div class="dx-field-label">Телефон</div>
                    <div class="dx-field-value">
                        <DxTextBox
                            :mask-rules="phoneMaskRules"
                            :use-masked-malue="true"
                            mask="+7 (000) 000-0000"
                            mask-invalid-message="Телефон введен неверно"
                            v-model="formData.phone"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Пожалуйста, введите ваш телефонный нормер"/>
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>

                <!-- Email -->
                <div class="dx-field">
                    <div class="dx-field-label">Email</div>
                    <div class="dx-field-value">
                        <DxAutocomplete
                            :data-source="emails"
                            v-model="formData.email"
                            placeholder="Email"
                            :search-timeout="100"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Пожалуйста, введите ваш email"/>
                                <DxEmailRule message="Email введен неверно"/>
                            </DxValidator>
                        </DxAutocomplete>
                    </div>
                </div>
                <!-- /.Email -->
            </div>


            <div class="dx-fieldset">
                <div class="dx-fieldset-header">Детали обращения</div>
                <div class="dx-field">
                    <div class="dx-field-label">Выбор сервисного центра (опционально)</div>
                    <div class="dx-field-value">
                        <DxSelectBox
                            v-if="this.$store.state.sscId !== null"
                            v-model="this.$store.state.sscId"
                            :items="servePoints"
                            :show-clear-button="true"
                            :valueExpr="valueExpression"
                            :displayExpr="displayExpression"
                            placeholder="Выберите сервисный центр (опционально)"
                            @change="vendorChanges()"
                            item-template="item"
                        >

                            <template #item="{ data }">
                                <AddressItem :item-data="data"/>
                            </template>
                        </DxSelectBox>
                        <DxSelectBox
                            v-else
                            v-model="formData.servePoint"
                            :items="servePoints"
                            :show-clear-button="true"
                            :valueExpr="valueExpression"
                            :displayExpr="displayExpression"
                            placeholder="Выберите сервисный центр (опционально)"
                            @change="vendorChanges()"
                            item-template="item"
                        >

                            <template #item="{ data }">
                                <AddressItem :item-data="data"/>
                            </template>
                        </DxSelectBox>
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Номер Заявки</div>
                    <div class="dx-field-value">
                        <DxTextBox
                            v-model="formData.numOrder"
                            placeholder="Номер Заявки"
                        >
                        </DxTextBox>
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Детали обращения</div>
                    <div class="dx-field-value">
                        <DxTextArea
                            v-model="formData.message"
                            placeholder="Отзыв"

                        >
                            <DxValidator>
                                <DxRequiredRule message="Пожалуйста, введите ваше сообщение"/>
                            </DxValidator>
                        </DxTextArea>
                    </div>
                </div>

            </div>

            <div class="dx-fieldset">
                <DxCheckBox
                    id="check"
                    :value="false"
                    text="Отправляя запрос на обслуживание, вы соглашаетесь с Условиями обработки и использования персональных данных и с Условиями обслуживания Rus.support"
                >
                    <DxValidator>
                        <DxCompareRule
                            :comparison-target="checkTermsComparison"
                            message="Вы должны принять Условия"
                        />
                    </DxValidator>
                </DxCheckBox>

            </div>

            <div class="dx-fieldset">

                <DxValidationSummary id="summary"/>


            </div>

            <div class="form-content-bottom submit-area" align="center" style="padding: 10px 0 40px 0">
                <DxButton
                    id="button"
                    :use-submit-behavior="true"
                    text="Отправить"
                    template="buttonTemplate"
                >
                    <!--                    <template #buttonTemplate="{ data }">
                                            <div class="button-template hovered" style="transition: background-color 0.3s ease; background-color: #0a73bb; color: white; font-size: 1.5em; line-height: 2em; border:0; border-radius: 50px; padding: 0 50px;">{{data.text}}</div>
                                        </template>-->
                    <template #buttonTemplate="{ data }">
                        <div>
                            <div class="button-template hovered dx-button-text">
                                {{ data.text }}
                            </div>
                            <div class="send-preload">
                                <DxLoadIndicator
                                    :visible="loadIndicatorVisible"
                                    class="button-indicator"
                                />
                                <div v-if="loadIndicatorVisible" class="dx-button-text">{{ buttonText }}</div>
                            </div>
                        </div>
                    </template>
                </DxButton>
            </div>
        </form>
    </div>
</template>

<script>
import httpClient from "@/services/http.service.js"
import DxSelectBox from 'devextreme-vue/select-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import DxDateBox from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
import DxValidationSummary from 'devextreme-vue/validation-summary';
import DxRadioGroup from 'devextreme-vue/radio-group';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import {DxAutocomplete} from "devextreme-vue/autocomplete";
import {
    DxValidator,
    DxRequiredRule,
    DxCompareRule,
    DxEmailRule,
    DxPatternRule,
    DxStringLengthRule,
    DxRangeRule,
    DxAsyncRule,
    DxCustomRule
} from 'devextreme-vue/validator';

import DxForm, {
    DxSimpleItem,
    DxButtonItem,
    DxGroupItem
} from 'devextreme-vue/form';
import 'devextreme-vue/text-area';
import 'devextreme-vue/radio-group';

import notify from 'devextreme/ui/notify';
import AddressItem from './formCreateOrder/templates/AddressItem'


export default {
    name: "formCreateOrder",
    components: {
        DxForm,
        DxSimpleItem,
        DxButtonItem,
        DxRequiredRule,
        DxGroupItem,
        DxPatternRule,
        DxEmailRule,
        DxSelectBox,
        DxCheckBox,
        DxTextBox,
        DxDateBox,
        DxButton,
        DxValidationSummary,
        DxValidator,
        DxCompareRule,
        DxStringLengthRule,
        DxRangeRule,
        DxAsyncRule,
        DxRadioGroup,
        DxCustomRule,
        AddressItem,
        DxTextArea,
        DxLoadIndicator,
        DxAutocomplete
    },
    data() {
        const phoneMaskRules = {
            X: /[02-9]/
        };
        const nameEditorOptions = {
            height: "40",
            maxLength: "255",
            placeholder: "Имя"
        };
        const surnameEditorOptions = {
            height: "40",
            maxLength: "255",
            placeholder: "Фамилия"
        };
        const companyEditorOptions = {
            height: "40",
            maxLength: "255",
            placeholder: "Организация"
        };
        const cityEditorOptions = {
            height: "40",
            maxLength: "255",
            placeholder: "Ваш город"
        };
        const emailEditorOptions = {
            height: "40",
            maxLength: "255",
            placeholder: "E-mail"
        };
        const textAreaOptions = {
            height: "80",
            maxLength: "1000",
            placeholder: "Напишите нам"
        };
        const buttonOptions = {
            text: 'ОТПРАВИТЬ',
            type: 'success',
            useSubmitBehavior: true
        };

        const textBoxOptions = {
            height: "40",
            maxLength: "255",
            placeholder: "Type something..."
        };
        const checkBoxOptions = {
            text: "Согласен на обработку персональных данных"
        };

        const radioEditorOptions = {
            items: ["1", "2"],
            value: "1"
        };

        const repairTypeNames = [
            "обслуживание по гарантии производителя",
            "платное, негарантийное обслуживание"
        ];

        const repairTypes = [
            {
                id: 0,
                name: "обслуживание по гарантии производителя"
            },
            {
                id: 1,
                name: "платное, негарантийное обслуживание"
            }
        ];

        const contactTemplate = {
            text: String(),
            name: String(),
            surname: String(),
            company: String(),
            city: String(),
            phone: null,
            email: null,
            agreement: false,
            servePoint: null,
            repairType: null,
            numOrder: null,
            message: ""
        };

        return {
            names: [],
            surnames: [],
            emails: [],
            buttonText: 'Подождите, идет отправка данных...',
            loadIndicatorVisible: false,
            formData: contactTemplate,
            useColCountByScreen: true,
            useTreeCols: true,
            phoneMaskRules: phoneMaskRules,
            emailEditorOptions: emailEditorOptions,
            companyEditorOptions: companyEditorOptions,
            nameEditorOptions: nameEditorOptions,
            textAreaOptions: textAreaOptions,
            buttonOptions: buttonOptions,
            textBoxOptions: textBoxOptions,
            checkBoxOptions: checkBoxOptions,
            surnameEditorOptions: surnameEditorOptions,
            cityEditorOptions: cityEditorOptions,
            radioEditorOptions: radioEditorOptions,
            phonePattern: /^\+\s*7\s*\(\s*[0-9]\d{2}\)\s*\d{3}\s*-\s*\d{4}$/,
            namePattern: /^[^0-9]+$/,
            formInstance: null,

            repairTypeNames: repairTypeNames,
            repairTypes: repairTypes,
            selectedRepairTypeName: repairTypeNames[0],
            submitted: false,
            successMessage: "Спасибо за обращение! Наши специалисты свяжутся с вами в ближайшее время!"
        };
    },
    computed: {
        dataLoaded() {
            return this.$store.state.serviceVendors.length && this.$store.state.serviceCenters.length;
        },
        servePoints() {

            return this.$store.state.serviceCenters
                .map(item => {
                    return {
                        value: item.ssc_id,
                        displayValue: item.ssc_address
                    }
                });
        },
        selectedRepairType() {
            return this.repairTypes.find(type => type.name === this.formData.repairType);
        }
    },
    methods: {
        displayExpression(item) {
            if (!item) return "";
            return item.displayValue
        },
        valueExpression(item) {
            if (!item) return "";
            return item.value
        },
        serialRequiredRule() {
            return !(this.selectedRepairType && this.selectedRepairType.id === 0 && !this.formData.serialNumber)
        },
        productRequiredRule() {
            return !(this.selectedRepairType && this.selectedRepairType.id === 0 && !this.formData.productNumber)
        },
        repairTypeNameChanges(e) {
            this.formData.repairType = e.value
        },
        vendorChanges() {
            this.formData.environment = null
        },
        screenByWidth(width) {
            return width < 720 ? 'sm' : 'md';
        },
        handleSubmit(event) {
            this.loadIndicatorVisible = true
            this.$submitter.submit(this.formData)
                .then(result => {
                    if (result.success) {
                        this.sendAppeal();
                    } else {
                        notify("Oops! Что-то пошло не так. Свяжитесь с нами. webdevelopment@tillypad.ru", "error", 10000);
                        this.loadIndicatorVisible = false
                    }
                });
        },

        async sendAppeal() {
            const {status, data} = await httpClient.post('/appeal', {
                formData: this.formData,
                preLoad: this.loadIndicatorVisible = true
            });
            if (status === 200) {
                this.formData = {};
                this.submitted = true;
                this.loadIndicatorVisible = false;

                localStorage.setItem('username', this.formData.name);
                localStorage.setItem('surname', this.formData.surname);
                localStorage.setItem('email', this.formData.email);
                localStorage.setItem('location', this.formData.city);
                localStorage.setItem('organization', this.formData.company);

                notify("Спасибо за обратную связь! Ваш отзыв отправлен в Rus.support!", "success", 5000);
            } else {
                console.log(status, data)
            }
        },
        formInitialized(event) {
            //console.log(event.component.instance())
            this.formInstance = event.component.instance()
        },
        checkTermsComparison() {
            return true;
        },
    },
    mounted() {
        if (localStorage.username !== null) {
            this.names.push(localStorage.username)
            this.surnames.push(localStorage.surname)
            this.emails.push(localStorage.email)
        }
    },
    watch: {
        formData: {
            deep: true,
            handler(val) {
                //console.log(val)
            }
        }

    }
}
</script>

<style lang="scss" scoped>
.form-content-bottom {
    position: relative;
    //background-color: red;

    #button {

        .send-preload {
            display: flex;
            align-items: center;
            //padding-top: 20px;

            .button-indicator {
                margin: 0 10px;
                position: absolute;
                bottom: 15px;
                left: 100px;
            }

            .dx-button-text {
                position: absolute;
                bottom: 20px;
            }
        }

        .button-template {
            transition: background-color 0.3s ease;
            background-color: #0a73bb;
            color: white;
            font-size: 1.5em;
            line-height: 2em;
            border: 0;
            border-radius: 50px;
            padding: 8px 50px;
        }

    }
}

::v-deep .dx-field-label {
    width: 100%;
    display: none;
}

::v-deep .dx-field-value {
    width: 100% !important;
}

::v-deep .dx-button {
    border-color: transparent !important;
    background-color: transparent !important;
}

::v-deep .dx-button:hover {
    background-color: transparent !important;
}

.button-template:hover {
    background-color: $bootstrap-green !important;
}
</style>