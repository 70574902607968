<template>
    <div class="balloon-template">
        <div class="balloon_header bh">
            <h1>
                {{featureData.ssc_name}}
            </h1>

            <div class="bh requisites">
                <div class="requisites offset">
                    <i class="offset-icon"></i>
                </div>
                <div  class="requisites content-area">
                    <p align="left" class="paragraph address">
                        <span>{{ featureData.ssc_address }}</span>
                    </p>
                    <div class="paragraph splitted">
                        <div class="splitted left">
                            <p class="contact-area">
                                <strong class="phone">
                                    <a href="#">{{ featureData.ssc_telephone }}</a>
                                </strong>
                                <strong class="email">
                                    <a href="mailto:spb@rus.support">{{ featureData.ssc_email }}</a>
                                </strong>
                            </p>
                        </div>
                        <div class="splitted right">
                            <p class="workhours-area">
                                <span v-for="(hours, index) in splittedWorkhours(featureData.ssc_workhours)" :key="index">
                                    {{ hours }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="balloon_body bb">
            <div class="buttongroup">
                <div class="modal-buttongroup">
                    <div class="button" :class="btn.btnCapture" v-for="btn in scButtons" :key="btn.id" :id="btn.formType" @click="handler">
                        <span>{{ btn.caption }}</span>
                    </div>
                </div>

                <div v-for="price in priceLists" :key="price.id">
                    <div class="download-price button" v-if="price.priceCapture === featureData.ssc_capture">
                        <div class="download-price__button">
                            <a :href="price.link" target="_blank">Посмотреть прайслист на негарантийный сервис</a>
                        </div>
                    </div>
                </div>

            </div>

            <div class="auth-area">
                <p class="title">
                    <span class="text-blue">Обслуживаемое оборудование:</span>
                </p>
                <div class="auth-items-area" v-for="(auth, index, key) in featureData.auths" :key="key">
                    <div class="icon-offset" :style="iconOffsetStyle(index)"></div>

                    <div class="items-content">
                        <div class="auth-item" v-for="(authData, index) in auth" :key="index">
                            <span>{{authData.sdau_name}}</span>

                                <img :id="'auth'+authData.sdau_id" class="question-img" src="/img/icons/question-mark.svg" />
                                <DxTooltip
                                    :target="'#auth'+authData.sdau_id"
                                    show-event="dxhoverstart"
                                    hide-event="dxhoverend"
                                    position="top"
                                >
                                    <template>
                                        <p>{{ authData.sdau_description }}</p>
                                    </template>
                                </DxTooltip>
                        </div>
                    </div>
                </div>
            </div>


            <div class="balloon_footer">
                <div class="container">
                    <div class="d-flex justify-content-end">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapPriceLists} from "../Home/ServiceLocator/Departments/dispalyViews/Map/mapPriceLists";
import { DxTooltip } from 'devextreme-vue/tooltip';

export default {
        name: "balloon",
        props: ["featureData"],
        components: {DxTooltip},
        data () {
            return {
                scButtons: [
                    {
                        id: 0,
                        btnCapture: this.featureData.ssc_capture,
                        caption: "Создать заявку",
                        formType: "create-order",
                    },
                    {
                        id: 1,
                        btnCapture: this.featureData.ssc_capture,
                        caption: "Оставить отзыв в службу качества",
                        formType: "feedback",
                    }
                ],
                priceLists: mapPriceLists,
            }
        },

        methods: {
            handler(e) {

                if (e.currentTarget.id === 'create-order' || e.currentTarget.id === 'feedback') {

                    const action = this.$store.state.feedbackMode ? "off" : "on";
                    this.$store.commit('switchFeedbackMode', action)
                    this.$store.commit('setModalFormType', e.currentTarget.id)
                }

                for (let i = 0; i < this.$store.state.serviceCenters.length; i++) {
                    let test = this.$store.state.serviceCenters[i]

                    if (e.currentTarget.className === 'button ' + test.ssc_capture) {
                        const adr = test.ssc_address
                        const id = test.ssc_id

                        this.$store.commit('setAdressCapture', adr)
                        this.$store.commit('setSscId', id)
                    }
                }
            },
            splittedWorkhours(sting) {
                return sting.split(';')
            },
          iconOffsetStyle (vendorName) {
            vendorName = vendorName.split(" ").join("").replace(/\./g, "");

            let backgroundUrl = "/img/vendors/"+vendorName+".png";
            return {
              "background": 'url('+backgroundUrl+') no-repeat'
            }
          }
        }
    }
</script>

<style lang="scss" scoped>

    .balloon-template {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
             width: 0 !important
        }

        .balloon_header {
            & > h1 {
                font-size: 22px;
                font-weight: 600;
                color:#0171ba;
                text-transform:uppercase;
                padding:10px;
            }

            .bh.requisites {
                display: flex;
                justify-content: space-between;
                width: 100%;
                background-color: rgb(245,251,254);
                border-left: 2px solid rgba(0,115,192,0.2);

                .offset {
                    padding-left: 15px;

                    .offset-icon {
                        display: block;
                        background: url('/img/icons/call.svg') no-repeat;
                        background-size:contain;
                        background-position: 0 50%;
                        width: 40px;
                        height: 100%;
                    }
                }

                .requisites.content-area {
                    width: 100%;

                    .paragraph.address {
                        padding: 15px 15px 0 15px;
                    }

                    .paragraph.splitted {

                        display: flex;
                        flex-wrap: wrap;
                        position:relative;

                        & > * {
                            width:50%;
                            min-width: 140px;
                            padding: 0 15px;
                        }

                        .splitted.left {
                            .contact-area {
                                word-wrap: break-word;
                                display: flex;
                                flex-direction: column;

                                & > * a {
                                    text-decoration:none;
                                    font-weight: normal;
                                    color: #0a0a0a;
                                    cursor: pointer;
                                }
                            }

                        }

                        .splitted.right {
                            position:relative;

                            .workhours-area {
                                word-wrap: break-word;
                                display: flex;
                                flex-direction: column;
                            }
                        }

                        .splitted.right:before {
                            content:"";
                            position: absolute;
                            left:0px;
                            top:0;
                            height: 40px;
                            width: 1px;
                            border-right: 1px solid rgba(0,115,192,0.1);
                        }
                    }
                }
            }
        }

        .balloon_body {

            .buttongroup {
                display: flex;
                flex-direction: column;
                width: 100%;

                .modal-buttongroup {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 5px;

                    div.button {
                        width:49%;
                    }

                }

                .button {
                    text-align: center;
                    border-radius: 5px;
                    padding:5px 0;
                    background: rgb(237,252,241);
                    border: 1px solid rgba(77,120,148,0.5);
                    cursor: pointer;
                }

                .button.download-price {
                    margin-top: 5px;

                    .download-price__button {
                        width: 100%;

                        & > a {
                            text-decoration:none;
                            color: #0a0a0a;
                            display: block;
                            width:100%;
                            height: 100%;
                        }
                    }
                }
            }

            .auth-area {
                .title {
                    margin: 0;
                    padding: 10px 0 5px 20%;

                }
                .auth-items-area {
                    display: flex;
                    padding: 10px 0;

                    & > .icon-offset {
                        background: url("/img/icons/call.svg");
                        background-size: contain !important;
                        background-position: 0 50% !important;
                        width: 18% !important;
                        height: 30px !important;
                    }

                    .items-content {
                        padding: 5px 5px 0 2%;
                        width: 80%;
                        display: flex;
                        flex-direction: column;
                        position: relative;

                        .auth-item {
                            word-wrap: break-word;
                            margin-bottom:5px;
                            position: relative;

                            .question-img {
                                width:10px;
                                margin-left:5px;
                                margin-bottom:1px;
                            }

                            .auth-tooltip {
                                background-color: #0a73bb;
                                opacity: 0.3;
                                position: absolute;
                                top: -15px;
                            }

                            .testTooltip {
                                position: absolute;
                            }
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            width: 100%;
                            top:-10px;
                            border-top: 1px solid rgba(0,115,192,0.3);
                        }
                    }
                }

            }
        }
    }

</style>