<template>
  <div>
    <div class="row py-5">
      <div class="col-md-9">

        <div class="list">
          <div class="list__icon"></div>
          <div class="list__content">
            <p class="list__content_text">
              Одна из самых популярных форм аутсорсинга печати – покопийный контракт (клик-контракт)
            </p>
          </div>
        </div>
        <!-- /.LIST BLOCK -->
        <div class="list">
          <div class="list__icon"></div>
          <div class="list__content">
            <p class="list__content_text">
              Это услуга, при которой вы оплачиваете только напечатанные страницы, а профилактику, обслуживание, ремонт
              техники и поставку расходных материалов осуществляем мы
            </p>
          </div>
        </div>
        <!-- /.LIST BLOCK -->
        <div class="list">
          <div class="list__icon"></div>
          <div class="list__content">
            <p class="list__content_text">
              Вы можете использовать свое оборудование или заменить его на новое
            </p>
          </div>
        </div>
        <!-- /.LIST BLOCK -->
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Стоимость новых устройств можно заложить в стоимость контракта и распределить на весь его срок
            </p>
          </div>
        </div>

      </div>
      <div class="col-md-3">
        <div class="note-white">
          <span class="note__square"></span>
          <span class="note__title">
          Стоимость зависит
        </span>
          <p class="note__text">
            от набора дополнительных сервисов, количества напечатанных в месяц страниц и оборудования, на котором вы
            работаете.
          </p>
        </div>
        <!-- /.NOTE-WHITE BLOCK -->
      </div>
    </div>

    <div class="row">
      <img class="img-fluid img-horizontal" src="/img/pages/hp-outsourceprint_schema.png" alt="">

      <div class="img-vertical">
        <div class="img-vertical__item">
          <img class="img-fluid" src="/img/hp-outsourceprint_icon01.png" alt="">
        </div>
        <div class="img-vertical__item">
          <img class="img-fluid" src="/img/hp-outsourceprint_icon02.png" alt="">
          <div class="img-vertical__arrow"></div>
        </div>
        <div class="img-vertical__item">
          <img class="img-fluid" src="/img/hp-outsourceprint_icon03.png" alt="">
          <div class="img-vertical__arrow"></div>
        </div>
        <div class="img-vertical__item">
          <img class="img-fluid" src="/img/hp-outsourceprint_icon04.png" alt="">
          <div class="img-vertical__arrow"></div>
        </div>
        <div class="img-vertical__item">
          <img class="img-fluid" src="/img/hp-outsourceprint_icon05.png" alt="">
          <div class="img-vertical__arrow"></div>
        </div>
      </div>

      <div class="title">ПРЕИМУЩЕСТВА</div>

      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Удобно вести контроль за печатью в компании
          </p>
        </div>
      </div>
      <!-- /.LIST BLOCK -->
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Необязательно сразу делать крупные вложения в покупку техники
          </p>
        </div>
      </div>
      <!-- /.LIST BLOCK -->
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Оплата по договору уменьшает налогооблагаемую базу вашей компании
          </p>
        </div>
      </div>
      <!-- /.LIST BLOCK -->
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Постоплата, один счет в месяц упрощает документооборот
          </p>
        </div>
      </div>
      <!-- /.LIST BLOCK -->
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Сотрудники ИТ-отдела освобождаются от непрофильной работы
          </p>
        </div>
      </div>

      <p class="paragraph">
        Если вы заинтересовались аутсорсином печатных процессов и покопийным контрактом — пришлите нам запрос на
        <a class="link" href="mailto:mps@rus.support">mps@rus.support</a>
      </p>
    </div>
  </div>

</template>

<script>
export default {
  name: "HpContractsContent"
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
  margin: 1rem 0;
}

.paragraph {
  margin: 2rem 0;
}

.img-horizontal {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.img-vertical {
  display: none;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1024px) {
    display: flex;
  }

  .img-vertical__item {
    margin: 2rem 0;
    position: relative;

    .img-vertical__arrow {
      background: url("/img/hp-outsourceprint_arrow_down.png") center center no-repeat;
      height: 30px;
      position: absolute;
      width: 30px;
      top: -27%;
      right: 40%;
    }
  }
}
</style>
