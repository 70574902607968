<template>

    <div id="modalWindowLeft" :class="modalLoaded ? 'spread' : ''">
        <transition name="fade">
            <div class="modal-toggle-area" v-if="modalLoaded" @click="closeModal"></div>
        </transition>

        <div class="window">
            <div class="content-wrapper">
                <div class="modal-header">
                    <div class="close-button" @click="closeModal"><span>X</span></div>
                    <div class="modal-header_title">
                        <span style="color: #0071ba">Отправьте <b>запрос на обслуживание</b> в ближайший сервисный центр Rus.support</span>
                    </div>
                </div>
                <div class="modal-content" style="border:0">
                    <component v-bind:is="formName"/>
                </div>
                <div class="modal-footer">
                  <div class="modal-logo">
                    <router-link to="/about/hp" class="modal-link">
                      <img class="form-logo__img hp" src="/img/layout/hp.svg"/>
                    </router-link>
                    <router-link to="/about/hpe" class="modal-link">
                      <img class="form-logo__img hpe" src="/img/layout/hpe.svg"/>
                    </router-link>
                    <router-link to="/" class="modal-link">
                      <img class="form-logo__img rs" src="/img/layout/logo.svg"/>
                    </router-link>
                  </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
  import FormCreateOrder from "./formCreateOrder";
  import FormFeedback from "./formFeedback";
  export default {
    name: "modalWindowLeft",
    components: {FormCreateOrder, FormFeedback},
    data () {
      return {
        modalLoaded: false
      }
    },
    computed: {
      formName () {
        return "form-"+this.$store.state.modalFormType
      }
    },
    methods: {
      closeModal () {
        this.$store.commit("switchFeedbackMode", "off");
        this.$store.state.infoHpe = false
      }
    },
    mounted () {
      this.modalLoaded = true;
    }
  }
</script>

<style lang="scss" scoped>
#modalWindowLeft {
    position:fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 0 20px black;
    overflow-y: scroll;
    overflow-x: hidden;
    background: white !important;
    z-index: 10;
    padding:0 !important;
    margin: 0;
    transition: right .3s;


    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &.spread {
        right: 0 !important;
    }

    .window {
        height: 100%;

    }

    &::-webkit-scrollbar {
        display: none;
    }

    .content-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 11;
        background: white !important;
        width: 100%;
        padding:0 !important;
        margin: 0;
        min-height: 100vh;
    }


    .modal-header {

        &_title {
            padding-top:20px;
            font-size: 1rem;
            font-weight: 400;
            color: $font-color-blue;
            box-shadow: none;
            text-align: left;
            line-height: 1.5;
        }

        .close-button {
            position: absolute;
            right: 10px;
            top:10px;
            cursor: pointer;
            color: white;
            font-weight: bolder;
            background-color: #888;
            width: 20px;
            height: 20px;
            text-align: center;
            box-sizing: padding-box;
            border: 0;
            border-radius: 5px;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: $base-color;
            }

        }
    }

    .modal-footer {
      .modal-logo {
        width: 100%;
        height: 100%;
        background: #0a73bb;

        .modal-link {

          img {
            padding: 4%;
            width: 100%;
          }
        }

        .form-logo__img {
          max-width: 33%;
        }
        .form-logo__img.hp {
          width: 25%;
          padding-right: 0;
        }
        .form-logo__img.hpe {
          width: 30%;
        }
        .form-logo__img.rs {
          padding-right: 0;
        }
      }

    }

    .modal-toggle-area {
        width: 100vw;
        height: 100vh;
        position: fixed;
        left:0;
        top:0;
        background: rgba(0,0,0,0.5);
        z-index: 10;

        &.toggle-black {
            background: rgba(0,0,0,0.1);
        }
        &.toggle-transparent {
            background: transparent;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
        transition-timing-function: ease-in;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
}

</style>