<template>
    <div id="layout" class="h-100">
        <BaseLayout>
            <template v-slot:header="headerProps">
                <header-slider
                        v-if="Object.keys(slides).length"
                        :slides="slides"
                        :key="sliderComponentKey"
                >
                    <template v-slot:header>
                        <pageHeader
                            :headerSize="headerProps.size"
                        />
                    </template>
                </header-slider>
            </template>

            <template v-slot:content>
                <slot></slot>
            </template>

            <template v-slot:footer>
                <pageFooter/>
            </template>
        </BaseLayout>
    </div>
</template>

<script>
    import pageHeader from "./pageHeader"
    import pageFooter from "./pageFooter"
    import BaseLayout from "../BaseLayout"
    import HeaderSlider from "@/components/widgets/headerSlider";

  export default {
    name: "MainLauout",
    components: {pageHeader, pageFooter, BaseLayout, HeaderSlider},
    data () {
        return {
            headerBottom:1000,
            sliderComponentKey:0
        }
    },
    computed: {
      slides () {
        return this.$store.state.sliderData
      },
      currentRoute () {
          return this.$route.path
      }
    },
    watch: {
        currentRoute() {
            this.sliderComponentKey++
        }
    },
    mounted () {
        //this.$watch(() => this.$refs.comp.prop, (val) => {...})
    }
  }
</script>

<style scoped>

</style>