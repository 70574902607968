<template>
    <div class="b-component">
        <div class="b-title">НАШИМИ КЛИЕНТАМИ УЖЕ СТАЛИ</div>
        <div class="partner">
            <div class="partner__logo" v-for="(item, index) in partners" :key="index">
                <img class="partner__logo-img" :src="item.logo" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Partners",
    data() {
        return {
            partners: [
                {
                    id: 0,
                    name: 'pepsi',
                    logo: '/img/partners/pepsi-logo.png'
                },
                {
                    id: 1,
                    name: 'nexign',
                    logo: '/img/partners/nexign.png'
                },
                {
                    id: 2,
                    name: 'baltika',
                    logo: '/img/partners/baltika.png'
                },
                {
                    id: 3,
                    name: 'citylink',
                    logo: '/img/partners/citylink.png'
                },
                {
                    id: 4,
                    name: 'reso',
                    logo: '/img/partners/reso.png'
                },
                {
                    id: 5,
                    name: 'wclass',
                    logo: '/img/partners/wclass.png'
                },
                {
                    id: 6,
                    name: 'major',
                    logo: '/img/partners/major.png'
                },
                {
                    id: 7,
                    name: 'philip',
                    logo: '/img/partners/philip.png'
                },
                {
                    id: 8,
                    name: 'ingeo',
                    logo: '/img/partners/ingeo.png'
                },
                {
                    id: 9,
                    name: 'peter',
                    logo: '/img/partners/peter.png'
                },
                {
                    id: 10,
                    name: 'atom',
                    logo: '/img/partners/atom.png'
                },
                {
                    id: 11,
                    name: 'mosgortrans',
                    logo: '/img/partners/mosgortrans.png'
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.partner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1040px;
    margin: 0 auto;

    .partner__logo {
        width: calc(100% / 12 * 2);
        margin: 1.2rem 0;
        padding: 0.625rem;
        display: flex;
        justify-content: space-around;

        @media screen and (max-width: 1200px) {
            width: calc(100% / 12 * 3);
        }

        .partner__logo-img {
            @media screen and (max-width: 1200px) {
                max-width: 100%;
                margin: 0;
            }
        }
    }
}
</style>