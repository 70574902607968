<template>
  <div>

    <div class="row py-5">
      <div class="title">
        <b>ГАРАНТИЙНЫЕ ПАКЕТЫ</b><br>
        HPE Packaged Service
      </div>
      <div class="col-md-8">
        <div class="list">
          <div class="list__icon"></div>
          <div class="list__content">
            <p class="list__content_text">
              Увеличивают срок гарантии на устройства
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon"></div>
          <div class="list__content">
            <p class="list__content_text">
              Обеспечивают выездное обслуживание для всех видов оборудования HPE
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon"></div>
          <div class="list__content">
            <p class="list__content_text">
              Увеличивают радиус выездного обслуживания до 220 км
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon"></div>
          <div class="list__content">
            <p class="list__content_text">
              Повышают срочность и уровень обслуживания, вплоть до восстановления за фиксированный срок
            </p>
          </div>
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Включают в себя дополнительные сервисные опции, необходимые вашей компании
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="note-white">
          <span class="note__square"></span>
          <span class="note__title">HPE Packaged Service обеспечивает производитель</span>
          <p class="note__text">
            Это значит, что сроки и условия будут отражаться на официальном сайте HPE, а оригинальные запасные части
            всегда будут в наличии
          </p>
        </div>
        <!-- /.NOTE BLOCK -->
      </div>
    </div>
    <!-- /.row -->

    <div class="row py-5">
      <div class="col-md-8">
        <div class="title">
          <b>ИНСТАЛЛЯЦИОННЫЕ ПАКЕТЫ</b><br>
          HPE Packaged Service
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Позволяют проводить инсталляцию оборудования по указанному вами адресу
            </p>
          </div>
        </div>
        <div class="title">
          <b>УЧЕБНЫЕ ПАКЕТЫ</b><br>
          HPE Packaged Service
        </div>
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              Позволяют вашим сотрудникам проходить обучающие курсы в учебном центре HPE в Москве
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="note">
          <span class="note__square"></span>
          <span class="note__title">Срочное восстановление</span>
          <p class="note__text">
            Сервис гарантированного восстановления доступен лишь в некоторых городах. Уточняйте у нас возможность
            срочного обслуживания
          </p>
        </div>
        <!-- /.NOTE BLOCK -->
      </div>
    </div>
    <!-- /.row -->

    <div class="row">
      <div class="caption">
        <p class="caption__title">
          ОТЛИЧИЕ ОТ СЕРВИСНОГО КОНТРАКТА
        </p>
      </div>
      <!-- /.caption -->

      <div class="col-md-6">
        <div class="b-shadow">
          <div class="b-shadow__content">
            <h2 class="title">HPE PACKAGED SERVICE</h2>
            <!--  LIST BLOCK  -->
            <div class="list">
              <div class="list__icon"></div>
              <div class="list__content">
                <p class="list__content_text">
                  Готовые пакеты сервисных услуг.
                </p>
              </div>
            </div>
            <div class="list">
              <div class="list__icon"></div>
              <div class="list__content">
                <p class="list__content_text">
                  Сроки и уровни сервиса заранее определены и подходят для типовых ситуаций.
                </p>
              </div>
            </div>
            <div class="list">
              <div class="list__icon"></div>
              <div class="list__content">
                <p class="list__content_text">
                  Пакеты имеют фиксированную цену.
                </p>
              </div>
            </div>
            <div class="list">
              <div class="list__icon last"></div>
              <div class="list__content">
                <p class="list__content_text">
                  Зарегистрировать сервисный пакет HPE Packaged Service можно на устройство с действующей гарантией или
                  в течение 30 дней после ее окончания.
                </p>
              </div>
            </div>
            <!--  /.LIST BLOCK  -->
          </div>
        </div>
        <!--  /.B-SHADOW BLOCK  -->
      </div>

      <div class="col-md-6">
        <div class="b-shadow">
          <div class="b-shadow__content">
            <h2 class="title">СЕРВИСНЫЕ КОНТРАКТЫ HPE</h2>
            <!--  LIST BLOCK  -->
            <div class="list">
              <div class="list__icon"></div>
              <div class="list__content">
                <p class="list__content_text">
                  Сервисное обслуживание со сроками и уровнем сервиса, который вы выбираете сами.
                </p>
              </div>
            </div>
            <div class="list">
              <div class="list__icon"></div>
              <div class="list__content">
                <p class="list__content_text">
                  В сервисный контракт можно добавить дополнительные опции. Например, работу выделенного менеджера по
                  технической поддержке, проактивные работы, персональный график оплаты.
                </p>
              </div>
            </div>
            <div class="list">
              <div class="list__icon"></div>
              <div class="list__content">
                <p class="list__content_text">
                  Цена зависит от перечня выбранных услуг.
                </p>
              </div>
            </div>
            <div class="list">
              <div class="list__icon last"></div>
              <div class="list__content">
                <p class="list__content_text">
                  Зарегистрировать сервисный контракт можно на устройство, у которого давно закончилась гарантия.
                </p>
              </div>
            </div>

            <p>Подробнее о сервисных контрактах:
              <a class="link" href="http://rus.support/about/we-do?action=hpe_contracts">ссылка</a>
            </p>
            <!--  /.LIST BLOCK  -->
          </div>
        </div>
        <!--  /.B-SHADOW BLOCK  -->
      </div>

      <p class="paragraph">
        Если вас интересуют услуги HPE Packaged Services — отправьте нам заявку на адрес
        <a class="link" href="mailto:sale@rus.support">sale@rus.support</a>, и мы подберем
        нужный сервис и вышлем вам предложение
      </p>
    </div>
    <!-- /.row -->

  </div>
</template>

<script>
export default {
  name: "HpePackagedContent"
}
</script>

<style lang="scss" scoped>
.b-shadow {
  flex-direction: column;
  text-align: left;
  height: 100%;

  @media screen and (max-width: 767px) {
    height: auto;
  }

  .title {
    font-weight: 600;
    width: 100%;
    margin-bottom: 1rem;
  }
}

.title {
  margin-bottom: 1rem;
}

.note-white {
  max-width: 100%;
  margin-top: 0;
}

.note {
  margin-top: 0;
}

.paragraph {
  margin: 2rem 0;
}
</style>