import { render, staticRenderFns } from "./SamsungGuarantee.vue?vue&type=template&id=aafeaace&scoped=true&"
import script from "./SamsungGuarantee.vue?vue&type=script&lang=js&"
export * from "./SamsungGuarantee.vue?vue&type=script&lang=js&"
import style0 from "./SamsungGuarantee.vue?vue&type=style&index=0&id=aafeaace&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aafeaace",
  null
  
)

export default component.exports