import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuelidate from "vuelidate/src";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './styles/fonts/fonts.scss';
import './styles/index.scss';
import './components';
import Submitter from './submitter';

Vue.use(Vuelidate);

Vue.config.productionTip = false;
Vue.prototype.$submitter = new Submitter();

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
