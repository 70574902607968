<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      РАБОЧЕЕ МЕСТО
      КАК СЕРВИС - DAAS
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Rus.support предлагает относительно новый для российского рынка подход к обслуживанию рабочих мест – DaaS (Device
      as a Service).
    </p>
  </div>
</template>

<script>
export default {
  name: "hpDaasSliderContent"
}
</script>

<style scoped>

</style>