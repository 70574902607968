<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      ПОСТГАРАНТИЙНОЕ ОБСЛУЖИВАНИЕ ОБОРУДОВАНИЯ
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Когда гарантия уже не действует, мы проводим диагностику и послегарантийный ремонт оборудования на платной основе
    </p>
  </div>
</template>

<script>
export default {
  name: "hpPostguaranteeSliderContent"
}
</script>

<style scoped>

</style>