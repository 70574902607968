<template>
    <div id="app" :class="inLoading?'':'loaded'">
        <!--        <div class="notification">
                    <div class="notification-content">
                        Данные сайта устарели,
                        пожалуйста, обновите страницу
                        с помощью комбинации клавиш Ctrl + F5
                    </div>
                </div>-->
        <component v-bind:is="currentLayout">
            <div class="content">
                <router-view/>
            </div>
        </component>
        <CookieNotice/>
    </div>
</template>

<script>
import httpClient from "@/services/http.service.js";
import CookieNotice from "@/components/cookie/CookieNotice.vue";

export default {
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                this.url = to.path;
            }
        }
    },
    data() {
        return {
            url: '',
            timeout: 3000,
            lastActiveTimestamp: 0,
            userIsActive: false
        }
    },
    components: {CookieNotice},
    computed: {
        currentLayout() {
            return this.$store.state.layout;
        },
        inLoading() {
            return this.$store.state.inLoading
        }
    },
    updated() {
        if (this.inLoading) {
        }
    },

    async created() {
        await this.$store.dispatch("loadServiceCenters");
        await this.$store.dispatch("loadServiceVendors");
    },

    async mounted() {
        let error = null;
        const {status, data} = await httpClient.get('web/routes');
        if (status === 200) {
            this.dsRoutes = data;
            this.dsRoutes.forEach((item, index) => this.createAndAppendRoute(item, index));
        } else {
            console.error(status, data, error);
        }

        // window.addEventListener('mousemove', this.active);
        // window.addEventListener('keypress', this.active);
        // window.addEventListener('click', this.active);
        //
        // setInterval(this.checkUserIsActive, 3600000)
        // this.active()
    },
    methods: {
        createAndAppendRoute(newRoute, index) {
            this.$router.addRoute(newRoute);
        },
        checkUserIsActive() {
            if (this.userIsActive && new Date().getTime() - this.lastActiveTimestamp > this.timeout) {
                // console.log('user is not active');
                document.querySelector('.notification').style.display = 'block'
                this.userIsActive = false;
            }
        },
        /*active() {
            this.lastActiveTimestamp = new Date().getTime();
            if (!this.userIsActive) {
                this.userIsActive = true;
                // console.log('user is active');
            }
        }*/
    }
}

</script>
<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    font-size: 16px;
    min-width: 220px;
    transition: opacity .2s ease-in;
    opacity: 0;
    -webkit-user-select: auto !important;
    height: 100%;
    position: relative;

    &.loaded {
        transition: opacity .5s ease-out;
        opacity: 1;
    }

    .notification {
        padding: 50px 10px;
        background-color: #ffffffdb;
        color: #035c96;
        position: absolute;
        width: 100%;
        z-index: 99;
        box-shadow: 0 10px 30px #0000007d;
        display: none;

        .notification-content {
            text-align: center;
            font-weight: 600;
            font-size: 20px;
        }
    }

}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

// hides the recaptcha nameplate
/*.grecaptcha-badge {
    visibility: hidden;
}*/
</style>
