const editionTitles = {
    2017: [
        {
            placement_date: '12 сентября 2017 г.',
            effective_date: '13 сентября 2017 г.',
            obj: [
                {
                    id: 0,
                    num: 1,
                    title: 'Общие положения',
                    component: 'generalProvisions2017',
                    visible: false
                },
                {
                    id: 1,
                    num: 2,
                    title: 'Термины',
                    component: 'termsContent2017',
                    visible: false
                },
                {
                    id: 2,
                    num: 3,
                    title: 'Права и обязанности сторон',
                    component: 'rightsContent2017',
                    visible: false
                },
                {
                    id: 3,
                    num: 4,
                    title: 'Общие условия предоставления сервиса',
                    component: 'provisionServices2017',
                    visible: false
                },
                {
                    id: 4,
                    num: 5,
                    title: 'Условия выездного сервисного обслуживания',
                    component: 'termsOfService2017',
                    visible: false
                },
                {
                    id: 5,
                    num: 6,
                    title: 'Условия приёмки оборудования в сервисный центр и выдачи из сервисного центра',
                    component: 'admissionConditions2017',
                    visible: false
                },
                {
                    id: 6,
                    num: 7,
                    title: 'Условия приемки и выдачи оборудования посредством курьерской доставки',
                    component: 'termsOfIssue2017',
                    visible: false
                },
                {
                    id: 7,
                    num: 8,
                    title: 'Условия хранения в СЦ отремонтированного оборудования',
                    component: 'storageСonditions2017',
                    visible: false
                },
                {
                    id: 8,
                    num: 9,
                    title: 'Условия сервиса по гарантийному сервисному обслуживанию оборудования',
                    component: 'serviceGuarantee2017',
                    visible: false
                }
            ]
        },
    ],
    2020: [
        {
            placement_date: '6 мая 2020 г.',
            effective_date: '7 мая 2020 г.',
            obj: [
                {
                    id: 0,
                    num: 1,
                    title: 'Общие положения',
                    component: 'generalProvisions2020',
                    visible: false
                },
                {
                    id: 1,
                    num: 2,
                    title: 'Термины',
                    component: 'termsContent2020',
                    visible: false
                },
                {
                    id: 2,
                    num: 3,
                    title: 'Права и обязанности сторон',
                    component: 'rightsContent2020',
                    visible: false
                },
                {
                    id: 3,
                    num: 4,
                    title: 'Общие условия предоставления сервиса',
                    component: 'provisionServices2020',
                    visible: false
                },
                {
                    id: 4,
                    num: 5,
                    title: 'Условия выездного сервисного обслуживания',
                    component: 'termsOfService2020',
                    visible: false
                },
                {
                    id: 5,
                    num: 6,
                    title: 'Условия приёмки оборудования в сервисный центр и выдачи из сервисного центра',
                    component: 'admissionConditions2020',
                    visible: false
                },
                {
                    id: 6,
                    num: 7,
                    title: 'Условия приемки и выдачи оборудования посредством курьерской доставки',
                    component: 'termsOfIssue2020',
                    visible: false
                },
                {
                    id: 7,
                    num: 8,
                    title: 'Условия хранения в СЦ отремонтированного оборудования',
                    component: 'storageConditions2020',
                    visible: false
                },
                {
                    id: 8,
                    num: 9,
                    title: 'Условия сервиса по гарантийному сервисному обслуживанию оборудования',
                    component: 'serviceGuarantee2020',
                    visible: false
                },
                {
                    id: 9,
                    num: 10,
                    title: 'Условия сервиса по негарантийному сервисному обслуживанию оборудования',
                    component: 'outOfWarranty2020',
                    visible: false
                },
            ]
        }
    ],
    2021: [
        {
            placement_date: '23 июля 2021 г.',
            effective_date: '26 июля 2021 г.',
            obj: [
                {
                    id: 0,
                    num: 1,
                    title: 'Общие положения',
                    component: 'generalProvisions2021',
                    visible: false
                },
                {
                    id: 1,
                    num: 2,
                    title: 'Термины',
                    component: 'termsContent2021',
                    visible: false
                },
                {
                    id: 2,
                    num: 3,
                    title: 'Права и обязанности сторон',
                    component: 'rightsContent2021',
                    visible: false
                },
                {
                    id: 3,
                    num: 4,
                    title: 'Общие условия предоставления сервиса',
                    component: 'provisionServices2021',
                    visible: false
                },
                {
                    id: 4,
                    num: 5,
                    title: 'Условия выездного сервисного обслуживания',
                    component: 'termsOfService2021',
                    visible: false
                },
                {
                    id: 5,
                    num: 6,
                    title: 'Условия приёмки оборудования в сервисный центр и выдачи из сервисного центра',
                    component: 'admissionConditions2021',
                    visible: false
                },
                {
                    id: 6,
                    num: 7,
                    title: 'Условия приемки и выдачи оборудования посредством курьерской доставки',
                    component: 'termsOfIssue2021',
                    visible: false
                },
                {
                    id: 7,
                    num: 8,
                    title: 'Условия хранения в СЦ отремонтированного оборудования',
                    component: 'storageConditions2021',
                    visible: false
                },
                {
                    id: 8,
                    num: 9,
                    title: 'Условия сервиса по гарантийному сервисному обслуживанию оборудования',
                    component: 'serviceGuarantee2021',
                    visible: false
                },
                {
                    id: 9,
                    num: 10,
                    title: 'Условия сервиса по негарантийному сервисному обслуживанию оборудования',
                    component: 'outOfWarranty2021',
                    visible: false
                },
            ]
        }
    ]
};

export {editionTitles};