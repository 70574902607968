<template>
    <div class="balloon-template scroll" id="widthBalloon">
        <div class="balloon_header bh">

            <h1>
                <span style="font-size: 14px">Сервисный центр сети Рус.Саппорт</span> <br>
                {{ featureData.ssc_name }}
            </h1>

            <div class="bh requisites">
                <div class="requisites offset">
                    <i class="offset-icon"></i>
                </div>
                <div class="requisites content-area">
                    <p align="left" class="paragraph address">
                        <span>{{ featureData.ssc_address }}</span>
                    </p>
                    <div class="paragraph splitted">
                        <div class="splitted left">
                            <p class="contact-area">
                                <strong class="phone">
                                    <a href="#">{{ featureData.ssc_telephone }}</a>
                                </strong>
                                <strong class="email">
                                    <a href="mailto:spb@rus.support">{{ featureData.ssc_email }}</a>
                                </strong>
                            </p>
                        </div>
                        <div class="splitted right">
                            <p class="workhours-area">
                                <span v-for="(hours, index) in splittedWorkhours(featureData.ssc_workhours)"
                                      :key="index">
                                    {{ hours }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="balloon_body bb">
            <div class="buttongroup">
                <div class="modal-buttongroup">
                    <div class="button" :class="btn.btnCapture" v-for="btn in scButtons" :key="btn.id"
                         :id="btn.formType">
                        <span>{{ btn.caption }}</span>
                    </div>
                </div>
                <!--<div v-for="price in priceLists" :key="price.id">
                      <div class="download-price button" v-if="price.priceCapture === featureData.ssc_capture">
                        <div class="download-price__button">
                          <a :href="price.link" target="_blank">Посмотреть прайслист на негарантийный сервис</a>
                        </div>
                      </div>
                    </div>-->
            </div>
            <div class="auth-area">
                <p class="title">
                    <span class="text-blue">Обслуживаемое оборудование:</span>
                </p>
                <div class="auth-items-area" v-for="(auth, key) in featureData.auths" :key="key">
                    <div class="icon-offset" :style="iconOffsetStyle(key)"></div>
                    <!--            <pre>-->
                    <!--                {{key}}-->
                    <!--            </pre>-->
                    <div style="width: 85%">
                        <div class="items-content" v-for="(authData, index) in auth" :key="index">
                            <div class="auth"
                                 v-if="featureData.ssc_works[authData.sdau_id].sca_sdau_warranty === '1' ||
                 featureData.ssc_works[authData.sdau_id].sca_sdau_non_warranty === '1'"
                            >
                                <!--              <div class="icon-offset" :style="iconOffsetStyle(authData.sdau_vendor)"></div>-->
                                <div class="auth-item">
                                    <span>{{ authData.sdau_name }}</span>
                                    <!--                                    <img :data-tooltip="authData.sdau_description" :id="'auth'+authData.sdau_id"
                                                                             class="question-img" src="/img/icons/question-mark.svg"/>
                                                                        <div class="auth-tooltip"></div>-->
                                    <div class="tooltiptest">
                                        <img style="width: 12px; margin: 0 5px;"

                                             src="/img/icons/question-mark.svg" alt="" :id="'auth'+authData.sdau_id">
                                        <div class="tooltiptexttest">{{ authData.sdau_description }}</div>
                                    </div>
                                </div>

                                <div class="tooltiptest">
                                    <img style="width: 15px; margin: 0 5px;"
                                         v-if="featureData.ssc_works[authData.sdau_id].sca_sdau_warranty === '1'"
                                         src="/img/icons/warr.png" alt="">
                                    <div class="tooltiptexttest">Гарантийное обслуживание</div>
                                </div>

                                <div class="tooltiptest">
                                    <img style="width: 15px; margin: 0 5px;"
                                         v-if="featureData.ssc_works[authData.sdau_id].sca_sdau_non_warranty === '1'"
                                         src="/img/icons/oow.png" alt="">
                                    <div class="tooltiptexttest">Платное обслуживание</div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!--      <div class="balloon_footer">
                    <div class="container">
                      <div class="d-flex justify-content-end">

                      </div>
                    </div>
                  </div>-->
        </div>
    </div>
</template>

<script>
import {mapPriceLists} from './mapPriceLists'
import {DxTooltip} from 'devextreme-vue/tooltip';

export default {
    name: "balloon",
    props: ["featureData"],
    components: {DxTooltip},
    data() {
        return {
            iconOffsetStyle(vendorName) {
                vendorName = vendorName.split(" ").join("").replace(/\./g, "");

                let backgroundUrl = "/img/vendors_baloon/" + vendorName + ".svg";
                return {
                    background: 'url(' + backgroundUrl + ') no-repeat',
                    "background-size": 'contain',
                    "background-position": "0 50%",
                    width: '10%',
                    height: '25px',
                    // marginRight: '10px'
                    margin: '5px 0'
                }
            },

            scButtons: [
                {
                    id: 0,
                    btnCapture: this.featureData.ssc_capture,
                    caption: "Создать заявку",
                    formType: "create-order",
                },
                {
                    id: 1,
                    btnCapture: this.featureData.ssc_capture,
                    caption: "Оставить отзыв в службу качества",
                    formType: "feedback",
                }
            ],
            priceLists: mapPriceLists,
        }
    },

    methods: {
        splittedWorkhours(sting) {
            return sting.split(';')
        },

    },
}
</script>

<style lang="scss" scoped>

.tooltiptest {
    position: relative;
    display: inline-block;
    z-index: 99;
}

.tooltiptest .tooltiptexttest {
    visibility: hidden;
    width: 170px;
    background-color: white;
    color: #000000;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    box-shadow: 0 3px 5px #00000063;
    position: absolute;
    z-index: 99;
}

.tooltiptest:hover .tooltiptexttest {
    visibility: visible;
    z-index: 99;
}

.balloon-template {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: auto;
    //width: 98%;
    z-index: 1;

    @media screen and (max-width: 500px) {
        width: 100%;
    }

    &::-webkit-scrollbar {
        width: 25px !important;
    }

    .balloon_header {
        & > h1 {
            font-size: 22px;
            font-weight: 600;
            color: #0171ba;
            text-transform: uppercase;
            padding: 10px;

            @media screen and (max-width: 500px) {
                font-size: 18px;
                padding: 0;
            }
        }

        .bh.requisites {
            display: flex;
            justify-content: space-between;
            width: 100%;
            background-color: rgb(245, 251, 254);
            border-left: 2px solid rgba(0, 115, 192, 0.2);

            @media screen and (max-width: 500px) {
                flex-direction: column;
            }

            .offset {
                padding-left: 15px;

                .offset-icon {
                    display: block;
                    background: url('/img/icons/call.svg') no-repeat;
                    background-size: contain;
                    background-position: 0 50%;
                    width: 40px;
                    height: 100%;
                }
            }

            .requisites.content-area {
                width: 100%;

                .paragraph.address {
                    padding: 15px 15px 0 15px;
                }

                .paragraph.splitted {

                    display: flex;
                    flex-wrap: wrap;
                    position: relative;

                    & > * {
                        width: 50%;
                        min-width: 140px;
                        padding: 0 15px;

                        @media screen and (max-width: 500px) {
                            width: 100%;
                        }
                    }

                    .splitted.left {
                        .contact-area {
                            word-wrap: break-word;
                            display: flex;
                            flex-direction: column;

                            & > * a {
                                text-decoration: none;
                                font-weight: normal;
                                color: #0a0a0a;
                                cursor: pointer;
                            }
                        }

                    }

                    .splitted.right {
                        position: relative;

                        .workhours-area {
                            word-wrap: break-word;
                            display: flex;
                            flex-direction: column;
                        }
                    }

                    .splitted.right:before {
                        content: "";
                        position: absolute;
                        left: 0px;
                        top: 0;
                        height: 40px;
                        width: 1px;
                        border-right: 1px solid rgba(0, 115, 192, 0.1);
                    }
                }
            }
        }
    }

    .balloon_body {

        .buttongroup {
            display: flex;
            flex-direction: column;
            width: 100%;

            .modal-buttongroup {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-top: 5px;

                div.button {
                    width: 49%;

                    @media screen and (max-width: 500px) {
                        width: 100%;
                        margin: 0 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

            }

            .button {
                text-align: center;
                border-radius: 5px;
                padding: 5px 0;
                background: rgb(237, 252, 241);
                border: 1px solid rgba(77, 120, 148, 0.5);
                cursor: pointer;
            }

            .button.download-price {
                margin-top: 5px;

                @media screen and (max-width: 500px) {
                    margin: 5px;
                }

                .download-price__button {
                    width: 100%;

                    & > a {
                        text-decoration: none;
                        color: #0a0a0a;
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .auth-area {
            .title {
                margin: 0;
                padding: 10px 0 5px 20%;

                @media screen and (max-width: 500px) {
                    padding: 10px;
                }
            }

            .auth-items-area {
                display: flex;
                //flex-direction: column;
                padding: 10px 0;
                border-top: 1px solid #0171bb4d;

                .items-content {
                    //padding: 5px 5px 0 1%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    position: relative;

                    .auth {
                        display: flex;
                        align-items: center;
                        padding: 0 0 0 10px;
                    }

                    .auth-item {
                        word-wrap: break-word;
                        position: relative;
                        font-size: 12px;

                        .question-img {
                            width: 12px;
                            margin-left: 10px;
                            margin-right: 5px;
                            margin-bottom: 1px;
                        }


                        .auth-tooltip {
                            background-color: #ffffff;
                            box-shadow: 0 3px 15px #00000063;
                            padding: 5px;
                            border-radius: 5px;
                            color: #000000;
                            display: none;
                            text-align: center;
                        }


                    }

                    /*&:before {
                      content: "";
                      position: absolute;
                      width: 100%;
                      top: 0;
                      border-top: 1px solid rgba(0, 115, 192, 0.3);
                    }*/
                }
            }

        }
    }
}

</style>