<template>
  <!-- Content start -->
  <div class="container list-service-wrap">
    <HpePostGuaranteeContent/>
  </div>
  <!-- Content end -->
</template>

<script>

import HpePostGuaranteeContent from "@/pages/About/we-do/HpePostGuarantee/HpePostGuaranteeContent";

const buttons = [
  {
    link: "link",
    customClass: "class",
    size: "1",
    sign: "+",
    caption: "ОФОРМИТЬ НОВУЮ ЗАЯВКУ",
    formType: "create-order"
  },
];

const slides = [
  {
    background: "/img/page-slider/hp-postguarantee.jpg",
    template: "hpPostguaranteeSliderContent",
    buttons: buttons
  },
];


export default {
  data() {
    return {
      dsVendors: [],
      slides: slides,
    }
  },
  created() {
    const layout = {
      name: "Slider",
      data: this.slides
    };
    this.$store.commit('setLayout', layout)

    //console.log(this.$route.params.sc, this.$store.state.serviceCenters)
  },
  components: {
    HpePostGuaranteeContent,
  }
}
</script>

<style lang="scss" scoped>
.list-service-wrap {
  max-width: 1140px;
}
</style>
