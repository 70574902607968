<template>
  <div class="privacy-accordion">
    <div class="privacy-accordion__item" v-for="item in accordion" :key="item.id">
      <div class="privacy-accordion__item-title" :id="item.id" @click="item.componetShow = !item.componetShow">
        {{ item.title }}
      </div>
      <transition name="fade">
        <component :is="item.component" v-if="item.componetShow"/>
      </transition>
    </div>
  </div>
</template>

<script>

import {accordionContent} from "./dataAccordionPrivacy";

import termsDefinitions from "./PrivacyAccordionContent/termsDefinitions";
import generalProvisions from "./PrivacyAccordionContent/generalProvisions";
import personalData from "./PrivacyAccordionContent/personalData";
import termsOfService from "./PrivacyAccordionContent/termsOfService";
import finalProvisions from "./PrivacyAccordionContent/finalProvisions"

export default {
  name: "PrivacyAccordion",
  data() {
    return {
      accordion: accordionContent
    }
  },
  components: {
    termsDefinitions,
    generalProvisions,
    personalData,
    termsOfService,
    finalProvisions
  }
}
</script>

<style lang="scss" scoped>
.privacy-accordion {
  text-align: left;

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .privacy-accordion__item {
    padding: 0.625rem;
    margin: 1rem 0;

    .privacy-accordion__item-title {
      color: #FFFFFF;
      font-weight: 600;
      font-size: 1.188rem;
      padding: 1rem;
      background-color: #0071ba;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .privacy-accordion__item-content {
    padding: 1rem;
    display: block;
  }
}
</style>