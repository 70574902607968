<template>
  <div class="terms-accordion__item-content">
    <p>6.1. Оборудование с согласия Заказчика, принимается в СЦ без разборки и проверки внутренних повреждений. За
      внутренние повреждения, которые могут быть обнаружены в принятом оборудовании в ходе оказания сервисных услуг, СЦ
      ответственности не несёт.</p>
    <p>6.2. Оборудование принимается для сервисного обслуживания в СЦ без внешних аксессуаров, не входящих в стандартный
      комплект поставки оборудования, вроде отдельных USB-устройств, карт памяти, SIM-карт и т.д., за исключением тех
      случаев, когда эти аксессуары необходимы для выявления заявленной неисправности. В любом случае все внешние
      предметы и аксессуары, передаваемые в СЦ вместе с основным оборудованием, должны быть перечислены в квитанции о
      приеме оборудования для сервисного обслуживания в поле «Комплектация», в противном случае СЦ не несёт за них
      никакой ответственности.</p>
    <p>6.3. Все видимые значимые внешние повреждения и дефекты оборудования в момент приемки оборудования для сервисного
      обслуживания в СЦ должны быть перечислены в квитанции о приеме оборудования для сервисного обслуживания в поле
      «Внешний вид, видимые дефекты, важные замечания». Такие повреждение и дефекты признаются возникшими не по вине СЦ,
      и СЦ не несет за них ответственности.</p>
    <p>6.4. СЦ выдает оборудование только при предъявлении квитанции о приеме оборудования для сервисного
      обслуживания.</p>
    <p>6.5. Выдача оборудования, переданного к сервисному обслуживанию юридическим лицом (организацией), осуществляется
      только при наличии у получателя доверенности от юридического лица (организации) установленного образца.</p>
    <p>6.6. При утере квитанции о приеме оборудования, переданного к сервисному обслуживанию физическим лицом:</p>
    <ul>
      <li>Заказчик обязан незамедлительно сообщить об утере в СЦ;</li>
      <li>если Заказчик сообщил об утере в СЦ, то выдача оборудования будет осуществляться только предъявителю
        документа, удостоверяющего личность на территории Российской Федерации, данные в котором совпадают с данными,
        указанными при регистрации Заявки и на основании письменного заявления Заказчика.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "admissionConditions2020"
}
</script>

<style scoped>

</style>