<template>
    <div class="b-component">
        <div class="b-title">НАШИ ПРЕИМУЩЕСТВА</div>
        <AdvantagesAccordion/>
    </div>
</template>

<script>

import AdvantagesAccordion from "./components/advantagesAccordion/AdvantagesAccordion";

export default {
    name: "Advantages",
    components: {
        AdvantagesAccordion
    }
}
</script>

<style lang="scss" scoped>

</style>