<template>
  <div class="vendor-page-slide-logo">
    <img class="img-fluid" src="/img/logo/hplogo.png" alt="">
  </div>
</template>

<script>
export default {
  name: "hpSlideContent"
}
</script>

<style lang="scss" scoped>
.vendor-page-slide-logo {
  text-align: center;
  padding-top: 0;
}
</style>