<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      ПОСТАВКИ
      ОРИГИНАЛЬНЫХ ЗАПЧАСТЕЙ
      И РАСХОДНЫХ МАТЕРИАЛОВ
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Заказчикам, которые хотят пополнить собственный склад запасных частей и расходных материалов или провести ремонт
      самостоятельно, мы помогаем с поставкой оригинальной продукции
    </p>
  </div>
</template>

<script>
export default {
  name: "hpSparepartsSliderContent"
}
</script>

<style scoped>

</style>