<template>
  <div class="row py-5">
    <p class="paragraph">
      Мы являемся сервисным центром компании American Power Conversion (APC by Schneider Electric) в Санкт-Петербурге и
      Ленинградской области
    </p>
    <p class="paragraph">
      Мы выполняем диагностику, ремонт и сервисное обслуживание однофазного оборудования АРС:
    </p>

    <div class="col-md-6">

      <!-- LIST BLOCK -->
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            сетевые фильтры
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            удлинители
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            стабилизаторы напряжения
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            источники бесперебойного питания серии Back UPS
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            источники бесперебойного питания серии Smart- UPS
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            UPS Symmetra LX/RM
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Power Banks
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Replacement battery cartridge
          </p>
        </div>
      </div>
      <!-- /.LIST BLOCK -->

      <p class="paragraph">
        Также мы проводим пусконаладочные работы, ремонт и сервисное обслуживание трёхфазных источников бесперебойного
        питания моделей:
      </p>

      <!-- LIST BLOCK -->
      <div class="list">
        <div class="list__icon"></div>
        <div class="list__content">
          <p class="list__content_text">
            Smart-UPS VTP/ Galaxy 3500
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list__icon last"></div>
        <div class="list__content">
          <p class="list__content_text">
            Symmetra PX 48/160 КВА (PX2)
          </p>
        </div>
      </div>
      <!-- /.LIST BLOCK -->

    </div>
    <div class="col-md-6">
      <formFeedbackApc
          :key="componentKey"
          @restartFormApc="forceRerender"
      />
    </div>
    <p class="paragraph mt-5">
      Мы имеем все необходимое для выполнения гарантийных и постгарантийных работ: обученных специалистов, сервисные
      инструменты, доступ к экспертизе производителя и складам запасных частей APC.
    </p>
  </div>
</template>

<script>

import formFeedbackApc from "../../../../components/layouts/common/modal/formFeedbackApc";

export default {
  name: "OthersApcContent",
  components: {
    formFeedbackApc
  },
  data() {
    return {
      componentKey: 0,
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
  },
}
</script>

<style lang="scss" scoped>

</style>