<template>
    <div>
        <ul class="nav" :class="navClass">
            <li class="nav-item" v-for="item in items" :key="item.id">
                <slot :name="item.id"></slot>
                <a class="nav-link link-menu-right"
                   @click="linkClickAction($event,item)"
                   :id="item.id"
                   :class="item.linkClass"
                   :href="item.link">
                    {{ item.caption }}
                </a>
            </li>
          <router-link v-if="loc.pathname !== '/'" class="nav-link link-menu-right" to="/#how-find">
            КАК НАС НАЙТИ
          </router-link>
        </ul>
    </div>

</template>
<script>



export default {
    data() {
        return {
          loc: location
        }
    },

    props: {
        items: {
            type: Array
        },
        navClass: {
            type: String,
        },
        linkClass: {
            type: String
        }
    },

  methods: {
      linkClickAction(event, menuItem) {
        if (!menuItem.link) event.preventDefault()

        if(menuItem.id === "wedo") {
          this.$emit("wedoOpen")
        }
        if(menuItem.id === "howfind") {
          this.$emit("howfindClick")
        }
        console.log(this.loc.pathname)
      },
  },
  mounted() {
    if (this.loc.pathname !== '/') {
      let test = document.getElementById('howfind')
      test.style.display = 'none'
    }
  }
}

</script>
<style lang="scss" scoped>
    .nav-link {
        cursor: pointer;
    }
</style>
