<template>
  <div class="slider-content">
    <h2 class="slider-content__title">HP CARE PACK</h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      HP Care Pack – сервисный продукт HP Inc., расширяющий условия и сроки стандартной гарантии HP Inc. для <br>
      оборудования и программного обеспечения
    </p>
  </div>
</template>

<script>
export default {
  name: "hpCarepackSliderContent"
}
</script>

<style lang="scss" scoped>

</style>