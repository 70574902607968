<template>
  <svg viewBox="0 0 81.97 142.29"><line x1="5.8" y1="5.3" x2="76.66" y2="76.17"/><line x1="5.3" y1="136.98" x2="76.17" y2="66.12"/></svg>
</template>

<script>
export default {
name: "arrow"
}
</script>

<style scoped>

</style>