<template>
    <div class="menu menu-right">
        <BootstrapNav
            :items="navItems"
            :linkClass="bootstrapLinkClass"
            :navClass="bootstrapNavClass"
            @wedoOpen="wedoOpenAction"
            @howfindClick="scrollToMapSelector"
        >
            <template v-slot:wedo >
                <div class="menu-beak" v-if="wedoShow"></div>
            </template>
        </BootstrapNav>

    </div>
</template>

<script>
  export default {
    name: "headerMenu",
    props: ["wedoShow","headerBottom"],
    data () {
      const navItems = [
          {
            id: "whowe",
            caption: "КТО МЫ",
            link: "/about",

          },
          {
            id: "wedo",
            caption: "ЧТО МЫ ДЕЛАЕМ"
          },
          {
            id: "howfind",
            caption: "КАК НАС НАЙТИ",
            link: '/#how-find'
          }
      ];
      return {
        navItems:navItems,
        bootstrapNavClass: "justify-content-around justify-content-md-center",
        bootstrapLinkClass: "link-menu-right"
      }
    },
    methods: {
      wedoOpenAction() {
        this.$emit("wedoOpen")
      },
      /*howfindClickAction() {
        window.scrollTo({
            top: this.headerBottom+40,
            behavior: "smooth"
        });
      },*/
      scrollToMapSelector() {
        let clientWidthWindow = document.documentElement.clientWidth
        let fromService = document.getElementById('form-services')
        let targetElem = document.querySelector('.scroll-title')

        if (clientWidthWindow > 991)
          targetElem.scrollIntoView({block: "start", inline: "nearest"})
        else
          fromService.scrollIntoView({block: "center", behavior: "smooth"})
      },
    }
  }
</script>

<style lang="scss" scoped>
    .menu {
        position: relative;

    }
    .menu > div {
    }
    .menu-beak {
        position: absolute;

        &::after {
            position: absolute;
            left: 20px;
            top: 55px;
            width: 0;
            height: 0;
            content: '';
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #fff;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            &::after {
                position: absolute;
                left: 20px;
                top: 75px;
                width: 0;
                height: 0;
                content: '';
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #fff;
            }
        }
    }
</style>