<template>
<!--    <div class="partners">
        <div class="container">
            <div class="row align-items-center">
                <div class="title">
                    НАШИМИ КЛИЕНТАМИ УЖЕ СТАЛИ
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                    <img class="img-fluid" src="/img/ulmart.png" alt="">
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                    <div class="partners-logo">
                        <img class="img-fluid" src="/img/nexign.png" alt="">
                    </div>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                    <div class="partners-logo">
                        <img class="img-fluid" src="/img/baltika.png" alt="">
                    </div>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                    <div class="partners-logo">
                        <img class="img-fluid" src="/img/citylink.png" alt="">
                    </div>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                    <div class="partners-logo">
                        <img class="img-fluid" src="/img/reso.png" alt="">
                    </div>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                    <div class="partners-logo">
                        <img class="img-fluid" src="/img/wclass.png" alt="">
                    </div>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                    <div class="partners-logo">
                        <img class="img-fluid" src="/img/major.png" alt="">
                    </div>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                    <div class="partners-logo">
                        <img class="img-fluid" src="/img/philip.png" alt="">
                    </div>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                    <div class="partners-logo">
                        <img class="img-fluid" src="/img/ingeo.png" alt="">
                    </div>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                    <div class="partners-logo">
                        <img class="img-fluid" src="/img/peter.png" alt="">
                    </div>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                    <div class="partners-logo">
                        <img class="img-fluid" src="/img/atom.png" alt="">
                    </div>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                    <div class="partners-logo">
                        <img class="img-fluid" src="/img/mosgortrans.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>-->
  <!-- c-partners -->
  <div class="partners">
    <div class="container">
      <div class="title">
        <p>НАШИМИ КЛИЕНТАМИ УЖЕ СТАЛИ</p>
      </div>
      <div class="partner">
        <div class="partner__logo" v-for="(item, index) in partners" :key="index">
          <img class="partner__logo-img" :src="item.logo" alt="">
        </div>
      </div>
    </div>

  </div>
</template>

<script>


export default {
  data() {
    return {
      partners: [
        {
          id: 0,
          name: 'ulmart',
          logo: '/img/partners/ulmart.png'
        },
        {
          id: 1,
          name: 'nexign',
          logo: '/img/partners/nexign.png'
        },
        {
          id: 2,
          name: 'baltika',
          logo: '/img/partners/baltika.png'
        },
        {
          id: 3,
          name: 'citylink',
          logo: '/img/partners/citylink.png'
        },
        {
          id: 4,
          name: 'reso',
          logo: '/img/partners/reso.png'
        },
        {
          id: 5,
          name: 'wclass',
          logo: '/img/partners/wclass.png'
        },
        {
          id: 6,
          name: 'major',
          logo: '/img/partners/major.png'
        },
        {
          id: 7,
          name: 'philip',
          logo: '/img/partners/philip.png'
        },
        {
          id: 8,
          name: 'ingeo',
          logo: '/img/partners/ingeo.png'
        },
        {
          id: 9,
          name: 'peter',
          logo: '/img/partners/peter.png'
        },
        {
          id: 10,
          name: 'atom',
          logo: '/img/partners/atom.png'
        },
        {
          id: 11,
          name: 'mosgortrans',
          logo: '/img/partners/mosgortrans.png'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
/* c-partners */
.partners {
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  background-color: rgb(248, 248, 248);

  .partner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .partner__logo {
      width: calc(100% / 12 * 2);
      margin: 1.2rem 0;
      padding: 0.625rem;
      text-align: center;

      @media screen and (max-width: 1200px) {
        width: calc(100% / 12 * 3);
      }

      .partner__logo-img {
        @media screen and (max-width: 1200px) {
          max-width: 100%;
          margin: 0;
        }
      }
    }
  }
}

.title {
  color: #0071ba;
  font-size: 36px;
  font-family: "HP Simplified";
  padding: 40px 0 0 0;
  text-align: center;

  @media screen and (max-width: 991px) {
    font-size: 25px;
    color: #000000;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
    color: #000000;
  }
}
/*.partners {
    background-color: #F8F8F8;
    padding: 1rem 0 3rem 0;
    margin: 2rem 0;

    .title {
        text-align: center;
        font-size: 2.5em;
        margin: 2rem 0;
    }

    .partners-logo {
        img {
            padding: 2rem;
        }
    }
}*/
</style>
