<template>
    <div id="basicMapView" class="container d-flex w-100">
        <!--div class="header" style="padding:25px;"></div-->
        <div id="serviceDetails">
            <div v-if="!detailsOpen">Выберите метку на карте, или введите СЦ</div>
            <!--div style="float:right; cursor:pointer" @click="detailsOpen = !detailsOpen">X</div-->
            <balloon
                    v-if="!!selectedFeature"
                    :featureData = "selectedFeature"
            ></balloon>
        </div>

        <div class="map-section">
            <yandex-map
                    ref="map"
                    :zoom="zoom"
                    :settings="settings"
                    :coords="location.coords"
                    :controls="['zoomControl']"
                    :scroll-zoom = false
                    @map-was-initialized="mapInitialized($event)"
                    style="width: 100%; height: 100%"
                    map-type="map"
            >
                <ymap-marker
                        v-for="(data) in features"
                        :key="data.ssc_id"
                        :marker-id="data.ssc_id"
                        :marker-type="options.markerType"
                        :hint-content="options.hintContent"
                        :icon="iconTemplate(data.ssc_id)"
                        :coords="markerCoord(data)"
                        :options="markerOptions(data.ssc_id)"
                        @click="markerClickAction(data)"
                >
                </ymap-marker>
            </yandex-map>


        </div>



    </div>
</template>

<script>
  import { yandexMap, ymapMarker } from 'vue-yandex-maps'
  import httpClient from "@/services/http.service.js"
  import { DxCheckBox } from 'devextreme-vue/check-box'
  import balloon from './__balloon'

  export default {
    name: "ServiceSearch",
    components: { yandexMap, ymapMarker, DxCheckBox, balloon },
    data () {
      const settings = {
        apiKey: '870eeb7b-3515-4a77-b4b5-a57f172731e4',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      };
      const options = {
        markerType:"placemark",
        hintContent:""
      };
      const balloonOptions = {
        headerIcon: "/images/balloones/top/top_icon.png"
      };

      return {
        options: options,
        settings: settings,
        mapInstance: null,
        dsCache: [],
        features: [],
        zoom: 4,
        location: {coords:[58.734251, 91.714046]},
        checked: {
          self:true,
          parthners:true,
          unauthorized:true
        },
        selfDomains: [],
        authorizedDomains: [],
        balloonOptions:balloonOptions,
        detailsOpen: false,
        selectedServiceCapture: null,
        selectedService: null
      }
    },

    computed: {
      markerOptions() {
        return (markerId) => {
          return {zIndex: this.selfDomains.includes(markerId) ? 2 : this.authorizedDomains.includes(markerId) ? 1 : 0}
        }
      },
      selectedFeature() {
        const featureCaption = this.selectedServiceCapture;
        if(this.features.length && featureCaption) {
          this.selectedService = this.features.find( (fd) => fd.ssc_capture.toLowerCase() === featureCaption.toLowerCase())
          if (this.selectedService) {
            return this.selectedService
          }
        }
      }
    },

    methods: {
      markerCoord (data) {
        return [data.ssc_latitude, data.ssc_longitude];
      },

      iconTemplate(markerId) {
        const serverName = location.origin;
        const isself = this.selfDomains.includes(markerId);
        const authorized = this.authorizedDomains.includes(markerId);

        const imageName = isself ? "icon_self.png" : authorized ? "marker_icon_green.png" : "marker_icon_pink.png";
        const imageHref = serverName + "/img/icons/" + imageName;
        const imageSize = isself ? [50, 50] : [40, 40];
        return {
          layout: 'default#image',
          imageHref: imageHref,
          imageSize: imageSize,
          imageOffset: [-20,-20],
        }
      },
      getZoom() {
        let width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        let zoomPoints = [
          [1,0,600],
          [2,601,1000],
          [3,1001,1600],
          [4,1601,10000],
        ];

        let zoom = zoomPoints.filter( (item) => {
          return item[1] <= width && width < item[2];
        } );

        return zoom[0][0]
      },
      valueChanged() {
        const checkedServices = this.checked;
        this.features = this.dsCache.filter ( (item) => {
          if (checkedServices.self !== true)
          {
            if(item.ssc_isself === "1" && item.authorisations > 0)
              return false
          }
          if (checkedServices.parthners !== true)
          {
            if(item.ssc_isself !== "1" && item.authorisations > 0)
              return false
          }
          if (checkedServices.unauthorized !== true)
          {
            if(item.authorisations === 0)
              return false
          }
          return true
        } )
      },
      markerClickAction(data) {
        this.detailsOpen = true;
        this.selectedServiceCapture = data.ssc_capture

      },
      updateMap() {
        const myMap = this.mapInstance;
        const ss = this.selectedService;

        if (!!this.selectedService && typeof myMap.setCenter !== "undefined") {
          myMap.setCenter([ss.ssc_latitude,ss.ssc_longitude]);
          myMap.setZoom(11);
        }

        return 0
      },
      mapInitialized (instance) {
        this.mapInstance = instance;
        this.updateMap();
      }
    },

    created () {
      const layout = {
        name: "Main"
      };
      this.$store.commit('setLayout', layout);
      this.zoom = this.getZoom()
    },

    async mounted() {
      const serviceCapture = this.$route.params.capture;
      let request = 'map/features';
      if(serviceCapture && serviceCapture.length) {
        this.selectedServiceCapture = serviceCapture;
        this.detailsOpen = true;
        request += '?capture='+serviceCapture;
      }

      const {status, data} = await httpClient.get(request);
      
      if (status === 200) {
        this.dsCache = data.filter( (f) => f.authKeys !== null);
        
        this.features = this.dsCache;
        this.selfDomains = this.dsCache
            .filter ( item => item.ssc_isself === "1")
            .map ( item => item.ssc_id );
        this.authorizedDomains = this.dsCache
            .filter ( item => !!item.authKeys)
            .map ( item => item.ssc_id )
      } else {
        console.log(status,data, error)
      }
    },
    watch: {
      selectedService () {
        if(!!this.mapInstance) {
          this.updateMap()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    #basicMapView {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 400px;
        padding: 20px 0;
    }

    #basicMapView:after {
        content: "";
        display: block;
        padding-bottom: 40%;
    }

    #basicMapView > div.map-section {
        z-index: 1;
        position: relative;
        display: block;
        height:62vh;
        width:62%;
    }
    .form.selectors {
        display: flex;
        flex-wrap: wrap;
        padding-bottom:10px;
        .selector {
            padding: 0 20px;
            display: flex;
            min-width: 150px;
        }
    }
    ::v-deep .dx-checkbox-icon {
        font-family: "DXIcons" !important;
    }
    ::v-deep .checkbox-not-authorized.dx-checkbox-checked .dx-checkbox-icon {

        &::before {
            background-color: #FFBC93 !important;
        }

    }
    ::v-deep .checkbox-authorized.dx-checkbox-checked .dx-checkbox-icon {

        &::before {
            background-color: #57CC65 !important;
        }

    }
    #serviceDetails {
        z-index: 1000;
        padding:10px;
        border: 0;
        border-radius: 10px 0 0 10px;
        box-shadow: 2px 0 5px rgba(0,0,0,.5);
        width: 38%;
        min-width: 400px;
        height: 62vh;
        overflow:auto;
        background: white;

        &::-webkit-scrollbar {
            display: none;
        }
    }
</style>