import './layouts/LayoutSlider/slideTemplates'

import Vue from 'vue'

import vueLayoutMain from "./layouts/LayoutMain/LayoutMain.vue"
import vueLayoutSlider from "./layouts/LayoutSlider/LayoutSlider.vue"
import vueLayoutNone from "./layouts/LayoutNone/LayoutNone.vue"
import vueBootstrapNav from "./bootstrap/nav.vue"
import titleComponent from './custom/Title.vue';

Vue.component('LayoutMain', vueLayoutMain)
Vue.component('LayoutSlider', vueLayoutSlider)
Vue.component('LayoutNone', vueLayoutNone)
Vue.component('BootstrapNav', vueBootstrapNav)
Vue.component('vue-title', titleComponent);
