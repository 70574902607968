<template>
    <div class="case-status-wrap">
        <h1 class="case-status-main">Проверка статуса заявки</h1>
        <div>

            <form
                id="case-status-form"
                @submit.prevent="sendAppealApc()"
            >

                <div class="dx-fieldset">
                    <!-- Номер заявки -->
                    Номер заявки <span style="color: red">*</span>
                    <div class="dx-field">
                        <div class="dx-field-value">
                            <DxTextBox
                                v-model="formData.appNum"
                            >
                                <DxValidator>
                                    <DxRequiredRule message="Пожалуйста, введите номер заявки"/>
                                    <DxPatternRule
                                        :pattern="appNumPattern"
                                        message="Номер заявки введён неверно"
                                    />
                                </DxValidator>
                            </DxTextBox>
                        </div>
                        <div>
                            <div
                                class="dx-field-tooltip"
                                id="q1"
                                @mouseenter="toggleNum"
                                @mouseleave="toggleNum"
                            >
                                <span>?</span>
                            </div>
                            <DxTooltip
                                :visible="numVisible"
                                :close-on-outside-click="false"
                                target="#q1"
                                position="right"
                            >
                                <div class="dx-tooltip-content">
                                    Укажите номер обращения на сайте <br>
                                    Rus.support, или номер заявки. <br>
                                    Номер заявки указан в письмах от <br>
                                    наших СЦ, или в сохранной <br>
                                    расписке, выданной при сдаче <br>
                                    оборудования в СЦ. <br>
                                </div>
                            </DxTooltip>
                        </div>

                    </div>
                    <!-- /.Номер заявки -->

                    <!-- Фамилия или серийный номер -->
                    Фамилия или серийный номер <span style="color: red">*</span>
                    <div class="dx-field">
                        <div class="dx-field-value">
                            <DxTextBox
                                v-model="formData.fioOrSerial"
                            >
                                <DxValidator>
                                    <DxRequiredRule message="Пожалуйста, фамилию или серийный номер"/>
                                    <DxPatternRule
                                        :pattern="fioOrSerialNumPattern"
                                        message="Фамилия или серийный номер введены неверно"
                                    />
                                </DxValidator>
                            </DxTextBox>
                        </div>
                        <div>
                            <div
                                class="dx-field-tooltip"
                                id="q2"
                                @mouseenter="toggleFio"
                                @mouseleave="toggleFio"
                            >
                                <span>?</span>
                            </div>
                            <DxTooltip
                                :visible="fioVisible"
                                :close-on-outside-click="false"
                                target="#q2"
                                position="right"
                            >
                                <div class="dx-tooltip-content">
                                    Укажите фамилию лица, <br>
                                    указанного как контакт по заявке, <br>
                                    или серийный номер устройства. <br>
                                    Это необходимо для контроля <br>
                                    доступа к информации по заявке. <br>
                                </div>
                            </DxTooltip>
                        </div>

                    </div>
                    <!-- /.Фамилия или серийный номер -->

                    <span class="dx-tooltip-note">Поля, отмеченные *, обязательны для заполнения</span>

                    <div class="form-content-bottom submit-area" align="center" style="padding: 10px 0 40px 0">
                        <DxButton
                            id="button"
                            :use-submit-behavior="true"
                            text="Отправить"
                            template="buttonTemplate"
                        >
                            <template #buttonTemplate="{ data }">
                                <div class="button-template hovered"
                                     style="transition: background-color 0.3s ease; background-color: #0a73bb; color: white; font-size: 1.5em; line-height: 2em; border:0; border-radius: 50px; padding: 0 50px;">
                                    {{ data.text }}
                                </div>
                            </template>
                        </DxButton>
                    </div>
                </div>

            </form>
        </div>
    </div>
</template>

<script>

import httpClient from "../../services/http.service";
import DxTextBox from 'devextreme-vue/text-box';
import DxButton from 'devextreme-vue/button';
import {
    DxValidator,
    DxRequiredRule,
    DxPatternRule,
} from 'devextreme-vue/validator';
// import notify from "devextreme/ui/notify";
import {DxTooltip} from "devextreme-vue/tooltip";

export default {
    name: "CaseStatusForm",
    data() {
        const gSiteKey = "6LepKg8cAAAAAKnQhjaelUMd9tKAP4XFxhi8inpJ";
        const contactTemplate = {
            appNum: String(),
            fioOrSerial: String(),
        };
        return {
            sitekey: gSiteKey,
            formData: contactTemplate,
            appNumPattern: '',
            fioOrSerialNumPattern: '',
            submitted: false,
            numVisible: false,
            fioVisible: false,
        }
    },
    components: {
        DxTextBox,
        DxValidator,
        DxRequiredRule,
        DxPatternRule,
        DxTooltip,
        DxButton,
    },
    methods: {
        handleSubmit() {
            this.$refs.recaptcha.execute();
        },
        toggleNum() {
            this.numVisible = !this.numVisible;
        },
        toggleFio() {
            this.fioVisible = !this.fioVisible;
        },
        clearForm() {
            this.$emit('restartCaseStatusForm');
        },
        async contact(recaptchaToken) {
            const {status, data} = await httpClient.post('/capcheck', {
                recaptchaToken: recaptchaToken
            });
            if (status === 200) {
                if (data.status === "success") {
                    this.sendAppealApc()
                } else {
                    console.log(status);
                }
            } else {
                this.$refs.recaptcha.reset()
            }
        },
        async sendAppealApc() {
            const {
                status,
                data
            } = await httpClient.get(`/order/case-status?num=${this.formData.appNum}&fioOrSerial=${this.formData.fioOrSerial}`, {
                formData: this.formData,
            });
            if (status === 200) {
                this.formData = {};
                this.submitted = true;
                this.$store.commit('setFoundApp', data)
                this.clearForm()
            } else {
                console.log(status, data)
            }
        },
    }
}
</script>

<style lang="scss" scoped>

.dx-tooltip-content {
    text-align: left;
    font-size: 16px;
}

.dx-tooltip-note {
    color: #aaa;
    font-size: 12px;
}

.dx-field {
    display: flex;

    .dx-field-tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        border: 1px solid #0a73bb;
        border-radius: 5px;
        width: 35px;
        height: 35px;
        cursor: pointer;

        &:hover {
            background-color: #fbffc6;
        }
    }
}

.case-status-main {
    text-align: center;
    text-transform: uppercase;
    padding: 50px 0 20px 0;
}

.case-status-wrap {
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#case-status-form {
    width: 350px;

    @media screen and (max-width: 350px) {
        width: 100%;
    }
}

::v-deep .dx-field-label {
    width: 100%;
    display: none;
}

::v-deep .dx-field-value {
    width: 100% !important;
}

::v-deep .dx-button {
    border-color: transparent !important;
    background-color: transparent !important;
}

::v-deep .dx-button:hover {
    background-color: transparent !important;
}

.button-template:hover {
    background-color: $bootstrap-green !important;
}
</style>