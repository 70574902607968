<template>
    <div id="departments">
        <div class="wrapper">
            <Map
                    v-if="displayType === 'map'"
                    :features="features"
                    :location="location"
                    :zoom="zoom"
                    :selectedServiceKeys="selectedServiceKeys"
                    :scToLoad="scToLoad"
            ></Map>
            <List
                    v-if="displayType === 'list'"
                    :features="features"
            ></List>
        </div>
    </div>
</template>

<script>
    import Map from "./dispalyViews/Map/Map";
    import List from "./dispalyViews/List/List";
    export default {
        name: "Departments",
        components: {List, Map},
        props: ["features", "location", "zoom", "displayType","selectedServiceKeys", "scToLoad"]
    }
</script>

<style scoped>
    #departments {
        position: relative;
        width: 100%;
      min-height: 500px;
    }

    #departments:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    #departments > div.wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
    }
</style>