import "axios";

const authService = {
    user: null,
    login () {
        return "login";
    },
    setUser() {
        return "setUser";
    },
    logout() {
        return "logout";
    },
    isLoggedIn() {
        return true;
    },
    getToken() {
        return "token";
    }
};

export default authService;
