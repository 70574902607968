<template>
    <div id="form-services" class="form-area" v-if="true">

        <div class="location-group form-item">
            <div class="input-area">

                <locationInput
                    :currentLocation="currentLocation"
                    @locationChanged="$emit('locationChanged', $event)"
                />
                <distanceSelect
                    :zoomMap="zoomMap"
                    :selectedDistance="selectedDistance"
                    @distanceSelected="$emit('distanceSelected', $event)"
                />

            </div>
        </div>

        <div id="first" class="switch">
            <displayTypeInput
                :selectedDisplayType="selectedDisplayType"
                @displayTypeChanged="$emit('displayTypeChanged', $event)"
            />

            <div class="checkboxes">
                <div class="checkboxes-field">
                    <DxCheckBox
                        :value="true"
                        text="СЦ для гарантийного обслуживания"
                        @valueChanged="$emit('changedWarranty', $event)"
                    />
                </div>
                <div class="checkboxes-field">
                    <DxCheckBox
                        :value="true"
                        text="СЦ для платного обслуживания"
                        @valueChanged="$emit('changedNonWarranty', $event)"
                    />
                </div>
            </div>
        </div>

        <div id="second" class="service-group form-item">
            <GroupedList
                :feat="featureAuth"
                :datasource="dsVendors"
                @servicesChanged="$emit('servicesChanged', $event)"
            />
        </div>
    </div>
</template>

<script>
import distanceSelect from './__distanceSelect'
import locationInput from './__locationInput'
import GroupedList from './GroupedList/GroupedList';
import displayTypeInput from "./__displayTypeInput";
import {DxCheckBox} from 'devextreme-vue/check-box';

export default {
    name: "Form",
    props: ["featureAuth", "dsVendors", "currentLocation", "zoomMap", "selectedDisplayType", "selectedDistance"],
    components: {
        distanceSelect, GroupedList, locationInput, displayTypeInput, DxCheckBox
    },
    data() {
        return {}
    },
    computed: {
        permutation() {
            let first = document.querySelector('#first')
            let second = document.querySelector('#second')

            return window.onresize = () => {
                first.after(second)
                first.style.marginBottom = '0'
                if (document.documentElement.clientWidth <= 992) {
                    second.after(first)
                    first.style.marginBottom = '20px'
                }
            }
        }
    },
    mounted() {
        this.permutation()
    }
}
</script>

<style lang="scss" scoped>
#form-services {
    padding: 0;

    .service-group {
        position: relative;

    }

    .location-group {
        border: 2px solid #0171bb;
        border-radius: 15px;

        @media screen and (max-width: 330px) {
            font-size: 13px;
        }

        .input-area {
            width: 100%;
            height: 55px;
            //border: 2px solid blue;
            border-radius: 15px;
            display: flex;

            @media screen and (max-width: 1399px) {
                height: 50px;
            }
        }

        @media screen and (max-width: 475px) {
            .input-area {
                height: 50px;
            }
        }

        @media screen and (max-width: 360px) {
            .input-area {
                height: 45px;
            }
        }

        .service-group.form-item:first-child {

        }
    }
}


</style>