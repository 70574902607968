<template>
  <div class="terms-accordion__item-content">
    <p>4.1. Для оказания сервисного обслуживания СЦ принимает от Заказчика Заявку и создаёт (регистрирует) в сервисной
      системе СЦ соответствующую запись с уникальным номером (далее «Номер Заявки»), содержащую всю информацию о ходе
      сервисного обслуживания.</p>
    <p>4.2. Сервисное обслуживание производятся по заявленной Заказчиком при регистрации Заявки неисправности или
      заявленной задаче. Обнаружение неисправностей, не связанных с заявленной проблемой, может произойти в ходе работ,
      но не гарантируется.</p>
    <p>4.3. Узнать о текущем статусе диагностики или ремонта оборудования Заказчик может на сайте <a
        href="http://rus.support/">http://rus.support/</a> или обратившись в СЦ осуществляющий ремонт, или в
      контакт-центр Исполнителя, предоставив номер Заявки и ФИО Заказчика (или его представителя), указанного при
      регистрации Заявки.</p>
    <p>4.4. СЦ не несёт ответственности за возможную потерю пользовательских данных, настроек, программного обеспечения,
      информации и т.п., хранимых во внутренней памяти оборудования, а также на съёмных носителях, связанных с заменой
      запасных частей или обновлением микрокода и программного обеспечения.</p>
    <p>4.5. Об изменениях в Заявке (в том числе о результатах диагностики, завершении работ, возникновении необходимости
      проведения дополнительных работ, необходимости прислать документы о покупке, изменениях сроков или стоимости
      ремонта) Заказчик информируется по телефонным номерам и электронной почте, указанным в Заявке. СЦ производит 3
      попытки установления контакта с Заказчиком, с интервалом между попытками не менее чем в один рабочий день. Если в
      ходе этих попыток СЦ не удаётся связаться с Заказчиком, то обязанности СЦ по информированию Заказчика считаются
      выполненными и обязанности по дальнейшей попытке установить контакт возлагаются на самого Заказчика.</p>
    <p>4.6. Если настройки оборудования требуют ввода пароля при включении, или для доступа к изменению настроек, то
      Заказчик обязан предоставить сотруднику СЦ пароли или отключить данные настройки. В противном случае СЦ не несет
      ответственности за задержку и/или невыполнение заявки.</p>
    <p>4.7. Если для диагностики неисправности и проверки работоспособности оборудования требуются расходные материалы
      (например, картриджи, бумага, и т.п.), то Заказчик обязан предоставить их вместе с оборудованием, или по запросу
      от СЦ (в указанном случае срок выполнения продляется соразмерно времени предоставления таких материалов).</p>
  </div>
</template>

<script>
export default {
  name: "provisionServices2017"
}
</script>

<style scoped>

</style>