<template>
  <div class="vendor-page-slide-logo">
    <img class="img-fluid" src="/img/logo/samsung-logo-white.png" alt="">
  </div>
</template>

<script>
export default {
  name: "samsungSliderContent"
}
</script>

<style lang="scss" scoped>
.vendor-page-slide-logo {
  text-align: center;
}
</style>