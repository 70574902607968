<template>
    <commonFooter/>
</template>

<script>
import commonFooter from "../common/footer/commonFooter"

export default {
    name: "pageFooter",
    components: {commonFooter}
}
</script>

<style scoped>

</style>