<template>
    <div class="tutorials">
        <div class="tutorials-wrap" v-if="showForm">
            <form
                class="tutorials-form"
                action=""
                @submit="onFormSubmit($event)"
            >
                <div class="dx-fieldset">
                    <div class="dx-field">
                        <div class="dx-field-label">Для доступа к контенту введите пароль</div>
                        <div class="dx-field-value">
                            <DxTextBox mode="password" placeholder="Пароль">
                                <DxValidator>
                                    <DxRequiredRule message="Пароль обязателен для ввода"/>
                                    <DxCompareRule
                                        :comparison-target="passwordComparison"
                                        message="Неверный пароль"
                                    />
                                </DxValidator>
                            </DxTextBox>
                        </div>

                    </div>
                    <div class="button">
                        <DxValidationSummary id="summary"/>
                        <DxButton
                            id="button"
                            :use-submit-behavior="true"
                            text="Подтвердить"
                            type="success"
                        />
                    </div>
                </div>
            </form>
        </div>
        <TutorialsContent v-if="showContent"/>
    </div>
</template>
<script>

import DxTextBox from 'devextreme-vue/text-box';
import DxButton from 'devextreme-vue/button';
import DxValidationSummary from 'devextreme-vue/validation-summary';
import {
    DxValidator,
    DxRequiredRule,
    DxCompareRule,
} from 'devextreme-vue/validator';

import notify from 'devextreme/ui/notify';

import TutorialsContent from "./TutorialsContent";

const buttons = [];

const slides = [
    {
        background: "/img/page-slider/main_page_1.jpg",
        template: "hpSlideContent",
        buttons: buttons
    },
];

export default {
    name: "Tutorials",
    data() {
        return {
            password: '!tutor789',
            showContent: false,
            showForm: true,
            dsVendors: [],
            slides: slides
        };
    },
    created() {
        const layout = {
            name: "Main",
            data: this.slides
        };
        this.$store.commit('setLayout', layout)
    },
    components: {
        DxTextBox,
        DxButton,
        DxValidator,
        DxRequiredRule,
        DxCompareRule,
        DxValidationSummary,
        TutorialsContent
    },
    methods: {
        passwordComparison() {
            return this.password;
        },
        onFormSubmit(e) {
            if (this.password) {
                this.showContent = true
                this.showForm = false
            }
            notify({
                message: 'Вы успешно вошли',
                position: {
                    my: 'center bottom',
                    at: 'center bottom'
                }
            }, 'success', 3000);

            e.preventDefault();
        }
    }
};
</script>
<style lang="scss" scoped>
.tutorials {
    min-height: 450px;
    position: relative;

    .tutorials-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 450px;


        .tutorials-form {
            max-width: 300px;
        }
    }
}

#summary {
    padding-left: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

#button {
    display: block;
}

.dx-field-value-static, .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
    width: 100%;
}

.dx-field-label {
    float: left;
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #0073c0;
    cursor: default;
    padding: 8px 15px 9px 0;
}

::v-deep .dx-placeholder {
    position: absolute;
    top: 0;
    left: 38%;
    max-width: 100%;
    width: auto;
    height: 100%;

    cursor: text;
    pointer-events: none;
    color: #495057;
}

::v-deep .dx-field input, .dx-field textarea {
    font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
    line-height: 1.35715;
    text-align: center;
}

.dx-texteditor.dx-editor-outlined {
    background: #fff;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #0073c0;
}

.dx-button-mode-contained.dx-button-success {
    background-color: #ffffff;
    color: #0073c0;
    border-radius: 50px;
    border: 2px solid #0073c0;

    &:hover {
        background-color: #0073c0;
        color: #ffffff;
    }

    &:focus {
        background-color: #0073c0;
        color: #ffffff;
    }
}

</style>