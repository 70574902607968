<template>
    <div class="hp">
        <!-- Content start -->
        <div class="container list-service-wrap">
            <InstructionContent/>
        </div>
        <!-- Content end -->
    </div>
</template>

<script>

import InstructionContent from "@/pages/About/Instruction/InstructionContent";

export default {
    name: "Instruction",
    created() {
        const layout = {
            name: "Main",
            data: this.slides
        };
        this.$store.commit('setLayout', layout)

        console.log(this.$route.params.sc, this.$store.state.serviceCenters)
    },
    components: {
        InstructionContent
    }
}
</script>

<style scoped>

</style>