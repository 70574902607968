<template>
  <div class="terms-accordion__item-content">
    <p>3.1. СЦ обязан выполнить сервисное обслуживание оборудования Заказчика, на условиях, предусмотренных данным
      документом («Условия сервисного обслуживания») и законодательством РФ.</p>
    <p>3.2. Заказчик обязан принять выполненные СЦ работы.</p>
    <p>3.3. В случае, если какие-либо услуги, выполняемые в ходе сервисного обслуживания, не являются бесплатными,
      Заказчик обязан оплатить СЦ стоимость таких услуг в порядке и на условиях, предусмотренных настоящим документом и
      прейскурантом СЦ.</p>
    <p>3.4. В случае, если какие-либо запасные части (детали) или материалы, используемые в ходе сервисного
      обслуживания, не являются бесплатными, Заказчик обязан оплатить СЦ стоимость таких запчастей и материалов в
      порядке и на условиях, предусмотренных настоящим документом, и по стоимости, определенной Сервисным Центром.</p>
  </div>
</template>

<script>
export default {
  name: "rightsContent2017"
}
</script>

<style scoped>

</style>