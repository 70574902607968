<template>
  <div>
    <!--
    {{ dsTitles }}
    -->
  </div>
</template>

<script>
  import httpClient from "@/services/http.service.js";
  
  const DEFAULT_TITLE = 'RUS.SUPPORT';
  const DEFAULT_DELIMITER = ' — ';

  export default {
    name: 'vue-title',
    watch: {
      $route: {
        immediate: true,
        handler(to, from) {
          document.title = DEFAULT_TITLE;
          if(typeof(to.path) !== 'undefined') {
            this.url = to.path;
          }
          if(typeof(to.meta.title) !== 'undefined') {
            document.title = to.meta.title + DEFAULT_DELIMITER + DEFAULT_TITLE;
          }
        }
      },
    },
    data() {
      return {
      	url: '',
      	dsTitles: [],
      }
    },
    async mounted() {
      let error = null;
      const {status, data} = await httpClient.get('web/titles');
      if (status === 200) {
        this.dsTitles = data;
        try {
        	for(let objTitle of this.dsTitles) {
        		if(objTitle.route !== this.url) {
        			continue;
        		}
        		document.title = objTitle.title + DEFAULT_DELIMITER + DEFAULT_TITLE;
        	}
        } catch(e) {}
      } else {
        console.error(status, data, error);
      }
    },
  }
</script>