<template>
    <div id="header">
        <div class="wrap wrap-max">
            <div class="header-container container ">
                <div class="flex-wrap d-flex justify-content-between">
                    <div class="link-home height" align="left">
                        <link-home/>
                    </div>
                    <div class="link-sponsors d-none d-md-block d-lg-block d-xl-block height">
                        <link-sponsors/>
                    </div>
                    <div class="link-mobile d-md-none d-lg-none d-xl-none height">
                        <link-mobile/>
                    </div>
                    <div ref="headerContainer"
                         class="header-menu height d-flex align-items-center justify-content-center">
                        <header-menu
                            :wedoShow="wedoShow"
                            @wedoOpen="wedoOpenAction"
                        />
                        <!--:scrollTop="scrollTop"-->
                    </div>
                </div>

                <div class="wedo-container">
                    <transition name="fade">
                        <WeDoMenu v-if="wedoShow" @toggleClick="wedoShow = false"/>
                    </transition>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import linkHome from '../common/linkHome'
import linkMobile from '../common/linkMobile'
import linkSponsors from '../common/linkSponsors'
import headerMenu from '../common/headerMenu'
import WeDoMenu from "../common/header/WeDoMenu";

export default {
    name: "pageHeader",
    data() {
        return {
            wedoShow: false,
            isMounted: false
        }
    },
    props: ["headerSize"],
    computed: {
        headerContainer() {
            if (!this.isMounted) return
            return this.$refs["headerContainer"]
        }
    },
    methods: {
        wedoOpenAction() {
            this.wedoShow = !this.wedoShow
        }
    },
    components: {
        linkHome,
        headerMenu,
        linkSponsors,
        linkMobile,
        WeDoMenu
    },
    watch: {
        headerContainer(val) {

        },
    },
    mounted() {
        this.isMounted = true
        this.$nextTick(() => {
        })
    }
}
</script>

<style lang="scss" scoped>
#header {
    .wrap {
        //padding: 15px 0;
        background-color: $base-color;

        &.wrap-max {
            width: 100%;
            height: 100%;
        }

        .header-container {
            min-height: 70px;
        }
    }

    .height {
        min-height: 80px;
    }

    .header-container {
        position: relative;

        .flex-wrap {
            flex-wrap: wrap;
        }

        .flex-wrap .link-home {
            flex: 1 1 227px;
        }

        .flex-wrap .link-sponsors {
            flex: 2 2 420px;
        }

        .flex-wrap .header-menu {
            flex: 3 3 260px;
        }

        .flex-wrap .link-mobile {
            flex: 3 3 140px;
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

</style>