<template>
    <div class="serviceLocatorWrapper">
        <div id="serviceLocator" class="container-lg">
            <div class="title" align="center">
                <p id="how-find" class="title scroll-title" @click="scrollToMapSelector()">НАЙТИ БЛИЖАЙШИЙ СЕРВИСНЫЙ
                    ЦЕНТР</p>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <Form
                        :dsVendors="dsVendors"
                        :currentLocation="selectedLocation"
                        :zoomMap="distanceZoomMap"
                        :selectedDistance="selectedDistance"
                        :selectedDisplayType="selectedDisplayType"
                        @servicesChanged="servicesChanged($event)"
                        @distanceSelected="distanceSelected($event)"
                        @locationChanged="locationChanged($event)"
                        @displayTypeChanged="displayTypeChanged($event)"

                        @changedWarranty="warranty"
                        @changedNonWarranty="nonWarranty"
                    ></Form>
                </div>
                <div class="col-lg-8 department-height">
                    <Departments
                        :key="componentKey"
                        :features="chosenFeatures"
                        :location="selectedLocation"
                        :zoom="selectedDistance.zoom"
                        :displayType="selectedDisplayType"
                        :selectedServiceKeys="selectedServiceKeys"
                        :scToLoad="scToLoad"
                    ></Departments>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import httpClient from "@/services/http.service.js"
import Departments from "./Departments/Departments"
import Form from "./Form/Form"

export default {
    name: "ServiceLocator",
    props: ["dsVendors", "scToLoad"],
    components: {Departments, Form},
    data() {
        let distanceZoomMap = [
            {'distance': 5, 'zoom': 12},
            {'distance': 10, 'zoom': 11},
            {'distance': 20, 'zoom': 10},
            {'distance': 50, 'zoom': 9},
            {'distance': 90, 'zoom': 8},
            {'distance': 180, 'zoom': 7},
            {'distance': 370, 'zoom': 6},
        ]
        return {
            distanceZoomMap: distanceZoomMap,
            features: [],
            getFeatures: [],
            selectedServiceKeys: [],
            selectedDistance: distanceZoomMap[1],
            selectedLocation: {
                coords: ["59.938630000000003", "30.314129999999999"],
                name: "Санкт-Петербург"
            },
            selectedDisplayType: "map",
            componentKey: 0,
            autoZoom: true,
        //     ---
            warr: false,
            nowarr: false,
            arr: [],
            wnow: true
        }
    },
    created() {
        this.getFeatures = this.$store.state.serviceCenters;
    },
    computed: {
        warrantyInfo() {
            return this.$store.state.warranty
        },
        nonWarrantyInfo() {
            return this.$store.state.non_warranty
        },
        fullWarr() {
            if (this.$store.state.warranty === true && this.$store.state.non_warranty === true) {
                return true
            }
        },
        fullNoWarr() {
            if (this.$store.state.warranty === false && this.$store.state.non_warranty === false) {
                return false
            } else {
                return true
            }
        },
        chosenFeatures() {
            let sortedByDistance = [];
            const selectedServiceKeys = this.selectedServiceKeys;
            // const selectedLocation = this.selectedLocation;
            // console.log('ключи',selectedServiceKeys);
            let filteredCenters = this.features.filter(function (feature) {
                if (feature.authKeys === null) return false;

                // const featureAddress = feature.ssc_address.split(' ');

                const featureAuthKeys = feature.authKeys.split(',');

                // if (feature.authKeys === null) return false;

                // if (!featureAddress.includes(selectedLocation.name)) return false;

                return featureAuthKeys.some(r => selectedServiceKeys.includes(r))
            });
            // console.log('Выбрано', temp);
            let arr = [];
            for (let i = 0; i < filteredCenters.length; i++) {
                let count = 0;
                const keys = filteredCenters[i].authKeys.split(',');
                for (let j = 0; j < selectedServiceKeys.length; j++) {
                    if (keys.indexOf(selectedServiceKeys[j]) !== -1) {
                        count++;
                    }
                }
                if (count === selectedServiceKeys.length) {
                    arr.push(filteredCenters[i]);
                }
            }
            for (let i = 0; i < arr.length; i++) {
                let distance = this.getDistance(arr[i]);
                let radius;
                if (this.selectedDistance.distance <= 50) {
                    radius = 50
                } else {
                    radius = this.selectedDistance.distance;
                }
                if (distance <= radius) {
                    sortedByDistance.push(arr[i]);
                }
            }
            sortedByDistance.sort((a, b) => a.from_centre - b.from_centre);
            // console.log(this.autoZoom);
            if (this.autoZoom) {
                this.setZoom(sortedByDistance);
            }
            sortedByDistance.sort((a, b) => b.ssc_isself - a.ssc_isself);
            // console.log(sortedByDistance);
            return sortedByDistance;
        }
    },
    methods: {
        scrollToMapSelector() {
            let clientWidthWindow = document.documentElement.clientWidth
            let fromService = document.getElementById('form-services')
            let targetElem = document.querySelector('.scroll-title')

            if (clientWidthWindow > 991)
                targetElem.scrollIntoView({block: "start", inline: "nearest"})
            else
                fromService.scrollIntoView({block: "center", behavior: "smooth"})
        },
        forceRerender() {
            this.componentKey += 1;
        },
        setZoom(arr) {
            if (arr.length > 0) {
                const maxDist = arr[arr.length - 1].from_centre;
                // console.log(maxDist);
                for (let i = 0; i < this.distanceZoomMap.length; i++) {
                    // console.log(this.distanceZoomMap[i].distance);
                    if (this.distanceZoomMap[i].distance <= maxDist) {
                        if (i < this.distanceZoomMap.length - 1) {
                            if (maxDist <= this.distanceZoomMap[i + 1].distance) {
                                this.selectedDistance = this.distanceZoomMap[i + 1];
                                // console.log(this.distanceZoomMap[i+1].distance);
                            }
                        }
                    }
                }
            }
        },
        getDistance(sc) {
            let f1 = sc.ssc_latitude * Math.PI / 180;
            let f2 = this.selectedLocation.coords[0] * Math.PI / 180;
            let d1 = sc.ssc_longitude * Math.PI / 180;
            let d2 = this.selectedLocation.coords[1] * Math.PI / 180;
            let distance = Math.acos((Math.sin(f1) * Math.sin(f2)) + Math.cos(f1) * Math.cos(f2) * Math.cos(Math.abs(d1 - d2)));
            distance = distance * 6372795 / 1000;
            sc.from_centre = distance;
            return distance;
        },
        servicesChanged(selectedKeys) {
            this.selectedServiceKeys = selectedKeys;
        },
        distanceSelected(distance) {
            this.autoZoom = false;
            this.selectedDistance = distance;
            // this.forceRerender();
        },
        locationChanged(location) {
            this.autoZoom = true;
            this.selectedLocation = location;
            this.selectedDistance = this.distanceZoomMap[0];
            this.forceRerender();
        },
        displayTypeChanged(displayType) {
            this.selectedDisplayType = displayType
        },
        warranty(e) {
            this.$store.commit('setWarranty', e.value)
        },
        nonWarranty(e) {
            this.$store.commit('setNonWarranty', e.value)
        },
        recalWarrantyServiceCenters() {
            if (this.warrantyInfo) {
                let arr = []
                for (let i = 0; i < this.getFeatures.length; i++) {
                    for (let e = 0; e < this.selectedServiceKeys.length; e++) {
                        if (this.getFeatures[i].ssc_works[this.selectedServiceKeys[e]] === undefined) continue
                        if (this.getFeatures[i].ssc_works[this.selectedServiceKeys[e]]["sca_sdau_warranty"] === '1') {
                            arr.push(this.getFeatures[i])
                        }
                    }
                }
                this.warr = true
                this.features = arr
            } else if (this.warrantyInfo === false && this.nonWarrantyInfo === true) {
                this.recalWarrantyNonServiceCenters()
            } else {
                this.warr = false
                this.features = this.getFeatures
            }
        },
        recalWarrantyNonServiceCenters() {
            if (this.nonWarrantyInfo) {
                let arr = []
                for (let i = 0; i < this.getFeatures.length; i++) {
                    for (let e = 0; e < this.selectedServiceKeys.length; e++) {
                        if (this.getFeatures[i].ssc_works[this.selectedServiceKeys[e]] === undefined) continue
                        if (this.getFeatures[i].ssc_works[this.selectedServiceKeys[e]]["sca_sdau_non_warranty"] === '1') {
                            arr.push(this.getFeatures[i])
                        }
                    }
                }
                this.nowarr = true
                this.features = arr
            } else if (this.nonWarrantyInfo === false && this.warrantyInfo === true) {
                this.recalWarrantyServiceCenters()
            } else {
                this.nowarr = false
                this.features = this.getFeatures
            }
        },
        recalWarrNonWarr() {
            if (this.warrantyInfo === true && this.nonWarrantyInfo === true) {
                let arr = []
                for (let i = 0; i < this.getFeatures.length; i++) {
                    for (let e = 0; e < this.selectedServiceKeys.length; e++) {
                        if (this.getFeatures[i].ssc_works[this.selectedServiceKeys[e]] === undefined) continue
                        if (this.getFeatures[i].ssc_works[this.selectedServiceKeys[e]]["sca_sdau_warranty"] === '1' || this.getFeatures[i].ssc_works[this.selectedServiceKeys[e]]["sca_sdau_non_warranty"] === '1') {
                            arr.push(this.getFeatures[i])
                        }
                    }
                }
                this.features = arr
            } else {
                this.features = this.getFeatures
            }
        }
    },

    async mounted() {
        if (this.getFeatures.length !== 0) {
            this.features = this.getFeatures
        } else {
            let error = null
            const {status, data} = await httpClient.get('map/features');
            if (status === 200) {
                this.features = data
            } else {
                console.log(error)
            }
        }

        // отправка к карте при переходе с другой страницы
        let howFind = this.$router.currentRoute.hash.replace("#", "");
        if (howFind)
            this.$nextTick(() => window.document.getElementById(howFind).scrollIntoView());
    },
    watch: {
        selectedServiceKeys: function () {
            if (this.warrantyInfo) {
                this.recalWarrantyServiceCenters()
            }
            if (this.nonWarrantyInfo) {
                this.recalWarrantyNonServiceCenters()
            }
            if (this.fullWarr) {
                this.recalWarrNonWarr()
            }
        },
        warrantyInfo: function () {
            this.recalWarrantyServiceCenters()
        },
        nonWarrantyInfo: function () {
            this.recalWarrantyNonServiceCenters()
        },
        fullWarr: function (e) {
            if (e === true) {
                this.recalWarrNonWarr()
            } else {
                return false
            }
        },
        fullNoWarr: function (e) {
            if (e === false) {
                this.wnow = false
                this.selectedServiceKeys = []
            } else {
                this.wnow = true
                this.selectedServiceKeys = this.arr
            }
        }
    },
}

</script>

<style lang="scss" scoped>
.serviceLocatorWrapper {
    #serviceLocator {
        height: 827px;

        .department-height {
          min-height: 500px;
        }

        @media screen and (max-width: 1400px) {
            height: 750px;
        }

        @media screen and (max-width: 1200px) {
            height: 650px;
        }

        @media screen and (max-width: 991px) {
            height: auto;
        }

        * {
            font-family: "HP Simplified" !important;
        }
    }
}

.title {
    color: #0071ba;
    font-size: 36px;
    font-family: "HP Simplified";
    padding: 30px 0 20px 0;
    text-align: center;

    &:hover {
        cursor: pointer;
    }

    @media screen and (max-width: 991px) {
        font-size: 25px;
        color: #000000;
        padding: 20px 0 10px 0;
    }

    @media screen and (max-width: 767px) {
        font-size: 16px;
        color: #000000;
    }
}
</style>