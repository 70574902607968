<template>
  <div id="pageHome">
    <ServiceLocator
        v-if="dsVendors.length"
        :open-on-load="openScOnLoad"
        :sc-to-load="gettingCenter"
        :dsVendors="dsVendors"
    />
    <div class="container">
      <Partners/>
      <Advantages/>
    </div>
  </div>
</template>

<script>
import ServiceLocator from "./ServiceLocator/ServiceLocator"
import httpClient from "@/services/http.service.js"
import Advantages from "./Advantages";
import Partners from "./Partners";

let btnSize = 1;
let clientScreenSize = document.documentElement.clientWidth

function btnResize() {
  if (clientScreenSize <= 768) {
    btnSize = 0.7
  }
}

btnResize()

const buttons = [
  {
    link: "link",
    customClass: "class",
    size: btnSize,
    // size: "1",
    sign: "?",
    caption: "ПРОВЕРИТЬ СТАТУС ЗАЯВКИ",
    formType: "/case-status",
  },
  {
    link: "link",
    customClass: "class",
    size: btnSize,
    // size: "1",
    sign: "+",
    caption: "ОФОРМИТЬ НОВУЮ ЗАЯВКУ",
    formType: "create-order",
  },
  {
    link: "link",
    customClass: "class",
    size: btnSize,
    // size: "1",
    sign: "!",
    caption: "ОТЗЫВ В СЛУЖБУ КАЧЕСТВА",
    formType: "feedback",
  }
];

const slides = [
  {
    background: "/img/page-slider/main_page_1.jpg",
    template: "homeSlide1Content",
    buttons: buttons
  },
  {
    background: "/img/page-slider/main_page_2.jpg",
    template: "homeSlide2Content",
    buttons: buttons
  },
];

export default {
  name: "Home.vue",
  components: {
    ServiceLocator,
    Advantages,
    Partners
  },
  data() {
    return {
      dsVendors: [],
      getVendors: [],
      slides: slides,
      gettingCenter: null,
      openScOnLoad: false,
    }
  },
  computed: {
    serviceCenters() {
      return this.$store.state.serviceCenters
    },
  },

  created() {
    const layout = {
      name: "Slider",
      data: this.slides
    };
    this.$store.commit('setLayout', layout);
    this.getVendors = this.$store.state.serviceVendors;
  },

  async mounted() {
    if (this.getVendors.length !== 0) {
      this.dsVendors = this.getVendors
    } else {
      let error = null;
      const {status, data} = await httpClient.get('map/vendors');
      if (status === 200) {
        this.dsVendors = data.filter((e) => {
          return !!e["authorisations"].length
        })
      } else {
        console.log(error)
      }
    }
  },

  watch: {
    serviceCenters(dataArray) {
      const scRequest = this.$route.params.sc

      if (!scRequest) return;
      const gettingCenter = dataArray.find((item) => item.ssc_capture === scRequest);

      this.gettingCenter = gettingCenter || null;
    },
  }
}
</script>

<style lang="scss">

.b-title {
  color: #0071ba;
  font-size: 36px;
  font-family: "HP Simplified";
  padding: 50px 0 30px 0;
  text-align: center;

  @media screen and (max-width: 991px) {
    font-size: 25px;
    color: #000000;
    padding: 40px 0 0 0;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
    color: #000000;
  }
}

.b-component {

}
</style>