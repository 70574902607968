<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      ГАРАНТИЙНЫЙ РЕМОНТ
      ПРИНТЕРОВ И МФУ SAMSUNG
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Сеть сервисных центров Rus.support имеет авторизацию на ремонт печатной техники Samsung
    </p>
  </div>
</template>

<script>
export default {
  name: "samsungGuaranteeSliderContent"
}
</script>

<style scoped>

</style>