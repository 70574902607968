<template>
    <div class="container list-service-wrap">
        <AvisionContent />
    </div>
</template>

<script>
import AvisionContent from './AvisionContent.vue';

const buttons = [];
const slides = [
    {
        background: '/img/page-slider/main_page_1.jpg',
        template: 'avisionSlideConent',
        buttons: buttons,
    },
];

export default {
    name: 'Avision',
    data() {
        return {
            dsVendors: [],
            slides: slides,
        };
    },
    created() {
        const layout = {
            name: 'Slider',
            data: this.slides,
        };
        this.$store.commit('setLayout', layout);
    },
    components: {
        AvisionContent,
    },
};
</script>

<style scoped lang="scss"></style>
