<template>
  <div class="row py-5">
    <div class="caption">
      <p class="caption__title">
        ПРОЧИЕ УСЛУГИ
      </p>
    </div>
    <!-- /.caption -->

    <ul class="list-link">
      <li class="list-link__item"></li>
      <li class="list-link__item" v-for="(service, index) in otherServices" :key="index">
        <router-link class="list-link__link" :to="service.pathLink">
          {{ service.name }}
        </router-link>
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  name: "OtherServiceContent",
  data() {
    return {
      otherServices: [
        {
          name: 'АУТСОРСИНГ ИТ-УСЛУГ',
          pathLink: '/about/others_outsource'
        },
        {
          name: 'СБОРКА И ПОСТАВКА СЕРВЕРОВ ПОД ЗАКАЗ',
          pathLink: '/about/others_servers'
        },
        {
          name: 'ОБСЛУЖИВАНИЕ ИБП APC',
          pathLink: '/about/others_apc'
        },
        {
          name: 'КУРСЫ ДЛЯ СЕРВИСНЫХ ИНЖЕНЕРОВ И МЕРОПРИЯТИЯ ДЛЯ КЛИЕНТОВ',
          pathLink: '/about/others_courses'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.paragraph {
  text-align: left;
}

.list-link {
  max-width: calc(100% / 12 * 6);

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
</style>