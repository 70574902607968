<template>
    <div id="basicMapView">
        <div class="header" style="padding:25px;"></div>
        <div class="form selectors">
            <div class="selector">
                <DxCheckBox :value="true" v-model="checked.self" @valueChanged="valueChanged"/>
                <div class="label" style="padding:0 10px">
                    <span> Собственные СЦ (авторизованные)</span>
                </div>
            </div>
            <div class="selector">
                <DxCheckBox class="checkbox-authorized" :value="true" v-model="checked.parthners" @valueChanged="valueChanged"/>
                <div class="label" style="padding:0 10px">
                    <span> Партнерские СЦ (авторизованные) </span>
                </div>
            </div>
            <div class="selector">
                <DxCheckBox class="checkbox-not-authorized" :value="true" v-model="checked.unauthorized" @valueChanged="valueChanged"/>
                <div class="label" style="padding:0 10px">
                    <span>&nbsp;Партнерские СЦ (проектные, не авторизованные)</span>
                </div>
            </div>
        </div>

        <div class="map-section">
            <yandex-map
                    :zoom="zoom"
                    :settings="settings"
                    :coords="location.coords"
                    :controls="['zoomControl']"
                    :scroll-zoom = false
                    style="width: 100%; height: 100%"
                    map-type="map"
            >
                <ymap-marker
                        v-for="(data) in features"
                        :key="data.ssc_id"
                        :marker-id="data.ssc_id"
                        :marker-type="options.markerType"
                        :hint-content="options.hintContent"
                        :icon="iconTemplate(data.ssc_id)"
                        :coords="markerCoord(data)"
                        :options="markerOptions(data.ssc_id)"
                >
                </ymap-marker>
            </yandex-map>
        </div>
    </div>
</template>

<script>
  import { yandexMap, ymapMarker } from 'vue-yandex-maps'
  import httpClient from "@/services/http.service.js"
  import { DxCheckBox } from 'devextreme-vue/check-box'

  const slides = [
    {
      background: "",
      name: "slide1"
    },
  ];

  export default {
    name: "ServiceMap",
    components: { yandexMap, ymapMarker, DxCheckBox },
    data () {
      const settings = {
        apiKey: '870eeb7b-3515-4a77-b4b5-a57f172731e4',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      };
      const options = {
        markerType:"placemark",
        hintContent:""
      };

      return {
        options: options,
        settings: settings,
        mapInstance: null,
        dsCache: [],
        features: [],
        zoom: 4,
        location: {coords:[58.734251, 91.714046]},
        checked: {
          self:true,
          parthners:true,
          unauthorized:true
        },
        selfDomains: [],
        authorizedDomains: []
      }
    },

    computed: {
      markerOptions() {
        return (markerId) => {
          return {zIndex: this.selfDomains.includes(markerId) ? 2 : this.authorizedDomains.includes(markerId) ? 1 : 0}
        }
      },
    },

    methods: {
      markerCoord (data) {
        return [data.ssc_latitude, data.ssc_longitude];
      },

      iconTemplate(markerId) {
        const serverName = location.origin;
        const isself = this.selfDomains.includes(markerId);
        const authorized = this.authorizedDomains.includes(markerId);

        const imageName = isself ? "icon_self.png" : authorized ? "marker_icon_green.png" : "marker_icon_pink.png";
        const imageHref = serverName + "/img/icons/" + imageName;
        const imageSize = isself ? [50, 50] : [40, 40];
        return {
          layout: 'default#image',
          imageHref: imageHref,
          imageSize: imageSize,
          imageOffset: [-20,-20],
        }
      },
      getZoom() {
        let width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        let zoomPoints = [
          [1,0,600],
          [2,601,1000],
          [3,1001,1600],
          [4,1601,10000],
        ];

        let zoom = zoomPoints.filter( (item) => {
          return item[1] <= width && width < item[2];
        } );

        return zoom[0][0];
      },
      valueChanged() {
        const checkedServices = this.checked
        this.features = this.dsCache.filter ( (item) => {
          if (checkedServices.self !== true)
          {
            if(item.ssc_isself === "1" && item.authorisations > 0)
              return false
          }
          if (checkedServices.parthners !== true)
          {
            if(item.ssc_isself !== "1" && item.authorisations > 0)
              return false
          }
          if (checkedServices.unauthorized !== true)
          {
            if(item.authorisations === 0)
              return false
          }
          return true;
        } )
      }
    },

    created () {
      const layout = {
        name: "None"
      };
      this.$store.commit('setLayout', layout)
      this.zoom = this.getZoom()

      this.$store.commit('setLayout', layout)

      //console.log(this.$route.params.sc, this.$store.state.serviceCenters)
    },

    async mounted() {
      let error = null;
      const  {status, data} = await httpClient.get('map/basic');
      if (status === 200) {
        this.dsCache = data;
        this.features = this.dsCache;
        this.selfDomains = this.dsCache
            .filter ( item => item.ssc_isself === "1")
            .map ( item => item.ssc_id )
        this.authorizedDomains = this.dsCache
            .filter ( item => item.authorisations > 0)
            .map ( item => item.ssc_id )
      } else {
        console.error(status,data, error)
      }
    }
  }
</script>

<style lang="scss" scoped>
    #basicMapView {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 400px;

    }

    #basicMapView:after {
        content: "";
        display: block;
        padding-bottom: 40%;
    }

    #basicMapView > div.map-section {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .form.selectors {
        display: flex;
        flex-wrap: wrap;
        padding-bottom:10px;
        .selector {
            padding: 0 20px;
            display: flex;
            min-width: 150px;
        }
    }
    ::v-deep .dx-checkbox-icon {
        font-family: "DXIcons" !important;
    }
    ::v-deep .checkbox-not-authorized.dx-checkbox-checked .dx-checkbox-icon {

        &::before {
            background-color: #FFBC93 !important;
        }

    }
    ::v-deep .checkbox-authorized.dx-checkbox-checked .dx-checkbox-icon {

        &::before {
            background-color: #57CC65 !important;
        }

    }
</style>