<template>
  <div class="row py-5 justify-content-center">
    <div class="col-md-4">
      <div class="note-white">
        <span class="note__square"></span>
        <span class="note__title">
          ИНСТАЛЛЯЦИЯ ПЕЧАТНЫХ УСТРОЙСТВ
        </span>
        <p class="note__text">
          Наши специалисты готовы осуществить распаковку, подключение, запуск и первичную настройку любой печатной
          техники.
        </p>
        <p>
          Эта услуга может быть полезна при:
        </p>
        <div class="list">
          <div class="list__icon"></div>
          <div class="list__content">
            <p class="list__content_text">
              пусконаладке промышленных печатных устройств
            </p>
          </div>
        </div>
        <!-- /.LIST BLOCK -->
        <div class="list">
          <div class="list__icon"></div>
          <div class="list__content">
            <p class="list__content_text">
              запуске устройств в удаленных офисах
            </p>
          </div>
        </div>
        <!-- /.LIST BLOCK -->
        <div class="list">
          <div class="list__icon"></div>
          <div class="list__content">
            <p class="list__content_text">
              массовом развертывании или замене парка устройств
            </p>
          </div>
        </div>
        <!-- /.LIST BLOCK -->
        <div class="list">
          <div class="list__icon last"></div>
          <div class="list__content">
            <p class="list__content_text">
              необходимости обеспечить квалифицированную и профессиональную инсталляцию
            </p>
          </div>
        </div>
        <!-- /.LIST BLOCK -->
      </div>
    </div>
    <div class="col-md-4">
      <div class="note-white">
        <span class="note__square"></span>
        <span class="note__title">
          ЗАЛИВКА И РАЗВЕРТЫВАНИЕ РАБОЧИХ МЕСТ ПОЛЬЗОВАТЕЛЕЙ
      </span>
        <p class="note__text">
          Мы оказываем услуги по развертыванию рабочих мест, начиная от установки операционных систем и заканчивая
          передачей устройства пользователям и переносом их данных.
        </p>
        <p>
          Данная услуга может пригодиться клиентам, планирующим замену парка персональных устройств или разворачивающим
          новые офисы, особенно в географически распределенных проектах.
        </p>
      </div>
    </div>
    <p class="paragraph">
      Если вас заинтересовали данные услуги, пришлите запрос на <a class="link" href="mailto:sale@rus.support">sale@rus.support</a>
      и мы предоставим вам предложение
    </p>
  </div>
</template>

<script>
export default {
  name: "HpUpgradeContent"
}
</script>

<style lang="scss" scoped>
.note-white {
  max-width: 100%;
}

.paragraph {
  text-align: center;
  padding-top: 1rem;
}
</style>
