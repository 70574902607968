import Vue from 'vue';

import homeSlide1Content from "./content/homeSlide1Content.vue";
import homeSlide2Content from "./content/homeSlide2Content.vue";
import hpGuaranteeSliderContent from "./content/hpGuaranteeSliderContent";
import hpCarepackSliderContent from "./content/hpCarepackSliderContent";
import hpContractsSliderContent from "./content/hpContractsSliderContent";
import hpPostguaranteeSliderContent from "./content/hpPostguaranteeSliderContent";
import hpSparepartsSliderContent from "./content/hpSparepartsSliderContent";
import hpWidescreenSliderContent from "./content/hpWidescreenSliderContent";
import hpUpgradeSliderContent from "./content/hpUpgradeSliderContent";
import hpDaasSliderContent from "./content/hpDaasSliderContent";
import samsungGuaranteeSliderContent from "./content/samsungGuaranteeSliderContent";
import arubaServiceSliderContent from "./content/arubaServiceSliderContent";
import hpeGuaranteeSliderContent from "./content/hpeGuaranteeSliderContent";
import hpePackagedSliderContent from  "./content/hpePackagedSliderContent";
import hpeContractsSliderContent from "./content/hpeContractsSliderContent";
import hpeUpgradeSliderContent from "./content/hpeUpgradeSliderContent";
import hpeProactiveSliderContent from "./content/hpeProactiveSliderContent";
import othersOutsourceSliderContent from "./content/othersOutsourceSliderContent";
import othersServersSliderContent from "./content/othersServersSliderContent";
import othersApcSliderContent from "./content/othersApcSliderContent";
import othersCoursesSliderContent from "./content/othersCoursesSliderContent";
import hpSlideContent from "./content/hpSlideContent";
import hpeSlideContent from "./content/hpeSlideContent";
import arubaSliderContent from "./content/arubaSliderContent";
import samsungSliderContent from "@/components/layouts/LayoutSlider/slideTemplates/content/samsungSliderContent";
import apcSliderContent from "@/components/layouts/LayoutSlider/slideTemplates/content/apcSliderContent";
import hpOutsourceprintSliderContent from "./content/hpOutsourceprintSliderContent";
import avisionSlideConent from './content/avisionSlideContent.vue';

Vue.component('homeSlide1Content', homeSlide1Content);
Vue.component('homeSlide2Content', homeSlide2Content);
Vue.component('hpGuaranteeSliderContent', hpGuaranteeSliderContent);
Vue.component('hpCarepackSliderContent', hpCarepackSliderContent);
Vue.component('hpContractsSliderContent', hpContractsSliderContent);
Vue.component('hpPostguaranteeSliderContent', hpPostguaranteeSliderContent);
Vue.component('hpSparepartsSliderContent', hpSparepartsSliderContent);
Vue.component('hpWidescreenSliderContent', hpWidescreenSliderContent);
Vue.component('hpUpgradeSliderContent', hpUpgradeSliderContent);
Vue.component('hpDaasSliderContent', hpDaasSliderContent);
Vue.component('samsungGuaranteeSliderContent', samsungGuaranteeSliderContent);
Vue.component('arubaServiceSliderContent', arubaServiceSliderContent);
Vue.component('hpeGuaranteeSliderContent', hpeGuaranteeSliderContent);
Vue.component('hpePackagedSliderContent', hpePackagedSliderContent);
Vue.component('hpeContractsSliderContent', hpeContractsSliderContent);
Vue.component('hpeUpgradeSliderContent', hpeUpgradeSliderContent);
Vue.component('hpeProactiveSliderContent', hpeProactiveSliderContent);
Vue.component('othersOutsourceSliderContent', othersOutsourceSliderContent);
Vue.component('othersServersSliderContent', othersServersSliderContent);
Vue.component('othersApcSliderContent', othersApcSliderContent);
Vue.component('othersCoursesSliderContent', othersCoursesSliderContent);
Vue.component('hpSlideContent', hpSlideContent);
Vue.component('hpeSlideContent', hpeSlideContent);
Vue.component('arubaSliderContent', arubaSliderContent);
Vue.component('samsungSliderContent', samsungSliderContent);
Vue.component('apcSliderContent', apcSliderContent);
Vue.component('hpOutsourceprintSliderContent', hpOutsourceprintSliderContent);
Vue.component('avisionSlideConent', avisionSlideConent);