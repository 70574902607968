<template>
  <div class="terms-accordion__item-content">
    <p>9.1. Если оборудование не имеет подтверждённых гарантийных обязательств, неисправность не попадает под условия
      гарантии производителя или определенная услуга не входит в состав гарантийных обязательств, то СЦ может выполнить
      сервисное обслуживание на платной основе.</p>
    <p>9.2. Срок выполнения сервисного обслуживания зависит в том числе и от наличия запасных частей на локальных
      складах СЦ или его поставщиков. Ожидаемый срок ремонта в каждом конкретном случае согласовывается с Заказчиком до
      заказа запасных частей. Как правило срок такого негарантийного ремонта составляет до 20 рабочих дней, но в
      некоторых случаях может быть увеличен на срок до 60 рабочих дней, о чём СЦ обязуется уведомить Заказчика
      своевременно.</p>
    <p>9.3. По результатам диагностики Заказчику озвучиваются стоимость и ожидаемые сроки выполнения сервисного
      обслуживания, с учётом сроков поставки необходимых для такого обслуживания запасных частей. Данное предложение
      актуально в течение трех рабочих дней. Работы выполняются на условиях 100% предоплаты. В случае, если Заказчик
      производит оплату позднее этого срока, то стоимость и сроки могут измениться. Фактом оплаты считается поступление
      полной суммы на расчётный счёт СЦ, или предоставление копии банковского платёжного поручения, подтверждающего
      перевод средств, или успешное прохождение транзакции при оплате банковской картой непосредственно в СЦ.</p>
    <p>9.4. В случае отказа от ремонта Заказчик обязан оплатить стоимость уже выполненных с оборудованием работ и
      использованных материалов и запасных частей. Оборудование возвращается Заказчику только после предоставления
      документов об оплате уже выполненных СЦ работ или поступления оплаты.</p>
    <p>9.5. Гарантия составляет 90 календарных дней (если иное не указано в карточке ремонта (для работ, проводившихся в
      СЦ) или в акте выездного обслуживания (при выездных работах)) и исчисляется для работ по сервисному обслуживанию
      &#8212; с момента окончания работ и для замененных запасных частей – с момента их установки . Гарантия
      распространяется на проведенные СЦ работы и замененные комплектующие, при условии отсутствия на оборудовании
      следов механических и электрических повреждений, проникновения жидкостей, иных нарушений условий эксплуатации,
      возникших после выдачи оборудования из ремонта. . Гарантийные требования принимаются лишь в течение указанного
      гарантийного срока.</p>
    <p>9.6. СЦ может признать оборудование неремонтопригодным и сообщить о невозможности ремонта Заказчику, например, по
      причине отсутствия необходимых запасных частей и недоступности их к заказу. При этом уже выполненные СЦ работы по
      сервисному обслуживанию подлежат оплате Заказчиком в полном объёме.</p>
    <p>9.7. СЦ при приёмке оборудования не даёт предварительных гарантий относительно ожидаемых результатов и сроков
      ремонта. Стоимость ремонта (включая стоимость работ и запчастей) может быть установлена только после проведения
      диагностики. СЦ не гарантирует одинаковую стоимость одних и тех же запасных частей, заказанных в рамках разных
      ремонтов.</p>
  </div>
</template>

<script>
export default {
  name: "serviceGuarantee2017"
}
</script>

<style scoped>

</style>