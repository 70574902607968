<template>
    <div id="mobileMenu">

        <div class="menu-wrapper" >

            <div align="center" class="menu-area selected"
                 :class="{ open: open, closed: !open}"
                 >

                <div class="menu-button"
                     @click="open = !open"
                >
                    <div class="part1"></div>
                    <div class="part2"></div>
                    <div class="part3"></div>
                </div>

                <div class="toggle-area" v-if="open" @click="open = false"></div>

                <div class="menu-items" id="focusArea" :class="{ selectHide: !open }">
                    <div class="items-area" >
                        <slot name="headerMenu" ></slot>
                        <slot name="mobileLinks" ></slot>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
  export default {
    name: "mobileMenu",
    data () {
      return {
        open: false
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>

    #mobileMenu {
        height: 100%;
        width: 100%;


        .menu-wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .menu-area {
                //position: relative;
                position: absolute;
                height: 60px;
                //width: 10px;
                right: 5px;

                @media screen and (max-width: 430px) and (min-width: 400px) {
                  //height: 60px;
                  //width: 0;
                }

                .toggle-area {
                    position: fixed;
                    top:0;
                    left:0;
                    width: 100vw;
                    height: 100vh;
                    z-index:2;
                    background-color: transparent;
                }

                &.closed {
                    background-color: transparent !important;
                }

                &.open {
                    //background-color: $base-color-dark;
                    background-color: #006fcd;
                    outline: none;
                    border:0;
                    border-radius: 10px 10px 0 0 ;
                }

                .menu-items {
                    position: absolute;
                    top: 50px;
                    right:0;
                    z-index: 3;

                    .items-area {
                        position: static;
                        //background: $base-color-dark;
                        background: #006fcd;
                        width: 150px;
                        border:0;
                        border-radius: 10px 0 10px 10px;
                        box-shadow: 0 10px 10px rgba(0,0,0,.5);
                    }

                    &:focus {
                        background-color: $base-color-dark;
                        outline: none;
                        border:0;
                        border-radius: 10px;
                    }
                }

                .menu-button {
                    //padding: 4px 10px;
                    padding: 4px 10px;

                    & > div {
                        width:25px;
                        height:5px;
                        margin:3px;
                        background-color:white;
                    }

                }
            }

        }



    }
    .selectHide {
        display: none;
    }
</style>