<template>
    <div id="layout">
        <BaseLayout>

            <template v-slot:content>
                <slot></slot>
            </template>

        </BaseLayout>
    </div>
</template>

<script>
  import BaseLayout from "../BaseLayout"
  export default {
    name: "MainLauout",
    components: {BaseLayout}
  }
</script>

<style scoped>

</style>