<template>
  <div class="container py-4 equipment">
    <h2>Инсталляция устройств Катюша</h2>
    <p>
      Сервис по установке и первоначальной настройке широкоформатных устройств Катюша.
    </p>
    <img class="equipment-image m348_image" src="/img/certificate/katusha_m348.jpg" alt="">
    <p>
      <b>Обслуживаемое оборудование:</b>
    </p>
    <div class="equipment-serviced-wrapper">
      <p class="equipment-serviced">Катюша M348</p>
      <p class="equipment-serviced">Sindoh 330/332</p>
    </div>
    <p>
      <b>География обслуживания:</b> Ижевск, Иркутск, Казань, Киров, Краснодар, Москва, Нижний Новгород, Нижневартовск, Новороссийск, Новосибирск, Петрозаводск, Петропавловск-Камчатский, Самара, Санкт-Петербург, Саратов, Сургут, Улан-Удэ, Ульяновск, Хабаровск. В пределах дистанции 50 км от сервисного центра Rus.Support по дорогам общего пользования.
    </p>
    <p>
      География обслуживания может меняться с течением времени.
    </p>
    <p>
      <b>Уровень сервиса:</b> дата и время проведения установки подлежат согласованию. Начало работ по установке - в
      течение 5 рабочих дней с момента подтверждения готовности оборудования и помещения к проведению работ.
    </p>
    <p>
      <b>Состав сервиса: </b>
    </p>
    <ul>
      <li>Выезд квалифицированного специалиста</li>
      <li>Проверка комплектности оборудования</li>
      <li>Перемещение оборудования к месту установки*</li>
      <li>Распаковка и размещение оборудования в пределах помещения установки*
      <li>Подключение сетевых кабелей и кабелей питания</li>
      <li>Включение и инициализация устройства</li>
      <li>Базовая настройка и проверка работоспособности оборудования</li>
      <li>Проведение краткой демонстрации режимов работы и основных пользовательских сервисных операций.</li>
    </ul>
    <p class="equipment-note">
      * применимо только для сервисного пакета с входящей услугой «Запуск с подъемом/перемещением устройства
    </p>
    <p>
      <b>Перечень услуг, входящих в сервисный пакет:</b>
    </p>
    <table class="table table-bordered">
      <tbody>
      <tr>
        <td></td>
        <td>ISA3KT-1</td>
        <td>ISA3KT-2</td>
      </tr>
      <tr>
        <td>
          1. Запуск без подъёма/перемещения устройства или при условии такелажа силами клиента
        </td>
        <td class="ok-check-mark">√</td>
        <td class="ok-check-mark"></td>
      </tr>
      <tr>
        <td>2. Запуск с подъёмом/перемещением устройства</td>
        <td class="ok-check-mark"></td>
        <td class="ok-check-mark">√</td>
      </tr>
      </tbody>
    </table>
    <p>
      <b>Ограничения сервиса:</b>
    </p>
    <p>
      Сервисные услуги оказываются по рабочим дням и в рабочее время. Клиент обеспечивает возможность непрерывного
      выполнения работ в пределах рабочего времени. Исполнение по графику, имеющему перерывы/простои, равно как и
      исполнение во внерабочие часы/нерабочие дни возможно только по предварительному согласованию с Rus.Support и может
      потребовать дополнительной оплаты.
    </p>
    <p>
      Перед началом работ Rus.Support направляет клиенту требования к подготовке помещения для установки оборудования
      (если это применимо для конкретного оборудования), которые должны быть выполнены клиентом до начала проведения
      работ.
    </p>
    <p>
      Клиент обеспечивает беспрепятственный доступ специалистов Rus.Support к месту установки оборудования.
    </p>
    <p>
      Клиент самостоятельно и за свой счет предоставляет материалы и оборудование необходимые для запуска устройства, и
      не входящие в комплект поставки (например, бумагу, картриджи, рабочую станцию для проверки работы оборудования из
      сети заказчика и т.д.).
    </p>
    <p>
      Перемещение оборудования к месту установки (услуга 2.) производится только в пределах одного здания, при наличии
      достаточных габаритов проёмов по пути перемещения для прохождения устройства в заводской упаковке, а при
      необходимости перемещения между этажами только при обязательном наличии действующего грузового лифта.
    </p>
    <p>
      Если производится инсталляция нескольких устройств по одному адресу, то демонстрации режимов работы и основных
      пользовательских сервисных операций производится только для одного из устройств.
    </p>
    <p>
      Срок действия сертификата 1 год с даты выпуска, указанной на сертификате.
    </p>
  </div>
</template>

<script>
export default {
  name: "ISA3KT",
  created() {
    const layout = {
      name: "Main",
    };
    this.$store.commit('setLayout', layout)
  },
}
</script>

<style lang="scss" scoped>
.m348_image {

}
</style>