<template>
  <div id="locationInput" class="input-area">

      <div class="city-input">
          <div class="offset-placeholder"></div>
          <input
                  size="20"
                  v-model="inputValue"
                  @keydown.enter='enter'
                  @keydown.down='down'
                  @keydown.up='up'
                  @input='change($event)'
                  @focusout="focusout()"
          >
      </div>


    <ul class="dropdown-list" v-bind:class="{'open':openSuggestion}">
      <li v-for="(suggestion,index) in suggestions"
          :key="index"
          v-bind:class="{'active': isActive(index)}"
      >
        <a href="#"
           @mousedown.prevent
           @click.prevent="suggestionClick(suggestion)"
        >
          {{ suggestion.value }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "locationInput",
  props: ["currentLocation"],
  data() {
    return {
      open: false,
      current: 0,
      suggestions: [],
      selectedValue: this.currentLocation,
      inputValue: this.currentLocation.name,
      suggestionTimeout: null
    }
  },
  computed: {
    openSuggestion() {
      return this.suggestions.length != 0 &&
          this.open === true;
    }
  },
  watch: {
    selectedValue(location) {
      const locationCoords = [location.geo_lat, location.geo_lon]
      this.$emit("locationChanged", {
        coords: locationCoords,
        name: location.city
      })
    }
  },
  methods: {
    enter() {
      this.selectedValue = this.suggestions[this.current].data;
      this.inputValue = this.suggestions[this.current].data.city;
      this.open = false;
    },

    up() {

      if (this.current > 0)
        this.current--;

        this.inputValue = this.suggestions[this.current].data.city;
    },

    down() {

      if (this.current < this.suggestions.length - 1)
        this.current++;

        this.inputValue = this.suggestions[this.current].data.city;



        //console.log("down", this.current)
    },

    isActive(index) {
        //console.log(index)
      return index === this.current;
    },

    change(event) {
      clearTimeout(this.suggestionTimeout)
      this.suggestionTimeout = setTimeout(
          () => this.loadSuggestions(event.target.value)
          , 1000
      )
    },

    loadSuggestions(data) {
      const that = this;
      const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
      const token = "97e8144d39f1fe765f7217eabc624a79f76526e7";

      let config = {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
        }
      };

      let params = {
        "query": data,
        "from_bound": {"value": "city"},
        "to_bound": {"value": "city"},
        "locations": [
          {
            "city_type_full": "город"
          }
        ],
        "restrict_value": true
      };

      axios.post(url, JSON.stringify(params), config)
          .then((response) => {
                that.open = true;
                that.suggestions = response.data.suggestions;
              }
          )
    },

    suggestionClick(suggestion) {
      this.selectedValue = suggestion.data;
      this.inputValue = suggestion.data.city;
      this.open = false;
    },

    focusout() {
      this.open = false;
    },
      submitForm (event) {
        event.preventDefault()
        //console.log(event)
      }
  }
}

</script>

<style lang="scss" scoped>
#locationInput {
    position: relative;
    //height: 100%;

    .city-input {
        border: 0;
        display: flex;

        input {
            outline: none!important;
            width: 100%;
            border: none;
            border-radius: 15px;

            &:focus {
                outline: none!important;
            }
        }

        .offset-placeholder {
            width: 85px;
            background: url("/img/icons/geo2.svg") no-repeat;
            background-size: 23px;
            background-position: center;
            border-right: 1px solid #0071ba3d;

            @media screen and (max-width: 1199px) {
              background-size: 18px;
            }

            @media screen and (max-width: 420px) {
              display: none;
            }
        }

        input {
            padding-left: 20px;
            font-family: "HP Simplified";
            font-size: 18px;

          @media screen and (max-width: 1199px){
            font-size: 16px;
          }
        }
    }

    .dropdown-list {
        position: absolute;
        list-style: none;
        background-color: white;
        width: 100%;
        max-height: 300px;
        overflow-y: hidden;
        z-index: 10;
        top: 70px;
        display: none;
        text-align: left;
        padding: 0;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.14);
        border-radius: 5px;

        &:hover {
            overflow-y: auto;
        }

        &::-webkit-scrollbar {
            width: 0.5em;
        }

        &::-webkit-scrollbar-track {
            background-color: #e9e9e9;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #bcbcbc;
            border-radius: 10px;
        }

        &.open {
            display: block;
        }

        li.active {
            background-color: #dcdcdc;
        }

        li {

            border-bottom: 1px solid #dcdcdc;

            &:hover {
                background-color: #dcdcdc;
            }

            a {
                text-decoration: none;
                color: #292b2c;
                display: block;
                width:100%;
                height: 100%;
                padding: 10px 15px;
            }

            &:first-child {
                border-radius: 5px 5px 0 0;
            }

            &:last-child {
                border-radius: 0 0 5px 5px;
                border-bottom: none;
            }
        }
    }
}




</style>