<template>
    <div class="privacy-accordion__item-content">
        <!--    <p>
              2.1 Положение о порядке хранения и защиты персональных данных Пользователей Сайта (далее — Положение) разработано
              с
              целью соблюдения требований законодательства РФ, содержащих персональные данные и идентификации Пользователей,
              находящихся на Сайте.
            </p>

            <p>
              2.2. Положение разработано в соответствии с Конституцией РФ, Гражданским кодексом РФ, действующим
              законодательством
              РФ в области защиты персональных данных. Оператор обеспечивает защиту обрабатываемых персональных данных от
              несанкционированного доступа и разглашения, неправомерного использования или утраты в соответствии с требованиями
              Федерального закона от 27 июля 2006 г. № 152-ФЗ «О персональных данных».
            </p>

            <p>
              2.3. Положение устанавливает порядок обработки персональных данных Пользователей Сайта: действия по сбору,
              систематизации, накоплению, хранению, уточнению (обновлению, изменению), уничтожению персональных данных
            </p>

            <p>
              2.4. Положение устанавливает обязательные для сотрудников Оператора, задействованных в обслуживании Сайта, общие
              требования и правила по работе со всеми видами носителей информации, содержащими персональные данные Пользователей
              Сайта.
            </p>

            <p>
              2.5. В Положении не рассматриваются вопросы обеспечения безопасности персональных данных, отнесенных в
              установленном
              порядке к сведениям, составляющим государственную тайну Российской Федерации.
            </p>

            <p>2.6. Целями Положения являются:</p>
            <ul class="content-list">
              <li>
                обеспечение требований защиты прав и свобод человека и гражданина при обработке персональных данных, в том
                числе
                защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
              </li>
              <li>
                исключение несанкционированных действий сотрудников Оператора и любых третьих лиц по сбору, систематизации,
                накоплению, хранению, уточнению (обновлению, изменению) персональных данных, иных форм незаконного вмешательства
                в
                информационные ресурсы и локальную вычислительную сеть Оператора, обеспечение правового и нормативного режима
                конфиденциальности недокументированной информации Пользователей Сайта; защита конституционных прав граждан на
                личную
                тайну, конфиденциальность сведений, составляющих персональные данные, и предотвращение возникновения возможной
                угрозы безопасности Пользователей Сайта.
              </li>
            </ul>


            <p>2.7. Принципы обработки персональных данных:</p>

            <ul class="content-list">
              <li>обработка персональных данных должна осуществляться на законной и справедливой основе;</li>
              <li>обработка персональных данных должна ограничиваться достижением конкретных, заранее определенных и законных
                целей.
                Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных;
              </li>
              <li>
                не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях,
                несовместимых между собой;
              </li>
              <li>
                обработке подлежат только персональные данные, которые отвечают целям их обработки;
              </li>
              <li>
                содержание и объем обрабатываемых персональных данных должны соответствовать заявленным целям обработки.
                Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки;
              </li>
              <li>
                при обработке персональных данных должны быть обеспечены точность персональных данных, их достаточность, а в
                необходимых случаях и актуальность по отношению к целям обработки персональных данных;
              </li>
              <li>
                хранение персональных данных должно осуществляться не дольше, чем этого требуют цели обработки персональных
                данных,
                если срок хранения персональных данных не установлен Федеральным законом, договором, стороной которого является
                Пользователь;
              </li>
              <li>
                обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в
                случае утраты необходимости в достижении этих целей, если иное не предусмотрено Федеральным законом.
              </li>
            </ul>
            <p>
              2.8. Условия обработки персональных данных
            </p>
            <p>
              2.8.1. Обработка персональных данных Пользователей Сайта осуществляется на основании Гражданского кодекса РФ,
              Конституции РФ, действующего законодательства РФ в области защиты персональных данных
            </p>
            <p>
              2.8.2. Обработка персональных данных на Сайте осуществляется с соблюдением принципов и правил, предусмотренных
              Положением и законодательством РФ.
            </p>
            <p>2.9. Цели обработки персональных данных.</p>
            <p>
              2.9.1. Обработка персональных данных Пользователей Сайта осуществляется в целях заключения и/или исполнения
              договора
              между Пользователем и Оператором, регистрации на мероприятия, подачи заявок на обратный звонок, заполнение анкеты
              соискателя на вакансии Оператора, оптимизации рекламы.
            </p>
            <p>2.10. Источники получения персональных данных Пользователей.</p>
            <p>2.10.1.Источником информации обо всех персональных данных Пользователя является непосредственно сам
              Пользователь.
            </p>
            <p>
              2.10.2.Источником информации о персональных данных Пользователя являются сведения, полученные вследствие внесения
              Пользователем своих данных в учетные формы.
            </p>
            <p>
              2.10.2.Источником информации о персональных данных Пользователя являются сведения, полученные вследствие внесения
              Пользователем своих данных в учетные формы.
            </p>
            <p>
              2.10.3.Персональные данные Пользователей относятся к конфиденциальной информации ограниченного доступа
            </p>
            <p>
              2.10.4.Обеспечения конфиденциальности персональных данных не требуется в случае их обезличивания, а также в
              отношении общедоступных персональных данных.
            </p>

            <p>2.10.5.Оператором ведется обезличенная обработка следующих параметров:</p>
            <ul class="content-list">
              <li>
                источник захода на Сайт и информация поискового или рекламного запроса;
              </li>
              <li>
                данные о пользовательском устройстве (среди которых разрешение, версия и другие атрибуты, характеризующие
                пользовательское устройство);
              </li>
              <li>
                пользовательские клики, просмотры страниц, заполнения полей, показы и просмотры баннеров и видео;
              </li>
              <li>
                данные, характеризующие аудиторные сегменты;
              </li>
              <li>
                параметры сессии;
              </li>
              <li>
                данные о времени посещения;
              </li>
              <li>
                идентификатор пользователя, хранимый в cookie.
              </li>
            </ul>

            <p>
              2.10.6.Оператор не имеет права собирать и обрабатывать персональные данные Пользователя о его расовой,
              национальной
              принадлежности, политических взглядах, религиозных или философских убеждениях, частной жизни, за исключением
              случаев, предусмотренных действующим законодательством.
            </p>
            <p>
              2.10.7.Оператор не имеет права получать и обрабатывать персональные данные Пользователя о его членстве в
              общественных объединениях или его профсоюзной деятельности, за исключением случаев, предусмотренных Федеральным
              законом.
            </p>
            <p>2.11. Способы обработки персональных данных.</p>
            <p>2.11.1.Персональные данные Пользователей Сайта обрабатываются исключительно с использованием средств
              автоматизации.
            </p>
            <p>2.12. Права субъектов (Пользователей) персональных данных</p>
            <p>
              2.12.1.Пользователь имеет право на получение сведений об Операторе, о месте его нахождения, о наличии у Оператора
              персональных данных, относящихся к конкретному субъекту персональных данных (Пользователю), а также на
              ознакомление
              с такими персональными данными, за исключением случаев, предусмотренных частью 8 статьи 14 Федерального закона «О
              персональных данных».
            </p>

            <p>
              2.12.2.Пользователь имеет право на получение от Оператора при личном обращении к нему либо при получении
              Оператором
              письменного запроса от Пользователя следующей информации, касающейся обработки его персональных данных, в том
              числе
              содержащей:
            </p>

            <ul class="content-list">
              <li>подтверждение факта обработки персональных данных Оператором, а также цель такой обработки;</li>
              <li>правовые основания и цели обработки персональных данных;</li>
              <li>цели и применяемые Оператором способы обработки персональных данных;</li>
              <li>
                наименование и место нахождения Оператора, сведения о лицах (за исключением работников оператора), которые имеют
                доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с
                Оператором
                или на основании Федерального закона;
              </li>
              <li>
                обрабатываемые персональные данные, относящиеся к соответствующему субъекту персональных данных, источник их
                получения, если иной порядок предоставления таких данных не предусмотрен Федеральным законом;
              </li>
              <li>сроки обработки персональных данных, в том числе сроки их хранения;</li>
              <li>порядок осуществления субъектом персональных данных прав, предусмотренных Федеральным законом;</li>
              <li>информацию об осуществленной или о предполагаемой трансграничной передаче данных;</li>
              <li>наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по
                поручению
                Оператора, если обработка поручена или будет поручена такому лицу;
              </li>
              <li>иные сведения, предусмотренные Федеральным законом или другими федеральными законами;</li>
              <li>требовать изменения, уточнения, уничтожения информации о самом себе;
                обжаловать неправомерные действия или бездействие по обработке персональных данных и требовать соответствующей
                компенсации в суде;
              </li>
              <li>
                на дополнение персональных данных оценочного характера заявлением, выражающим его собственную точку зрения;
              </li>
              <li>
                определять представителей для защиты своих персональных данных;
              </li>
              <li>
                требовать от Оператора уведомления обо всех произведенных в них изменениях или исключениях из них.
              </li>
            </ul>
            <p>
              2.12.3.Пользователь имеет право обжаловать в уполномоченном органе по защите прав субъектов персональных данных
              или
              в судебном порядке действия или бездействие Оператора, если считает, что последний осуществляет обработку его
              персональных данных с нарушением требований Федерального закона «О персональных данных» или иным образом нарушает
              его права и свободы.
            </p>
            <p>
              2.12.4.Пользователь персональных данных имеет право на защиту своих прав и законных интересов, в том числе на
              возмещение убытков и (или) компенсацию морального вреда в судебном порядке.
            </p>
            <p>2.13. Обязанности Оператора.</p>
            <p>
              2.13.1.По факту личного обращения либо при получении письменного запроса субъекта персональных данных или его
              представителя Оператор, при наличии оснований, обязан в течение 30 дней с даты обращения либо получения запроса
              субъекта персональных данных или его представителя предоставить сведения в объеме, установленном Федеральным
              законом. Такие сведения должны быть предоставлены субъекту персональных данных в доступной форме, и в них не
              должны
              содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, если
              имеются законные основания для раскрытия таких персональных данных.
            </p>
            <p>
              2.13.2.Все обращения субъектов персональных данных или их представителей регистрируются в Журнале учета обращений
              граждан (субъектов персональных данных) по вопросам обработки персональных данных.
            </p>
            <p>
              2.13.3.В случае отказа в предоставлении субъекту персональных данных или его представителю при обращении либо при
              получении запроса субъекта персональных данных или его представителя информации о наличии персональных данных о
              соответствующем субъекте персональных данных Оператор обязан дать в письменной форме мотивированный ответ,
              содержащий ссылку на положение части 8 статьи 14 Федерального закона «О персональных данных» или иного
              федерального
              закона, являющееся основанием для такого отказа, в срок, не превышающий 30 дней со дня обращения субъекта
              персональных данных или его представителя, либо с даты получения запроса субъекта персональных данных или его
              представителя.
            </p>
            <p>
              2.13.4.В случае получения запроса от уполномоченного органа по защите прав субъектов персональных данных о
              предоставлении информации, необходимой для осуществления деятельности указанного органа, Оператор обязан сообщить
              такую информацию в уполномоченный орган в течение 30 дней с даты получения такого запроса.
            </p>
            <p>
              2.13.5.В случае выявления неправомерной обработки персональных данных при обращении или по запросу субъекта
              персональных данных или его представителя либо уполномоченного органа по защите прав субъектов персональных данных
              Оператор обязан осуществить блокирование неправомерно обрабатываемых персональных данных, относящихся к этому
              субъекту персональных данных, с момента такого обращения или получения указанного запроса на период проверки.
            </p>
            <p>
              2.13.6.В случае выявления неправомерной обработки персональных данных, осуществляемой Оператором, последний в
              срок,
              не превышающий трех рабочих дней с даты этого выявления, обязан прекратить неправомерную обработку персональных
              данных. Об устранении допущенных нарушений Оператор обязан уведомить субъекта персональных данных или его
              представителя, а в случае если обращение субъекта персональных данных или его представителя либо запрос
              уполномоченного органа по защите прав субъектов персональных данных были направлены уполномоченным органом по
              защите
              прав субъектов персональных данных, также указанный орган.
            </p>
            <p>
              2.13.7.В случае достижения цели обработки персональных данных Оператор обязан прекратить обработку персональных
              данных и уничтожить персональные данные в срок, не превышающий 30 рабочих дней с даты достижения цели обработки
              персональных данных, если иное не предусмотрено договором, стороной которого является субъект персональных данных.
            </p>
            <p>
              2.13.8.Запрещается принятие на основании исключительно автоматизированной обработки персональных данных решений,
              порождающих юридические последствия в отношении субъекта персональных данных или иным образом затрагивающих его
              права и законные интересы.
            </p>
            <p>
              2.14. Режим конфиденциальности персональных данных.
            </p>
            <p>
              2.14.1.Оператор обеспечивает конфиденциальность и безопасность персональных данных при их обработке в соответствии
              с
              требованиями законодательства РФ.
            </p>
            <p>
              2.14.2.Оператор не раскрывает третьим лицам и не распространяет персональные данные без согласия на это субъекта
              персональных данных, если иное не предусмотрено Федеральным законом.
            </p>
            <p>
              2.14.3.В соответствии с перечнем персональных данных, обрабатываемых на сайте, персональные данные Пользователей
              Сайта являются конфиденциальной информацией.
            </p>-->
        <h2>2. Общие положения</h2>
        <p>2.1. В рамках настоящей Политики конфиденциальности под персональной информацией Пользователя понимаются:</p>
        <p>
            2.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации
            (создании учетной записи) или в процессе использования Сервисов, включая персональные данные Пользователя.
            Обязательная для предоставления Сервисов информация помечена специальным образом.
        </p>
        <p>
            2.1.2. Данные, которые автоматически передаются сервисам Сайта в процессе их использования с помощью
            установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные Файлов
            Cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к
            сервисам), технические характеристики оборудования и программного обеспечения, используемых Пользователем,
            дата и время доступа к сервисам, адреса запрашиваемых страниц и иная подобная информация.
        </p>
        <p>
            2.1.3. Настоящая Политика конфиденциальности применяется только к Сайту https://rus.support. Сайт
            https://rus.support не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь
            может перейти по ссылкам, доступным на Сайте https://rus.support.
        </p>
        <p>
            2.2. Настоящая Политика конфиденциальности разработана с целью соблюдения требований законодательства РФ,
            содержащих персональные данные и идентификации Пользователей, находящихся на Сайте.
        </p>
        <p>
            2.3. Настоящая Политика конфиденциальности разработана в соответствии со следующими нормативными и
            методическими документами:
        </p>
        <p>
            - Конституция Российской Федерации; <br>
            - Трудовой кодекс Российской Федерации; <br>
            - Федеральный закон от 27 июля 2006 г. № 152-ФЗ «О персональных данных»; <br>
            - Федеральный закон от 21.07.2014 г. № 242-ФЗ «О внесении изменений в отдельные законодательные акты
            Российской
            Федерации в части уточнения порядка обработки персональных данных в информационно-телекоммуникационных
            сетях»;
            <br>
            - Федеральный закон от 27.07.2006 г. № 149-ФЗ «Об информации, информационных технологиях и о защите
            информации»;
            <br>
            - Постановление Правительства Российской Федерации от 15 сентября 2008 г. № 687 «Об утверждении Положения об
            особенностях обработки персональных данных, осуществляемой без использования средств автоматизации»;
            <br>
            - Постановление Правительства Российской Федерации от 01 ноября 2012 г. № 1119 «Об утверждении требований к
            защите персональных данных при их обработке и информационных системах персональных данных»;
            <br>
            - Постановление Правительства Российской Федерации от 06.07.2008 г. № 512 «Об утверждении требований к
            материальным носителям биометрических персональных данных и технологиям хранения таких данных вне
            информационных
            систем персональных данных»;
            <br>
            - Приказ ФСТЭК России от 18 февраля 2013 г. № 21 «Об утверждении состава и содержания организационных и
            технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах
            персональных данных»;
            <br>
            - Указ Президента Российской Федерации от 06 марта 1997 г. № 188 «Об утверждении Перечня сведений
            конфиденциального характера».
            <br>
        </p>
        <p>2.4. Целями Политики конфиденциальности являются:</p>
        <p>
            - обеспечение требований защиты прав и свобод человека и гражданина при обработке персональных данных, в том
            числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну; <br>
            - исключение несанкционированных действий сотрудников Оператора и любых третьих лиц по сбору,
            систематизации,
            накоплению, хранению, уточнению (обновлению, изменению) персональных данных, иных форм незаконного
            вмешательства
            в информационные ресурсы и локальную вычислительную сеть Оператора, обеспечение правового и нормативного
            режима
            конфиденциальности недокументированной информации Пользователей Сайта; защита конституционных прав граждан
            на
            личную тайну, конфиденциальность сведений, составляющих персональные данные, и предотвращение возникновения
            возможной угрозы безопасности Пользователей Сайта. <br>
        </p>

        <p>2.5. Источники получения персональных данных Пользователей:</p>
        <p>
            2.5.1. Источником информации обо всех персональных данных Пользователя является непосредственно сам
            Пользователь.
        </p>
        <p>
            2.5.2. Источником информации о персональных данных Пользователя являются сведения, полученные вследствие
            внесения Пользователем своих данных в учетные формы.
        </p>
    </div>
</template>

<script>
export default {
    name: "generalProvisions"
}
</script>

<style lang="scss" scoped>
.content-list {
    list-style: none;
}

.content-list > li {
    &::before {
        content: "- ";
    }
}
</style>