<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      СЕРВИСНЫЕ КОНТРАКТЫ
      НА ОБОРУДОВАНИЕ HPE
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Для тех клиентов, у кого есть достаточно большой парк оборудования HPE, выгоднее приобретать сервис не в виде
      набора пакетов НРЕ Packaged Services, а в виде сервисных контрактов
    </p>
  </div>
</template>

<script>
export default {
  name: "hpeContractsSliderContent"
}
</script>

<style scoped>

</style>