<template>
  <div class="hp">
    <!-- Content start -->
    <div class="container list-service-wrap">
      <PrivacyContent/>
    </div>
    <!-- Content end -->
  </div>
</template>

<script>

import PrivacyContent from "./PrivacyContent";

export default {
  name: "Privacy",
  created() {
    const layout = {
      name: "Main",
    };
    this.$store.commit('setLayout', layout)
  },
  components: {
    PrivacyContent
  }
}
</script>

<style>

</style>