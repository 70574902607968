<template>
  <div class="slider-content">
    <h2 class="slider-content__title">
      ИНСТАЛЛЯЦИЯ УСТРОЙСТВ HP INC.
    </h2>
    <div class="slider-separator"></div>
    <p class="slider-content__text">
      Мы проводим установку и развертывание любых устройств HP Inc.
    </p>
  </div>
</template>

<script>
export default {
  name: "hpUpgradeSliderContent"
}
</script>

<style scoped>

</style>